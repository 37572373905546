import { AxiosError } from "axios";
import type { Failure } from "~/src/api";

export function create404Error(message: string) {
  return createError({
    statusCode: 404,
    statusMessage: "404 - Not found",
    message,
  });
}

const pathMatch = /^\/?(?<entity>[^/]+)\/(?<id>[^/]+)/i;

export function ApiToNuxtError(e: any, fatal = false) {
  if (e instanceof AxiosError) {
    const data = e.response?.data as Failure;
    const path = e.request?.path ?? "";
    const match = pathMatch.exec(path);

    const status = e.response?.status ?? 500;

    const message =
      status === 404
        ? `${
            match?.groups?.entity
              ? match?.groups?.entity.toLowerCase() + "_"
              : ""
          }not_found`
        : data?.errors?.[0]?.detail;

    return createError({
      statusCode: status,
      statusMessage: message,
      message,
      data: e.response
        ? {
            ...data,
            requestPath: e.request?.path,
            requestId: e.response.headers["x-request-id"],
          }
        : undefined,
      fatal,
    });
  }

  return e;
}

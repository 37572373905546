import { createGtm, type VueGtmUseOptions } from "@gtm-support/vue-gtm";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(
    createGtm({
      enabled: !!nuxtApp.$config.public.gtmId,
      id: nuxtApp.$config.public.gtmId || "GTM-N9SDV3C",
      loadScript: true,
      debug: nuxtApp.$config.public.environment !== "production",
      vueRouter: useRouter() as VueGtmUseOptions["vueRouter"],
    }),
  );
});

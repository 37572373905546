import { unrefElement, useDebounceFn } from "@vueuse/core";
type OverlayComponentWrapperInstance = abstract new (...args: any[]) => any;

export type OverlayState<
  Overlay extends
    OverlayComponentWrapperInstance = OverlayComponentWrapperInstance,
> = {
  key: string;
  current: {
    component: Overlay;
    props: InstanceType<Overlay>["$props"];
  } | null;
  el: HTMLElement | SVGElement | null | undefined;
  anchorX: "left" | "right";
  anchorY: "top" | "bottom";
  state: "show" | "hide";
  /**
   * The
   */
  animation: Record<
    "show" | "hide",
    {
      duration: number;
    }
  >;
  trackedElements: (HTMLElement | SVGElement)[];
};

export function useOverlayState(key: string = "default") {
  return useState(
    `overlay-${key}`,
    () =>
      ({
        key: key,
        current: null,
        el: undefined,
        anchorX: "left",
        anchorY: "top",
        state: "hide",
        animation: {
          show: { duration: 300 },
          hide: { duration: 300 },
        },
        trackedElements: [],
      }) as OverlayState,
  );
}

let currentHooks:
  | {
      onShow?: () => any;
      onHide?: () => any;
    }
  | undefined = undefined;

export function useOverlayControls(key: string = "default") {
  const overlayState = useOverlayState(key);

  function showOverlay(
    track: HTMLElement | SVGElement | ComponentPublicInstance,
    componentData: NonNullable<OverlayState["current"]>,
  ) {
    overlayState.value.el = unrefElement(track);
    overlayState.value.current = componentData;
    overlayState.value.state = "show";

    currentHooks?.onShow?.call(null);
  }

  function hideOverlay() {
    overlayState.value.state = "hide";
    currentHooks?.onHide?.call(null);
  }

  const setOverlayState = useDebounceFn(
    (
      state: "show" | "hide",
      track: HTMLElement | SVGElement | ComponentPublicInstance,
      componentData: NonNullable<OverlayState["current"]>,
      hooks?: {
        onShow?: () => any;
        onHide?: () => any;
      },
    ) => {
      currentHooks = hooks;

      switch (state) {
        case "show":
          return showOverlay(track, componentData);
        case "hide":
          return hideOverlay();
      }
    },
    overlayState.value.animation.hide.duration,
  );

  function registerElement(
    element: HTMLElement | SVGElement,
    componentData: NonNullable<OverlayState["current"]>,
    hooks?: {
      onShow?: () => any;
      onHide?: () => any;
    },
  ) {
    if (isMobile()) {
      return;
    }

    element.addEventListener("mouseenter", () => {
      setOverlayState("show", element, componentData, hooks);
    });

    element.addEventListener("mouseleave", () => {
      if (overlayState.value.state === "hide") {
        setOverlayState("hide", element, componentData, hooks);
      }
    });
  }

  return { registerElement, showOverlay, hideOverlay };
}

/**
 *
 * @param key the overlay key to attach
 * @param animation The expected time for the show or hide animations to finish
 */
export function useOverlay(
  key: string = "default",
  animation: OverlayState["animation"],
) {
  const session = useSession();
  const overlayState = useOverlayState(key);
  overlayState.value.animation = animation;

  if (!session.value.allOverlayKeys.includes(key)) {
    session.value.allOverlayKeys.push(key);
  }

  return useOverlayControls(key);
}

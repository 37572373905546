<template>
  <NotificationsItem
    :is-unread
    :href="href ?? undefined"
    :date="notification.date"
  >
    <template #icon>
      <div
        class="w-10 h-10 flex items-center justify-center rounded-full bg-neutral-300 dark:bg-neutral-700"
      >
        <NamiReaction :reaction="notification.data.reaction" class="w-6 h-6" />
      </div>
    </template>
    <template #content>
      <div class="space-y-1">
        <UserAvatar
          :size="32"
          no-link
          :user="notification.data.userWhoReacted"
        />
        <p>
          <i18n-t
            keypath="notifications.social.reaction_to_comment.body"
            scope="global"
          >
            <template #reactor>
              <strong>{{ reactorName }}</strong>
            </template>
            <template #reactionType>
              <strong>{{ notification.data.reaction }}</strong>
            </template>
          </i18n-t>
        </p>
        <p class="text-sm text-neutral-600 dark:text-neutral-400 line-clamp-2">
          {{ commentContent }}
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import type { NamiNotificationSocialReactionToComment } from "~/src/notifications/types/nami/social-reaction-to-comment";
import type { Props } from "../NotificationsItem.vue";
import {
  Comment,
  isChapter,
  isComment,
  isExpanded,
  isOrg,
  isPost,
  isTitle,
  type CommentEntity,
  type UserEntity,
} from "~/src/api";
import { stripMarkdown } from "~/utils/ui/excerpt";

const props = defineProps<
  {
    notification: NamiNotificationSocialReactionToComment;
  } & Omit<Props, "actionIcon">
>();

const { data: href } = useAsyncData(
  `notification-link-${props.notification.id}`,
  async () => {
    const link = await getLinkToComment(props.notification.data.comment);
    return link;
  },
  { watch: [() => props.notification.data.comment] },
);

const commentContent = computed(() => {
  const message = props.notification.data.comment.attributes.message;
  if (!message) return null;
  return stripMarkdown(message.replaceAll(/[\n]+/g, " "));
});

const reactorName = computed(() => {
  return getReactorName(props.notification.data.userWhoReacted);
});

function getReactorName(user: UserEntity) {
  return user.attributes.displayName ?? user.attributes.username;
}

async function getLinkToComment(
  comment: CommentEntity,
): Promise<string | undefined> {
  let link: string | undefined;

  const chapterRelation = comment.relationships.find(isChapter);
  if (chapterRelation && isExpanded(chapterRelation)) {
    link = linkTo(chapterRelation);
  }

  const postRelation = comment.relationships.find(isPost);
  if (postRelation && isExpanded(postRelation)) {
    link = linkTo(postRelation);
  }

  const organizationRelation = comment.relationships.find(isOrg);
  if (organizationRelation && isExpanded(organizationRelation)) {
    link = linkTo(organizationRelation);
  }

  const titleRelation = comment.relationships.find(isTitle);
  if (titleRelation && isExpanded(titleRelation)) {
    link = linkTo(titleRelation);
  }

  const newsRelation = comment.relationships.find(
    (rel) => rel.type === "wp_news",
  );
  if (newsRelation) {
    link = linkTo("wp_news", newsRelation.id);
  }

  // No link set so far, so there are no direct relationships.
  // What's left is for the reaction to be to a comment on another entity, let's fine it
  if (!link) {
    const commentRelation = comment.relationships.find(isComment);

    if (!commentRelation || !isExpanded(commentRelation)) return undefined;
    const deeperComment = await Comment.get(
      commentRelation.id,
      ["*"],
      await getTokenOrThrow(),
    );
    return getLinkToComment(deeperComment);
  }

  return link;
}
</script>

<template>
  <div
    class="nc-style"
    :class="{
      emoteOnly,
    }"
    ref="mdRenderContainer"
    v-html="DOMPurify.sanitize(rendered)"
  ></div>
</template>

<script setup lang="ts">
import type { TokenizerObject } from "marked";
import { marked } from "marked";
import markedSpoiler from "~/src/md/spoiler";
import markedEmote from "~/src/md/emote";
import { useMarkdownLink } from "~/composables/useMarkdownLink";
import DOMPurify from "isomorphic-dompurify";

const props = defineProps<{ text?: string; linkSource?: string }>();

const config = useRuntimeConfig();

const ncTokenizer: TokenizerObject = {
  heading: () => undefined,
  hr: () => undefined,
  code: () => undefined,
  fences: () => undefined,
  codespan: () => undefined,
  link: () => undefined,
  reflink: () => undefined,
  table: () => undefined,
};

const entityMatcher = /\w+\/(?<type>title|chapter)\/(?<id>\w+)/;

const renderer = new marked.Renderer();
renderer.link = function ({ href, title, text }) {
  let target = "_blank";
  let newHref = href;
  let internal = false;
  if (href.indexOf(config.public.baseUrl) === 0) {
    target = "_self";
    newHref = href.replace(config.public.baseUrl, "");

    if (props.linkSource) {
      const constructor = new URL(href, config.public.baseUrl);
      constructor.searchParams.set("s", props.linkSource);
      newHref = constructor.pathname + constructor.search;
    }
    internal = true;
  } else {
    newHref = "https://link.namicomi.com/?url=" + newHref;
  }

  // this block hides any params that might be on chapter links
  let newText = text;
  const match = entityMatcher.exec(newText);
  if (match) {
    const { type } = match.groups!;
    if (type === "chapter") {
      const constructor = new URL(href);
      newText = constructor.href.replace(constructor.search, "");
    }
  }

  return `<a target="${target}" ${
    internal ? "" : 'rel="noopener nofollow noreferrer"'
  } href="${newHref}">${newText}</a>`;
};

const mdParse = marked
  .use({
    breaks: true,
    tokenizer: ncTokenizer,
    renderer,
  })
  .use(markedSpoiler())
  .use(markedEmote());

const mdRenderContainer = ref<HTMLDivElement>();

const emoteMatcher = /<emoji:(?<id>[^:]*):(?<key>[^:]*):(?<fileName>[^:>]*)>/;

const textWithNormalizedEmotes = computed(() => {
  if (!props.text) return "";

  let consumedText = props.text;
  let processedText = "";
  let totalEmotesUsed = 0;

  while (true) {
    const match = consumedText.match(emoteMatcher);

    if (!match) {
      processedText += consumedText;
      break;
    }

    if (totalEmotesUsed < 5) {
      processedText += consumedText.slice(0, match.index!) + match[0];
      consumedText = consumedText.slice(match.index! + match[0].length);
      totalEmotesUsed++;
    } else {
      const { key } = match.groups!;
      processedText += consumedText.slice(0, match.index!) + `:${key}:`;
      consumedText = consumedText.slice(match[0].length + 1);
    }
  }

  return processedText;
});

const adjusted = computed(
  // the replace should preserve all line breaks
  () =>
    textWithNormalizedEmotes.value
      .replaceAll(/(\n(?=\n))+/g, "\n<br>\n")
      // Normalizing here removes non-breaking spaces but keeps linebreaks
      // result of poisoining due to quill issue
      // TODO: the problem is a result of quill 2.0.3 https://github.com/slab/quill/issues/4509 and should be reverted once fixed
      .normalize("NFKD") ?? "",
);
const rendered = useMarkdownLink(mdRenderContainer, mdParse, adjusted);

const emoteOnly = computed(
  () =>
    !adjusted.value
      .replaceAll(
        /<emoji:(\w{8}):(\w+?):([A-Za-z0-9\-]{36}\.(?:png|jpg|jpeg|gif))>/g,
        "",
      )
      .trim(),
);
</script>

<style scoped lang="postcss">
.nc-style {
  :deep(a) {
    word-break: break-word;
    @apply text-nami-comi-blue hover:underline break-words;
  }

  &.emoteOnly:deep(.emote) {
    width: 48px;
    height: 48px;
  }

  :deep(.spoiler) {
    display: inline-block;
    position: relative;
    user-select: none;
    @apply cursor-pointer;

    & > summary {
      & > * {
        pointer-events: none;
        opacity: 0;
      }

      content: none;
      user-select: none;
      color: transparent;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition-duration: 0s, 0.25s;
      transition-property: visibility, opacity;
      @apply bg-neutral-500 rounded;

      list-style-type: none; /* Firefox */

      &::-webkit-details-marker {
        display: none; /* Chrome */
      }
    }

    &[open] > summary {
      position: absolute;
      opacity: 0;
      transition-delay: 0.25s, 0s;
      visibility: hidden;
    }

    &[open] {
      user-select: revert;
      @apply cursor-auto;
    }
  }
}
</style>

import { getLocales } from "~/src/api";
import type { H3Event } from "h3";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

//TODO: debug why provided functions return empty objects
function parseCookies(event: H3Event) {
  const cookieString = parseHeaders(event)["cookie"] ?? "";
  if (!cookieString) return {};

  const cookies = cookieString
    .split(";")
    .map((str) => str.trim())
    .map((str) => str.split("="));
  return Object.fromEntries(cookies);
}

function parseHeaders(event: H3Event): { [key: string]: string } {
  const rawHeaders = event.node.req.rawHeaders;
  const groupedHeaders = [];
  for (let i = 0; i < rawHeaders.length; i += 2) {
    groupedHeaders.push([rawHeaders[i], rawHeaders[i + 1]]);
  }

  if (groupedHeaders.length < 1) return {};
  return Object.fromEntries(groupedHeaders);
}

const ignoredPaths = [
  "/.well-known",
  "/_nuxt",
  "/assets",
  "/fonts",
  "/img",
  "/pwa",
  "/static",
  "/apple-touch-icon.png",
  "/favicon.ico",
  "/favicon.svg",
  "/opensearch.xml",
  "/robots.txt",
  "/admin",
  "/studio",
  "/publish",
  "/c/",
  "/o/",
  "/t/",
  "/n/",
  "/embed/social",
  "/dev-sw.js",
  "/manifest.webmanifest",
  "/dev",
  "/firebase-messaging-sw.js",
  "/firebase-messaging-sw-dev.js",
  "/sitemaps",
  "/sitemap.xml",
];

const pattern = new RegExp(
  `^(${ignoredPaths
    .map((path) => path.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))
    .join("|")})`,
);

const locales = getLocales().map((locale) => reverseLocaleMap(locale.value));

function isValidLocale(locale: string) {
  return locales.includes(locale as any);
}

function findSupportedBrowserLocale(
  acceptLanguageHeader?: string,
): string | undefined {
  if (!acceptLanguageHeader) {
    return undefined;
  }

  let localeString = acceptLanguageHeader.split(",")[0];
  localeString = localeString.split(";")[0];
  localeString = localeString.split("-")[0];
  localeString = localeString.toLowerCase();

  if (isValidLocale(localeString)) {
    return localeString;
  } else {
    return "en";
  }
}

interface RedirectionRequired {
  location: RouteLocationRaw | string;
  setCookiePrefLang?: string;
}

export function redirectIfNecessary(
  route: RouteLocationNormalized,
  acceptLanguage?: string,
  cookiePrefLang?: string | null,
): RedirectionRequired | undefined {
  // Ignore all paths that aren't on the main site /{locale} or index /
  if (pattern.test(route.path) || route.query.preferredLocale) {
    return;
  }

  // additionally ignore unmatched paths
  if (route.matched.length === 0) return;

  const routeLocale = route.params.locale as string | undefined;

  let selectedLocale = cookiePrefLang;
  if (!selectedLocale) {
    selectedLocale =
      routeLocale ?? findSupportedBrowserLocale(acceptLanguage) ?? "en";
  }

  if (!routeLocale && route.path === "/") {
    return {
      location: `/${selectedLocale}`,
      setCookiePrefLang:
        selectedLocale !== cookiePrefLang ? selectedLocale : undefined,
    };
  } else if (routeLocale && !isValidLocale(routeLocale) && cookiePrefLang) {
    return {
      location: {
        name: route.matched[route.matched.length - 1].name,
        params: {
          ...route.params,
          locale: cookiePrefLang,
        },
        query: route.query,
      },
    };
  } else if (routeLocale && selectedLocale !== routeLocale && !cookiePrefLang) {
    return {
      location: {
        name: route.matched[route.matched.length - 1].name,
        params: {
          ...route.params,
          locale: selectedLocale,
        },
        query: route.query,
      },
      setCookiePrefLang: selectedLocale,
    };
  } else if (cookiePrefLang && routeLocale && cookiePrefLang !== routeLocale) {
    return {
      location: {
        path: route.path,
        query: {
          ...route.query,
          preferredLocale: isValidLocale(routeLocale ?? "")
            ? cookiePrefLang
            : undefined,
        },
      },
    };
  }

  // no redirect required
  return undefined;
}

export default defineNuxtRouteMiddleware((to, _) => {
  if (process.client) return;
  if (process.server) {
    const httpEvent = useRequestEvent();
    if (!httpEvent) return;
    const httpHeaders = parseHeaders(httpEvent);
    const headerAcceptLanguage = httpHeaders["accept-language"];
    const headerCookies = parseCookies(httpEvent);
    const headerCookiePrefLang = headerCookies["PREF_LANG"] ?? undefined;

    const redirect = redirectIfNecessary(
      to,
      headerAcceptLanguage,
      headerCookiePrefLang,
    );
    if (redirect !== undefined) {
      if (redirect.setCookiePrefLang) {
        useCookie("PREF_LANG", { maxAge: 60 * 60 * 24 * 30 }).value =
          redirect.setCookiePrefLang;
      }

      httpEvent.node.res.setHeader("vary", "accept-language, cookie");

      return navigateTo(redirect.location, { redirectCode: 302 });
    }
  }
});

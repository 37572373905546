import type { AvailableLanguages, AvailableLocales } from "../types/locales";

export const defaultProfileName = "Default";
export const languages: { [key in AvailableLanguages]: string } = {
  ar: "العربية",
  bg: "български",
  ca: "Català",
  cs: "Čeština",
  da: "Dansk",
  de: "Deutsch",
  el: "Ελληνικά",
  en: "English",
  "es-419": "Español (Latino)",
  "es-es": "Español (España)",
  et: "Eesti",
  fa: "فارسی",
  fi: "Suomi",
  fil: "Filipino",
  fr: "Français",
  ga: "Gaeilge",
  he: "עברית",
  hi: "हिन्दी",
  hr: "Hrvatski",
  hu: "Magyar",
  id: "Bahasa Indonesia",
  is: "Íslenska",
  it: "Italiano",
  ja: "日本語",
  ko: "한국어",
  lt: "Lietuvių",
  ms: "Bahasa Melayu",
  ne: "नेपाली",
  nl: "Nederlands",
  no: "Norsk",
  pa: "ਪੰਜਾਬੀ",
  pl: "Polski",
  "pt-br": "Português (Brasil)",
  "pt-pt": "Português (Portugal)",
  ru: "Русский",
  sk: "Slovenčina",
  sl: "Slovenščina",
  sv: "Svenska",
  th: "ไทย",
  tr: "Türkçe",
  uk: "Українська",
  "zh-hans": "简体中文",
  "zh-hant": "繁體中文",
};

export function getLanguages(): { text: string; value: AvailableLanguages }[] {
  return Object.entries(languages).map(([key, value]) => ({
    text: value,
    value: key as AvailableLanguages,
  }));
}

export function getLocales(): { text: string; value: AvailableLocales }[] {
  return [
    // { text: 'Chinese (Simp)', value: 'zh' },
    // { text: 'Chinese (Trad)', value: 'zh-hk' },
    { text: "Español (Latino)", value: "es-419" },
    { text: "English", value: "en" },
    { text: "French", value: "fr" },
    // { text: 'German', value: 'de' },
    { text: "Italiano", value: "it" },
    { text: "日本語", value: "ja" },
    { text: "한국어", value: "ko" },
    // { text: 'Portuguese (Pt)', value: 'pt-pt' },
    { text: "Português (Brasil)", value: "pt-br" },
    // { text: 'Spanish (Es)', value: 'es' },
  ];
}

import { getAsyncContext } from "~/components/app/context/util/async";

export function useDeveloperTools() {
  const asyncDeveloperContext = getAsyncContext("developer");

  async function executeIfDevModeIsEnabled(func: () => void) {
    const { isEnabled: isDevModeEnabled } = await asyncDeveloperContext;

    if (isDevModeEnabled.value) {
      func();
    }
  }

  function debug(...items: any[]) {
    executeIfDevModeIsEnabled(() => {
      console.debug(
        "%c DEV ",
        "color: #111111; background-color: #10b0eb77; border-radius: 4px",
        ...items,
      );
    });
  }

  return {
    debug,
  };
}

import { Comment, type CommentEntity } from "~/src/api";
import { useGenericCache } from "./generic";

export function useCommentCache(timeout = 1000 * 60) {
  const cache = useGenericCache<string, CommentEntity>("comments", timeout);

  async function getById(id: string, authenticated = false) {
    const cached = cache.get(id);
    if (cached) return cached;

    const comment = await Comment.get(
      id,
      ["*"],
      authenticated ? await getTokenOrThrow() : undefined,
    );
    cache.store(id, comment);
    return comment;
  }

  return {
    cache,
    getById,
  };
}

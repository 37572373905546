import { Emote, type EmoteEntity } from "~/src/api";
import { createAsyncResource } from "./async/asyncResource";

export function useEmotePreview(id: MaybeRef<string>) {
  const asyncEmoteResource = createAsyncResource<EmoteEntity | null>(
    async (id: string) => {
      if (!id) return null;
      const emote = await Emote.getEmoteById(id, ["organization", "tier_item"]);
      return emote;
    },
    id,
  );

  return asyncEmoteResource;
}

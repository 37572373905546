import { linkTo } from "~/utils/functions";
import type { Ref, ToRefs } from "vue";
import type { MaybeRef } from "@vueuse/shared";
import {
  type BadgeRelation,
  isBadge,
  isExpanded,
  type PopulateRelationship,
  type UserEntity,
  type UserRelations,
} from "~/src/api";

type ParsedUserOutput = {
  id: string;
  username: string;
  displayName: string;
  avatar: string;
  banner: string;
  href: string;
  createdAt: string;
  verified: boolean;
  official: boolean;
  badges: BadgeRelation[];
};

const DefaultReturn: ParsedUserOutput = {
  id: "",
  username: "",
  displayName: "",
  avatar: "/img/sea-deity.png",
  banner: "/img/great-wave.png",
  href: "",
  createdAt: "",
  verified: false,
  official: false,
  badges: [],
};

export function parseUserForDisplay(
  x: UserEntity | UserRelations | null | undefined,
  res?: "small" | "med" | "large" | "original",
): ParsedUserOutput;
export function parseUserForDisplay(
  x: Ref<UserEntity | UserRelations | null | undefined>,
  res?: "small" | "med" | "large" | "original",
): ToRefs<ParsedUserOutput>;
export function parseUserForDisplay(
  userInput: MaybeRef<UserEntity | UserRelations | null | undefined>,
  res: "small" | "med" | "large" | "original" = "original",
): ParsedUserOutput | ToRefs<ParsedUserOutput> {
  const { getUserBanner, getUserAvatar } = useMediaLink();
  let thumbRes: "128" | "256" | "512" | "original";
  let bannerRes: "640" | "1280" | "1920" | "original";
  switch (res) {
    case "small":
      thumbRes = "128";
      bannerRes = "640";
      break;
    case "med":
      thumbRes = "256";
      bannerRes = "1280";
      break;
    case "large":
      thumbRes = "512";
      bannerRes = "1920";
      break;
    default:
      thumbRes = "original";
      bannerRes = "original";
  }

  let id: MaybeRef<string>;
  let displayName: MaybeRef<string>;
  let username: MaybeRef<string>;
  let avatar: MaybeRef<string>;
  let banner: MaybeRef<string>;
  let href: MaybeRef<string>;
  let createdAt: MaybeRef<string>;
  let verified: MaybeRef<boolean>;
  let official: MaybeRef<boolean>;
  let badges: MaybeRef<BadgeRelation[]>;

  if (isRef(userInput)) {
    id = computed(() => userInput.value?.id ?? "");
    username = computed(() => userInput.value?.attributes?.username ?? "");
    displayName = computed(
      () => userInput.value?.attributes?.displayName || unref(username),
    );
    avatar = computed(() =>
      userInput.value && isExpanded(userInput.value)
        ? getUserAvatar(userInput.value, thumbRes)
        : "",
    );
    banner = computed(() =>
      userInput.value && isExpanded(userInput.value)
        ? getUserBanner(userInput.value, bannerRes)
        : "",
    );
    href = computed(() =>
      userInput.value?.attributes?.username
        ? linkTo("user", userInput.value.attributes?.username)
        : "",
    );
    createdAt = computed(() =>
      userInput.value?.attributes
        ? formatDateAlt(userInput.value?.attributes?.createdAt)
        : "",
    );
    verified = computed(() => !!userInput.value?.attributes?.isVerified);
    official = computed(() => !!userInput.value?.attributes?.isOfficial);
    badges = computed(
      () =>
        (userInput.value as UserEntity)?.relationships?.filter(isBadge) ?? [],
    );
  } else {
    if (!userInput) return DefaultReturn;
    id = userInput.id;
    username = userInput.attributes?.username ?? "";
    displayName = userInput?.attributes?.displayName || username;
    avatar =
      userInput && isExpanded(userInput)
        ? getUserAvatar(userInput, thumbRes)
        : "";
    banner =
      userInput && isExpanded(userInput)
        ? getUserBanner(userInput, bannerRes)
        : "";
    // avatar = getUserAvatar(userInput, thumbRes) ?? "";
    href = userInput.attributes?.username
      ? linkTo("user", userInput.attributes?.username)
      : "/";
    createdAt = userInput?.attributes?.createdAt ?? "";
    verified = !!userInput?.attributes?.isVerified;
    official = !!userInput?.attributes?.isOfficial;
    badges = (userInput as UserEntity).relationships?.filter(isBadge) ?? [];
  }

  return {
    id,
    username,
    displayName,
    avatar,
    banner,
    href,
    createdAt,
    verified,
    official,
    badges,
  } as ToRefs<ParsedUserOutput> | ParsedUserOutput;
}

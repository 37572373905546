import axios from "../core/axios";
import type { CollectionResult, MessageResult, Result } from "../types/result";

import { assertSuccess } from "../core/assert";
import type {
  CombinedChapterStatistic,
  CombinedOrganizationStatistic,
  CombinedPostStatistic,
  CombinedTitleStatistic,
  ViewStatistics,
} from "../types/statistics";

export class Statistics {
  static async getViews(type: "title" | "chapter", id: string) {
    const resp = await axios<CollectionResult<ViewStatistics>>(
      `/view/${type}/${id}`,
    );

    return assertSuccess(resp.data).data;
  }

  static async postView(type: "title" | "chapter", id: string) {
    const resp = await axios<MessageResult<"View was recorded">>(
      `/view/${type}/${id}`,
      {
        method: "PUT",
      },
    );

    return assertSuccess(resp.data);
  }

  static async getTitleStats(titleId: string): Promise<CombinedTitleStatistic> {
    const resp = await axios<Result<CombinedTitleStatistic>>(
      `/statistics/title/${titleId}`,
    );

    return assertSuccess(resp.data).data;
  }

  static async getOrganizationStats(
    titleId: string,
  ): Promise<CombinedOrganizationStatistic> {
    const resp = await axios<Result<CombinedOrganizationStatistic>>(
      `/statistics/organization/${titleId}`,
    );

    return assertSuccess(resp.data).data;
  }

  static async getChapterStats(
    chapterId: string,
  ): Promise<CombinedChapterStatistic> {
    const resp = await axios<Result<CombinedChapterStatistic>>(
      `/statistics/chapter/${chapterId}`,
    );

    return assertSuccess(resp.data).data;
  }

  static async getPostStats(postId: string): Promise<CombinedPostStatistic> {
    const resp = await axios<Result<CombinedPostStatistic>>(
      `/statistics/post/${postId}`,
    );

    return assertSuccess(resp.data).data;
  }
}

<script setup lang="ts">
import type {
  FrameEntity,
  FrameRelation,
  PopulateRelationship,
} from "~/src/api";

interface Link {
  imageUrl?: string;
}

interface Entity {
  frame: FrameEntity | PopulateRelationship<FrameRelation>;
}

const isLink = (input: Link | Entity): input is Link =>
  !!(input as Link).imageUrl;
const isEntity = (input: Link | Entity): input is Entity =>
  !!(input as Entity).frame;

const props = defineProps<Link | Entity>();

const { getImageUrl } = useMediaLink();
const frameUrl = computed(() => {
  if (isLink(props)) return props.imageUrl;
  if (isEntity(props))
    return getImageUrl(
      props.frame.id,
      "emote",
      "avatar",
      props.frame.attributes.fileName,
    );

  return;
});
</script>

<template>
  <AsyncImage v-show="frameUrl" :src="frameUrl" />
</template>

<template>
  <Transition
    enter-active-class="transition-opacity"
    enter-from-class="opacity-0"
    enter-to-class=" opacity-100"
    leave-active-class="transition-opacity"
    leave-to-class="opacity-0"
    leave-from-class=" opacity-100"
  >
    <slot></slot>
  </Transition>
</template>

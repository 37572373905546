<template>
  <div class="flex flex-col gap-4">
    <label
      v-if="select.label"
      :for="select.id"
      class="block text-sm sm:text-base font-semibold"
      >{{ select.label }}</label
    >
    <select
      ref="selectElement"
      :id="select.id"
      :name="select.name"
      @change="handleSelectChange"
      class="block rounded-md py-2 pl-4 pr-8 text-sm sm:text-base cursor-pointer w-full bg-white dark:bg-neutral-900 border-neutral-300 dark:border-neutral-700 focus:border-nami-comi-blue focus:outline-none focus:ring-nami-comi-blue focus:ring-1"
      :class="{
        disabled: select.disabled,
      }"
    >
      <option
        v-for="(option, index) in select.options"
        class=""
        :key="index"
        :selected="modelValue.value === option.value"
        :id="option.text"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import type { Option } from "~/types/input";
import type { Props as SelectProps } from "~/types/select";

export interface Props extends SelectProps {}

interface Events {
  (e: "selectEvent", v: Option): void;
  (e: "update:modelValue", v: Option): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();

const selectElement = ref<HTMLSelectElement | null>(null);

function handleSelectChange() {
  if (!selectElement.value) return;

  emit(
    "selectEvent",
    props.select.options.find(
      (option) => option.value == selectElement.value!.value,
    )!,
  );
  emit(
    "update:modelValue",
    props.select.options.find(
      (option) => option.value == selectElement.value!.value,
    )!,
  );
}
</script>

import {
  tryOnBeforeUnmount,
  tryOnMounted,
  useBroadcastChannel,
} from "@vueuse/core";
import { createNotificationsContext } from "./context";
import { asyncContextDependencies, contexts } from "../../util/async";
import {
  NotificationType,
  type Notification,
} from "~/src/notifications/types/notifications";
import NotificationsNamiAny from "~/components/notifications/nami/NotificationsNamiAny.vue";
import type { NamiNotification } from "~/src/notifications/types/nami";
import type { MessagePayload } from "firebase/messaging";
import type { TwilioNotification } from "~/src/notifications/types/twilio";
import NotificationsTwilioAny from "~/components/notifications/twilio/NotificationsTwilioAny.vue";

export function initNotificationsContext() {
  const nuxtApp = useNuxtApp();
  const app = nuxtApp.$app();
  const context = createNotificationsContext();
  const firebase = useFirebaseApp();
  const { onMessage, enable, disable, token, begForEnablePush, onActivated } =
    useFirebaseMessaging(firebase);
  const { channel, post, close } = useBroadcastChannel<
    MessagePayload[] | "get-notifications",
    "get-notifications"
  >({ name: "notifications-sw" });
  const { parseNotification } = useNotificationParser();
  const { debug } = useDeveloperTools();

  context.begForEnablePushFunction.value = begForEnablePush;
  context.onActivated.value = onActivated;

  watch(
    token,
    (newToken) => {
      context.firebaseToken.value = newToken;
    },
    { immediate: true },
  );

  onMessage(async (payload) => {
    const parsed = await parseNotification(payload);

    debug("Received notification payload", payload);
    debug("Parsed notification is", parsed);

    context.allNotifications.value.push(payload);

    if (parsed) {
      showNotification(parsed);
    }
  });

  function onBroadcastChannelMessage(
    message: MessageEvent<MessagePayload[] | "get-notifications">,
  ) {
    if (document.visibilityState === "hidden") {
      return;
    }

    switch (message.data) {
      case "get-notifications": {
        break;
      }

      default: {
        context.allNotifications.value.push(...message.data);
      }
    }
  }

  // When the current document is visible, send a message to the service worker
  // requesting all the background notifications it stored
  function onDocumentVisibilityChange() {
    if (document.visibilityState === "hidden") {
      return;
    }

    post("get-notifications");
  }

  function addListeners() {
    if (channel.value) {
      channel.value.onmessage = onBroadcastChannelMessage;
    }

    document.addEventListener("visibilitychange", onDocumentVisibilityChange);
  }

  function removeListeners() {
    close();
    document.removeEventListener(
      "visibilitychange",
      onDocumentVisibilityChange,
    );
  }

  async function showNotification(notification: Notification) {
    switch (notification.type) {
      case NotificationType.Nami:
        return await showNamiNotification(notification);

      case NotificationType.Twilio:
        return await showTwilioNotification(notification);
    }
  }

  async function showNamiNotification(notification: NamiNotification) {
    return await showNotificationComponent(NotificationsNamiAny, notification);
  }

  async function showTwilioNotification(notification: TwilioNotification) {
    return await showNotificationComponent(
      NotificationsTwilioAny,
      notification,
    );
  }

  async function showNotificationComponent(
    component: any,
    notification: Notification,
  ) {
    return await app?.notifyCustom({
      extra: {
        showCloseButton: true,
        clickToClose: true,
      },
      timeout: {
        enabled: true,
        duration: 10000,
        pauseTimerOnHover: true,
      },
      content: {
        children: [
          {
            items: [
              {
                kind: "component",
                component: shallowRef(component),
                props: {
                  notification,
                  isUnread: false,
                  class: "w-128",
                },
              },
            ],
          },
        ],
      },
    });
  }

  tryOnMounted(() => {
    addListeners();
    setTimeout(() => {
      enable();
    }, 5000);
  });

  tryOnBeforeUnmount(() => {
    removeListeners();
    disable();
  });

  asyncContextDependencies.notifications.forEach((dep) => dep(context));
  contexts.notifications = context;

  return context;
}

import { doFileUpload, EntityViewSet, Headers } from "../index";
import axios from "../../core/axios";
import { assertSuccess, paramsParser } from "../../core";
import type {
  BadgeCreateBody,
  BadgeEditBody,
  BadgeEntity,
  BadgeListParams,
  BadgeUserAwardEntity,
  CollectionResult,
  MessageResult,
  UserBadgeListParams,
} from "../../types";

class BadgeViewSet extends EntityViewSet<
  BadgeListParams,
  BadgeCreateBody,
  BadgeEditBody,
  BadgeEntity
> {
  constructor() {
    super("/badge");
  }

  // create & update
  async uploadImage(
    id: string,
    image: File | Blob,
    version: number,
    auth: string,
  ) {
    return doFileUpload<BadgeEntity>(
      `${this.apiPath}/${id}/avatar?version=${version}`,
      image,
      auth,
    );
  }

  async awardBadge(userId: string, badgeId: string, auth: string) {
    const resp = await axios.post<CollectionResult<BadgeUserAwardEntity>>(
      `/user/${userId}/badge`,
      {
        badgeId: badgeId,
      },
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  async unawardBadge(
    userId: string,
    badgeId: string | undefined,
    auth: string,
  ) {
    const resp = await axios.delete<CollectionResult<BadgeUserAwardEntity>>(
      `/user/${userId}/badge` + (badgeId ? `/${badgeId}` : ""),
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  async getUserBadges(params: UserBadgeListParams = {}, auth: string) {
    const resp = await axios.get<CollectionResult<BadgeUserAwardEntity>>(
      `/user/me/badge` + paramsParser(params),
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  async setUserBadgeOrder(ids: string[], auth: string) {
    const resp = await axios.post<MessageResult<"Badge order was set.">>(
      `/user/me/badge/order`,
      { badgeOrder: ids },
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }
}

export const Badge = new BadgeViewSet();

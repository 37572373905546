const mapOfMaps = new Map<string, Map<any, any>>();

export function useGenericCache<T, V>(name: string, timeout = 1000 * 60) {
  function getMap() {
    const existing = mapOfMaps.get(name);
    if (existing) return existing;

    const created = new Map();
    mapOfMaps.set(name, created);
    return created;
  }

  function get(key: T): V | null {
    const map = getMap();
    return map.get(key);
  }

  function store(key: T, value: V) {
    const map = getMap();
    map.set(key, value);
    setTimeout(() => {
      map.delete(key);
    }, timeout);
  }

  return {
    get,
    store,
    getMap,
  };
}

<template>
  <span class="flex-shrink-0">
    <Tooltip fixed size="small">
      <img
        width="64px"
        height="64px"
        src="/assets/badges/verified.svg"
        alt="verified"
      />
      <template #tooltip>
        <span class="whitespace-nowrap">
          {{ $t("components.badgeVerified.toolTipText") }}
        </span>
      </template>
    </Tooltip>
  </span>
</template>

import type {
  CollectionResult,
  CreateEmoteBody,
  CreateEmoteSlotBody,
  EditEmoteBody,
  EditEmoteSlotBody,
  EmoteEntity,
  EmoteFavoriteEntity,
  EmoteListParams,
  EmoteSlotEntity,
  EmoteSlotLadderEntity,
  EmoteSlotListParams,
  GenericListParams,
  IncludeArr,
  MessageResult,
  MyEmotesListParams,
  Result,
} from "../types";
import axios from "../core/axios";
import { assertSuccess, paramsParser } from "../core";
import { Headers } from "./generic";
import { nanoid } from "nanoid";

export type GetEmoteFavoriteParams = GenericListParams & {
  includes?: EmoteFavoriteEntity["relationships"][number]["type"][];
  order?: Partial<Record<"createdAt", "asc" | "desc">>;
};

export class Emote {
  static async getEmoteById(
    id: string,
    includes?: IncludeArr<EmoteEntity>,
    auth?: string,
  ) {
    const resp = await axios.get<Result<EmoteEntity>>(
      `/emote/${id}${paramsParser({ includes })}`,
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );
    return assertSuccess(resp.data).data;
  }

  static async getEmotes(params: EmoteListParams = {}, auth?: string) {
    const resp = await axios.get<CollectionResult<EmoteEntity>>(
      "/emote" + paramsParser(params),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );
    return assertSuccess(resp.data);
  }

  static async createEmote(body: CreateEmoteBody, auth: string) {
    const resp = await axios.post<Result<EmoteEntity>>("/emote/create", body, {
      headers: Headers.Bearer(auth),
    });
    return assertSuccess(resp.data).data;
  }

  static async editEmote(
    emoteId: string,
    body: EditEmoteBody,
    version: number,
    auth: string,
  ) {
    const resp = await axios.post<Result<EmoteEntity>>(
      `/emote/${emoteId}`,
      {
        ...body,
        version,
      },
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data).data;
  }

  static async deleteEmote(emoteId: string, version: number, auth: string) {
    //TODO: fix message result
    const resp = await axios.delete<MessageResult<"todo: fix this">>(
      `/emote/${emoteId}?version=${version}`,
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data).data;
  }

  static async uploadEmote(
    emoteId: string,
    file: File | Blob,
    version: number,
    auth: string,
  ) {
    const formData = new FormData();
    formData.append(`file`, file, nanoid());

    const resp = await axios.post<Result<EmoteEntity>>(
      `/emote/${emoteId}/emoji?version=${version}`,
      formData,
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data).data;
  }

  static async uploadSticker(
    emoteId: string,
    file: File | Blob,
    version: number,
    auth: string,
  ) {
    const formData = new FormData();
    formData.append(`file`, file, nanoid());

    const resp = await axios.post<Result<EmoteEntity>>(
      `/emote/${emoteId}/sticker?version=${version}`,
      formData,
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data).data;
  }

  static async getSlotById(
    id: string,
    includes: IncludeArr<EmoteSlotEntity>,
    auth: string,
  ) {
    const resp = await axios.get<Result<EmoteSlotEntity>>(
      `/emote/slot/${id}${paramsParser({ includes })}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getSlots(params: EmoteSlotListParams, auth: string) {
    const resp = await axios.get<
      CollectionResult<
        EmoteSlotEntity,
        {
          extra: {
            totalSlotCount: {
              emoji: number;
              sticker: number;
            };
          };
        }
      >
    >("/emote/slot" + paramsParser(params), {
      headers: Headers.Bearer(auth),
    });
    return assertSuccess(resp.data);
  }

  static async createSlot(body: CreateEmoteSlotBody, auth: string) {
    const resp = await axios.post<Result<EmoteSlotEntity>>(
      "/emote/slot/create",
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data).data;
  }

  static async editSlot(
    slotId: string,
    body: EditEmoteSlotBody,
    version: number,
    auth: string,
  ) {
    const resp = await axios.post<Result<EmoteSlotEntity>>(
      `/emote/slot/${slotId}`,
      {
        ...body,
        version,
      },
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data).data;
  }

  static async deleteSlot(slotId: string, auth: string) {
    const resp = await axios.delete<MessageResult<"Emote Slot was deleted.">>(
      `/emote/slot/${slotId}`,
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data).data;
  }

  static async getAuthedUserEmotes(params: MyEmotesListParams, auth: string) {
    const resp = await axios.get<CollectionResult<EmoteEntity>>(
      "/user/me/emote" + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data);
  }

  static async getSlotLadder() {
    return {
      type: "entity",
      data: {
        id: "emote_slot_ladder",
        type: "emote_slot_ladder",
        attributes: {
          baseEmojiSlotCount: 5,
          baseStickerSlotCount: 1,
          slotsByMilestone: [
            {
              subscriptionCount: 10,
              awardedEmojiSlotCount: 2,
              awardedStickerSlotCount: 1,
            },
            {
              subscriptionCount: 20,
              awardedEmojiSlotCount: 3,
              awardedStickerSlotCount: 2,
            },
            {
              subscriptionCount: 30,
              awardedEmojiSlotCount: 4,
              awardedStickerSlotCount: 3,
            },
            {
              subscriptionCount: 40,
              awardedEmojiSlotCount: 5,
              awardedStickerSlotCount: 4,
            },
            {
              subscriptionCount: 50,
              awardedEmojiSlotCount: 6,
              awardedStickerSlotCount: 5,
            },
            {
              subscriptionCount: 75,
              awardedEmojiSlotCount: 7,
              awardedStickerSlotCount: 6,
            },
            {
              subscriptionCount: 100,
              awardedEmojiSlotCount: 8,
              awardedStickerSlotCount: 7,
            },
            {
              subscriptionCount: 125,
              awardedEmojiSlotCount: 9,
              awardedStickerSlotCount: 8,
            },
            {
              subscriptionCount: 150,
              awardedEmojiSlotCount: 10,
              awardedStickerSlotCount: 9,
            },
            {
              subscriptionCount: 200,
              awardedEmojiSlotCount: 11,
              awardedStickerSlotCount: 10,
            },
            {
              subscriptionCount: 250,
              awardedEmojiSlotCount: 12,
              awardedStickerSlotCount: 11,
            },
            {
              subscriptionCount: 300,
              awardedEmojiSlotCount: 13,
              awardedStickerSlotCount: 12,
            },
            {
              subscriptionCount: 400,
              awardedEmojiSlotCount: 14,
              awardedStickerSlotCount: 13,
            },
            {
              subscriptionCount: 500,
              awardedEmojiSlotCount: 15,
              awardedStickerSlotCount: 14,
            },
            {
              subscriptionCount: 600,
              awardedEmojiSlotCount: 16,
              awardedStickerSlotCount: 15,
            },
            {
              subscriptionCount: 700,
              awardedEmojiSlotCount: 17,
              awardedStickerSlotCount: 16,
            },
            {
              subscriptionCount: 800,
              awardedEmojiSlotCount: 18,
              awardedStickerSlotCount: 17,
            },
            {
              subscriptionCount: 900,
              awardedEmojiSlotCount: 19,
              awardedStickerSlotCount: 18,
            },
            {
              subscriptionCount: 1000,
              awardedEmojiSlotCount: 20,
              awardedStickerSlotCount: 19,
            },
            {
              subscriptionCount: 1100,
              awardedEmojiSlotCount: 21,
              awardedStickerSlotCount: 20,
            },
            {
              subscriptionCount: 1200,
              awardedEmojiSlotCount: 22,
              awardedStickerSlotCount: 21,
            },
            {
              subscriptionCount: 1300,
              awardedEmojiSlotCount: 23,
              awardedStickerSlotCount: 22,
            },
            {
              subscriptionCount: 1400,
              awardedEmojiSlotCount: 24,
              awardedStickerSlotCount: 23,
            },
            {
              subscriptionCount: 1500,
              awardedEmojiSlotCount: 25,
              awardedStickerSlotCount: 24,
            },
            {
              subscriptionCount: 1600,
              awardedEmojiSlotCount: 26,
              awardedStickerSlotCount: 25,
            },
            {
              subscriptionCount: 1700,
              awardedEmojiSlotCount: 27,
              awardedStickerSlotCount: 26,
            },
            {
              subscriptionCount: 1800,
              awardedEmojiSlotCount: 28,
              awardedStickerSlotCount: 27,
            },
            {
              subscriptionCount: 1900,
              awardedEmojiSlotCount: 29,
              awardedStickerSlotCount: 28,
            },
            {
              subscriptionCount: 2000,
              awardedEmojiSlotCount: 30,
              awardedStickerSlotCount: 29,
            },
            {
              subscriptionCount: 2250,
              awardedEmojiSlotCount: 31,
              awardedStickerSlotCount: 30,
            },
            {
              subscriptionCount: 2500,
              awardedEmojiSlotCount: 32,
              awardedStickerSlotCount: 31,
            },
            {
              subscriptionCount: 2750,
              awardedEmojiSlotCount: 33,
              awardedStickerSlotCount: 32,
            },
            {
              subscriptionCount: 3000,
              awardedEmojiSlotCount: 34,
              awardedStickerSlotCount: 33,
            },
            {
              subscriptionCount: 3250,
              awardedEmojiSlotCount: 35,
              awardedStickerSlotCount: 34,
            },
            {
              subscriptionCount: 3500,
              awardedEmojiSlotCount: 36,
              awardedStickerSlotCount: 35,
            },
            {
              subscriptionCount: 3750,
              awardedEmojiSlotCount: 37,
              awardedStickerSlotCount: 35,
            },
            {
              subscriptionCount: 4000,
              awardedEmojiSlotCount: 38,
              awardedStickerSlotCount: 36,
            },
            {
              subscriptionCount: 4250,
              awardedEmojiSlotCount: 39,
              awardedStickerSlotCount: 36,
            },
            {
              subscriptionCount: 4500,
              awardedEmojiSlotCount: 40,
              awardedStickerSlotCount: 37,
            },
            {
              subscriptionCount: 4750,
              awardedEmojiSlotCount: 41,
              awardedStickerSlotCount: 37,
            },
            {
              subscriptionCount: 5000,
              awardedEmojiSlotCount: 42,
              awardedStickerSlotCount: 38,
            },
            {
              subscriptionCount: 5250,
              awardedEmojiSlotCount: 43,
              awardedStickerSlotCount: 38,
            },
            {
              subscriptionCount: 5500,
              awardedEmojiSlotCount: 44,
              awardedStickerSlotCount: 39,
            },
            {
              subscriptionCount: 5750,
              awardedEmojiSlotCount: 45,
              awardedStickerSlotCount: 39,
            },
            {
              subscriptionCount: 6000,
              awardedEmojiSlotCount: 46,
              awardedStickerSlotCount: 40,
            },
            {
              subscriptionCount: 6250,
              awardedEmojiSlotCount: 47,
              awardedStickerSlotCount: 40,
            },
            {
              subscriptionCount: 6500,
              awardedEmojiSlotCount: 48,
              awardedStickerSlotCount: 41,
            },
            {
              subscriptionCount: 6750,
              awardedEmojiSlotCount: 49,
              awardedStickerSlotCount: 41,
            },
            {
              subscriptionCount: 7000,
              awardedEmojiSlotCount: 50,
              awardedStickerSlotCount: 42,
            },
            {
              subscriptionCount: 7250,
              awardedEmojiSlotCount: 51,
              awardedStickerSlotCount: 42,
            },
            {
              subscriptionCount: 7500,
              awardedEmojiSlotCount: 52,
              awardedStickerSlotCount: 43,
            },
            {
              subscriptionCount: 7750,
              awardedEmojiSlotCount: 53,
              awardedStickerSlotCount: 43,
            },
            {
              subscriptionCount: 8000,
              awardedEmojiSlotCount: 54,
              awardedStickerSlotCount: 44,
            },
            {
              subscriptionCount: 8250,
              awardedEmojiSlotCount: 55,
              awardedStickerSlotCount: 44,
            },
            {
              subscriptionCount: 8500,
              awardedEmojiSlotCount: 56,
              awardedStickerSlotCount: 45,
            },
            {
              subscriptionCount: 8750,
              awardedEmojiSlotCount: 57,
              awardedStickerSlotCount: 45,
            },
            {
              subscriptionCount: 9000,
              awardedEmojiSlotCount: 58,
              awardedStickerSlotCount: 46,
            },
            {
              subscriptionCount: 9250,
              awardedEmojiSlotCount: 59,
              awardedStickerSlotCount: 46,
            },
            {
              subscriptionCount: 9500,
              awardedEmojiSlotCount: 60,
              awardedStickerSlotCount: 47,
            },
            {
              subscriptionCount: 9750,
              awardedEmojiSlotCount: 61,
              awardedStickerSlotCount: 47,
            },
            {
              subscriptionCount: 10000,
              awardedEmojiSlotCount: 62,
              awardedStickerSlotCount: 48,
            },
          ],
        },
      },
    }.data as EmoteSlotLadderEntity;

    const resp =
      await axios.get<Result<EmoteSlotLadderEntity>>(`/emote/slot/ladder`);
    return assertSuccess(resp.data).data;
  }

  static async setFavorite(id: string, auth: string) {
    const resp = await axios.post<Result<EmoteFavoriteEntity>>(
      `/emote/${id}/favorite`,
      {},
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data).data;
  }

  static async removeFavorite(id: string, auth: string) {
    const resp = await axios.delete<
      MessageResult<"Emote Favorite was removed.">
    >(`/emote/${id}/favorite`, {
      headers: Headers.Bearer(auth),
    });
    return assertSuccess(resp.data).data;
  }

  static async getFavorites(params: GetEmoteFavoriteParams, auth: string) {
    const resp = await axios.get<CollectionResult<EmoteFavoriteEntity>>(
      `/emote/favorites${paramsParser(params)}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }
}

import { nanoid } from "nanoid";
import { assertSuccess } from "../core/assert";
import axios from "../core/axios";
import { paramsParser } from "../core/paramsParser";
import type {
  ConversationAccessTokenEntity,
  ConversationAccessTokenParams,
  ConversationCreatePayload,
  ConversationEntity,
} from "../types/conversation";
import type { Result } from "../types/result";
import { Headers } from "./generic";

export class Conversations {
  static async getAccessToken(
    params: ConversationAccessTokenParams,
    auth: string,
  ) {
    const resp = await axios.get<Result<ConversationAccessTokenEntity>>(
      `/conversation/access_token${paramsParser(params)}`,
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(resp.data);
  }

  static async createConversation(
    payload: ConversationCreatePayload,
    auth: string,
  ) {
    const resp = await axios.post<Result<ConversationEntity>>(
      `/conversation/create`,
      payload,
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(resp.data);
  }

  static async editConversation(
    id: string,
    payload: {
      title: string;
      organizationId?: string;
    },
    auth: string,
  ) {
    const resp = await axios.post<Result<ConversationEntity>>(
      `/conversation/${id}`,
      payload,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async uploadAvatar(
    conversationId: string,
    image: File | Blob,
    auth: string,
  ) {
    const formData = new FormData();
    formData.append(`avatar`, image, nanoid());

    const resp = await axios.post<Result<ConversationEntity>>(
      `/conversation/${conversationId}/avatar`,
      formData,
      {
        headers: {
          ...Headers.Bearer(auth),
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async deleteAvatar(
    conversationId: string,
    params: {
      organizationId?: string;
    },
    auth: string,
  ) {
    const resp = await axios.delete<Result<ConversationEntity>>(
      `/conversation/${conversationId}/avatar${paramsParser(params)}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async addParticipantsToConversation(
    conversationId: string,
    organizationId: string | undefined,
    participants: { type: "user" | "organization"; id: string }[],
    auth: string,
  ) {
    const resp = await axios.post<Result<ConversationEntity>>(
      `/conversation/${conversationId}/participants`,
      {
        organizationId,
        targetParticipants: participants,
      },
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async removeParticipantsFromConversation(
    conversationId: string,
    organizationId: string | null,
    participants: { type: "user" | "organization"; id: string }[],
    auth: string,
  ) {
    const resp = await axios.post<Result<ConversationEntity>>(
      `/conversation/${conversationId}/participants/remove`,
      {
        organizationId,
        targetParticipants: participants,
      },
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }
}

<template>
  <NotificationsItem :is-unread :href="link" :date="notification.date">
    <template #avatar>
      <template v-if="notification.data.sender">
        <UserAvatar
          :size="48"
          v-if="notification.data.sender.type === 'user'"
          :user="notification.data.sender"
          no-link
        />
        <OrganizationAvatar
          :size="48"
          v-else-if="notification.data.sender.type === 'organization'"
          :org="notification.data.sender"
        />
      </template>
      <template v-else>
        <AsyncImage
          src="/apple-touch-icon.png"
          class="w-12 h-12 aspect-square object-cover rounded-full"
        />
      </template>
    </template>
    <template #content>
      <div class="space-y-1">
        <p>
          <strong>{{ senderName }}</strong>
        </p>
        <p class="text-sm text-neutral-700 dark:text-neutral-300">
          <template v-if="notification.data.message.content">
            {{ notification.data.message.content }}
          </template>
          <template v-else-if="notification.data.message.attachments > 0">
            <span class="italic"
              >Sent
              {{ notification.data.message.attachments }} attachments</span
            >
          </template>
          <template v-else>
            <span class="italic">New Message</span>
          </template>
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import type { TwilioNotificationNewMessage } from "~/src/notifications/types/twilio/new-message";
import type { Props } from "../NotificationsItem.vue";
import type { OrganizationEntity, UserEntity } from "~/src/api";

const props = defineProps<
  {
    notification: TwilioNotificationNewMessage;
  } & Omit<Props, "actionIcon">
>();

const href = useHref();

const link = computed(() => {
  return href.value.messages.id(props.notification.data.conversation.sid);
});

const senderName = computed(() => {
  return props.notification.data.sender
    ? props.notification.data.sender.type === "user"
      ? getNameForUser(props.notification.data.sender)
      : getNameForOrganization(props.notification.data.sender)
    : "NamiComi";
});

function getNameForUser(user: UserEntity) {
  return user.attributes.displayName ?? user.attributes.username;
}

function getNameForOrganization(organization: OrganizationEntity) {
  return organization.attributes.name;
}
</script>

import { paramsParser } from "~/src/api";
import axios from "axios";

export const wpCategories = {
  anime: "78",
  manga: "80",
  novels: "428",
  games: "82",
  namicomi: "60",
};

export const wpCategoriesReverse = {
  "78": "anime",
  "80": "manga",
  "428": "novels",
  "82": "games",
  "60": "namicomi",
};

export interface TermIdTaxonomyQuery {
  terms: number[];
  include_children?: boolean;
  operator?: "AND" | "OR";
}

export interface WpV2PostsQueryParams {
  context?: "view" | "embed" | "edit";
  page?: number;
  per_page?: number;
  search?: string;
  after?: string;
  modified_after?: string;
  author?: number[];
  author_exclude?: number[];
  before?: string;
  modified_before?: string;
  exclude?: number[];
  include?: number[];
  offset?: number;
  order?: "asc" | "desc";
  orderby?:
    | "author"
    | "date"
    | "id"
    | "include"
    | "modified"
    | "parent"
    | "relevance"
    | "slug"
    | "include_slugs"
    | "title";
  search_columns?: "post_title" | "post_content" | "post_excerpt"[];
  slug?: string[];
  status?:
    | "publish"
    | "future"
    | "draft"
    | "pending"
    | "private"
    | "trash"
    | "auto-draft"
    | "inherit"
    | "request-pending"
    | "request-confirmed"
    | "request-failed"
    | "request-completed"
    | "any"[];
  tax_relation?: "AND" | "OR";
  categories?: number[] | TermIdTaxonomyQuery;
  categories_exclude?: number[] | TermIdTaxonomyQuery;
  tags?: number[] | TermIdTaxonomyQuery;
  tags_exclude?: number[] | TermIdTaxonomyQuery;
  sticky?: boolean;
}

export interface WpV2MediaQueryParams {
  id?: number;
  context?: "view" | "embed" | "edit";
}

export interface Post {
  id: number;
  date: string;
  date_gmt: string;
  slug: string;
  status: string;
  type: string;
  link: string;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
    protected: boolean;
  };
  excerpt: {
    rendered: string;
    protected: boolean;
  };
  author: number;
  featured_media: number;
  comment_status: string;
  ping_status: string;
  sticky: boolean;
  template: string;
  format: string;
  meta: any[];
  categories: number[];
  tags: number[];
  _links: {
    author: {
      id: number;
      name: string;
      avatar_urls: {
        [size: string]: string;
      };
      description: string;
    }[];
    "wp:featuredmedia": {
      id: number;
      href: string;
      title: {
        rendered: string;
      };
      caption: {
        rendered: string;
      };
      alt_text: string;
    }[];
    "wp:term": {
      id: number;
      name: string;
      slug: string;
    }[][];
  };
  // Additional properties specific to your WordPress installation
}

export interface Media {
  id: number;
  date: string;
  date_gmt: string;
  guid: {
    rendered: string;
  };
  modified: string;
  modified_gmt: string;
  slug: string;
  status: string;
  type: string;
  link: string;
  title: {
    rendered: string;
  };
  author: number;
  comment_status: string;
  ping_status: string;
  template: string;
  meta: any[];
  description: {
    rendered: string;
  };
  caption: {
    rendered: string;
  };
  alt_text: string;
  media_type: string;
  mime_type: string;
  post: number | null;
  media_details: {
    width: number;
    height: number;
    file: string;
    sizes: {
      [key: string]: {
        file: string;
        width: number;
        height: number;
        mime_type: string;
        source_url: string;
      };
    };
    image_meta: {
      aperture: string;
      credit: string;
      camera: string;
      caption: string;
      created_timestamp: string;
      copyright: string;
      focal_length: string;
      iso: string;
      shutter_speed: string;
      title: string;
      orientation: string;
      keywords: string[];
    };
  };
}

export type Tag = {
  id: number;
  count: number;
  description: string;
  link: string;
  name: string;
  slug: string;
  taxonomy: string;
  meta: any[];
  _links: (Record<
    "self" | "collection" | "about" | "wp:post_type",
    { href: string }
  > &
    Record<
      "curies",
      {
        name: string;
        href: string;
        templated: boolean;
      }
    >)[];
};

let baseUrl: string = "";

export function setBaseUrl(url: string) {
  baseUrl = url;
}

export async function getPosts(params: WpV2PostsQueryParams = {}) {
  const resp = await axios<Post[]>(`${baseUrl}/posts` + paramsParser(params));

  return resp.data;
}

export async function getSinglePost(id: number) {
  const resp = await axios<Post>(`${baseUrl}/posts/${id}`);

  return resp.data;
}

export async function getMedia(params: WpV2MediaQueryParams = {}) {
  const resp = await axios<Media[]>(`${baseUrl}/media` + paramsParser(params));

  return resp.data;
}

export async function getSingleMediaFromUrl(url: string) {
  const resp = await axios<Media>(url);

  return resp.data;
}

export async function getTagsFromIDs(tagIds: number[]) {
  const resp = await axios<Tag[]>(
    `${baseUrl}/tags${paramsParser({
      per_page: tagIds.length,
      include: tagIds.map((t) => t.toString()),
    })}`,
  );

  return resp.data;
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("app:created", () => {
    defineExtraBaseMethodsIfNecessary();
  });
});

function defineExtraBaseMethodsIfNecessary() {
  if (!isArrayPrototypeAlreadyExtended()) {
    defineArrayPrototypeMethods();
  }

  if (!isArrayConstructorAlreadyExpanded()) {
    defineArrayConstructorMethods();
  }

  if (!isObjectConstructorAlreadyExtended()) {
    defineObjectConstructorMethods();
  }

  if (!isStringPrototypeAlreadyExtended()) {
    defineStringPrototypeMethods();
  }

  if (!isNumberPrototypeAlreadyExtended()) {
    defineNumberPrototypeMethods();
  }
}

function isArrayPrototypeAlreadyExtended() {
  return (
    "dedupe" in Array.prototype &&
    "findOrThrow" in Array.prototype &&
    "findIndexOrThrow" in Array.prototype
  );
}

function isArrayConstructorAlreadyExpanded() {
  return "range" in Array && "partition" in Array;
}

function isObjectConstructorAlreadyExtended() {
  return (
    "typedKeys" in Object && "typedValues" in Object && "typedEntries" in Object
  );
}

function isStringPrototypeAlreadyExtended() {
  return "capitalize" in String.prototype;
}

function isNumberPrototypeAlreadyExtended() {
  return "round" in Number.prototype;
}

function defineArrayPrototypeMethods() {
  Array.prototype.dedupe = function (key = (item) => item) {
    const dedupedView = [] as any[];
    const dedupedArr = [] as typeof this;

    for (const item of this) {
      const itemKey = key(item);
      if (dedupedView.includes(itemKey)) {
        continue;
      } else {
        dedupedArr.push(item);
        dedupedView.push(itemKey);
      }
    }

    return dedupedArr;
  };

  Array.prototype.shuffle = function () {
    let currentIndex = this.length;
    let newArr = [...this];

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [newArr[currentIndex], newArr[randomIndex]] = [
        newArr[randomIndex],
        newArr[currentIndex],
      ];
    }

    return newArr;
  };

  Array.prototype.group = function (size: number) {
    let newArr = [];

    // slice in batches
    for (let i = 0; i < this.length; i += size) {
      newArr.push(this.slice(i, i + size));
    }

    return newArr;
  };

  Array.prototype.findOrThrow = function (predicate) {
    const index = this.findIndexOrThrow(predicate);

    return this[index];
  };

  Array.prototype.findIndexOrThrow = function (predicate) {
    const index = this.findIndex(predicate);

    if (index === -1) {
      throw new Error("Could not find predicate in array.");
    }

    return index;
  };

  Array.prototype.expand = function (min) {
    const copies = min / this.length;

    const expandedArray = [];

    for (let i = 0; i < copies; ++i) {
      expandedArray.push(...this);
    }

    return expandedArray;
  };
}

function defineArrayConstructorMethods() {
  function createRangeWithMax(max: number) {
    const total = Math.floor(max);

    return Array(total)
      .fill(null)
      .map((_, index) => index);
  }

  function createRangeWithMinMax(min: number, max: number) {
    if (max < min) {
      throw new Error("max must be greater than or equal to min");
    }

    const total = Math.floor(max - min);

    return Array(total)
      .fill(null)
      .map((_, index) => index + min);
  }

  function createRangeWithMinMaxAndStep(
    min: number,
    max: number,
    step: number,
  ) {
    const total = Math.abs(Math.floor((max - min) / step));

    return Array(total)
      .fill(null)
      .map((_, index) => index + min + index * step);
  }

  Array.range = function (
    ...args:
      | [max: number]
      | [min: number, max: number]
      | [min: number, max: number, step: number]
  ) {
    switch (args.length) {
      case 1:
        return createRangeWithMax(...args);
      case 2:
        return createRangeWithMinMax(...args);
      case 3:
        return createRangeWithMinMaxAndStep(...args);
    }
  };

  Array.partition = function (total, chunkSize) {
    if (!Number.isInteger(total) || !Number.isInteger(chunkSize)) {
      throw new Error("Both total and chunkSize must be integers");
    }

    return Array(Math.floor(total / chunkSize))
      .fill(chunkSize)
      .concat(total % chunkSize);
  };
}

function defineObjectConstructorMethods() {
  Object.typedKeys = function <T extends string | number, U>(o: Record<T, U>) {
    return Object.keys(o) as T[];
  };

  Object.typedValues = function <T extends string | number, U>(
    o: Record<T, U>,
  ) {
    return Object.values(o) as U[];
  };

  Object.typedEntries = function <T extends string | number, U>(
    o: Record<T, U>,
  ) {
    return Object.entries(o) as [T, U][];
  };
}

function defineStringPrototypeMethods() {
  String.prototype.capitalize = function () {
    const firstLetter = this.at(0);

    if (!firstLetter) return "";

    return firstLetter.toUpperCase() + this.slice(1);
  };
}

function defineNumberPrototypeMethods() {
  Number.prototype.round = function (places = 0) {
    const multiplier = Math.pow(10, places);
    return Math.round(this.valueOf() * multiplier) / multiplier;
  };

  Number.prototype.separate = function (separator = ",") {
    return this.toString().replace(
      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
      separator,
    );
  };
}

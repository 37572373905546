<template>
  <component
    :is="noLink ? 'div' : TheNuxtLink"
    class="org-card-dense grid grid-cols-[auto_1fr] items-center py-2 gap-4 rounded-md"
    :to="noLink ? undefined : href"
  >
    <OrganizationAvatar
      :org="org"
      class="flex-shrink-0"
      :size="avatarSize || 40"
      no-link
    />
    <div class="org-card-dense__name truncate">
      <BadgeUsername :class="plain ? 'font-medium' : 'font-bold'" :verified>
        {{ displayName }}
        <template #after>
          <slot name="postDisplayName"></slot>
        </template>
      </BadgeUsername>
      <div class="text-sm flex items-center" v-if="!nameOnly">
        <slot name="subtext"
          ><span class="inline-block opacity-70">Organization</span></slot
        >
        <slot name="postUsername"></slot>
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
import type {
  OrganizationEntity,
  OrganizationRelation,
  PopulateRelationship,
} from "~/src/api";
import { parseOrgForDisplay } from "~/utils/display/org";

const TheNuxtLink = resolveComponent("TheNuxtLink");

interface Props {
  org: OrganizationEntity | OrganizationRelation;
  avatarSize?: number;
  noLink?: boolean;
  plain?: boolean;
  nameOnly?: boolean;
}

const props = defineProps<Props>();

const { displayName, verified, href, tagline } = parseOrgForDisplay(
  computed(() => props.org),
  "small",
);
</script>

import { doFileUpload, EntityViewSet, Headers } from "../index";
import type {
  AchievementCreateBody,
  AchievementEditBody,
  AchievementEntity,
  AchievementListParams,
  AchievementProgressEntity,
  CollectionResult,
  OrganizationAchievementListParams,
  OrganizationAchievementProgressListParams,
  UserAchievementListParams,
  UserAchievementProgressListParams,
} from "../../types";
import axios from "../../core/axios";
import { assertSuccess, paramsParser } from "../../core";

class AchievementViewSet extends EntityViewSet<
  AchievementListParams,
  AchievementCreateBody,
  AchievementEditBody,
  AchievementEntity
> {
  constructor() {
    super("/achievement");
  }

  // create & update
  async uploadImage(
    id: string,
    image: File | Blob,
    version: number,
    auth: string,
  ) {
    return doFileUpload<AchievementEntity>(
      `${this.apiPath}/${id}/avatar?version=${version}`,
      image,
      auth,
    );
  }

  async getOrganizationAchievements(
    orgId: string,
    params: OrganizationAchievementListParams = {},
    auth?: string,
  ) {
    const resp = await axios.get<CollectionResult<AchievementEntity>>(
      `${this.apiPath}/award/organization/${orgId}` + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  async getOrganizationAchievementProgresses(
    orgId: string,
    params: OrganizationAchievementProgressListParams = {},
    auth?: string,
  ) {
    const resp = await axios.get<CollectionResult<AchievementProgressEntity>>(
      `${this.apiPath}/progress/organization` +
        paramsParser({ ...params, organizationId: orgId }),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  async getUserAchievements(
    userId: string,
    params: UserAchievementListParams = {},
    auth?: string,
  ) {
    const resp = await axios.get<CollectionResult<AchievementEntity>>(
      `${this.apiPath}/award/user/${userId}` + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  async getUserAchievementProgresses(
    userId: string,
    params: UserAchievementProgressListParams = {},
    auth?: string,
  ) {
    const resp = await axios.get<CollectionResult<AchievementProgressEntity>>(
      `${this.apiPath}/progress/user` + paramsParser({ ...params, userId }),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }
}

export const Achievement = new AchievementViewSet();

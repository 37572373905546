export type ReadProgressOptions = {
  isEnabled?: boolean;
};

export function useReadProgress(
  startIndex: MaybeRef<number>,
  currentIndex: MaybeRef<number>,
  finalIndex: MaybeRef<number>,
  options?: ReadProgressOptions,
) {
  const currentIndexMin = ref(unref(currentIndex));
  const currentIndexMax = ref(unref(currentIndex));
  const isEnabled = ref(options?.isEnabled ?? true);

  const hasReachedMin = computed(
    () => currentIndexMin.value <= unref(startIndex),
  );
  const hasReachedMax = computed(
    () => currentIndexMax.value >= unref(finalIndex),
  );
  const hasReachedAll = computed(
    () => hasReachedMax.value && hasReachedMin.value,
  );

  function reportReadIndex(index: number) {
    if (!isEnabled.value) return;
    currentIndexMax.value =
      index > currentIndexMax.value ? index : currentIndexMax.value;
    currentIndexMin.value =
      index < currentIndexMin.value ? index : currentIndexMin.value;
  }

  function disable() {
    isEnabled.value = false;
  }

  function enable() {
    isEnabled.value = true;
  }

  return {
    currentIndexMin,
    currentIndexMax,
    reportReadIndex,
    hasReachedMin,
    hasReachedMax,
    hasReachedAll,
    disable,
    enable,
  };
}

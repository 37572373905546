export async function withToken<R>(cb: (token: string) => R): Promise<R> {
  const { $auth } = useNuxtApp();

  const token = await $auth()?.getToken();
  if (!token) {
    throw new Error("Not logged in.");
  }

  return cb(token);
}

export async function getTokenOrThrow() {
  const { $auth } = useNuxtApp();

  const token = await $auth()?.getToken();

  if (!token) {
    throw new Error("Not logged in.");
  }

  return token;
}

export async function getTokenOrPrompt() {
  const { $auth, $app } = useNuxtApp();

  const token = await $auth()?.getToken();

  if (!token) {
    $app()?.openLoginRequiredModal();
    throw new Error("Not logged in.");
  }

  return token as string;
}

// https://vue-i18n.intlify.dev/guide/integrations/nuxt3.html#optimize-with-intlify-unplugin-vue-i18n

import { createI18n } from "vue-i18n";
import en from "../locales/en.json";
import es_la from "../locales/es-la.json";
import fr from "../locales/fr.json";
import it from "../locales/it.json";
import ja from "../locales/ja.json";
import ko from "../locales/ko.json";
import pt_br from "../locales/pt-br.json";

export default defineNuxtPlugin((nuxtApp) => {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "en",
    messages: {
      en,
      "es-419": es_la,
      fr,
      it,
      ja,
      ko,
      "pt-br": pt_br,
      // TODO: Add these when available
      // de: en,
    },
  });

  nuxtApp.vueApp.use(i18n);

  return {
    provide: {
      i18n: i18n,
      t: i18n.global.t,
    },
  };
});

import type { GroupRoleMap, Role } from "~/src/api";

export type Feature =
  | "arbitraryAccessToStudio"
  | "monthlyDailySubscriptionIntervalShift"
  | "organizationDelete"
  | "orgStats"
  | "chapterCollections"
  | "canEditGlobalEmotes"
  | "canEditGlobalFrames"
  | "canEditGlobalBadges"
  | "canEditGlobalAchievements"
  | "any";

const features = {
  arbitraryAccessToStudio: ["GROUP_STAFF"],
  monthlyDailySubscriptionIntervalShift: ["GROUP_STAFF"],
  organizationDelete: ["GROUP_STAFF"],
  orgStats: ["GROUP_STAFF"],
  chapterCollections: ["GROUP_STAFF"],
  canEditGlobalEmotes: ["GROUP_STAFF"],
  canEditGlobalFrames: ["GROUP_STAFF"],
  canEditGlobalBadges: ["GROUP_STAFF"],
  canEditGlobalAchievements: ["GROUP_STAFF"],
  any: ["GROUP_STAFF"],
} as Record<Feature, GroupRoleMap<Role>[]>;

function hasAccessToFeature(feature: Feature, userRoles: GroupRoleMap<Role>[]) {
  return userRoles.some((role) => features[feature].includes(role));
}

export function useFeatureAccess(feature: Feature) {
  const nuxtApp = useNuxtApp();
  const authSture = nuxtApp.$auth();

  return computed(() =>
    authSture?.user
      ? hasAccessToFeature(
          feature,
          authSture.user.profile.groups as GroupRoleMap<Role>[],
        )
      : false,
  );
}

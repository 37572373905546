import { assertSuccess, paramsParser } from "../core";
import { Headers } from "./generic";
import axios from "../core/axios";
import { nanoid } from "nanoid";
import { Statistics } from "./statistics";
import type {
  AvailableLanguages,
  ChapterApprovalDecision,
  ChapterApprovalEntity,
  ChapterApprovalListParams,
  ChapterEntity,
  ChapterHistoryEntity,
  ChapterHistoryParams,
  ChapterListParams,
  ChapterPostBody,
  ChapterState,
  ChapterUpdateEntity,
  Collection,
  CollectionResult,
  CompactResult,
  GenericListParams,
  IncludeArr,
  Result,
  ServerData,
  Success,
} from "../types";

export type GetChapterLastUpdatedParams = GenericListParams & {
  includes?: ChapterUpdateEntity["relationships"][number]["type"][];
  trimBuckets?: boolean;
  translatedLanguages: AvailableLanguages[];
};

const mapImageQualities = (
  chapterId: string,
  serverData: ServerData,
  quality: "source" | "high" | "medium" | "low",
) => {
  return serverData[quality].map((p) => ({
    imageUrl: `${serverData.baseUrl}/chapter/${chapterId}/${serverData.hash}/${quality}/${p.filename}`,
    resolution: p.resolution,
    size: p.size,
  }));
};

export const ALL_CHAPTER_STATES: ChapterState[] = [
  "pending",
  "rejected",
  "published",
  "unpublished",
  "processing",
  "publish_delayed",
];

export class Chapter {
  static async search(
    params: ChapterListParams = {},
    auth?: string,
  ): Promise<Collection<ChapterEntity>> {
    const resp = await axios<CollectionResult<ChapterEntity>>(
      `/chapter` + paramsParser(params),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
        responseType: "json",
      },
    );

    return assertSuccess(resp.data);
  }

  static async get(
    id: string,
    includes: IncludeArr<ChapterEntity> = [],
    auth?: string,
  ): Promise<ChapterEntity> {
    const resp = await axios<Result<ChapterEntity>>(
      `/chapter/${id}` + paramsParser({ includes: includes }),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
        responseType: "json",
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async updateMetadata(
    chapterId: string,
    body: ChapterPostBody,
    version: number,
    auth: string,
  ) {
    const resp = await axios<Result<ChapterEntity>>(`/chapter/${chapterId}`, {
      method: "PUT",
      headers: { ...Headers.Bearer(auth), ...Headers.JSON },
      data: {
        ...body,
        // organizations: chapterTemplate.organizations?.map(group => typeof group === 'string' ? group : group.id),
        // Disabled for now until API supports it
        organizations: undefined,
        version,
      },
    });

    return assertSuccess(resp.data).data;
  }

  static async update(
    chapterId: string,
    body: ChapterPostBody,
    version: number,
    auth: string,
  ) {
    body.chapter = body.chapter?.trim() ?? null;
    body.volume = body.volume?.trim() ?? null;
    body.name = body.name?.trim() ?? null;

    const resp = await axios<Result<ChapterEntity>>(`/chapter/${chapterId}`, {
      method: "PUT",
      headers: { ...Headers.Bearer(auth), ...Headers.JSON },
      data: {
        ...body,
        organizations: undefined,
        version: version,
      },
    });

    return assertSuccess(resp.data).data;
  }

  static async uploadThumbnail(
    image: File | Blob,
    chapterId: string,
    version: number,
    auth: string,
  ) {
    const formData = new FormData();
    formData.append(`avatar`, image, nanoid());
    formData.append("version", version.toString());
    return axios<Result<ChapterEntity>>(`/chapter/${chapterId}/avatar`, {
      method: "POST",
      headers: {
        ...Headers.Bearer(auth),
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  }

  static async deleteThumbnail(
    chapterId: string,
    version: number,
    auth: string,
  ) {
    return axios<Result<ChapterEntity>>(`/chapter/${chapterId}/avatar`, {
      method: "DELETE",
      headers: {
        ...Headers.Bearer(auth),
        "Content-Type": "application/json",
      },
      data: {
        version,
      },
    });
  }

  static async approve(chapterId: string, version: number, auth: string) {
    const resp = await axios<CompactResult>(
      `/admin/chapter/approval/${chapterId}`,
      {
        method: "PUT",
        headers: Headers.Bearer(auth),
        data: { version: version },
      },
    );

    return assertSuccess(resp.data);
  }

  static async publish(chapterId: string, version: number, auth: string) {
    const resp = await axios<Result<ChapterEntity>>(
      `/chapter/${chapterId}/publish`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: { version: version },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async unpublish(chapterId: string, version: number, auth: string) {
    const resp = await axios<Result<ChapterEntity>>(
      `/chapter/${chapterId}/unpublish`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: { version: version },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async resubmit(chapterId: string, version: number, auth: string) {
    const resp = await axios<Result<ChapterEntity>>(
      `/chapter/${chapterId}/resubmit`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: { version: version },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async delete(chapterId: string, version: number, auth: string) {
    const resp = await axios(`/chapter/${chapterId}`, {
      method: "DELETE",
      headers: Headers.Bearer(auth),
      data: { version: version },
    });

    return assertSuccess(resp.data);
  }

  static async getPageUrls(chapterId: string, auth?: string) {
    const resp = await axios<Result<ServerData>>(
      `/images/chapter/${chapterId}?newQualities=true`,
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
        responseType: "json",
      },
    );

    const serverData = assertSuccess(resp.data).data;

    return {
      source: mapImageQualities(chapterId, serverData, "source"),
      high: mapImageQualities(chapterId, serverData, "high"),
      medium: mapImageQualities(chapterId, serverData, "medium"),
      low: mapImageQualities(chapterId, serverData, "low"),
      expiresAt: Date.now() + 5 * 60 * 1000,
    };
  }

  static async getStats(id: string) {
    return Statistics.getChapterStats(id);
  }

  static async getAvailableLanguages(titleId?: string) {
    const res = await axios.get<
      CompactResult<{
        type: "chapter_languages";
        data: {
          aggregatedLanguageCounts: { [key in AvailableLanguages]?: number };
        };
      }>
    >("/chapter/languages" + (titleId ? `?titleIds[]=${titleId}` : ""));

    return assertSuccess(res.data);
  }

  static async getHistory(
    params: ChapterHistoryParams,
    auth: string,
  ): Promise<Collection<ChapterHistoryEntity>> {
    const resp = await axios<CollectionResult<ChapterHistoryEntity>>(
      `/chapter/history` + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
        responseType: "json",
      },
    );

    return assertSuccess(resp.data);
  }

  static async pushHistory(
    chapterId: string,
    auth: string,
    timestamp?: number,
  ) {
    const calculatedTimestamp = timestamp || Math.round(Date.now() / 1000);

    const resp = await axios<CompactResult<{ message: string }>>(
      `/chapter/${chapterId}/history`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        responseType: "json",
        data: {
          timestamp: calculatedTimestamp,
        },
      },
    );

    return assertSuccess(resp.data);
  }

  static async pushHistoryBatch(
    chapters: { [chapterId: string]: number },
    auth: string,
  ) {
    const resp = await axios<CompactResult<{ message: string }>>(
      "/chapter/history/batch",
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        responseType: "json",
        data: {
          data: chapters,
        },
      },
    );

    return assertSuccess(resp.data);
  }

  static async getLastUpdated(params: GetChapterLastUpdatedParams) {
    const resp = await axios<CollectionResult<ChapterUpdateEntity>>(
      `/chapter/latest` + paramsParser(params),
    );

    return assertSuccess(resp.data);
  }
}

export class ChapterApproval {
  static async search(
    params: ChapterApprovalListParams = {},
    auth: string,
  ): Promise<Collection<ChapterApprovalEntity>> {
    const resp = await axios<CollectionResult<ChapterApprovalEntity>>(
      `/admin/chapter/approval` + paramsParser(params),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
        responseType: "json",
      },
    );

    return assertSuccess(resp.data);
  }

  static async get(
    approvalId: string,
    includes: IncludeArr<ChapterApprovalEntity> = [],
    auth: string,
  ): Promise<Success<ChapterApprovalEntity>> {
    const resp = await axios<Result<ChapterApprovalEntity>>(
      `/admin/chapter/approval/${approvalId}` +
        paramsParser({ includes: includes }),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  static async update(
    approvalId: string,
    decision: ChapterApprovalDecision,
    version: number,
    auth: string,
  ) {
    const resp = await axios<CompactResult>(
      `/admin/chapter/approval/${approvalId}`,
      {
        method: "PUT",
        headers: Headers.Bearer(auth),
        data: {
          decision: decision,
          version: version,
        },
      },
    );

    return assertSuccess(resp.data);
  }
}

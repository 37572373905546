<template>
  <slot :time-ago-text>
    <span class="text-sm text-neutral-600 dark:text-neutral-400">
      {{ timeAgoText }}
    </span>
  </slot>
</template>

<script setup lang="ts">
const props = defineProps<{
  date: Date;
}>();

const nuxtApp = useNuxtApp();
const { t, locale } = nuxtApp.$i18n.global;
const timeAgo = useTimeAgo(locale);

const timeAgoText = computed(() => timeAgo.value(props.date, "mini"));
</script>

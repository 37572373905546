import { config } from "../core";
import type {
  AchievementEntity,
  AchievementRelation,
  AssetEntity,
  AssetRelation,
  AvailableLanguages,
  BadgeEntity,
  BadgeRelation,
  ChapterEntity,
  ChapterRelation,
  CoverEntity,
  CoverRelation,
  EmoteEntity,
  OrganizationEntity,
  OrganizationRelation,
  PopulateRelationship,
  PostMediaRelation,
  TierEntity,
  TierRelation,
  TitleEntity,
  TitleListEntity,
  TitleRelation,
  UserEntity,
  UserRelations,
} from "../types";
import { getMultipleRelationships, getRelationship } from "./common";

type ImageEntity =
  | "chapter"
  | "title"
  | "organization"
  | "user"
  | "emote"
  | "tier"
  | "post"
  | "comment"
  | "conversation"
  | "asset"
  | "title_list"
  | "badge"
  | "achievement";
type ImageCategory =
  | "cover"
  | "avatar"
  | "banner"
  | "post_image"
  | "media"
  | "emoji"
  | "sticker";

type AvatarResolutions = "128" | "256" | "512" | "original";

type BannerResolutions = "640" | "1280" | "1920" | "original";

// semantics
type CoverResolutions = AvatarResolutions;

type PostImageResolutions = "256" | "720" | "original";

type EmoteResolutions = "128" | "256" | "512" | "original";

type StickerResolutions = "128" | "256" | "512" | "original";

type Resolutions =
  | AvatarResolutions
  | BannerResolutions
  | PostImageResolutions
  | EmoteResolutions
  | StickerResolutions;

export function getImageUrl<T extends string | null | undefined>(
  entityId: string,
  // a bit messy, but profile frames use /media/emote/.../<avatar|banner>/...
  entityType:
    | "tier"
    | "chapter"
    | "organization"
    | "user"
    | "conversation"
    | "emote"
    | "badge"
    | "achievement",
  imageCategory: "avatar",
  fileName?: T,
  res?: AvatarResolutions,
): string | undefined;

export function getImageUrl<T extends string | null | undefined>(
  entityId: string,
  entityType: "comment",
  imageCategory: "media",
  fileName?: T,
  res?: PostImageResolutions,
): string | undefined;

export function getImageUrl<T extends string | null | undefined>(
  entityId: string,
  entityType: "post",
  imageCategory: "post_image",
  fileName?: T,
  res?: PostImageResolutions,
): string | undefined;

export function getImageUrl<T extends string | null | undefined>(
  entityId: string,
  entityType: "title",
  imageCategory: "cover",
  fileName?: T,
  res?: CoverResolutions,
): string | undefined;

export function getImageUrl<T extends string | null | undefined>(
  entityId: string,
  entityType: "asset",
  // this field is ignored
  imageCategory: ImageCategory,
  fileName?: T,
): string | undefined;

export function getImageUrl<T extends string | null | undefined>(
  entityId: string,
  // a bit messy, but profile frames use /media/emote/.../<avatar|banner>/...
  entityType:
    | "title"
    | "title_list"
    | "organization"
    | "tier"
    | "user"
    | "emote",
  imageCategory: "banner",
  fileName?: T,
  res?: BannerResolutions,
): string | undefined;

export function getImageUrl<T extends string | null | undefined>(
  entityId: string,
  entityType: "emote",
  // a bit messy, but profile frames use /media/emote/.../<avatar|banner>/...
  imageCategory: "emoji" | "sticker" | "avatar" | "banner",
  fileName?: T,
  res?: EmoteResolutions | StickerResolutions,
): string | undefined;

export function getImageUrl<T extends string | null | undefined>(
  entityId: string,
  entityType: ImageEntity,
  imageCategory: ImageCategory,
  fileName?: T,
  res: Resolutions = "original",
) {
  if (!fileName) return;

  // this is so we can mock title entities and pass them to the display functions
  if (fileName.startsWith("blob:")) return fileName;

  const actualRes = res !== "original" ? `.${res}.jpg` : "";

  const actualEntityType = entityType === "title" ? "manga" : entityType;

  switch (entityType) {
    // The entity type for tier is "subscription" in the upload service
    case "tier":
      return `${config.cdnOrigin}/media/subscription/${entityId}/${imageCategory}/${fileName}${actualRes}`;

    case "asset":
      return `${config.cdnOrigin}/media/system/${entityId}/system_asset/${fileName}`;

    case "comment":
      return `${config.cdnOrigin}/media/comment/${entityId}/post_image/${fileName}${actualRes}`;

    case "emote":
      return `${config.cdnOrigin}/media/emote/${entityId}/${imageCategory}/${fileName}${actualRes}`;

    default:
      return `${config.cdnOrigin}/media/${actualEntityType}/${entityId}/${imageCategory}/${fileName}${actualRes}`;
  }
}

type GetCoverUrlParamsPlain = [
  titleId: string,
  coverFileName: string,
  res?: "128" | "256" | "512" | "original",
];
type GetCoverUrlParamsEntity = [
  coverEntity: CoverEntity,
  res?: "128" | "256" | "512" | "original",
];
type GetCoverUrlParamsRelation = [
  coverRelation: PopulateRelationship<CoverRelation>,
  titleId: string,
  res?: "128" | "256" | "512" | "original",
];
type GetCoverUrlParamsTitle = [
  title: TitleEntity,
  res?: "128" | "256" | "512" | "original",
];

function getCoverUrlWithEntity(...args: GetCoverUrlParamsEntity) {
  const [coverEntity, res = "original"] = args;
  const titleId = getRelationship(coverEntity, "title").id;
  return getCoverUrlPlain(titleId, coverEntity.attributes.fileName, res);
}

function getCoverUrlWithRelation(...args: GetCoverUrlParamsRelation) {
  const [coverRelation, titleId, res = "original"] = args;
  return getCoverUrlPlain(titleId, coverRelation.attributes.fileName, res);
}

function getCoverUrlWithTitle(...args: GetCoverUrlParamsTitle) {
  const [title, res = "original"] = args;
  const coverRelationship = getRelationship(title, "cover_art", true);
  return getCoverUrlPlain(title.id, coverRelationship.attributes.fileName, res);
}

function getCoverUrlPlain(...args: GetCoverUrlParamsPlain) {
  const [titleId, coverFileName, res = "original"] = args;

  // this is so we can mock title entities and pass them to the display functions
  if (coverFileName.startsWith("blob:")) return coverFileName;

  const actualRes = res !== "original" ? `.${res}.jpg` : "";
  return `${config.cdnOrigin}/covers/${titleId}/${coverFileName}${actualRes}`;
}

export function getCoverUrl(...args: GetCoverUrlParamsPlain): string;
export function getCoverUrl(...args: GetCoverUrlParamsEntity): string;
export function getCoverUrl(...args: GetCoverUrlParamsRelation): string;
export function getCoverUrl(...args: GetCoverUrlParamsTitle): string;
export function getCoverUrl(
  ...args:
    | GetCoverUrlParamsPlain
    | GetCoverUrlParamsEntity
    | GetCoverUrlParamsRelation
    | GetCoverUrlParamsTitle
) {
  if (typeof args[0] === "string")
    return getCoverUrlPlain(...(args as GetCoverUrlParamsPlain));
  else if (args[0].type === "title")
    return getCoverUrlWithTitle(...(args as GetCoverUrlParamsTitle));
  else if ("relationships" in args[0])
    return getCoverUrlWithEntity(...(args as GetCoverUrlParamsEntity));
  else return getCoverUrlWithRelation(...(args as GetCoverUrlParamsRelation));
}

function getTitleCoversPerLocale(
  title: TitleEntity,
  res?: "128" | "256" | "512" | "original",
) {
  return Object.fromEntries(
    getMultipleRelationships(title, "cover_art", true).map((cover) => [
      cover.attributes.locale,
      getCoverUrl(cover, title.id, res),
    ]),
  ) as { [K in AvailableLanguages]?: string };
}

/**
 * Select a cover from a specific locale, if available.
 * If that does not exist, it will search for the title's original language.
 * Then for an English one, and then for the first available, before returning a placeholder.
 * @param locale The cover's locale to prioritize looking for
 * @param title The title entity. It must have expanded cover_art relationship
 * @param res The resolution for the cover (defaults to original).
 */
export function selectLocalizedCover(
  locale: AvailableLanguages,
  title: TitleEntity,
  res?: "128" | "256" | "512" | "original",
) {
  const covers = getTitleCoversPerLocale(title, res);
  if (covers[locale]) return covers[locale]!;
  if (covers[title.attributes.originalLanguage])
    return covers[title.attributes.originalLanguage]!;
  if (covers.en) return covers.en;
  return Object.values(covers)[0];
}

export function getLocalizedCoverFromTitleAndCoverRelationships(
  locale: AvailableLanguages,
  title: TitleEntity | PopulateRelationship<TitleRelation>,
  covers: PopulateRelationship<CoverRelation>[],
  res?: "128" | "256" | "512" | "original",
) {
  const coversPerLocale = Object.fromEntries(
    covers.map((cover) => [cover.attributes.locale, cover]),
  ) as { [K in AvailableLanguages]?: PopulateRelationship<CoverRelation> };

  return (
    coversPerLocale[locale] ||
    coversPerLocale[title.attributes.originalLanguage] ||
    coversPerLocale.en ||
    covers[0]
  );
}

export function getChapterThumbnail(
  chapter: ChapterEntity | PopulateRelationship<ChapterRelation>,
  res?: "128" | "256" | "512" | "original",
) {
  if (!chapter.attributes.avatarFileName) return;
  return getImageUrl(
    chapter.id,
    "chapter",
    "avatar",
    chapter.attributes.avatarFileName,
    res,
  );
}

export function getOrganizationAvatar(
  org: OrganizationEntity | PopulateRelationship<OrganizationRelation>,
  res?: "128" | "256" | "512" | "original",
) {
  return getImageUrl(
    org.id,
    "organization",
    "avatar",
    org.attributes.avatarFileName,
    res,
  );
}

export function getOrganizationBanner(
  org: OrganizationEntity | PopulateRelationship<OrganizationRelation>,
  res?: "640" | "1280" | "1920" | "original",
) {
  return getImageUrl(
    org.id,
    "organization",
    "banner",
    org.attributes.bannerFileName,
    res,
  );
}

export function getUserAvatar(
  user: UserEntity | PopulateRelationship<UserRelations>,
  res?: "128" | "256" | "512" | "original",
) {
  return getImageUrl(
    user.id,
    "user",
    "avatar",
    user.attributes.avatarFileName,
    res,
  );
}

export function getUserBanner(
  user: UserEntity | PopulateRelationship<UserRelations>,
  res?: "640" | "1280" | "1920" | "original",
) {
  return getImageUrl(
    user.id,
    "user",
    "banner",
    user.attributes.bannerFileName,
    res,
  );
}

export function getTitleBanner(
  title: TitleEntity | PopulateRelationship<TitleRelation>,
  res?: "640" | "1280" | "1920" | "original",
) {
  return getImageUrl(
    title.id,
    "title",
    "banner",
    title.attributes.bannerFileName,
    res,
  );
}

export function getTierBanner(
  tier: TierEntity | PopulateRelationship<TierRelation>,
  res?: "640" | "1280" | "1920" | "original",
) {
  return getImageUrl(
    tier.id,
    "tier",
    "banner",
    tier.attributes.bannerFileName,
    res,
  );
}

export function getEmote(
  emote: EmoteEntity,
  res: EmoteResolutions = "original",
) {
  return getImageUrl(
    emote.id,
    "emote",
    "emoji",
    emote.attributes.fileName,
    res,
  );
}

export function getSticker(
  emote: EmoteEntity,
  res: StickerResolutions = "original",
) {
  return getImageUrl(
    emote.id,
    "emote",
    "sticker",
    emote.attributes.fileName,
    res,
  );
}

export function getTitleListBanner(
  list: TitleListEntity,
  res: BannerResolutions = "original",
) {
  return getImageUrl(
    list.id,
    "title_list",
    "banner",
    list.attributes.bannerFileName,
    res,
  );
}

// assets dont exist without an image
export function getAssetUrl(
  asset: AssetEntity | PopulateRelationship<AssetRelation>,
  res: "SMALL" | "MEDIUM" | "LARGE" | "SOURCE" = "SOURCE",
) {
  return getImageUrl(
    asset.id,
    "asset",
    "avatar",
    asset.attributes.imageData[res],
  )!;
}

export function getBadgeImage(
  badge: BadgeEntity | PopulateRelationship<BadgeRelation>,
  res: AvatarResolutions = "original",
) {
  return getImageUrl(
    badge.id,
    "badge",
    "avatar",
    badge.attributes.avatarFileName,
    res,
  );
}

export function getAchievementImage(
  achievement: AchievementEntity | PopulateRelationship<AchievementRelation>,
  res: AvatarResolutions = "original",
) {
  return getImageUrl(
    achievement.id,
    "achievement",
    "avatar",
    achievement.attributes.avatarFileName,
    res,
  );
}

export function getPostMediaUrls(
  postMedia: PostMediaRelation,
  res?: "256" | "720" | "original",
) {
  return (
    postMedia.attributes?.files.map((file) =>
      getImageUrl(postMedia.id, "post", "post_image", file.fileName, res),
    ) ?? []
  );
}

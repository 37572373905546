import type { MessagePayload } from "firebase/messaging";

export function isNamiNotificationPayload(payload: MessagePayload) {
  return "notification" in payload;
}

export function isTwilioNewMessagePayload(payload: MessagePayload) {
  return (
    payload.data &&
    payload.data.twi_message_type === "twilio.conversations.new_message"
  );
}

function isTwilioMessageConsumptionPayload(payload: MessagePayload) {
  return (
    payload.data &&
    payload.data.twi_message_type === "twilio.channel.consumption_update"
  );
}

function isTwilioConversationCreationPayload(payload: MessagePayload) {
  return (
    payload.data &&
    payload.data.twi_message_type ===
      "twilio.conversations.added_to_conversation"
  );
}

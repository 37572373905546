<template>
  <component
    :is="noLink ? 'div' : TheNuxtLink"
    :to="noLink ? undefined : href.messages.id(conversation.sid)"
    class="grid grid-cols-[3rem_1fr_0.5rem] items-center gap-2 p-2 canhover:hover:bg-neutral-200 canhover:dark:hover:bg-neutral-800 cursor-pointer select-none border-r-2 rounded-l-md"
    @click="$emit('select')"
    :class="{
      'border-transparent': !active,
      'border-nami-comi-blue bg-neutral-200 dark:bg-neutral-800': active,
      'py-[0.625rem]': users.length > 2,
    }"
  >
    <template v-if="firstUser">
      <ConversationUserAvatarPreviews
        class="h-12 aspect-square"
        :users
        :current-user
        :avatar-url="conversationAvatarUrl"
      />
      <div class="grid">
        <div
          class="grid grid-cols-[auto_auto_auto_auto_auto_1fr] items-center gap-1"
          :class="{
            'py-[0.625rem]': !lastMessage,
          }"
        >
          <span class="truncate font-semibold">
            <ConversationName :conversation :users :name="conversationName" />
          </span>
          <template v-if="otherUsers.length === 1 && firstUser">
            <span
              v-if="
                firstUser.type === 'user' &&
                firstUser.username !== firstUser.displayName
              "
              class="truncate text-neutral-700 dark:text-neutral-600 text-sm"
            >
              @{{ firstUser.username }}
            </span>
            <BadgeUsername
              :badges="firstUser.badges"
              :official="firstUser.official"
              :verified="firstUser.verified"
            />
          </template>
          <template v-if="lastMessage">
            <span class="text-neutral-700 dark:text-neutral-600">
              &middot;
            </span>
            <span
              class="text-neutral-700 dark:text-neutral-600 text-sm whitespace-nowrap"
            >
              {{
                formatTime(
                  lastMessage?.date.toISOString() ?? "1970-01-01T00:00:00.000Z",
                )
              }}
            </span>
          </template>
          <div></div>
        </div>
        <span
          class="truncate text-sm"
          :class="{
            'text-neutral-700 dark:text-neutral-600': !active,
            italic:
              isLastMessageDeleted || lastGroup?.type === GroupType.MEDIAONLY,
          }"
          v-if="lastMessage?.index !== undefined"
        >
          {{
            isLastMessageDeleted
              ? lastMessage?.author?.id === currentUser.id
                ? "You deleted this message."
                : "This message has been deleted."
              : lastGroup?.type === GroupType.MEDIAONLY
                ? `${lastGroup.messages.length} attachments`
                : lastMessage.content
          }}
        </span>
      </div>
      <div
        v-if="!hasReadAllMessages"
        class="w-2 h-2 rounded-full bg-nami-comi-blue"
      ></div>
    </template>
  </component>
</template>

<script setup lang="ts">
import { convertTwilioUserToDisplayData, type UserDisplayData } from "./common";
import TheNuxtLink from "../the/TheNuxtLink.vue";

const props = defineProps<{
  conversation: EnhancedConversation;
  conversationMessageData: ConversationsMessageData[keyof ConversationsMessageData];
  groups: MessageGroupFamilyOrContainer[];
  conversationName: string | null;
  active?: boolean;
  hasReadAllMessages?: boolean;
  noLink?: boolean;
  avatarFileName: string | null | undefined;
  currentUser: UserDisplayData;
}>();

defineEmits<{
  (e: "select"): void;
}>();

const dayjs = useDayjs();
const href = useHref();
const { getImageUrl } = useMediaLink();

const { data: users } = useAsyncData(
  `convo-users-${props.conversation.sid}`,
  async () => {
    const participants = await props.conversation.getParticipants();
    const users = (await Promise.all(
      participants.map((p) => p.getUser()),
    )) as EnhancedUser[];
    return users.map((user) => convertTwilioUserToDisplayData(user));
  },
  { watch: [() => props.conversation], default: () => [] },
);

const otherUsers = computed(() => {
  const currentTwilioUserId = props.currentUser.id;
  return users.value.filter((user) => user.id !== currentTwilioUserId);
});

const lastGroup = computed(() => {
  return getLastGroup(props.groups);
});

const lastMessage = computed(() => {
  return getLastMessage(props.groups);
});

const isLastMessageDeleted = computed(() => {
  const message = lastMessage.value;
  return message && message.content !== "" && isMediaOnly(message);
});

const firstUser = computed(() => otherUsers.value.at(0));

const conversationAvatarUrl = computed(() => {
  if (!props.avatarFileName) return null;
  return getImageUrl(
    props.conversation.sid,
    "conversation",
    "avatar",
    props.avatarFileName,
    "256",
  );
});

function formatTime(time: string) {
  const date = dayjs(time);
  const wasLastYear = date.year() !== dayjs().year();

  return date.format(wasLastYear ? "D MMM YYYY" : "D MMM");
}

function getLastGroup(groups: MessageGroupFamilyOrContainer[]) {
  const lastGroup = groups.at(-1);
  if (!lastGroup) return undefined;
  if (isGroupFamily(lastGroup)) {
    return getLastGroup(lastGroup.groups);
  }
  return lastGroup;
}

function getLastMessage(groups: MessageGroupFamilyOrContainer[]) {
  const lastGroup = getLastGroup(groups);
  if (!lastGroup) return undefined;
  const lastMessage = lastGroup.messages.at(-1);
  if (!lastMessage) return undefined;
  return lastMessage;
}
</script>

<template>
  <div
    class="container flex items-center justify-center"
    :class="{
      'h-80': finalSize === 'normal',
      'h-16': finalSize === 'small',
      static: noSpin,
    }"
  >
    <div class="outer">
      <div class="inner">
        <svg
          id="Layer_2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2000 2000"
          fill="#737373"
          :class="{
            'h-20 w-20': finalSize === 'normal',
            'h-8 w-8': finalSize === 'small',
          }"
        >
          <path
            class="cls-3"
            d="M996.05,2000c3.12,0,6.24-.02,9.36-.06-3.2,.03-6.4,.06-9.61,.06,.08,0,.17,0,.25,0Z"
          />
          <path class="cls-2" d="M1457.3,1046.63s.01-.02,0,0h0Z" />
          <path
            class="cls-2"
            d="M1457.2,1046.79c-.04,.06-.07,.11-.07,.11,.03-.05,.05-.07,.07-.11Z"
          />
          <path
            class="cls-2"
            d="M1457.2,1046.79c.04-.06,.08-.13,.1-.16-.01,.02-.05,.08-.1,.16Z"
          />
          <path
            class="cls-4"
            d="M1963.96,749.87c.08,.29,.15,.58,.22,.88-.07-.29-.15-.58-.22-.88Z"
          />
          <path
            class="cls-4"
            d="M995.54,0s-.01,0-.02,0c-1.12,0-2.23,.02-3.35,.02,.02,0,.04,0,.06,0,1.1,0,2.2-.02,3.31-.02Z"
          />
          <path
            class="cls-4"
            d="M1607.62,205.73c.35,.27,.7,.54,1.06,.82-.35-.27-.7-.54-1.06-.82Z"
          />
          <path
            class="cls-4"
            d="M1615.7,212c1.9,1.49,3.79,2.98,5.67,4.48-1.89-1.5-3.77-2.99-5.67-4.48Z"
          />
          <path
            class="cls-1"
            d="M698.94,1952.64c.02-.09,0-.05,0,0-.01,.05-.03,.09,0,0Z"
          />
          <g>
            <path
              class="cls-1"
              d="M1999.47,988.22c-3.42-307.94-146.12-582.43-368.04-763.5-2.71-2.22-5.44-4.43-8.18-6.62-.72-.58-1.44-1.15-2.16-1.72-1.88-1.5-3.77-2.99-5.67-4.48-2.33-1.83-4.67-3.64-7.02-5.45-.35-.27-.7-.54-1.06-.82-22.34-17.16-45.41-33.4-69.19-48.64C1383.18,57.62,1198.87,0,1000.8,0c0,0-.01,0-.02,0-1.1,0-2.2,.02-3.3,.02-.02,0-.04,0-.06,0-210.63-.53-422.81,65.32-602.56,202.66-78.32,59.84-145.58,129.54-201.26,206.18C75.96,569.21,4.98,765.95,.69,979.06c-.2,7.65-.32,15.3-.34,22.96-.6,208.45,63.39,418.57,198.32,597.6,40.66,53.95,85.74,102.59,134.36,145.81,54.97,48.87,114.46,90.79,177.19,125.63,60.34,33.51,123.67,60.47,188.86,80.68-41.02-12.92-80.35-29.36-117.83-49.11-45.76-24.12-88.76-53.18-128.66-86.86-18.79-15.72-36.87-32.45-54.22-50.16-67.05-68.48-119.69-148.22-156.45-237-38.04-91.87-57.33-189.42-57.33-289.94s19.29-198.07,57.33-289.93c36.76-88.78,89.4-168.52,156.45-237,67.13-68.56,145.33-122.4,232.44-160.03,90.29-39.01,186.18-58.78,285.01-58.78s194.72,19.78,285.01,58.78c22.65,9.78,44.68,20.67,66.09,32.62,46.72,24.8,92.22,52.9,131.07,89.68,57.29,54.24,75.04,74.21,123.85,155.32,156.67,260.34,90.75,583.07,175.83,587.9,21.86,1.24,69.81-38.14,88.41-54.16,76.05-65.5,119.09-107.28,167.77-209.08,7.91-16.55,43.32-90.78,45.65-150.43,.08-2.04,.12-4.07,.12-6.08,.03-2.38-.03-5.51-.17-9.26Z"
            />
            <path
              class="cls-2"
              d="M1387.4,832.22c-15.29-24.03-40.45-50.02-46.08-55.38-53.77-51.09-115.12-91.7-182.68-120.89-77.18-33.34-159.18-50.25-243.73-50.25s-166.55,16.91-243.73,50.25c-74.65,32.25-141.71,78.44-199.34,137.3-57.7,58.93-103.01,127.57-134.66,204.01-32.79,79.18-49.41,163.33-49.41,250.11s16.63,170.93,49.41,250.11c31.65,76.43,76.96,145.07,134.66,204.01,57.63,58.86,124.69,105.05,199.34,137.3,60.18,26,123.29,42,188.26,47.79,18.35,1.64,36.85,2.46,55.47,2.46,84.55,0,166.55-16.91,243.73-50.25,3.11-1.34,6.2-2.72,9.28-4.11-173.3-3.03-366.18-68.6-442.51-200.26-126.38-217.99-46.46-518.17,201.11-561.5,333.84-58.44,387.32,345.55,455.79,345.55,62.52,0,113-210.35,91.29-385.46-11.77-94.91-60.42-160.28-86.19-200.78Z"
            />
          </g>
        </svg>
      </div>
      <div class="rings" />
      <div class="rings" />
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  size?: "small" | "normal";
  noSpin?: boolean;
}

const props = defineProps<Props>();

const finalSize = computed(() => props.size ?? "normal");
</script>

<style scoped>
.outer {
  --duration: 1s;
  --delay: 200ms;
  @apply relative;
}

.inner {
  z-index: 1;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  @apply bg-white dark:bg-black p-2 rounded-full relative;
}

.container:not(.static) .inner {
  animation: spin var(--duration) linear;
  animation-iteration-count: infinite;
}

.outer > .rings {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  border: 8px solid;
  border-radius: 100%;
  @apply border-nami-nami-blue border-opacity-40;
}

.container:not(.static) .outer > .rings {
  animation-name: ripple;
  animation-duration: var(--duration);
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
}

.container:not(.static) .outer > .rings:nth-child(2) {
  animation-delay: var(--delay);
}

@keyframes ripple {
  from {
    opacity: 1;
    border-width: 16px;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    border-width: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>

export async function executeWithNotificationOnError<T extends () => any>(
  cb: T,
): Promise<Awaited<ReturnType<T>>> {
  const appStore = useNuxtApp().$app();

  function tryNotification(err: Error) {
    const { title, detail } = resolveError(err);

    appStore?.notify({
      preset: "error.plain",
      title: title,
      detail: detail,
      timer: 5000,
    });
  }

  try {
    return await cb();
  } catch (e) {
    tryNotification(e as Error);

    throw e;
  }
}

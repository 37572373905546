<template>
  <NotificationsItem
    :is-unread
    :href
    :action-icon="IconHeartFilled"
    :date="notification.date"
  >
    <template #content>
      <div class="space-y-1">
        <UserAvatar
          v-if="notification.data.reactor.type === 'user'"
          :user="notification.data.reactor"
          :size="32"
          no-link
        />
        <OrganizationAvatar
          v-else-if="notification.data.reactor.type === 'organization'"
          :org="notification.data.reactor"
          :size="32"
        />
        <p class="line-clamp-2">
          <i18n-t
            keypath="notifications.creator.reaction_to_chapter.body"
            scope="global"
          >
            <template #reactor>
              <strong>{{ reactorName }}</strong>
            </template>
          </i18n-t>
        </p>
        <p class="text-sm text-neutral-600 dark:text-neutral-400 line-clamp-2">
          <template v-if="shortName">
            {{ shortName }}
          </template>
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import { IconHeartFilled } from "@tabler/icons-vue";
import { parseChapterForDisplay } from "~/utils/display/chapter";
import type { OrganizationEntity, UserEntity } from "~/src/api";
import type { NamiNotificationCreatorReactionToChapter } from "~/src/notifications/types/nami/creator-reaction-to-chapter";
import type { Props } from "../NotificationsItem.vue";

const props = defineProps<
  {
    notification: NamiNotificationCreatorReactionToChapter;
  } & Omit<Props, "actionIcon">
>();

const { shortName } = parseChapterForDisplay(
  computed(() => props.notification.data.chapter),
  "small",
  props.notification.data.title.attributes.chapterKind ?? undefined,
  props.notification.data.title.attributes.volumeKind ?? undefined,
);

const href = computed(() => {
  return linkTo(props.notification.data.chapter);
});

const reactorName = computed(() => {
  return props.notification.data.reactor.type === "user"
    ? getNameForUser(props.notification.data.reactor)
    : getNameForOrganization(props.notification.data.reactor);
});

function getNameForUser(user: UserEntity) {
  return user.attributes.displayName ?? user.attributes.username;
}

function getNameForOrganization(organization: OrganizationEntity) {
  return organization.attributes.name;
}
</script>

import axios, { config } from "../core/axios";
import { assertSuccess } from "../core/assert";
import { paramsParser } from "../core/paramsParser";
import { Headers } from "./generic";
import type {
  CollectionResult,
  GenericResult,
  Meta,
  Result,
} from "../types/result";
import {
  type SubscriptionSummaryItemEntity,
  type LedgerEntity,
  type LedgerListParams,
  type SubscriptionEntity,
  type SubscriptionListParams,
} from "../types/subscriptions";
import type { AvailableLocales } from "../types/locales";
import type { Currency } from "../types/Currency";
import type { RawEntity } from "../types/entity";
import type { SubscriptionExpansionTypes } from "../types/generic";

export type AddCreditBody = {
  money: {
    amount: number;
    currency: Currency;
  };
  ledgerItemType: "credit" | "creator_reward" | "contest_reward";
  externalId?: string;
  createdAt?: string;
};

export class Subscriptions {
  static async get(
    id: string,
    includes: SubscriptionExpansionTypes[],
    auth: string,
  ) {
    const resp = await axios<Result<SubscriptionEntity>>(
      `/subscription/${id}${paramsParser({ includes })}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getSubscriptions(
    auth: string,
    params: SubscriptionListParams = {},
  ): Promise<{ data: SubscriptionEntity[]; meta: Meta }> {
    const resp = await axios<CollectionResult<SubscriptionEntity>>(
      "/subscription" + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return {
      data: assertSuccess(resp.data).data,
      meta: assertSuccess(resp.data).meta,
    };
  }

  static async getAdminSubscriptions(
    auth: string,
    params: SubscriptionListParams = {},
  ): Promise<{ data: SubscriptionEntity[]; meta: Meta }> {
    const resp = await axios<CollectionResult<SubscriptionEntity>>(
      "/admin/subscription" + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return {
      data: assertSuccess(resp.data).data,
      meta: assertSuccess(resp.data).meta,
    };
  }

  static async getOrganizationSubscriptions(
    orgId: string,
    auth: string,
    params: SubscriptionListParams = {},
  ): Promise<{ data: SubscriptionEntity[]; meta: Meta }> {
    const resp = await axios<CollectionResult<SubscriptionEntity>>(
      `/organization/${orgId}/subscription` + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return {
      data: assertSuccess(resp.data).data,
      meta: assertSuccess(resp.data).meta,
    };
  }

  static async getUserSubscriptions(
    userId: string,
    auth: string,
    params: SubscriptionListParams = {},
  ): Promise<{ data: SubscriptionEntity[]; meta: Meta }> {
    return await Subscriptions.getAdminSubscriptions(auth, {
      ...params,
      userId,
    });
  }

  static async getSubscriptionManagePortalUrl(subId: string, auth: string) {
    const resp = await axios<
      GenericResult<{
        redirectUri: string;
      }>
    >(`${config.paymentsUrl}/subscription/${subId}/manage`, {
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data);
  }

  static async subscribe(
    auth: string,
    provider: "stripe",
    orgId: string,
    tierId: string,
    price: {
      amount: number;
      currency: string;
    },
    locale: AvailableLocales,
    interval: "month" | "day",
  ) {
    const resp = await axios<
      GenericResult<{
        redirectUri: string;
      }>
    >(`${config.paymentsUrl}/checkout/subscribe`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: {
        interface: provider,
        organizationId: orgId,
        tierItemId: tierId,
        price,
        locale,
        interval,
      },
    });

    return assertSuccess(resp.data);
  }

  static async getLedgerData(params: LedgerListParams = {}, auth: string) {
    const resp = await axios<CollectionResult<LedgerEntity>>(
      `${config.paymentsUrl}/ledger${paramsParser(params)}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    const data = assertSuccess(resp.data);

    return {
      data: data.data,
      meta: data.meta,
    };
  }

  static async getLedgerBalance(organizationId: string, auth: string) {
    type LedgerBalanceEntity = RawEntity<
      "ledger_balance",
      {
        availableMoney: {
          amount: number;
          currency: Currency;
        }[];
      }
    >;

    const resp = await axios<Result<LedgerBalanceEntity>>(
      `${config.paymentsUrl}/organization/${organizationId}/ledger/balance`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async addCredit(
    organizationId: string,
    body: AddCreditBody,
    auth: string,
  ) {
    const resp = await axios.post<Result<LedgerEntity>>(
      `${config.paymentsUrl}/ledger/${organizationId}/credit`,
      body,
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(resp.data).data;
  }

  static async getSubscriptionSummary(userId: string, auth: string) {
    const resp = await axios.get<
      CollectionResult<SubscriptionSummaryItemEntity>
    >(`/subscription/${userId}/summary`, { headers: Headers.Bearer(auth) });

    return assertSuccess(resp.data).data;
  }
}

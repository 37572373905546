<template>
  <div
    class="flex flex-col space-y-6 sm:space-y-8 items-center justify-center z-50 px-4 text-center py-12 sm:py-16 lg:py-20 2xl:py-24 max-h-screen"
  >
    <img
      src="/assets/nami/stickers/empty.png"
      class="w-full max-w-[12rem] sm:max-w-[16rem]"
      alt="nami-okay"
    />
    <h1 class="text-3xl md:text-6xl font-bold">{{ mainErrMsg }}</h1>
    <p v-if="is404" class="text-sm sm:text-base">
      Nami couldn't find the page you were looking for.
    </p>
    <NamiButton :href="href.home" buttonType="primary">
      Take me home
    </NamiButton>
    <NamiButton @buttonClick="copy" buttonType="secondary" text>
      Copy diagnostic information
    </NamiButton>
    
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";

const href = useHref();
const route = useRoute();
const { t, te } = useNuxtApp().$i18n.global;

const props = defineProps<{
  // type this to match the useError composable and then some
  error?:
    | NuxtError
    | Error
    | {
        statusCode: number;
        statusMessage: string;
        message: string;
      }
    | null;
}>();

const hasCode = (input: any): input is { statusCode: number } => {
  return typeof input.statusCode === "number";
};
const hasStatusAndCode = (
  input: any,
): input is { statusCode: number; statusMessage: string } => {
  return (
    typeof input.statusCode === "number" &&
    typeof input.statusMessage === "string"
  );
};

const devStack = computed(() => {
  if (props.error) return (props.error as NuxtError).stack;
});

const is404 = computed(() => {
  const err = props.error || undefined;
  if (hasCode(err)) return err.statusCode === 404;
  return err?.message.includes("404");
});

const mainErrMsg = computed(() => {
  const err = props.error || undefined;
  if (hasStatusAndCode(err)) {
    const message =
      err.statusMessage && te(`global_errors.${err.statusMessage}`)
        ? t(`global_errors.${err.statusMessage}`)
        : "";
    if (message) return `${err.statusCode} - ${message}`;
    return `Error ${err.statusCode}`;
  }
  return "404 - Page Not Found";
});

const copy = () => {
  if (props.error)
    copyJsonToClipboard({
      frontendUrl: route.fullPath,
      error: props.error,
    });
};
</script>

import { Title, type TitleEntity } from "~/src/api";
import { useGenericCache } from "./generic";

export function useTitleCache(timeout = 1000 * 60) {
  const cache = useGenericCache<string, TitleEntity>("titles", timeout);

  async function getById(id: string, authenticated = false) {
    const cached = cache.get(id);
    if (cached) return cached;

    const tier = await Title.get(
      id,
      ["cover_art", "creator", "organization", "tag"],
      authenticated ? await getTokenOrThrow() : undefined,
    );
    cache.store(id, tier);
    return tier;
  }

  return {
    cache,
    getById,
  };
}

import { useLocalStorage } from "@vueuse/core";

export enum InternalNotificationPermissionStatus {
  Default,
  Granted,
  Denied,
}

export function useInternalNotificationsPermissionStatusStorage() {
  return useLocalStorage<InternalNotificationPermissionStatus>(
    "notifications-enabled",
    InternalNotificationPermissionStatus.Default,
  );
}

<template>
  <template v-if="otherUsers.length === 1 && firstUser">
    <TheNuxtLink :to="linkTo('user', firstUser.username)">
      <ConversationUserAvatarPreviews
        :users
        class="block max-w-24 h-24 mx-auto mb-4"
        :conversation-sid
        :current-user
      />
      <h3 class="font-semibold text-xl text-center mt-1">
        {{ firstUser.displayName }}
      </h3>
      <p
        v-if="firstUser.username !== firstUser.displayName"
        class="text-neutral-400 dark:text-neutral-500 text-sm text-center mb-4"
      >
        @{{ firstUser.username }}
      </p>
    </TheNuxtLink>
    <MarkdownRender
      v-if="data?.id === otherUsers[0].id"
      class="px-4 text-center text-sm mb-2"
      :text="data?.attributes.about ?? ''"
      link-source="dm"
    />
    <p class="text-neutral-400 dark:text-neutral-500 text-sm text-center">
      Joined {{ getFormattedUserJoinData(firstUser) }}
    </p>
  </template>
  <template v-else-if="otherUsers.length > 1">
    <ConversationUserAvatarPreviews
      :users
      class="block w-24 h-24 mx-auto mb-4"
      :conversation-sid
      :current-user
    />
    <h3 class="font-semibold text-xl text-center mt-1">
      {{
        otherUsers
          .slice(0, 5)
          .map((u) => u.displayName)
          .join(", ")
      }}
      {{ otherUsers.length > 5 ? `and ${otherUsers.length - 5} more` : "" }}
    </h3>
  </template>
</template>

<script setup lang="ts">
import { User, type UserProfile } from "~/src/api";
import { type UserDisplayData } from "./common";

const props = defineProps<{
  users: UserDisplayData[];
  currentUser: UserDisplayData;
  conversationSid: string;
  avatarUrl: string | null;
}>();

const emit = defineEmits<{ (e: "loaded"): void }>();

const dayjs = useDayjs();

const profileCache = new Map<string, UserProfile | null>();

const otherUsers = computed(() => {
  return props.users.filter((user) => user.id !== props.currentUser.id);
});

const firstUser = computed(() => otherUsers.value.at(0));

const { data, pending, error, refresh } = useAsyncData(
  `profile-${otherUsers.value.join(",")}`,
  async () => {
    if (otherUsers.value.length !== 1 || otherUsers.value[0].type !== "user")
      return null;

    const id = otherUsers.value[0].id;

    const profile = profileCache.has(id)
      ? profileCache.get(id)!
      : await User.getProfile(id).then((p) => (profileCache.set(id, p), p));

    return profile;
  },
  { watch: [otherUsers] },
);

watch(data, () => emit("loaded"));

function getFormattedUserJoinData(user: UserDisplayData) {
  return dayjs(user.createdAt).format("MMMM YYYY");
}
</script>

import { getLanguages, getLocales } from "./static";
import type { AvailableLanguages, AvailableLocales } from "../types/locales";

export const isAvailableLocale = (input: any): input is AvailableLocales => {
  return getLocales()
    .map((x) => x.value)
    .includes(input);
};

export const isAvailableLanguage = (
  input: any,
): input is AvailableLanguages => {
  return getLanguages()
    .map((x) => x.value)
    .includes(input);
};

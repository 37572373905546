<template>
  <NamiNotifications
    ref="notifications"
    :position="position ?? 'top-middle'"
    :maxVisibleNotifications="maxVisibleNotifications ?? 5"
  />
</template>

<script setup lang="ts">
import NamiNotifications from "./NamiNotifications.vue";
import type { NotificationType, Positions } from "~/types/notifications";

import {
  IconCheck,
  IconCircleDotted,
  IconInfoCircle,
  IconX,
} from "@tabler/icons-vue";

export interface Props {
  position?: Positions;
  maxVisibleNotifications?: number;
}

export type PlainMessage = {
  title?: string;
  detail?: string;
  onClose?: () => {};
};

export type ConfirmListeners = {
  onConfirm?: () => any;
  onCancel?: () => any;
};

export type PresetOptions = { timer?: number } & (
  | ({ preset: "success.plain" } & PlainMessage)
  | ({ preset: "error.plain" } & PlainMessage)
  | ({ preset: "loading.plain" } & PlainMessage)
  | ({ preset: "info.plain" } & PlainMessage)
  | ({ preset: "confirm.plain" } & (PlainMessage & ConfirmListeners))
  | ({ preset: "deleteConfirm.plain" } & (PlainMessage & ConfirmListeners))
);

export interface NotifyHandlers {
  notification: NotificationType;
  update: (options: PresetOptions) => void;
  close: () => void;
  pause: () => void;
  resume: () => void;
}

export interface Exposes {
  notify: (options: PresetOptions) => Promise<NotifyHandlers>;
  closeAll: () => void;
}

defineProps<Props>();

defineExpose<Exposes>({
  notify,
  closeAll,
});

const notifications = ref<InstanceType<typeof NamiNotifications>>();

function createNotificationParamsObject(
  options: PresetOptions,
): NotificationType {
  switch (options.preset) {
    case "success.plain": {
      return {
        timeout: options.timer
          ? {
              enabled: true,
              duration: options.timer,
              pauseTimerOnHover: true,
              onClose: options.onClose,
            }
          : undefined,
        content: {
          class:
            "p-2 rounded-md bg-[#ffffff99] dark:bg-[#22222299] border-[1px] border-neutral-300 dark:border-neutral-700",
          style: {
            backdropFilter: "blur(32px)",
          },
          children: [
            {
              class: "pr-8 flex space-x-2",
              items: [
                {
                  kind: "icon",
                  icon: IconCheck,
                  class: "w-4 text-green-500 my-auto",
                  style: { strokeWidth: "4px" },
                },
                {
                  kind: "text",
                  content: options.title ?? "Success!",
                  class: "font-bold dark:text-white",
                },
              ],
            },
            options.detail
              ? {
                  items: [
                    {
                      kind: "text",
                      content: options.detail,
                      class:
                        "ml-6 mt-1 text-sm dark:text-neutral-300 text-neutral-700",
                    },
                  ],
                }
              : { items: [] },
          ],
        },
        extra: { showCloseButton: true },
      };
    }

    case "error.plain": {
      return {
        timeout: options.timer
          ? {
              enabled: true,
              duration: options.timer,
              pauseTimerOnHover: true,
              onClose: options.onClose,
            }
          : undefined,
        content: {
          class:
            "p-2 rounded-md bg-[#ffffff99] dark:bg-[#22222299] border-[1px] border-neutral-300 dark:border-neutral-800",
          style: {
            backdropFilter: "blur(32px)",
          },
          children: [
            {
              class: "pr-8 flex space-x-2",
              items: [
                {
                  kind: "icon",
                  icon: IconX,
                  class: "w-4 text-red-500",
                  style: { strokeWidth: "4px" },
                },
                {
                  kind: "text",
                  content: options.title ?? "Error",
                  class: "font-bold dark:text-white",
                },
              ],
            },
            options.detail
              ? {
                  items: [
                    {
                      kind: "text",
                      content: options.detail,
                      class:
                        "ml-6 mt-1 text-sm dark:text-neutral-300 text-neutral-700",
                    },
                  ],
                }
              : { items: [] },
          ],
        },
        extra: { showCloseButton: true },
      };
    }

    case "loading.plain": {
      return {
        timeout: options.timer
          ? {
              enabled: true,
              duration: options.timer,
              pauseTimerOnHover: true,
              onClose: options.onClose,
            }
          : undefined,
        content: {
          class:
            "p-2 rounded-md bg-[#ffffff99] dark:bg-[#22222299] border-[1px] border-neutral-300 dark:border-neutral-800",
          style: {
            backdropFilter: "blur(32px)",
          },
          children: [
            {
              class: "pr-8 flex space-x-2",
              items: [
                {
                  kind: "icon",
                  icon: IconCircleDotted,
                  class: "w-4 text-blue-500 animate-spin",
                  style: { strokeWidth: "4px" },
                },
                {
                  kind: "text",
                  content: options.title ?? "Please wait...",
                  class: "font-bold dark:text-white",
                },
              ],
            },
            options.detail
              ? {
                  items: [
                    {
                      kind: "text",
                      content: options.detail,
                      class:
                        "ml-6 mt-1 text-sm dark:text-neutral-300 text-neutral-700",
                    },
                  ],
                }
              : { items: [] },
          ],
        },
        extra: { showCloseButton: true },
      };
    }

    case "info.plain": {
      return {
        timeout: options.timer
          ? {
              enabled: true,
              duration: options.timer,
              pauseTimerOnHover: true,
              onClose: options.onClose,
            }
          : undefined,
        content: {
          class:
            "p-2 rounded-md bg-[#ffffff99] dark:bg-[#22222299] border-[1px] border-neutral-300 dark:border-neutral-800",
          style: {
            backdropFilter: "blur(32px)",
          },
          children: [
            {
              class: "pr-8 flex space-x-2",
              items: [
                {
                  kind: "icon",
                  icon: IconInfoCircle,
                  class: "w-4 text-nami-nami-blue",
                  style: { strokeWidth: "4px" },
                },
                {
                  kind: "text",
                  content: options.title ?? "Info",
                  class: "font-bold dark:text-white",
                },
              ],
            },
            options.detail
              ? {
                  items: [
                    {
                      kind: "text",
                      content: options.detail,
                      class:
                        "ml-6 mt-1 text-sm dark:text-neutral-300 text-neutral-700",
                    },
                  ],
                }
              : { items: [] },
          ],
        },
        extra: { showCloseButton: true },
      };
    }

    case "confirm.plain": {
      return {
        timeout: options.timer
          ? {
              enabled: true,
              duration: options.timer,
              pauseTimerOnHover: true,
              onClose: options.onClose,
            }
          : undefined,
        content: {
          class:
            "p-2 rounded-md bg-[#ffffff99] dark:bg-[#22222299] border-[1px] border-neutral-300 dark:border-neutral-800",
          style: {
            backdropFilter: "blur(32px)",
          },
          children: [
            {
              class: "pr-8 flex space-x-2",
              items: [
                {
                  kind: "text",
                  content: options.title ?? "Proceed with this action?",
                  class: "mb-2 font-bold dark:text-white",
                },
              ],
            },
            options.detail
              ? {
                  items: [
                    {
                      kind: "text",
                      content: options.detail,
                      class:
                        "mb-2 text-sm dark:text-neutral-300 text-neutral-700",
                    },
                  ],
                }
              : { items: [] },
            {
              class: "flex space-x-2",
              items: [
                {
                  kind: "button",
                  onButtonClick: options.onConfirm,
                  buttonType: "primary",
                  buttonText: "Confirm",
                  text: true,
                },
                {
                  kind: "button",
                  onButtonClick: options.onCancel,
                  buttonType: "secondary",
                  buttonText: "Cancel",
                  text: true,
                },
              ],
            },
          ],
        },
        extra: { showCloseButton: true },
      };
    }

    case "deleteConfirm.plain": {
      return {
        timeout: options.timer
          ? {
              enabled: true,
              duration: options.timer,
              pauseTimerOnHover: true,
              onClose: options.onClose,
            }
          : undefined,
        content: {
          class:
            "p-2 rounded-md bg-[#ffffff99] dark:bg-[#11111199] border-[1px] border-neutral-300 dark:border-neutral-800",
          style: {
            backdropFilter: "blur(32px)",
          },
          children: [
            {
              class: "pr-8 flex space-x-2",
              items: [
                {
                  kind: "text",
                  content: options.title ?? "Please confirm",
                  class: "mb-2 font-bold dark:text-white",
                },
              ],
            },
            options.detail
              ? {
                  items: [
                    {
                      kind: "text",
                      content: options.detail,
                      class:
                        "mb-2 text-sm dark:text-neutral-300 text-neutral-700",
                    },
                  ],
                }
              : { items: [] },
            {
              class: "flex space-x-2",
              items: [
                {
                  kind: "button",
                  onButtonClick: options.onConfirm,
                  buttonType: "danger",
                  buttonText: "Delete",
                },
                {
                  kind: "button",
                  onButtonClick: options.onCancel,
                  buttonType: "secondary",
                  buttonText: "Cancel",
                },
              ],
            },
          ],
        },
        extra: { showCloseButton: true },
      };
    }
  }
}

async function notify(options: PresetOptions) {
  if (!notifications.value)
    throw new Error("Notifications component not initialized.");

  const { notification, close, update, pause, resume } =
    await notifications.value.notify(createNotificationParamsObject(options));

  return {
    notification,
    close,
    update: function (options: PresetOptions) {
      update(createNotificationParamsObject(options));
    },
    pause,
    resume,
  };
}

function closeAll() {
  notifications.value?.closeAll();
}
</script>

import axios from "../core/axios";
import { Headers } from "./generic";
import { assertSuccess } from "../core/assert";
import type { MessageResult } from "../types/result";
import type { ReportPostBody } from "../types/report";

export class Report {
  static async create(data: ReportPostBody, auth: string) {
    data.message = data.message.trim();

    const resp = await axios<MessageResult<"Report submitted">>(
      `/report/${data.entityType}/${data.entityId}`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: data,
      },
    );

    return assertSuccess(resp.data).message;
  }

  static async reportConversationMessage(
    conversationId: string,
    messageId: string,
    message: string,
    auth: string,
  ) {
    const resp = await axios.post<MessageResult<"Report submitted">>(
      `/report/conversation/${conversationId}/${messageId}`,
      { message },
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }
}

<template>
  <HeadlessTransitionRoot
    as="template"
    :show="modelValue"
    class="dark:text-white"
  >
    <HeadlessDialog
      as="div"
      class="relative z-50"
      @close="
        () => {
          emit('close');
          if (!preventOutsideClicks) {
            emit('clickOutside');
            emit('update:modelValue', false);
          }
        }
      "
    >
      <HeadlessTransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-neutral-500 dark:bg-neutral-950 bg-opacity-75 dark:bg-opacity-50 transition-opacity"
          :style="{
            backdropFilter: censorBackground ? 'blur(64px)' : undefined,
            WebkitBackdropFilter: censorBackground ? 'blur(64px)' : undefined,
          }"
        />
      </HeadlessTransitionChild>

      <div
        class="fixed inset-0 z-10 transition-transform"
        :class="{
          'overflow-y-scroll': modelValue,
          'overflow-hidden': !modelValue,
        }"
      >
        <div
          class="flex min-h-full items-end justify-center text-center sm:items-center"
          :class="{
            'p-0': !large,
          }"
        >
          <HeadlessTransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="sm:opacity-0 translate-y-full sm:translate-y-0 sm:scale-95"
            enter-to="sm:opacity-100 translate-y-[max(0,calc(100%_-_50dvh))] sm:translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="sm:opacity-100 translate-y-[max(0,calc(100%_-_50dvh))] sm:translate-y-0 sm:scale-100"
            leave-to="sm:opacity-0 translate-y-full sm:translate-y-0 sm:scale-95"
          >
            <HeadlessDialogPanel
              class="relative transform rounded-t-lg sm:min-h-0 sm:rounded-lg text-left shadow-xl transition-[transform,max-width,width,opacity] sm:my-8 space-y-8 sm:space-y-0"
              :class="{
                'w-full sm:max-w-lg px-6 sm:px-8 py-4 sm:py-6 sm:p-6': !inherit,
                'sm:max-w-screen-md w-[90%]': large && !inherit,
                'bg-white dark:bg-neutral-800': !darker,
                'bg-white dark:bg-neutral-900': darker,
                'canhover:mr-[10px]': modelValue && hasCustomScrollbar,
              }"
            >
              <slot />
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script setup lang="ts">
interface Props {
  modelValue: boolean;
  censorBackground?: boolean;
  preventOutsideClicks?: boolean;
  dismissOnRouteChange?: boolean;
  large?: boolean;
  inherit?: boolean;
  darker?: boolean;
}

interface Events {
  (e: "update:modelValue", v: boolean): void;
  (e: "clickOutside"): void;
  (e: "close"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();
const route = useRoute();
const { $app } = useNuxtApp();

const appStore = $app();

watch(
  () => props.modelValue,
  (v) => appStore?.setOffsetContentOnWebkitBecauseScrollIsDisabled(v),
  { immediate: true },
);

watch(
  () => route.path,
  (newPath, oldPath) => {
    // Prevent vue-router subroute triggering this
    // e.g. /library -> /library/follows should not trigger this
    if (newPath.startsWith(oldPath)) {
      return;
    }

    emit("update:modelValue", false);
    emit("clickOutside");
  },
);

const hasCustomScrollbar = computed(() => {
  navigator &&
    /Chrome/.test(navigator.userAgent) &&
    !/Mac/.test(navigator.userAgent);
});
</script>

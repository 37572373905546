<template>
  <NuxtLink v-bind="normalizedAttrs">
    <slot></slot>
  </NuxtLink>
</template>

<script setup>
defineComponent({
  inheritAttrs: false,
});

const isEmbed = useIsEmbed();
const attrs = useAttrs();

const normalizedAttrs = computed(() => ({
  ...attrs,
  target: isEmbed.value ? "_blank" : attrs.target,
}));
</script>

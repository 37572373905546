import type { Ref, ToRefs } from "vue";
import type { MaybeRef } from "@vueuse/shared";
import {
  getMultipleRelationships,
  getRelationship,
  type TitleEntity,
  type UserRelation,
  type AvailableLanguages,
  type Demographic,
  type OrganizationRelation,
  type TagRelation,
  type TitleRelation,
} from "~/src/api";
import { extractFromLocale, linkTo } from "~/utils/functions";

export type ParsedTitleOutput = {
  id: string;
  title: string;
  description: string;
  tags: TagRelation[];
  primaryGenre: TagRelation | null;
  secondaryGenre: TagRelation | null;
  language: string;
  href: string;
  coverUrl: string;
  bannerUrl: string;
  demographic: Demographic | null;

  publishedAt: string;
  createdAt: string;
  updatedAt: string | null;

  uploader: UserRelation | null;
  organizations: OrganizationRelation[];
};

const DefaultReturn = {
  id: "",
  title: "",
  description: "",
  tags: [],
  primaryGenre: null,
  secondaryGenre: null,
  language: "",
  href: "",
  coverUrl: "",
  bannerUrl: "",
  demographic: null,

  publishedAt: "",
  createdAt: "",
  updatedAt: null,

  uploader: null,
  organizations: [],
};

export function isParsed(
  title: TitleEntity | TitleRelation | ParsedTitleOutput,
): title is ParsedTitleOutput {
  return "href" in title;
}

export function parseTitleForDisplay(
  x: TitleEntity | null,
  res?: "small" | "med" | "large" | "original",
): ParsedTitleOutput;
export function parseTitleForDisplay(
  x: Ref<TitleEntity | null | undefined> | null,
  res?: "small" | "med" | "large" | "original",
): ToRefs<ParsedTitleOutput>;
export function parseTitleForDisplay(
  titleInput: MaybeRef<TitleEntity | null | undefined> | null,
  res: "small" | "med" | "large" | "original" = "original",
): ParsedTitleOutput | ToRefs<ParsedTitleOutput> {
  const { getTitleBanner, selectLocalizedCover } = useMediaLink();
  const locale = useNuxtApp().$i18n.global.locale;

  let coverRes: "128" | "256" | "512" | "original";
  let bannerRes: "640" | "1280" | "1920" | "original";
  switch (res) {
    case "small":
      coverRes = "128";
      bannerRes = "640";
      break;
    case "med":
      coverRes = "256";
      bannerRes = "1280";
      break;
    case "large":
      coverRes = "512";
      bannerRes = "1920";
      break;
    default:
      coverRes = "original";
      bannerRes = "original";
  }

  let id: MaybeRef<string>;
  let title: MaybeRef<string>;
  let description: MaybeRef<string>;
  let tags: MaybeRef<TagRelation[]>;
  let primaryGenre: MaybeRef<TagRelation | null>;
  let secondaryGenre: MaybeRef<TagRelation | null>;
  let language: MaybeRef<AvailableLanguages>;
  let demographic: MaybeRef<Demographic | null>;
  let href: MaybeRef<string>;
  let coverUrl: MaybeRef<string>;
  let bannerUrl: MaybeRef<string>;

  let publishedAt: MaybeRef<string>;
  let createdAt: MaybeRef<string>;
  let updatedAt: MaybeRef<string | null>;

  let uploader: MaybeRef<UserRelation | null>;
  let organizations: MaybeRef<OrganizationRelation[]>;

  if (isRef(titleInput)) {
    id = computed(() => titleInput.value?.id ?? "");
    title = computed(() =>
      extractFromLocale(locale.value, titleInput.value?.attributes.title ?? {}),
    );

    description = computed(() =>
      extractFromLocale(
        locale.value,
        titleInput.value?.attributes.description ?? {},
      ),
    );

    tags = computed(() =>
      titleInput.value ? getMultipleRelationships(titleInput.value, "tag") : [],
    );
    primaryGenre = computed(() =>
      titleInput.value
        ? getMultipleRelationships(titleInput.value, "primary_tag")[0] || null
        : null,
    );
    secondaryGenre = computed(() =>
      titleInput.value
        ? getMultipleRelationships(titleInput.value, "secondary_tag")[0] || null
        : null,
    );

    language = computed(
      () => titleInput.value?.attributes.originalLanguage ?? "en",
    );

    demographic = computed(
      () => titleInput.value?.attributes.demographic ?? null,
    );

    href = computed(() =>
      titleInput.value
        ? linkTo("title", titleInput.value.id, titleInput.value.attributes.slug)
        : "",
    );
    coverUrl = computed(() =>
      titleInput.value
        ? selectLocalizedCover(locale.value, titleInput.value, coverRes)
        : "",
    );
    bannerUrl = computed(() =>
      titleInput.value ? getTitleBanner(titleInput.value, bannerRes) : "",
    );

    publishedAt = computed(
      () => titleInput.value?.attributes.publishedAt ?? "",
    );
    createdAt = computed(() => titleInput.value?.attributes.createdAt ?? "");
    updatedAt = computed(() => titleInput.value?.attributes.updatedAt ?? "");

    uploader = computed(() =>
      titleInput.value ? getRelationship(titleInput.value, "user") : null,
    );
    organizations = computed(() =>
      titleInput.value
        ? getMultipleRelationships(titleInput.value, "organization")
        : [],
    );
  } else {
    if (!titleInput) return DefaultReturn;
    id = titleInput.id;
    title = extractFromLocale(locale.value, titleInput.attributes.title);
    description = extractFromLocale(
      locale.value,
      titleInput.attributes.description,
    );
    tags = getMultipleRelationships(titleInput, "tag");
    primaryGenre =
      getMultipleRelationships(titleInput, "primary_tag")[0] ?? null;
    secondaryGenre =
      getMultipleRelationships(titleInput, "secondary_tag")[0] ?? null;
    language = titleInput.attributes.originalLanguage;
    demographic = titleInput.attributes.demographic;
    href = linkTo("title", titleInput.id, titleInput.attributes.slug);
    coverUrl = selectLocalizedCover(locale.value, titleInput, coverRes);
    bannerUrl = getTitleBanner(titleInput, bannerRes);

    publishedAt = titleInput.attributes.publishedAt;
    createdAt = titleInput.attributes.createdAt;
    updatedAt = titleInput.attributes.updatedAt;

    uploader = getRelationship(titleInput, "user");
    organizations = getMultipleRelationships(titleInput, "organization");
  }

  return {
    id,
    title,
    description,
    tags,
    primaryGenre,
    secondaryGenre,
    language,
    demographic,
    href,
    coverUrl,
    bannerUrl,

    publishedAt,
    createdAt,
    updatedAt,

    uploader,
    organizations,
  } as ToRefs<ParsedTitleOutput> | ParsedTitleOutput;
}

import axios from "../core/axios";
import { assertSuccess, paramsParser } from "../core";
import type {
  CollectionResult,
  DatapointAttributes,
  EntityMetricParams,
  Granularity,
  MessageResult,
  Metric,
  MetricChangeTypeAdded,
  MetricChangeTypeRemoved,
  MetricDatapoint,
  MetricDimensionMap,
  MetricToViewCategory,
  PluralizedForm,
  UserViewCompareData,
  UserViewData,
} from "../types";
import { Headers } from "./generic";
import { nanoid } from "nanoid";

type MaybeStringArray<T extends any[]> = T extends (infer U)[]
  ? (U | (string & {}))[]
  : never;

const MASKED_URL = "/traverse";

const pluralForms: { [K in Metric]: PluralizedForm<K> } = {
  comment: "comments",
  follow: "follows",
  reaction: "reactions",
  subscription: "subscriptions",
  browse: "browse",
  create: "create",
  view: "view",
  acquisition: "acquisition",
  navigation: "navigation",
};

const addedMetricVariants: { [K in Metric]: MetricChangeTypeAdded<K> } = {
  browse: "browse_added",
  comment: "comments_added",
  create: "create_added",
  follow: "follows_added",
  reaction: "reactions_added",
  subscription: "subscriptions_added",
  view: "view_added",
  acquisition: "acquisition_added",
  navigation: "navigation_added",
};

const removedMetricVariants: { [K in Metric]: MetricChangeTypeRemoved<K> } = {
  browse: "browse_removed",
  comment: "comments_removed",
  create: "create_removed",
  follow: "follows_removed",
  reaction: "reactions_removed",
  subscription: "subscriptions_removed",
  view: "view_removed",
  acquisition: "acquisition_removed",
  navigation: "navigation_removed",
};

const metricCategories: { [K in Metric]: MetricToViewCategory<K> } = {
  browse: "page",
  comment: "comments",
  create: "create",
  follow: "follower",
  reaction: "likes",
  subscription: "subscriber",
  view: "view",
  acquisition: "acquisition",
  navigation: "navigation",
};

export class Analytics {
  static async getChapterViewData(
    id: string,
    params?: Partial<{
      from: string;
      to: string;
      compare: boolean;
    }>,
  ): Promise<UserViewCompareData> {
    const res = await axios<UserViewCompareData>(
      "https://stats.namicomi.com/chapter/" +
        paramsParser({ uuid: id, ...params }),
    );

    return res.data;
  }

  static async getTitleViewData(
    id: string,
    params?: Partial<{
      from: string;
      to: string;
      compare: boolean;
    }>,
  ): Promise<UserViewCompareData | UserViewData> {
    const res = await axios<UserViewData | UserViewCompareData>(
      "https://stats.namicomi.com/title/" +
        paramsParser({ uuid: id, ...params }),
    );

    if (params?.compare) return res.data as UserViewCompareData;
    return res.data as UserViewData;
  }

  static async getEntityMetricData<M extends Metric, G extends Granularity>(
    metric: M,
    granularity: G,
    dimensions: MaybeStringArray<MetricDimensionMap[M][G]>,
    params: EntityMetricParams,
    auth: string,
  ) {
    const res = await axios<
      CollectionResult<
        MetricDatapoint<{ [key in (typeof dimensions)[number]]: any }>
      >
    >(
      `/analytics/${metric}/${granularity}` +
        paramsParser({ ...params, dimensions }),
      {
        headers: Headers.Bearer(auth),
        responseType: "json",
      },
    );

    return assertSuccess(res.data);
  }

  static async collectDatapoint(
    params: Partial<DatapointAttributes>,
    auth?: string,
  ) {
    const res = await axios.post<MessageResult<"Data Point collected">>(
      MASKED_URL,
      params,
      {
        responseType: "json",
        headers: {
          Authorization: auth ? "Bearer " + auth : undefined,
          "Nami-Route": nanoid(36),
        },
      },
    );

    return assertSuccess(res.data).data;
  }

  static pluralize<M extends Metric>(metric: M): PluralizedForm<M> {
    return pluralForms[metric];
  }

  static getMetricAddedVariant<M extends Metric>(
    metric: M,
  ): MetricChangeTypeAdded<M> {
    return addedMetricVariants[metric];
  }

  static getMetricRemovedVariant<M extends Metric>(
    metric: M,
  ): MetricChangeTypeRemoved<M> {
    return removedMetricVariants[metric];
  }

  static mapToViewCategory<M extends Metric>(
    metric: M,
  ): MetricToViewCategory<M> {
    return metricCategories[metric];
  }
}

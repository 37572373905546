<template>
  <div class="relative" :class="{ disabled }">
    <label
      v-if="label"
      class="absolute pointer-events-none transition-all rounded input-label-text max-w-[calc(100%-2rem)]"
      :class="{
        'text-base top-2 left-4': inputState === 'unfocused.empty',
        '-top-2 text-xs px-1 -mx-1': inputState !== 'unfocused.empty',
        'left-3': inputState !== 'unfocused.empty' && !pill,
        'left-5': inputState !== 'unfocused.empty' && pill,
        'text-neutral-700 dark:text-neutral-300': actualErrors.length === 0,
        error: actualErrors.length > 0,
      }"
    >
      <div class="relative z-[2] truncate">
        {{ label }}
      </div>
      <div
        class="absolute left-0 bottom-0 w-full h-1/2 z-[1] bg-neutral-100 dark:bg-neutral-900"
      ></div>
    </label>
    <textarea
      v-bind="$attrs"
      ref="textarea"
      class="py-2 transition-[border-color,background-color] outline-none min-h-[7rem] order bg-neutral-100 dark:bg-neutral-900 input-text input-border w-full text-sm sm:text-base"
      :class="{
        error: actualErrors.length > 0,
        'px-2 rounded-md': !pill,
        'px-4 rounded-full': pill,
        'pr-12': showSubmit,
      }"
      :value="modelValue"
      @focus="onFocus"
      @blur="onBlur"
      @input="(e) => updateInput((e.target as HTMLInputElement).value)"
    />
    <p v-if="actualErrors.length > 0" class="pt-1 text-red-400 text-sm">
      {{ actualErrors.join(" ") }}
    </p>
    <div class="absolute right-2 top-[calc(0.375rem+1px)]">
      <NamiButton
        v-if="showSubmit && modelValue.length > 0"
        buttonType="secondary"
        :icon="IconArrowRight"
        text
        :pill="pill"
        block
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconArrowRight } from "@tabler/icons-vue";
import type { Ref } from "vue";
import type { InputReplacer, InputRule } from "~/types/validation";

interface Props {
  modelValue: string;
  label?: string;
  block?: boolean;
  pill?: boolean;
  showSubmit?: boolean;
  disabled?: boolean;
  rules?: InputRule<string>[];
  replacers?: InputReplacer<string>[];
  focusOnMount?: boolean;
}

interface Events {
  (e: "update:modelValue", v: string): void;
}

interface Exposes {
  focus: () => void;
  errors: Ref<string[]>;
  validate: () => void;
  element: Ref<HTMLTextAreaElement | undefined>;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();

const textarea = ref<HTMLTextAreaElement>();
const inputState = ref<"unfocused.empty" | "unfocused.populated" | "focused">(
  props.modelValue ? "unfocused.populated" : "unfocused.empty",
);

watch(
  () => props.modelValue,
  (v) => {
    if (inputState.value !== "focused")
      inputState.value = v ? "unfocused.populated" : "unfocused.empty";
  },
);

function handleHotkeys(e: KeyboardEvent) {
  switch (e.code) {
    case "Escape": {
      textarea.value?.blur();
      onBlur();
      break;
    }
  }
}

const errors = ref<(string | null)[]>([]);
const actualErrors = computed(
  () => errors.value.filter((error) => error !== null) as string[],
);

defineExpose<Exposes>({
  focus: () => textarea.value?.focus(),
  errors: actualErrors,
  validate: validate,
  element: textarea,
});

function updateInput(val: string) {
  var finalVal = val;
  props.replacers?.forEach((replacer) => {
    finalVal = replacer(finalVal);
  });

  emit("update:modelValue", finalVal);
  errors.value = props.rules?.map((rule) => rule(finalVal)) ?? [];
}

function onFocus() {
  inputState.value = "focused";
  document.addEventListener("keydown", handleHotkeys);
}

function onBlur() {
  inputState.value =
    props.modelValue.length > 0 ? "unfocused.populated" : "unfocused.empty";
  document.removeEventListener("keypress", handleHotkeys);
}

function validate() {
  errors.value = props.rules?.map((rule) => rule(props.modelValue)) ?? [];
}

onMounted(() => {
  if (props.focusOnMount) {
    textarea.value?.focus();
  }
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style>
textarea:focus {
  box-shadow: none;
}
</style>

<template>
  <div
    v-bind="$attrs"
    ref="messageArea"
    class="relative pr-4"
    :class="{
      'opacity-0':
        !hasInitiallyScrolledAtTheBottom ||
        (groups.length === 0 && nextMessagesLoading),
    }"
  >
    <button
      v-if="totalUnreadMessages"
      class="sticky z-[1] left-0 top-0 w-full px-4 py-1 bg-nami-comi-blue text-sm font-semibold text-white rounded-b-md"
    >
      {{ totalUnreadMessages }} unread messages
    </button>
    <div>
      <ConversationMessageAreaUsers
        :users
        :conversation-sid
        :current-user
        :avatar-url
        @loaded="scrollBackToBottomIfHeightIncreased"
      />
    </div>
    <NamiDivider />
    <NamiButton
      v-if="hasMoreMessages"
      button-type="secondary"
      block
      text
      no-waves
      @button-click="$emit('loadNextMessages')"
    >
      Load More
    </NamiButton>
    <div class="relative mb-4" ref="messageGroupArea">
      <ConversationMessageGroups
        :groups
        :conversation-sid
        :is-group-conversation
        :current-user
        @report-message="
          (message) => {
            selectedMessageForReport = message;
            showReportMessageModal = true;
          }
        "
        @images-preview="
          (images, index) => {
            lightboxImagesUrls = images;
            startingLightboxImageIndex = index;
            isLightboxVisible = true;
          }
        "
      />
    </div>
    <div
      v-if="groups?.length === 0 && !nextMessagesLoading"
      class="p-4 text-center w-full text-neutral-500"
    >
      <AsyncImage
        class="w-32 mx-auto"
        :src="getAbsoluteAssetLink('/nami/stickers/happy.png')"
      />
      <p class="mt-2">Start by saying hi!</p>
    </div>
    <div class="flex-grow"></div>
  </div>
  <VueEasyLightbox
    :visible="isLightboxVisible"
    :imgs="lightboxImagesUrls"
    :index="startingLightboxImageIndex"
    @hide="isLightboxVisible = false"
    teleport="body"
    :zoomScale="1"
  />
  <NamiModal v-model="showReportMessageModal" large darker>
    <NamiModalTitle @close="showReportMessageModal = false">
      Report Message
    </NamiModalTitle>
    <div class="space-y-4">
      <div>
        <NamiTextArea v-model="reportMessageMessage" />
      </div>
      <div class="flex justify-end gap-2">
        <NamiButton
          button-type="secondary"
          @button-click="showReportMessageModal = false"
        >
          Cancel
        </NamiButton>
        <NamiButton
          button-type="danger"
          @button-click="
            () => {
              sendReportForMessage(
                selectedMessageForReport!,
                reportMessageMessage,
              );
              showReportMessageModal = false;
            }
          "
        >
          Report
        </NamiButton>
      </div>
    </div>
  </NamiModal>
</template>

<script setup lang="ts">
import VueEasyLightbox from "vue-easy-lightbox";
import {
  useScroll,
  useElementSize,
  useTimeout,
  debouncedWatch,
} from "@vueuse/core";
import type { UserDisplayData } from "./common";
import { Report } from "~/src/api";

const props = defineProps<{
  users: UserDisplayData[];
  groups: MessageGroupFamilyOrContainer[];
  conversationSid: string;
  isGroupConversation: boolean;
  totalUnreadMessages?: number;
  hasMoreMessages?: boolean;
  nextMessagesLoading?: boolean;
  lastMessageReadIndex: number;
  lastMessageIndex: number;
  avatarUrl: string | null;
  currentUser: UserDisplayData;
}>();

const emit = defineEmits<{
  (e: "loadNextMessages"): void;
  (e: "allMessagesRead"): void;
}>();

const nuxtApp = useNuxtApp();
const app = nuxtApp.$app();

const lastMessageIndex = computed(() => props.lastMessageIndex);

const { hasReachedAll, reportReadIndex } = useReadProgress(
  computed(() => props.lastMessageReadIndex),
  lastMessageIndex,
  lastMessageIndex,
);

const conversationSid = computed(() => props.conversationSid);
const conversationUiState = useConversationUiState(conversationSid);
const enableReadMessageReport = useTimeout(1250);

provide("reportReadIndex", (index: number) => {
  if (!enableReadMessageReport.value) return;
  reportReadIndex(index);
});

watch(
  hasReachedAll,
  () => {
    emit("allMessagesRead");
  },
  { immediate: true },
);

const messageArea = ref<HTMLDivElement>();
const messageGroupArea = ref<HTMLDivElement>();
const hasInitiallyScrolledAtTheBottom = ref(false);
const isLightboxVisible = ref(false);
const wasPreviouslyAtBottom = ref(false);
const selectedMessageForReport = ref<null | Message>(null);
const showReportMessageModal = ref(false);
const reportMessageMessage = ref("");
const startingLightboxImageIndex = ref(0);
const lightboxImagesUrls = ref<string[]>([]);

const { y, arrivedState } = useScroll(messageArea);
const { height } = useElementSize(messageGroupArea);

watch(
  height,
  () => {
    scrollBackToBottomIfHeightIncreased({ smooth: true });
  },
  { immediate: true },
);

debouncedWatch(
  [y, () => props.conversationSid],
  () => {
    conversationUiState.value.updateScrollPosition(y.value);
    wasPreviouslyAtBottom.value = arrivedState.bottom;

    if (
      y.value <= 1024 &&
      props.hasMoreMessages &&
      !props.nextMessagesLoading
    ) {
      emit("loadNextMessages");
    }
  },
  { immediate: true, debounce: 10 },
);

watch(
  () => props.nextMessagesLoading,
  () => {
    if (props.nextMessagesLoading === true) return;

    requestAnimationFrame(() =>
      messageArea.value?.scrollTo({
        top: 999999999999999,
        behavior: "auto",
      }),
    );

    hasInitiallyScrolledAtTheBottom.value = true;
  },
  { immediate: true },
);

function scrollBackToBottomIfHeightIncreased(options?: { smooth?: boolean }) {
  if (wasPreviouslyAtBottom.value) {
    hasInitiallyScrolledAtTheBottom.value = true;
    requestAnimationFrame(() =>
      messageArea.value?.scrollTo({
        top: 999999999999999,
        behavior: options?.smooth ? "smooth" : "auto",
      }),
    );
  }
}

async function sendReportForMessage(message: Message, reportMessage: string) {
  await executeWithNotificationOnError(async () => {
    await Report.reportConversationMessage(
      props.conversationSid,
      message.id,
      reportMessage,
      await getTokenOrThrow(),
    );

    app?.notify({
      preset: "success.plain",
      detail: "Report has been submitted",
      timer: 3000,
    });
  });

  reportMessageMessage.value = "";
}
</script>

export function useIsLoggedIn() {
  const $auth = useNuxtApp().$auth();
  return computed(() => !!$auth?.user);
}

export function useLoggedUser() {
  const $auth = useNuxtApp().$auth();
  return computed(() => $auth?.userEntity ?? null);
}

export function useLoggedOAuthUserData() {
  const nuxtApp = useNuxtApp();
  const auth = nuxtApp.$auth();
  return computed(() => auth?.user);
}

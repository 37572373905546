<template>
  <NotificationsItem
    :is-unread
    :href
    :action-icon="IconCoinFilled"
    :date="notification.date"
  >
    <template #content>
      <div class="space-y-1">
        <UserAvatar
          :user="notification.data.userWhoSubscribed"
          :size="32"
          no-link
        />
        <p>
          <i18n-t
            keypath="notifications.payment.organization_subscription.body"
            scope="global"
          >
            <template #subscriber>
              <strong>{{ subscriberName }}</strong>
            </template>
            <template #organization>
              <strong>{{ organizationName }}</strong>
            </template>
            <template #tier>
              <strong>{{ tierName }}</strong>
            </template>
          </i18n-t>
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import { IconCoinFilled } from "@tabler/icons-vue";
import type { Props } from "../NotificationsItem.vue";
import type { NamiNotificationPaymentOrganizationSubscription } from "~/src/notifications/types/nami/payment-organization-subscription";
import type { OrganizationEntity, UserEntity } from "~/src/api";

const props = defineProps<
  {
    notification: NamiNotificationPaymentOrganizationSubscription;
  } & Omit<Props, "actionIcon">
>();

const nuxtApp = useNuxtApp();
const locale = nuxtApp.$i18n.global.locale;

const href = computed(() => {
  return linkTo(props.notification.data.organization);
});

const subscriberName = computed(() => {
  return getNameForUser(props.notification.data.userWhoSubscribed);
});

const organizationName = computed(() => {
  return getNameForOrganization(props.notification.data.organization);
});

const tierName = computed(() => {
  return extractFromLocale(
    locale.value,
    props.notification.data.tier.attributes.name,
  );
});

function getNameForUser(user: UserEntity) {
  return user.attributes.displayName ?? user.attributes.username;
}

function getNameForOrganization(organization: OrganizationEntity) {
  return organization.attributes.name;
}
</script>

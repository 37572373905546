import type { AvailableLocales } from "~/src/api";
import TimeAgo, {
  type FormatStyleName,
  type Style,
  type FormatOptions,
} from "javascript-time-ago";
import de from "javascript-time-ago/locale/de";
import en from "javascript-time-ago/locale/en";
import es from "javascript-time-ago/locale/es";
import fr from "javascript-time-ago/locale/fr";
import it from "javascript-time-ago/locale/it";
import ja from "javascript-time-ago/locale/ja";
import ko from "javascript-time-ago/locale/ko";
import pt from "javascript-time-ago/locale/pt";
import zh from "javascript-time-ago/locale/zh";
import type { MaybeRef } from "vue";

TimeAgo.addLocale(de);
TimeAgo.addLocale(en);
TimeAgo.addLocale(es);
TimeAgo.addLocale(fr);
TimeAgo.addLocale(it);
TimeAgo.addLocale(ja);
TimeAgo.addLocale(ko);
TimeAgo.addLocale(pt);
TimeAgo.addLocale(zh);

export function useTimeAgo(locale: MaybeRef<AvailableLocales>) {
  return computed(() => {
    const formatter = new TimeAgo(unref(locale));

    return (
      date: Date,
      style?: FormatStyleName | Style,
      options?: FormatOptions,
    ) => {
      if (
        date.valueOf() > Date.now() &&
        (style as FormatStyleName)?.includes("mini")
      )
        return formatter.format(date, "twitter-now", options);
      return formatter.format(date, style, options);
    };
  });
}

export function useDateFormatter(locale: MaybeRef<AvailableLocales>) {
  const formatter = computed(
    () =>
      new Intl.DateTimeFormat(unref(locale), {
        dateStyle: "medium",
        timeStyle: "short",
      }),
  );

  return function (date: Date) {
    return computed(() => formatter.value.format(date));
  };
}

export function useNumberFormatter(
  locale: MaybeRef<AvailableLocales>,
  defaultOptions: Intl.NumberFormatOptions = { notation: "compact" },
) {
  return computed(
    () =>
      (num: number, options: Intl.NumberFormatOptions = {}) => {
        const formatter = new Intl.NumberFormat(unref(locale), {
          ...defaultOptions,
          ...options,
        });
        return formatter.format(num);
      },
  );
}

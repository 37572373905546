import {
  injectDeveloperContext,
  type DeveloperContextData,
} from "../sources/developer/context";
import {
  injectNotificationsContext,
  type NotificationsContextData,
} from "../sources/notifications/context";
import {
  injectTwilioContext,
  type TwilioContextData,
} from "../sources/twilio/context";

export type Contexts = {
  developer: DeveloperContextData;
  notifications: NotificationsContextData;
  twilio: TwilioContextData;
};

export const asyncContextDependencies: {
  [Ctx in keyof Contexts]: ((ctx: Contexts[Ctx]) => void)[];
} = {
  developer: [],
  notifications: [],
  twilio: [],
};

export const contexts: {
  [Ctx in keyof Contexts]: Contexts[Ctx] | null;
} = {
  developer: null,
  notifications: null,
  twilio: null,
};

export function getAsyncContext<Ctx extends keyof Contexts>(
  context: Ctx,
): Promise<Contexts[Ctx]> {
  const resolvedContext = contexts[context];

  return new Promise<Contexts[Ctx]>((resolve, reject) => {
    if (resolvedContext) return resolve(resolvedContext);

    asyncContextDependencies[context].push((ctx) => {
      resolve(ctx);
    });
  });
}

import { minimatch } from "minimatch";

const persistFromTo = [
  "/studio/*/audience-acquisition{/**,}*",
  "/studio/*/audience-overview{/**,}*",
  "/studio/*/audience-demographics{/**,}*",
];

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return;
  if (Object.keys(to.query).length > 0) return;
  if (Object.keys(to.query).length === Object.keys(from.query).length) return;

  const toPathIsIncluded = persistFromTo.some((glob) =>
    minimatch(to.path, glob),
  );
  const fromPathIsIncluded = persistFromTo.some((glob) =>
    minimatch(from.path, glob),
  );

  if (toPathIsIncluded && fromPathIsIncluded) {
    return navigateTo({
      path: to.path,
      hash: to.hash,
      query: from.query,
    });
  }
});

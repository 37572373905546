/**
 * After first call, the passed function will be executed after a set amount of time defined by `ms`.
 * Every subsequent call after that time will only change the arguments with which the function is called.
 * @param fn The function to execute
 * @param ms The time to defer its execution in ms
 * @returns A promise that resolves to the function's return value, or rejects with its error
 */
export function createDeferredFn<T extends (...args: any[]) => any>(
  fn: T,
  ms: number = 100,
) {
  const bound = fn.bind(fn);
  let latestArguments: any[] = [];
  let timer: NodeJS.Timeout | null = null;

  let resolver: (value: any) => void;
  let rejecter: (reason?: any) => void;

  const promise = new Promise<any>((resolve, reject) => {
    resolver = resolve;
    rejecter = reject;
  });

  return function (...args: any[]) {
    if (timer) {
      latestArguments = args;
      return promise;
    }

    setTimeout(async () => {
      clearTimeout(timer!);
      timer = null;
      try {
        resolver(await bound(...latestArguments));
      } catch (e) {
        rejecter(e);
      }
    }, ms);

    return promise;
  } as (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>>;
}

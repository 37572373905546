<template>
  <div
    ref="el"
    class="relative"
    @mouseenter="() => onMouseEnter()"
    @mouseleave="() => onMouseLeave()"
  >
    <slot :show="showTooltip" :hide="hideTooltip" :is-shown="isShown">
      <IconHelp />
    </slot>
    <Teleport to="body" :disabled="!fixed">
      <TransitionFade>
        <div
          v-show="isShown || alwaysVisible"
          @mouseenter="() => onMouseEnter(false)"
          @mouseleave="() => onMouseLeave(false)"
          class="z-[100]"
          :class="{
            'mb-2': !position || position === 'top',
            'mt-2': position === 'bottom',
            'pointer-events-none': noPointerEvents,
          }"
          :style="{
            ...tooltipStyle,
            maxWidth: `${maxWidth}px`,
            minWidth: `${maxWidth}px`,
          }"
          ref="tooltipContent"
        >
          <div class="w-full">
            <div
              class="tooltip max-w-full"
              :class="{
                'bg-black/90 dark:bg-white/90 text-white dark:text-black':
                  variant === 'contrast',
                'bg-white/70 dark:bg-black/70 border border-neutral-200 dark:border-neutral-700':
                  variant === 'default' || !variant,
                'flex rounded-md p-2 text-sm shadow-xl backdrop-blur-lg':
                  variant !== 'custom',
                'text-center justify-center': !textLeft,
              }"
            >
              <slot
                name="tooltip"
                :show="showTooltip"
                :hide="hideTooltip"
                :is-shown="isShown"
              >
                {{ text }}
              </slot>
            </div>
          </div>
        </div>
      </TransitionFade>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { IconHelp } from "@tabler/icons-vue";
import {
  useElementBounding,
  useWindowSize,
  useEventListener,
} from "@vueuse/core";

const props = defineProps<{
  text?: string;
  size?: "normal" | "small" | "xsmall" | "large" | "xlarge" | number;
  variant?: "default" | "contrast" | "custom";
  textLeft?: boolean;
  position?: "top" | "bottom";
  fixed?: boolean;
  alwaysVisible?: boolean;
  noMobile?: boolean;
  noPointerEvents?: boolean;
  disabled?: boolean;
  manual?: boolean;
  show?: boolean;
}>();

const emit = defineEmits<{
  (e: "clickOutside"): void;
}>();

const el = ref<HTMLDivElement>();
const tooltipContent = ref<HTMLDivElement>();
const isShown = ref(!!props.alwaysVisible);

const sizeMappings = {
  normal: 150,
  small: 100,
  xsmall: 50,
  large: 200,
  xlarge: 300,
};

const maxWidth = computed(() => {
  return typeof props.size === "number"
    ? props.size
    : sizeMappings[props.size ?? "normal"];
});

const boundsSafeArea = 32;

const { left, width, top, bottom, height, update } = useElementBounding(el);
const { width: windowWidth, height: windowHeight } = useWindowSize();

const horizontalOffset = computed(() => {
  const centerOfElement = left.value + width.value / 2;
  const leftBound = boundsSafeArea;
  const rightBound = windowWidth.value - boundsSafeArea;

  const leftBoundOverflow = centerOfElement - maxWidth.value / 2 - leftBound;
  const rightBoundOverflow = centerOfElement + maxWidth.value / 2 - rightBound;

  if (leftBoundOverflow < 0) {
    return -leftBoundOverflow;
  } else if (rightBoundOverflow > 0) {
    return -rightBoundOverflow;
  } else {
    return 0;
  }
});

const hasEnoughVerticalSpace = computed(() => {
  if (props.position === "bottom") {
    return bottom.value + 256 <= windowHeight.value;
  } else {
    return top.value - 256 >= 0;
  }
});

const finalPosition = computed(() => {
  return props.position === "bottom"
    ? hasEnoughVerticalSpace.value
      ? "bottom"
      : "top"
    : hasEnoughVerticalSpace.value
      ? "top"
      : "bottom";
});

const tooltipStyle = computed(() => {
  function getFixedStyle() {
    return {
      position: "fixed",
      left:
        left.value -
        (maxWidth.value - width.value) / 2 +
        horizontalOffset.value +
        "px",
      top:
        finalPosition.value === "bottom"
          ? top.value + height.value + "px"
          : undefined,
      bottom:
        finalPosition.value === "bottom"
          ? undefined
          : windowHeight.value - bottom.value + height.value + "px",
    } as const;
  }

  function getAbsoluteStyle() {
    return {
      position: "absolute",
      left: `calc(50% - ${maxWidth.value / 2}px + ${horizontalOffset.value}px)`,
      top: props.position === "bottom" ? "100%" : undefined,
      bottom: props.position === "bottom" ? undefined : "100%",
    } as const;
  }

  if (props.fixed) {
    return getFixedStyle();
  }

  return getAbsoluteStyle();
});

let timer: NodeJS.Timeout | null = null;

function queueShowValue(value: boolean) {
  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(() => {
    isShown.value = value;
  }, 100);
}

function showTooltip() {
  if ((props.noMobile && isMobile()) || props.disabled) {
    return;
  }

  if (props.alwaysVisible) {
    return;
  }

  update();
  queueShowValue(true);
}

function hideTooltip() {
  if ((props.noMobile && isMobile()) || props.disabled) {
    return;
  }

  if (props.alwaysVisible) {
    return;
  }

  queueShowValue(false);
}

watch(
  () => props.show,
  (newShow) => {
    if (newShow) {
      showTooltip();
    } else {
      hideTooltip();
    }
  },
  { immediate: true },
);

function onMouseEnter(immediate = true) {
  if (props.manual) return;
  if (immediate) {
    showTooltip();
  } else {
    queueShowValue(true);
  }
}

function onMouseLeave(immediate = true) {
  if (props.manual) return;
  if (immediate) {
    hideTooltip();
  } else {
    queueShowValue(false);
  }
}

defineExpose({
  isShown,
});

let cleanups: (() => void)[] = [];

onMounted(() => {
  if (props.manual) {
    const cleanup = useEventListener(document, "click", (e) => {
      if (!el.value || !tooltipContent.value) return;
      if (!isClickInsideElements(e, [el.value, tooltipContent.value])) {
        emit("clickOutside");
      }
    });

    cleanups.push(cleanup);
  }
});

onUnmounted(() => {
  cleanups.forEach((cleanup) => cleanup());
});
</script>

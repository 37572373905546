import { useAuthStore } from "~/stores/auth";
import { useSettingsStore } from "~/stores/settings";
import { useFeedsStore } from "~/stores/feeds";
import { useAppStore } from "~/stores/app";
import { useNewsStore } from "~/stores/news";

export type AuthStore = ReturnType<typeof useAuthStore>;
export type SettingsStore = ReturnType<typeof useSettingsStore>;
export type FeedsStore = ReturnType<typeof useFeedsStore>;
export type AppStore = ReturnType<typeof useAppStore>;
export type NewsStore = ReturnType<typeof useNewsStore>;

export default defineNuxtPlugin(() => {
  return {
    provide: {
      auth: (...args: Parameters<typeof useAuthStore>) =>
        process.server ? undefined : useAuthStore(...args),
      settings: (...args: Parameters<typeof useSettingsStore>) =>
        process.server ? undefined : useSettingsStore(...args),
      feeds: (...args: Parameters<typeof useFeedsStore>) =>
        process.server ? undefined : useFeedsStore(...args),
      app: (...args: Parameters<typeof useAppStore>) =>
        process.server ? undefined : useAppStore(...args),
      news: (...args: Parameters<typeof useNewsStore>) =>
        process.server ? undefined : useNewsStore(...args),
    },
  };
});

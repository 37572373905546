import { Organization, type OrganizationEntity } from "~/src/api";

export function useOrganizationCache(timeout = 1000 * 60) {
  const cache = useGenericCache<string, OrganizationEntity>(
    "organizations",
    timeout,
  );

  async function getById(id: string) {
    const cached = cache.get(id);
    if (cached) return cached;

    const organization = await Organization.get(id, [
      "creator",
      "member",
      "user",
    ]);
    cache.store(id, organization);
    return organization;
  }

  return {
    cache,
    getById,
  };
}

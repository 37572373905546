<template>
  <NotificationsItem
    :is-unread
    :href
    :action-icon="IconPlus"
    :date="notification.date"
  >
    <template #avatar>
      <AsyncImage
        :src="thumbnail || coverUrl"
        class="object-cover w-10 h-10 rounded-md"
      />
    </template>
    <template #content>
      <div class="space-y-1">
        <p>
          <i18n-t
            keypath="notifications.reader.chapter_published_to_followed_title.body"
            scope="global"
          >
            <template #title>
              <strong>{{ title }}</strong>
            </template>
          </i18n-t>
        </p>
        <p class="text-sm text-neutral-600 dark:text-neutral-400 line-clamp-2">
          {{ shortName }}
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import { parseTitleForDisplay } from "~/utils/display/title";
import { parseChapterForDisplay } from "~/utils/display/chapter";
import { IconPlus } from "@tabler/icons-vue";
import type { NamiNotificationReaderChapterPublishedToFollowedTitle } from "~/src/notifications/types/nami/reader-chapter-published-to-followed-title";
import type { Props } from "../NotificationsItem.vue";

const props = defineProps<
  {
    notification: NamiNotificationReaderChapterPublishedToFollowedTitle;
  } & Omit<Props, "actionIcon">
>();

const { title, coverUrl } = parseTitleForDisplay(
  computed(() => props.notification.data.title),
);
const { shortName, thumbnail } = parseChapterForDisplay(
  computed(() => props.notification.data.chapter),
  "small",
);

const href = computed(() => {
  return linkTo(props.notification.data.chapter);
});
</script>

import { defineStore } from "pinia";
import { User, UserManager, WebStorageStateStore } from "oidc-client-ts";
import {
  isBadge,
  isExpanded,
  isFrame,
  isUserProfile,
  User as UserClass,
  type UserEntity,
  type UserProfile,
} from "~/src/api";

const channel = new BroadcastChannel("namicomi-auth-state");
const loggedInUserChannel = new BroadcastChannel("store-oauth-user");

export const useAuthStore = defineStore({
  id: "auth",
  state: () => {
    const userStore = new WebStorageStateStore({ prefix: "namicomi." });
    const stateStore = new WebStorageStateStore({ prefix: "namicomi-state." });

    const config = useRuntimeConfig();

    const manager = new UserManager({
      authority: `${config.public.kcUrl}/realms/namicomi`,
      client_id: config.public.kcClientId,
      redirect_uri: `${config.public.baseUrl}/callback/login`,
      scope: "openid email groups profile roles",
      userStore: userStore,
      stateStore: stateStore,
      automaticSilentRenew: false,
    });

    return {
      user: null as User | null,
      userEntity: null as UserEntity | null,
      userProfile: null as UserProfile | null,
      userFetching: false,
      refreshing: true,
      _manager: manager,
    };
  },
  actions: {
    updateFromUserEntity(user: UserEntity) {
      // frames & badges are required for an update candidate
      const frame = user.relationships.find(isFrame);
      if (frame && !isExpanded(frame)) return;
      if (user.relationships.some((rel) => isBadge(rel) && !isExpanded(rel)))
        return;

      // update the stored user entity if the provided one is newer or we dont have one
      if (
        !this.userEntity ||
        this.userEntity.attributes.version <= user.attributes.version
      )
        this.userEntity = user;

      // update the profile if one exists
      const profile = user.relationships.find(isUserProfile);
      if (profile && !isExpanded(profile)) return;

      this.userProfile = {
        ...profile,
        relationships: [],
      } as UserProfile;
    },
    async updateUserData() {
      if (this.userFetching) {
        return new Promise((resolve) => {
          const timer = setInterval(() => {
            if (this.userFetching) {
              return;
            }

            clearInterval(timer);
            resolve({
              user: this.userEntity,
              profile: this.userProfile,
            });
          }, 100);
        });
      }

      if (this.user && !this.userEntity) {
        this.userFetching = true;
        this.userEntity = await UserClass.get(
          this.user.profile.sub,
          ["*"],
          this.user.access_token,
        );

        const profile = this.userEntity?.relationships.find(isUserProfile);

        // not expanded = not exist
        if (profile && isExpanded(profile))
          this.userProfile = {
            ...profile,
            relationships: [],
          } as UserProfile;
      } else if (!this.user) {
        this.userEntity = null;
        this.userProfile = null;
      }

      this.userFetching = false;
      return {
        user: this.userEntity,
        profile: this.userProfile,
      };
    },
    async getUser() {
      this.user = await this._manager.getUser();

      if (this.user?.expires_at && this.user.expires_at < Date.now() / 1000) {
        this.user = await this._manager.signinSilent();
      }

      await this.updateUserData();

      return this.user;
    },
    async getToken(): Promise<string | null> {
      const user = await this.getUser();
      return user?.access_token ?? null;
    },
    async isLoggedIn() {
      await this.waitUntilRefreshIsComplete();

      return !!this.user;
    },
    login(options: { redirectBackTo?: string; locale: string }) {
      const config = useRuntimeConfig();
      const isEmbed = useIsEmbed();

      // The page that'll handle the returned query parameters to set up user metadata
      // This page is also responsible for redirecting the user to their last page
      const redirectUri = new URL(
        `${config.public.baseUrl}/${options.locale}/callback/signin`,
      );

      redirectUri.searchParams.set(
        "afterAuthentication",
        options.redirectBackTo ?? "/",
      );

      if (!isEmbed.value) {
        this._manager.signinRedirect({
          redirect_uri: redirectUri.toString(),
        });
      } else {
        const signInEmbedUri = new URL(config.public.baseUrl);
        signInEmbedUri.pathname = "/embed/signin";
        window.open(
          signInEmbedUri.href,
          "_blank",
          "popup=1,width=600,height=600,left=100,top=100",
        );
      }
    },
    async logout(options: { locale: string }) {
      const config = useRuntimeConfig();

      // TODO
      // await firebase.deleteToken();

      // Signing out doesn't give us any query params, we can redirect immediately to the last page
      const redirectUri = new URL(`${config.public.baseUrl}/${options.locale}`);
      this.user = null;
      this.userEntity = null;
      this.userProfile = null;

      this._manager.signoutRedirect({
        post_logout_redirect_uri: redirectUri.toString(),
      });
    },
    async processRedirectCallback() {
      const user = await this._manager.signinRedirectCallback();
      this.user = user;
      this._manager.storeUser(user);

      // Update other tabs as well
      channel.postMessage({});
      return user;
    },
    async syncAuthState() {
      this.refreshing = true;
      this.user = await this._manager.getUser();

      if (this.user && this.user.expired) {
        console.debug("User token expired, refreshing...");
        await this._manager
          .signinSilent()
          .then((user) => (this.user = user))
          .catch((err) => {
            console.error(err);
            this._manager.removeUser();
            this.user = null;
          });
      }

      await this.updateUserData();

      this.refreshing = false;
    },
    async waitUntilRefreshIsComplete() {
      if (!this.refreshing) {
        return;
      }

      return new Promise<void>((resolve) => {
        const timer = setInterval(() => {
          if (this.refreshing) {
            return;
          }

          clearInterval(timer);
          resolve();
        }, 100);
      });
    },
  },
});

// Reinitialize our store once we've got a message.
channel.onmessage = resync;

function resync() {
  useAuthStore().syncAuthState();
}

loggedInUserChannel.onmessage = (event) => {
  const user = event.data as User | null;
  const authStore = useAuthStore();
  authStore._manager.storeUser(user);
  authStore.syncAuthState();
};

import axios from "../core/axios";
import { Headers } from "./generic";
import { assertSuccess } from "../core/assert";
import type { CompactResult, Result } from "../types/result";
import type {
  ColorTheme,
  ImageFit,
  ReaderSettings,
  ReadingDirection,
  ReadingMode,
  ScrollDirection,
  SettingsEntity,
  SettingsPostBody,
} from "../types/settings";
import type { AvailableLocales } from "../types/locales";

export class Settings {
  settings: SettingsEntity;

  constructor(data: SettingsEntity) {
    this.settings = data;
  }

  static async get(userId: string | null = null, auth: string) {
    const path = userId ? `/settings/${userId}` : "/settings";

    const resp = await axios<Result<SettingsEntity>>(path, {
      headers: Headers.Bearer(auth),
    });

    // No content = no settings
    if (resp.status === 204) {
      return null;
    }

    return new Settings(assertSuccess(resp.data).data);
  }

  static async create(
    userId: string | null,
    template: SettingsTemplate,
    auth: string,
  ) {
    const path = userId ? `/settings/${userId}` : "/settings";

    const resp = await axios<Result<SettingsEntity>>(path, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: {
        data: { ...template.template.data },
      },
    });

    return new Settings(assertSuccess(resp.data).data);
  }

  static async update(
    userId: string | null,
    template: SettingsTemplate,
    version: number,
    auth: string,
  ) {
    const path = userId ? `/settings/${userId}` : "/settings";

    const resp = await axios<Result<SettingsEntity>>(path, {
      method: "PUT",
      headers: Headers.Bearer(auth),
      data: {
        data: { ...template.template.data },
        version: version,
      },
    });

    return new Settings(assertSuccess(resp.data).data);
  }

  static async delete(userId: string | null, version: number, auth: string) {
    const path = userId ? `/settings/${userId}` : "/settings";

    const resp = await axios<CompactResult>(path, {
      method: "DELETE",
      headers: Headers.Bearer(auth),
      data: { version: version },
    });

    return assertSuccess(resp.data);
  }

  async update(template: SettingsTemplate, auth: string) {
    return Settings.update(
      this.settings.id,
      template,
      this.settings.attributes.version,
      auth,
    );
  }

  async delete(auth: string) {
    return Settings.delete(null, this.settings.attributes.version, auth);
  }

  save() {
    localStorage.setItem("namicomi.settings", JSON.stringify(this.settings));
  }
}

export class SettingsTemplate {
  template: SettingsPostBody;

  constructor(data: SettingsPostBody) {
    this.template = data;
  }

  parseFromSettings(settings: Settings) {
    this.template.data.site = settings.settings.attributes.data.site;
    this.template.data.reader = settings.settings.attributes.data.reader;
  }

  setLocale(locale: AvailableLocales) {
    this.template.data.site.locale = locale;

    return this;
  }

  setTheme(theme: ColorTheme) {
    this.template.data.site.theme = theme;

    return this;
  }

  profileExists(profile: string) {
    return (
      typeof this.template.data.reader.find(
        (setting) => setting.profile === profile,
      ) !== "undefined"
    );
  }

  setReaderMenuOpenOnPageLoad(profile: string, value: boolean) {
    this.template.data.reader.find(
      (setting) => setting.profile === profile,
    )!.openMenuOnPageLoad = value;
  }

  setReadingMode(profile: string, mode: ReadingMode) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.readingMode = mode;

    return this;
  }
  setScrollDirection(profile: string, mode: ScrollDirection) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.scrollDirection = mode;

    return this;
  }
  setUpscaleSmallPages(profile: string, mode: boolean) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.upscaleSmallPages = mode;

    return this;
  }

  setCurrentImageFit(profile: string, imageFit: ImageFit) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.imageFit.current = imageFit;

    return this;
  }

  setImageFitCustomValues(
    profile: string,
    value: ReaderSettings[number]["imageFit"]["custom"],
  ) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.imageFit.custom = value;

    return this;
  }

  setOpenMenuOnPageLoad(profile: string, should: boolean) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.openMenuOnPageLoad = should;

    return this;
  }

  setDirection(profile: string, direction: ReadingDirection) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.readingDirection = direction;

    return this;
  }

  setUseNaturalScrolling(profile: string, should: boolean) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.useNaturalScrolling = should;

    return this;
  }

  setAnimation(profile: string, should: boolean) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.animation = should;

    return this;
  }

  setHideReadingHint(profile: string, should: boolean) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.hideReadingHint = should;

    return this;
  }
  setContinuous(profile: string, should: boolean) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.continuous = should;

    return this;
  }

  setScrollWheelBehavior(
    profile: string,
    behavior: "pageTurn" | "zoom" | "none",
  ) {
    if (this.profileExists(profile))
      this.template.data.reader.find(
        (setting) => setting.profile === profile,
      )!.scrollWheelBehavior = behavior;

    return this;
  }
}

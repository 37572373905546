import axios from "../core/axios";
import { assertSuccess, paramsParser } from "../core";
import { Headers } from "./generic";
import { nanoid } from "nanoid";
import { Statistics } from "./statistics";
import { Subscriptions } from "./subscriptions";
import { Tier } from "./tier";
import { trimObjectStrings } from "../helpers";
import type {
  Collection,
  CollectionResult,
  IncludeArr,
  MessageResult,
  OrganizationEntity,
  OrganizationFollowsEntity,
  OrganizationFollowsParams,
  OrganizationFuzzySearchParams,
  OrganizationListParams,
  PostOrganizationBody,
  PrivateOrganizationEntity,
  Result,
  SubscriptionListParams,
  TierListParams,
} from "../types";
import { AxiosError } from "axios";

export class Organization {
  static async get(
    id: string,
    includes: IncludeArr<OrganizationEntity> = [],
  ): Promise<OrganizationEntity> {
    const resp = await axios<Result<OrganizationEntity>>(
      `/organization/${id}` + paramsParser({ includes: includes }),
      { responseType: "json" },
    );

    return assertSuccess(resp.data).data;
  }

  static async getBySlug(
    slug: string,
    includes: IncludeArr<OrganizationEntity> = [],
  ) {
    const resp = await axios<Result<OrganizationEntity>>(
      `/organization/slug/${slug}` + paramsParser({ includes: includes }),
      { responseType: "json" },
    );

    return assertSuccess(resp.data).data;
  }

  static async getPrivate(
    id: string,
    includes: IncludeArr<OrganizationEntity>,
    auth: string,
  ): Promise<PrivateOrganizationEntity> {
    const resp = await axios<Result<PrivateOrganizationEntity>>(
      `/organization/${id}` +
        paramsParser({
          includes: includes,
          showPrivate: true,
        }),
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async search(
    params: OrganizationListParams = {},
    expanded: boolean = true,
  ): Promise<Collection<OrganizationEntity>> {
    const resp = await axios<CollectionResult<OrganizationEntity>>(
      `/organization` +
        paramsParser({
          ...params,
          includes: expanded ? ["creator", "user", "member"] : params.includes,
        }),
      {
        responseType: "json",
      },
    );

    return assertSuccess(resp.data);
  }

  static async fuzzySearch(params: OrganizationFuzzySearchParams = {}) {
    const resp = await axios.get<CollectionResult<OrganizationEntity>>(
      `/organization/search` + paramsParser(params),
    );

    return assertSuccess(resp.data);
  }

  static async create(body: PostOrganizationBody, auth: string) {
    const resp = await axios<Result<PrivateOrganizationEntity>>(
      `/organization/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...Headers.Bearer(auth),
        },
        data: trimObjectStrings(body),
        responseType: "json",
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async update(
    organizationId: string,
    body: PostOrganizationBody,
    version: number,
    auth: string,
  ) {
    if (Object.keys(body).length === 0) return; // there are no differences in the template, dont needlessly update

    const resp = await axios<Result<PrivateOrganizationEntity>>(
      `/organization/${organizationId}`,
      {
        method: "PUT",
        headers: Headers.Bearer(auth),
        data: {
          ...trimObjectStrings(body),
          version: version,
        },
        responseType: "json",
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async uploadAvatar(
    image: File | Blob,
    orgId: string,
    version: number,
    auth: string,
  ) {
    const formData = new FormData();
    formData.append(`avatar`, image, nanoid());
    formData.append("version", version.toString());

    const resp = await axios<Result<PrivateOrganizationEntity>>(
      `/organization/${orgId}/avatar`,
      {
        method: "POST",
        headers: {
          ...Headers.Bearer(auth),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async uploadBanner(
    image: File | Blob,
    orgId: string,
    version: number,
    auth: string,
  ) {
    const formData = new FormData();
    formData.append(`banner`, image, nanoid());
    formData.append("version", version.toString());

    const resp = await axios<Result<PrivateOrganizationEntity>>(
      `/organization/${orgId}/banner`,
      {
        method: "POST",
        headers: {
          ...Headers.Bearer(auth),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async deleteAvatar(orgId: string, version: number, auth: string) {
    const resp = await axios<Result<PrivateOrganizationEntity>>(
      `/organization/${orgId}/avatar`,
      {
        method: "DELETE",
        headers: {
          ...Headers.Bearer(auth),
          "Content-Type": "application/json",
        },
        data: {
          version,
        },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async deleteBanner(orgId: string, version: number, auth: string) {
    const resp = await axios<Result<PrivateOrganizationEntity>>(
      `/organization/${orgId}/banner`,
      {
        method: "DELETE",
        headers: {
          ...Headers.Bearer(auth),
          "Content-Type": "application/json",
        },
        data: {
          version,
        },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async delete(orgId: string, version: number, auth: string) {
    const resp = await axios(`/organization/${orgId}`, {
      method: "DELETE",
      headers: Headers.Bearer(auth),
      data: { version: version },
    });

    return assertSuccess(resp.data);
  }

  static async follow(orgId: string, auth: string) {
    const resp = await axios<Result<OrganizationFollowsEntity>>(
      `/follows/organization/${orgId}`,
      {
        method: "PUT",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async unfollow(orgId: string, auth: string) {
    const resp = await axios<MessageResult<"Organization unfollowed">>(
      `/follows/organization/${orgId}`,
      {
        method: "DELETE",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  static async getFollow(
    orgId: string,
    includes: IncludeArr<OrganizationFollowsEntity> = [],
    auth: string,
  ) {
    const resp = await axios<Result<OrganizationFollowsEntity>>(
      `/follows/organization/${orgId}` + paramsParser({ includes: includes }),
      { headers: Headers.Bearer(auth) },
    ).catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 404)
        return null;
      else throw err;
    });

    if (resp === null) return null;
    else return assertSuccess(resp.data).data;
  }

  static async getFollows(
    params: OrganizationFollowsParams = {},
    auth?: string,
  ) {
    const resp = await axios<CollectionResult<OrganizationFollowsEntity>>(
      `/follows/organization` + paramsParser(params),
      { headers: auth ? Headers.Bearer(auth) : undefined },
    );

    return assertSuccess(resp.data);
  }

  static async getStats(id: string) {
    return Statistics.getOrganizationStats(id);
  }

  static async getSubscriptions(
    id: string,
    auth: string,
    params?: SubscriptionListParams,
  ) {
    return Subscriptions.getOrganizationSubscriptions(id, auth, params);
  }

  static async getSubscriptionTiers(id: string, params?: TierListParams) {
    return Tier.search({
      ...params,
      organizationId: id,
    });
  }
}

<template>
  <div
    class="space-y-2 transition-transform duration-300 bg-white dark:bg-neutral-900 flex flex-col justify-between rounded-t-lg overflow-hidden border border-neutral-200 dark:border-neutral-800"
    :class="{
      'translate-y-[calc(100%-3.5rem)]': isHidden,
    }"
  >
    <button
      class="p-2 px-4 h-[3.5rem] min-h-[3.5rem] max-h-[3.5rem] grid grid-cols-[1fr_auto] gap-2 items-center transition-colors hover:bg-neutral-100 dark:hover:bg-neutral-800 border-b border-neutral-300 dark:border-neutral-700"
      @click="isHidden = !isHidden"
    >
      <h2 class="font-semibold truncate text-left">
        <ConversationName
          v-if="conversation"
          :conversation
          :name="conversationName"
        />
      </h2>
      <div class="flex items-center">
        <NamiButton
          button-type="primary"
          small
          text
          pill
          :href="href.messages.id(conversationSid)"
          @button-click="$emit('close')"
        >
          <span class="p-0.5 inline-block">
            <IconArrowUpRight class="inline-block" :size="20" />
          </span>
        </NamiButton>
        <NamiButton
          button-type="danger"
          small
          text
          pill
          @button-click="$emit('close')"
        >
          <span class="p-0.5 inline-block">
            <IconX class="inline-block" :size="20" />
          </span>
        </NamiButton>
      </div>
    </button>
    <ConversationMessages
      :conversation-sid
      :users
      :groups
      :has-more-messages
      :is-group-conversation
      :last-message-index
      :last-message-read-index
      :next-messages-loading
      :total-unread-messages
      :current-user
      :avatar-url
      class="overflow-y-scroll overflow-x-hidden p-2 flex-grow"
    />
    <ConversationTextArea
      class="p-2"
      v-model="message"
      @send="() => sendCurrentMessageToConversation()"
      ref="messageInputRef"
    />
  </div>
</template>

<script setup lang="ts">
import { type UserDisplayData } from "./common";
import { IconArrowUpRight, IconX } from "@tabler/icons-vue";
import type ConversationTextArea from "./ConversationTextArea.vue";
import { injectTwilioContext } from "../app/context/sources/twilio/context";

const props = defineProps<{
  conversationSid: string;
  conversation: EnhancedConversation;
  groups: MessageGroupFamilyOrContainer[];
  hasMoreMessages: boolean;
  users: UserDisplayData[];
  lastMessageIndex: number;
  lastMessageReadIndex: number;
  nextMessagesLoading: boolean;
  totalUnreadMessages: number;
  avatarUrl: string | null;
  conversationName: string | null;
  currentUser: UserDisplayData;
}>();

defineEmits<{
  (e: "close"): void;
}>();

const href = useHref();

const { primaryTwilioClient } = injectTwilioContext();
const { sendMessageToConversation } = primaryTwilioClient;

const isHidden = ref(false);
const message = ref("");
const messageInputRef = ref<InstanceType<typeof ConversationTextArea>>();

const isGroupConversation = computed(() => props.users.length > 2);

async function sendCurrentMessageToConversation() {
  await executeWithNotificationOnError(async () => {
    assertDefined(messageInputRef.value);
    await sendMessageToConversation(
      props.conversation,
      message.value,
      messageInputRef.value.addedFiles,
    );
  }).catch(() => {});

  messageInputRef.value?.clear();
}
</script>

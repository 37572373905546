<template>
  <TheNuxtLink
    :to="to"
    class="relative text-nami-comi-blue hover:underline"
    :target="isNotRelative ? '_blank' : undefined"
    :rel="isNotRelative ? 'noopener noreferrer' : undefined"
  >
    <slot />
  </TheNuxtLink>
</template>

<script setup lang="ts">
const props = defineProps<{
  to: string;
}>();

const isNotRelative = computed(() => props.to.startsWith("http"));
</script>

import deepEqual from "deep-eql";
import type { Entity, RawEntity, StaticEntity } from "../types/entity";
import type { PopulateRelationship, Relationship } from "../types/relationship";

/**
 * Looks for and returns the relationship of the specified type in the given entity.
 *
 * @param entity The entity to get the relationship from.
 * @param relationshipType The type of the relationship to look for (can be "title" or "chapter" etc.).
 * @param forceExpanded Whether to be ensure that the reference expansion has been performed.
 * @returns The relationship entity of the given entity.
 * @throws An error if no such relationship is found in the entity, or if `forceExpanded` is set to `true`, and there hasn't been performed reference expansion on the entity.
 * @example
 * ```typescript
 * const orgRelation = getRelationship(title, 'organization')
 * // orgRelation.attributes?.name === 'org_name' || undefined
 * ```
 * @example
 * ```typescript
 * const orgRelation = getRelationship(title, 'organization', true)
 * // orgRelation.attributes.name === 'org_name'
 * ```
 */
export function getRelationship<
  T extends string,
  A extends object,
  B extends Relationship["type"],
  R extends Relationship & { type: B },
  E extends boolean,
>(
  entity: Entity<T, A> | RawEntity<T, A> | StaticEntity<T, A>,
  relationshipType: B,
  forceExpanded?: E,
): E extends true ? PopulateRelationship<R> : R {
  const found = entity.relationships.find(
    (r): r is R => r.type === relationshipType,
  );

  if (!found) {
    throw new Error(
      `Could not find relationship of type ${relationshipType} in entity ${JSON.stringify(
        entity,
      )}`,
    );
  } else if (forceExpanded && !found.attributes) {
    throw new Error(`Relationship is not expanded.`);
  }

  return found as PopulateRelationship<R>;
}

/**
 * Looks for and returns the all the relationships of the specified type in the given entity.
 *
 * @param entity The entity to get the relationships from.
 * @param relationshipType The type of the relationships to look for (can be "title" or "chapter" etc.).
 * @param forceExpanded Whether to be ensure that the reference expansion has been performed.
 * @returns The relationship entity of the given entity.
 * @throws An error if `forceExpanded` is set to `true`, at least 1 relationship has been found, and there hasn't been performed reference expansion on the entity.
 */
export function getMultipleRelationships<
  T extends string,
  A extends object,
  B extends Relationship["type"],
  R extends Relationship & { type: B },
  E extends boolean,
>(
  entity: Entity<T, A> | RawEntity<T, A>,
  relationshipType: B,
  forceExpanded?: E,
): E extends true ? PopulateRelationship<R>[] : R[] {
  const found = entity.relationships.filter(
    (r): r is R => r.type === relationshipType,
  );

  if (
    forceExpanded &&
    found.filter((r) => r.attributes).length !== found.length
  ) {
    throw new Error("Some relationships have not been expanded", {
      cause: found,
    });
  }

  return found as E extends true ? PopulateRelationship<R>[] : R[];
}

/**
 * Extracts the bit in the position specified by `bitPosition` from right to left of the binary representation of the number.
 *
 * @param number the number to get the bit from.
 * @param bitPosition the position of the binary representation of the number to get, from right to left.
 * @returns 0 or 1
 */
export function getBitAtPosition(number: number, bitPosition: number): 0 | 1 {
  return (number & (1 << bitPosition)) === 0 ? 0 : 1;
}

/**
 * Removes properties from the `newObject` that are either `undefined` or have
 * values that deeply equal the corresponding properties in `oldObject`.
 *
 * @param {T} newObject - The "new" or "current" version of the object.
 * @param {T | undefined} oldObject - The "old" or "previous" version of the object. If undefined, only properties with `undefined` values are removed from `newObject`.
 * @returns {Partial<T>} The modified `newObject` with removed properties.
 * @example
 * const newObj = { a: 1, b: 2, c: undefined };
 * const oldObj = { a: 1, b: 3 };
 * removeUnchanged(newObj, oldObj);
 * // Returns: { b: 2 }
 * @example
 * const newObj = { a: 1, b: undefined };
 * removeUnchanged(newObj);
 * // Returns: { a: 1 }
 * @note The function modifies `newObject` directly.
 */
export function removeUnchanged<T extends object>(
  newObject: T,
  oldObject?: T,
): Partial<T> {
  let template = newObject;

  const keys = Object.keys(template) as (keyof T)[];
  if (oldObject) {
    for (const key of keys) {
      if (template[key] === undefined) delete template[key];
      else if (deepEqual(template[key], oldObject[key])) delete template[key];
    }
  } else {
    for (const key of keys) {
      if (template[key] === undefined) delete template[key];
    }
  }

  return template;
}

/**
 * Recursively trims string properties of an object, including nested objects.
 *
 * @param {T} input - The object whose string properties need to be trimmed.
 * @returns {T} The modified object with trimmed string properties.
 * @example
 * const obj = { a: " hello ", b: { c: " world ", d: "!" } };
 * trimObjectStrings(obj);
 * // Returns: { a: "hello", b: { c: "world", d: "!" } }
 * @note The function modifies the `input` object directly.
 */
export function trimObjectStrings<T extends object>(input: T): T {
  const template = input;
  const keys = Object.keys(template) as (keyof T)[];

  for (const key of keys) {
    if (typeof template[key] === "string")
      template[key] = (
        template[key] as string
      ).trim() as (typeof template)[typeof key];
    else if (!!template[key] && typeof template[key] === "object") {
      // this is recursive
      template[key] = trimObjectStrings(
        template[key] as object,
      ) as (typeof template)[typeof key];
    }
  }
  return template;
}

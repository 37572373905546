import type { ChapterImageQualities, ImageQuality } from "~/src/api";

// TODO: remove fallback
export const migrateQualitySetting = (
  quality?: ImageQuality,
): ChapterImageQualities => {
  if (quality === "data") return "high";
  else if (quality === "dataSaver") return "medium";
  else if (quality) return quality as ChapterImageQualities;
  return "medium";
};

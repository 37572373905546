// Settings validation schema
import {
  array,
  boolean,
  enums,
  max,
  min,
  nonempty,
  number,
  object,
  optional,
  string,
} from "superstruct";
import { getLanguages, getLocales } from "../resources";
import type { AvailableLanguages } from "../types";

export const AvailableLocalesEnum = enums(getLocales().map((x) => x.value));
export const ColorThemeEnum = enums(["light", "dark", "system"]);
export const ContentRatingEnum = enums(["safe", "mature", "restricted"]);
export const ImageFitEnum = enums(["fit", "custom"]);
//TODO: Legacy values `data` and `dataSaver`, ~40 users still have this value as of Nov 15 2024
export const ImageQualityEnum = enums([
  "high",
  "medium",
  "low",
  // "source" is not accessible via UI, but accepted since it should match image quality options
  "source",
  "data",
  "dataSaver",
]);
export const ReadingDirectionEnum = enums(["ltr", "rtl", "content"]);
export const ReadingModeEnum = enums(["single", "double"]);
export const ScrollDirectionEnum = enums(["horizontal", "vertical"]);
export const TranslationLocaleEnum = enums<string, AvailableLanguages[]>(
  getLanguages().map((x) => x.value),
);

export const SettingsSchema = object({
  site: object({
    locale: AvailableLocalesEnum,
    theme: ColorThemeEnum,
    quality: ImageQualityEnum,
  }),
  platform: object({
    preferredTranslationLocales: array(TranslationLocaleEnum),
    matureContentEnabled: boolean(),
  }),
  reader: array(
    object({
      profile: nonempty(string()),
      condition: optional(string()),
      listCondition: string(),
      readingMode: ReadingModeEnum,
      scrollDirection: ScrollDirectionEnum,
      readingDirection: ReadingDirectionEnum,
      hideReadingHint: boolean(),
      continuous: boolean(),
      imageFit: object({
        current: ImageFitEnum,
        custom: object({
          maximumWidth: min(max(number(), 1), 0),
        }),
      }),
      upscaleSmallPages: boolean(),
      animation: boolean(),
      useNaturalScrolling: boolean(),
      openMenuOnPageLoad: boolean(),
      scrollWheelBehavior: enums(["zoom", "pageTurn", "none"]),
    }),
  ),
});

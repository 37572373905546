import {
  type BadgeRelation,
  isBadge,
  isExpanded,
  isUser,
  type OrganizationAttributes,
  type OrganizationEntity,
  type PopulateRelationship,
  type UserAttributes,
  type UserEntity,
} from "~/src/api";

export type UserDisplayData = {
  type: "user" | "organization";
  id: string;
  slug: string;
  username: string;
  displayName: string;
  avatarFileName: string | null;
  bannerFileName: string | null;
  verified: boolean;
  official: boolean;
  createdAt: string;
  badges: PopulateRelationship<BadgeRelation>[];
};

export type ConversationDisplayData = {
  name: string | null;
};

export function getTwilioUserType(user: EnhancedUser) {
  return isUserAttributes(user.attributes.attributes) ? "user" : "organization";
}

export function getTwilioUserSlug(user: EnhancedUser) {
  return isUserAttributes(user.attributes.attributes)
    ? user.attributes.attributes.username
    : user.attributes.attributes.slug;
}

export function getTwilioUserUsername(user: EnhancedUser) {
  return isUserAttributes(user.attributes.attributes)
    ? user.attributes.attributes.username
    : user.attributes.attributes.name;
}

export function getTwilioDisplayName(user: EnhancedUser) {
  return isUserAttributes(user.attributes.attributes)
    ? (user.attributes.attributes.displayName ??
        user.attributes.attributes.username)
    : user.attributes.attributes.name;
}

export function isTwilioUserOfficial(user: EnhancedUser) {
  return isUserAttributes(user.attributes.attributes)
    ? user.attributes.attributes.isOfficial
    : user.attributes.attributes.official;
}

export function isTwilioUserVerified(user: EnhancedUser) {
  return isUserAttributes(user.attributes.attributes)
    ? user.attributes.attributes.isVerified
    : user.attributes.attributes.verified;
}

export function isUserAttributes(
  attributes: UserAttributes | OrganizationAttributes,
): attributes is UserAttributes {
  return "username" in attributes;
}

export function isOrganizationAttributes(
  attributes: UserAttributes | OrganizationAttributes,
): attributes is OrganizationAttributes {
  return "username" in attributes;
}

export async function getConversationName(
  conversation: EnhancedConversation,
  users: EnhancedUser[],
) {
  const isGroup = conversation.attributes.private === "true";

  if (isGroup) {
    return conversation.friendlyName;
  } else {
    const commaSeparatedParticipantDisplayNames = users
      .map((user) => getTwilioDisplayName(user))
      .join(", ");

    return commaSeparatedParticipantDisplayNames;
  }
}

export function getInjectedTwilioSetup() {
  return inject<Ref<UserDisplayData> | null>("current-twilio-setup", null);
}

export function provideTwilioSetup(setup: Ref<UserDisplayData | null>) {
  return provide<Ref<UserDisplayData | null>>("current-twilio-setup", setup);
}

export function convertTwilioUserToDisplayData(
  user: EnhancedUser,
): UserDisplayData {
  return {
    type: getTwilioUserType(user),
    id: user.identity,
    slug: getTwilioUserSlug(user),
    username: getTwilioUserUsername(user),
    displayName: getTwilioDisplayName(user),
    avatarFileName: user.attributes.attributes.avatarFileName,
    bannerFileName: user.attributes.attributes.bannerFileName,
    verified: isTwilioUserVerified(user),
    official: isTwilioUserOfficial(user),
    // TODO: add badges here, maybe @stavros
    badges: [],
    createdAt: user.attributes.attributes.createdAt,
  };
}

export function getUserSlug(user: UserEntity | OrganizationEntity) {
  return isUser(user) ? user.attributes.username : user.attributes.slug;
}

export function getUserUsername(user: UserEntity | OrganizationEntity) {
  return isUser(user) ? user.attributes.username : user.attributes.name;
}

export function getUserDisplayName(user: UserEntity | OrganizationEntity) {
  return isUser(user)
    ? (user.attributes.displayName ?? user.attributes.username)
    : user.attributes.name;
}

export function isUserVerified(user: UserEntity | OrganizationEntity) {
  return isUser(user) ? user.attributes.isVerified : user.attributes.verified;
}

export function isUserOfficial(user: UserEntity | OrganizationEntity) {
  return isUser(user) ? user.attributes.isOfficial : user.attributes.official;
}

export function convertUserOrOrgToDisplayData(
  user: UserEntity | OrganizationEntity,
): UserDisplayData {
  const verified = isUserVerified(user);
  const official = isUserOfficial(user);

  return {
    type: user.type,
    id: user.id,
    slug: getUserSlug(user),
    username: getUserUsername(user),
    displayName: getUserDisplayName(user),
    avatarFileName: user.attributes.avatarFileName,
    bannerFileName: user.attributes.bannerFileName,
    verified,
    official,
    badges: user.relationships.filter(isBadge).filter(isExpanded),
    createdAt: user.attributes.createdAt,
  };
}

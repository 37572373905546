import { assertSuccess, paramsParser } from "../core";
import { Headers } from "./generic";
import axios from "../core/axios";
import { nanoid } from "nanoid";
import type { AxiosProgressEvent } from "axios";
import type {
  ApprovalCommentEntity,
  ChapterRelation,
  Collection,
  CollectionResult,
  CommentableResource,
  CommentEntity,
  CommentListParams,
  CommentPostBody,
  GenericListParams,
  IncludeArr,
  MessageResult,
  OrganizationRelation,
  Result,
  TitleRelation,
} from "../types";
import { isChapter, isNews, isOrg, isPost, isTitle } from "../helpers";

export type FindApprovalCommentsParams = GenericListParams & {
  includes?: ApprovalCommentEntity["relationships"][number]["type"][];
  order?: Partial<Record<"createdAt", "asc" | "desc">>;
  ids?: string;
  entityType: "title_approval";
  entityId: string;
  showDeleted?: boolean;
  creatorId?: string;
};

export class Comment {
  static async search(
    type: CommentableResource,
    id: string,
    params: CommentListParams = {},
  ): Promise<Collection<CommentEntity>> {
    const resp = await axios<CollectionResult<CommentEntity>>(
      `/comment/${type}/${id}` + paramsParser(params),
    );

    return assertSuccess(resp.data);
  }

  static async get(
    id: string,
    includes: IncludeArr<CommentEntity>,
    auth?: string,
  ): Promise<CommentEntity> {
    const resp = await axios<Result<CommentEntity>>(
      `/comment/${id}` + paramsParser({ includes: includes }),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async create(
    type: CommentableResource,
    id: string,
    body: CommentPostBody,
    auth: string,
  ): Promise<CommentEntity> {
    body.message = body.message?.trim() ?? "";

    const resp = await axios<Result<CommentEntity>>(`/comment/create`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: {
        ...body,
        entityType: type,
        entityId: id,
      },
    });

    return assertSuccess(resp.data).data;
  }

  static async uploadMedia(
    id: string,
    file: File | Blob,
    version: number,
    auth: string,
    onUploadProgress?: (e: AxiosProgressEvent) => any,
  ): Promise<CommentEntity> {
    const formData = new FormData();
    formData.append(`attachment`, file, nanoid());

    const resp = await axios.post<Result<CommentEntity>>(
      `/comment/${id}/media?version=${version}`,
      formData,
      {
        headers: Headers.Bearer(auth),
        onUploadProgress,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async deleteMedia(
    id: string,
    version: number,
    auth: string,
  ): Promise<CommentEntity> {
    const resp = await axios.delete<Result<CommentEntity>>(
      `/comment/${id}/media?version=${version}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async update(
    id: string,
    body: CommentPostBody,
    version: number,
    auth: string,
  ): Promise<CommentEntity> {
    body.message = body.message?.trim() ?? "";

    const resp = await axios<Result<CommentEntity>>(`/comment/${id}`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: {
        ...body,
        version: version,
      },
    });

    return assertSuccess(resp.data).data;
  }

  static async delete(id: string, version: number, auth: string) {
    const resp = await axios<MessageResult<"Comment was soft-deleted">>(
      `/comment/${id}`,
      {
        method: "DELETE",
        headers: Headers.Bearer(auth),
        data: { version: version },
      },
    );

    return assertSuccess(resp.data).message;
  }

  static async restore(id: string, version: number, auth: string) {
    const resp = await axios<MessageResult<"Comment was undeleted">>(
      `/comment/${id}/undelete`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: { version: version },
      },
    );

    return assertSuccess(resp.data).message;
  }

  static async loadMoreReplies(
    comment: CommentEntity,
    offset: number = 0,
    limit: number = 2,
  ): Promise<Collection<CommentEntity>> {
    if (
      comment.relationships.filter((rel) => rel.type === "comment").length === 0
    )
      return {
        type: "collection",
        data: [],
        meta: {
          limit,
          offset,
          total: 0,
        },
      };

    const commentedEntity = comment.relationships.find(
      (rel) =>
        isTitle(rel) ||
        isOrg(rel) ||
        isChapter(rel) ||
        isPost(rel) ||
        isNews(rel),
    )! as
      | TitleRelation
      | OrganizationRelation
      | ChapterRelation
      | { type: "wp_news"; id: string };

    return await Comment.search(commentedEntity.type, commentedEntity.id, {
      parentId: comment.id,
      order: { createdAt: "desc" },
      limit,
      offset,
      includes: ["user", "organization", "profile_frame", "badge"],
    });
  }

  static async createApprovalComment(
    entityType: "title_approval",
    entityId: string,
    message: string,
    auth: string,
  ) {
    const resp = await axios.post<Result<ApprovalCommentEntity>>(
      `/approval/comment`,
      {
        entityType,
        entityId,
        message,
      },
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async deleteApprovalComment(id: string, auth: string) {
    const resp = await axios.delete<MessageResult<"Comment was deleted">>(
      `/approval/comment/${id}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).message;
  }

  static async findApprovalComments(
    params: FindApprovalCommentsParams,
    auth: string,
  ) {
    const resp = await axios.get<CollectionResult<ApprovalCommentEntity>>(
      `/approval/comment` + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  static async viewApprovalComment(
    id: string,
    includes: ApprovalCommentEntity["relationships"][number]["type"],
    auth: string,
  ) {
    const resp = await axios.get<Result<ApprovalCommentEntity>>(
      `/approval/comment/${id}` + paramsParser({ includes }),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }
}

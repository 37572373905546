const ratelimits = {
  default: {
    amount: 10,
    interval: 1000,
  },
  chapter_history_push: {
    amount: 300,
    interval: 1000 * 60 * 10,
  },
  chapter_upload_session_find: {
    amount: 30,
    interval: 1000 * 60,
  },
  chapter_upload_session_create: {
    amount: 20,
    interval: 1000 * 60,
  },
  chapter_upload_session_delete: {
    amount: 30,
    interval: 1000 * 60,
  },
  chapter_upload_session_commit: {
    amount: 10,
    interval: 1000 * 60,
  },
  chapter_upload_session_images: {
    amount: 250,
    interval: 1000 * 60,
  },
  chapter_modify: {
    amount: 10,
    interval: 1000 * 60,
  },
  chapter_delete: {
    amount: 10,
    interval: 1000 * 60,
  },
  title_create: {
    amount: 50,
    interval: 1000 * 60 * 60,
  },
  title_submit: {
    amount: 10,
    interval: 1000 * 60 * 60,
  },
  title_modify: {
    amount: 60,
    interval: 1000 * 60 * 60,
  },
  title_delete: {
    amount: 10,
    interval: 1000 * 60 * 60,
  },
  title_random: {
    amount: 60,
    interval: 1000 * 60,
  },
  title_relation_create: {
    amount: 20,
    interval: 1000 * 60 * 60,
  },
  title_relation_delete: {
    amount: 20,
    interval: 1000 * 60 * 10,
  },
  organization_create: {
    amount: 10,
    interval: 1000 * 60 * 60,
  },
  organization_modify: {
    amount: 10,
    interval: 1000 * 60,
  },
  organization_delete: {
    amount: 10,
    interval: 1000 * 60 * 10,
  },
  cover_upload: {
    amount: 100,
    interval: 1000 * 60 * 10,
  },
  cover_modify: {
    amount: 100,
    interval: 1000 * 60 * 10,
  },
  cover_delete: {
    amount: 120,
    interval: 1000 * 60 * 60,
  },
  solve_captcha: {
    amount: 10,
    interval: 1000 * 60 * 10,
  },
  report_create: {
    amount: 10,
    interval: 1000 * 60,
  },
  report_list: {
    amount: 10,
    interval: 1000 * 60,
  },
  comment_create: {
    amount: 10,
    interval: 1000 * 60,
  },
  comment_modify: {
    amount: 10,
    interval: 1000 * 60,
  },
  comment_delete: {
    amount: 10,
    interval: 1000 * 60,
  },
  post_create: {
    amount: 10,
    interval: 1000 * 60,
  },
  post_modify: {
    amount: 10,
    interval: 1000 * 60,
  },
  post_delete: {
    amount: 10,
    interval: 1000 * 60,
  },
  notification_preferences_set: {
    amount: 10,
    interval: 1000 * 60,
  },
  device_token_set: {
    amount: 10,
    interval: 1000 * 60,
  },
  device_token_remove: {
    amount: 10,
    interval: 1000 * 60,
  },
  notification_read: {
    amount: 10,
    interval: 1000 * 60,
  },
  notification_test: {
    amount: 10,
    interval: 1000 * 60 * 10,
  },
  discord_handle: {
    amount: 10,
    interval: 1000 * 60,
  },
  "discord/connection_create": {
    amount: 10,
    interval: 1000 * 60,
  },
};

const tracker: {
  [key: string]: { timer: NodeJS.Timer | null; current: number };
} = {};

type Condition = () => "immediate" | "ratelimit";

const globalConditions: Condition[] = [];

export function setGlobalConditions(...conditions: Condition[]) {
  globalConditions.push(...conditions);
}

export function ratelimited(
  key: keyof typeof ratelimits,
  condition?: Condition,
) {
  return function (a: any, b: any, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    if (!Object.keys(ratelimits).includes(key))
      throw new Error(`Key ${key} not found in ratelimits.json`);

    descriptor.value = async function (...args: any[]) {
      const ref = this;

      return new Promise((resolve, reject) => {
        const allConditions = [
          ...globalConditions,
          condition ? condition : () => "ratelimit",
        ] as Condition[];
        if (allConditions.some((x) => x() === "immediate")) {
          original
            .apply(ref, args)
            .then((result: any) => resolve(result))
            .catch((err: any) => reject(err));

          return;
        }

        if (tracker[key]?.current) {
          tracker[key].current++;

          if (tracker[key].timer === null) {
            tracker[key].timer = setTimeout(() => {
              tracker[key].current = 0;
              tracker[key].timer = null;
            }, ratelimits[key].interval);
          }
        } else {
          tracker[key] = {
            current: 1,
            timer: setTimeout(() => {
              tracker[key].current = 0;
              tracker[key].timer = null;
            }, ratelimits[key].interval),
          };
        }

        if (tracker[key].current <= ratelimits[key].amount) {
          original
            .apply(ref, args)
            .then((result: any) => resolve(result))
            .catch((err: any) => reject(err));
        } else {
          const timer = setInterval(() => {
            if (tracker[key].current >= ratelimits[key].amount) return;

            clearInterval(timer);

            tracker[key].timer = null;
            original
              .apply(ref, args)
              .then((result: any) => resolve(result))
              .catch((err: any) => reject(err));
          }, 100);
        }
      });
    };

    return descriptor;
  };
}

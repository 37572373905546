export type Dimensions = {
  width: number;
  height: number;
  x: number;
  y: number;
};

export type ConversationUiStateReturn = {
  addGroup: (
    group: MessageGroupFamilyOrContainer,
    initialDimensions: Dimensions,
  ) => VirtualItem;
  removeGroup: (id: string) => void;
  updateScrollPosition: (y: number) => void;
  updateStartPosition: (y: number) => void;
  items: VirtualItem[];
};

const map = new Map<string, ConversationUiStateReturn>();

export function useConversationUiState(id: MaybeRef<string>) {
  return computed(() => {
    const idValue = unref(id);
    const foundItem = map.get(idValue);
    if (foundItem) return foundItem;
    const uiState = createConversationUiState();
    map.set(idValue, uiState);
    return uiState;
  });
}

function createConversationUiState() {
  const { addItem, removeItem, items, updateY, updateStartY } =
    useVirtualScroller({
      area: {
        width: 4096,
        height: 4096,
      },
      pollingRate: 100,
      renderArea: {
        width: 8192,
        height: 8192,
      },
    });

  function addGroup(
    group: MessageGroupFamilyOrContainer,
    initialDimensions: Dimensions,
  ) {
    const item = addItem({ ...initialDimensions, id: group.id });
    return item;
  }

  function removeGroup(id: string) {
    removeItem(id);
  }

  function updateScrollPosition(y: number) {
    updateY(y);
  }

  function updateStartPosition(y: number) {
    updateStartY(y);
  }

  return {
    addGroup,
    removeGroup,
    updateScrollPosition,
    updateStartPosition,
    items,
  } satisfies ConversationUiStateReturn;
}

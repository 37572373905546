<template>
  <div ref="contextMenuContainer">
    <slot
      name="facing"
      v-bind="{
        open,
        close,
        isOpen,
        toggle: () => (isOpen ? close() : open()),
      }"
    />
    <ClientOnly>
      <Teleport to="body">
        <div
          ref="contextMenu"
          class="text-sm sm:text-base dark:text-white fixed z-50 context-menu rounded-md shadow-[0_0_8px_1px_rgba(0,0,0,0.1)] bg-white dark:bg-neutral-700 overflow-hidden border border-black/10 dark:border-none"
          :class="{
            'mt-1': position.includes('bottom'),
            'mb-1': position.includes('top'),
          }"
          :style="{
            transformOrigin:
              customOrigin?.x && customOrigin?.y
                ? customOrigin.x + ' ' + customOrigin.y
                : position === 'bottom-left'
                  ? 'right top'
                  : position === 'bottom-right'
                    ? 'left top'
                    : position === 'top-left'
                      ? 'right bottom'
                      : 'left bottom',
          }"
        >
          <slot name="menu" v-bind="{ open, close, isOpen, position }" />
        </div>
      </Teleport>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useMotion } from "@vueuse/motion";

export type ContextMenuPosition =
  | "top-right"
  | "top-left"
  | "bottom-right"
  | "bottom-left";

export interface Props {
  closeOnOutsideClick?: boolean;
  defaultPosition?: ContextMenuPosition;
  customPosition?: { [key in "left" | "right" | "top" | "bottom"]?: string };
  customOrigin?: { [key in "x" | "y"]?: string };
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "beforeOpen"): void;
  (e: "beforeClose"): void;
  (e: "open"): void;
  (e: "onClosed"): void;
}>();

const contextMenuContainer = ref<HTMLDivElement>();
const contextMenu = ref<HTMLDivElement>();

const position = ref<ContextMenuPosition>(
  props.defaultPosition ?? "bottom-right",
);

const computedPosition = ref<{
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
}>({});

const computedLeft = computed(
  () =>
    props.customPosition?.left ||
    (computedPosition.value.left
      ? computedPosition.value.left + "px"
      : "unset"),
);
const computedRight = computed(
  () =>
    props.customPosition?.right ||
    (computedPosition.value.right
      ? computedPosition.value.right + "px"
      : "unset"),
);
const computedBottom = computed(
  () =>
    props.customPosition?.bottom ||
    (computedPosition.value.bottom
      ? computedPosition.value.bottom + "px"
      : "unset"),
);
const computedTop = computed(
  () =>
    props.customPosition?.top ||
    (computedPosition.value.top ? computedPosition.value.top + "px" : "unset"),
);

const { apply } = useMotion(contextMenu, {
  initial: {
    visibility: "hidden",
    opacity: 0,
    scale: 1,
    transition: { duration: 1, type: "tween" },
  },
  render: {
    visibility: "visible",
    opacity: 0,
    scale: 0.8,
    transition: { duration: 1 },
  },
  deRender: {
    visibility: "hidden",
    opacity: 0,
    scale: 1,
    transition: { duration: 1 },
  },
  show: {
    visibility: "visible",
    opacity: 1,
    scale: 1,
    transition: {
      scale: {
        type: "spring",
        stiffness: 450,
        damping: 30,
        mass: 0.5,
        velocity: 0.2,
      },
      opacity: {
        type: "tween",
        duration: 50,
      },
    },
  },
  hide: {
    visibility: "visible",
    opacity: 0,
    scale: 0.9,
    transition: {
      opacity: { duration: 200 },
    },
  },
});

const isOpen = ref(false);

watch(isOpen, (newState) => {
  if (newState) {
    setMostOptimalPosition();

    emit("beforeOpen");

    setTimeout(() => {
      props.closeOnOutsideClick && registerListeners();
    }, 10);

    apply("render")
      ?.then(() => apply("show"))
      .then(() => emit("open"));
  } else {
    emit("beforeClose");

    setTimeout(() => {
      props.closeOnOutsideClick && unregisterListeners();
    }, 10);

    apply("hide")
      ?.then(() => apply("deRender"))
      .then(() => emit("onClosed"));
  }
});

function setMostOptimalPosition() {
  const menuRect = contextMenuContainer.value?.getBoundingClientRect();
  const dropdownRect = contextMenu.value?.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();

  if (!menuRect || !bodyRect || !dropdownRect) return;

  const { width, height, x, y } = menuRect;
  const { width: totalWidth } = bodyRect;
  const { width: contentWidth, height: contentHeight } = dropdownRect;
  let { visualViewport } = window;

  if (!visualViewport) {
    // In case of incompatibility, assume not zoomed in.
    visualViewport = {
      height: innerHeight,
      width: innerWidth,
      offsetTop: 0,
      offsetLeft: 0,
      onresize: null,
      onscroll: null,
      pageLeft: window.scrollX,
      pageTop: window.scrollY,
      scale: 1,
      addEventListener: window.addEventListener,
      removeEventListener: window.removeEventListener,
      dispatchEvent: window.dispatchEvent,
    };
  }

  const deadZone = 16;

  const availableSpaceOnTheRight_viewport =
    visualViewport.width - (x + width - visualViewport.pageLeft);
  const availableSpaceOnTheRight_total = totalWidth - (x + width);

  const availableSpaceOnTheLeft_viewport = x;
  const availableSpaceOnTheLeft_total = x;

  const availableSpaceOnTheTop_total = y;
  const availableSpaceOnTheBottom_total = visualViewport.height - (y + height);

  const topRightAvailable = () => {
    return (
      availableSpaceOnTheRight_viewport > availableSpaceOnTheLeft_viewport &&
      availableSpaceOnTheRight_total > contentWidth - width + deadZone &&
      availableSpaceOnTheTop_total > contentHeight + deadZone
    );
  };

  const topLeftAvailable = () => {
    return (
      availableSpaceOnTheLeft_viewport > availableSpaceOnTheRight_viewport &&
      availableSpaceOnTheLeft_total > contentWidth - width + deadZone &&
      availableSpaceOnTheTop_total > contentHeight + deadZone
    );
  };

  const bottomRightAvailable = () => {
    return (
      availableSpaceOnTheRight_viewport > availableSpaceOnTheLeft_viewport &&
      availableSpaceOnTheRight_total > contentWidth - width + deadZone &&
      availableSpaceOnTheBottom_total > contentHeight + deadZone
    );
  };

  const bottomLeftAvailable = () => {
    return (
      availableSpaceOnTheLeft_viewport > availableSpaceOnTheRight_viewport &&
      availableSpaceOnTheLeft_total > contentWidth - width + deadZone &&
      availableSpaceOnTheBottom_total > contentHeight + deadZone
    );
  };

  switch (props.defaultPosition) {
    case "top-right":
      if (topRightAvailable()) {
        setPosition("top-right");
        return;
      }

    case "top-left": {
      if (topLeftAvailable()) {
        setPosition("top-left");
        return;
      }
    }

    case "bottom-right": {
      if (bottomRightAvailable()) {
        setPosition("bottom-right");
        return;
      }
    }

    case "bottom-left": {
      if (bottomLeftAvailable()) {
        setPosition("bottom-left");
        return;
      }
    }
  }

  if (bottomRightAvailable()) {
    setPosition("bottom-right");
  } else if (bottomLeftAvailable()) {
    setPosition("bottom-left");
  } else if (topLeftAvailable()) {
    setPosition("top-left");
  } else if (topRightAvailable()) {
    setPosition("top-right");
  } else {
    setPosition(props.defaultPosition ?? "bottom-right");
  }
}

function setPosition(inPosition: ContextMenuPosition) {
  const menuRect = contextMenuContainer.value?.getBoundingClientRect();
  if (!menuRect) return;

  let { visualViewport } = window;

  if (!visualViewport) {
    // In case of incompatibility, assume not zoomed in.
    visualViewport = {
      height: innerHeight,
      width: innerWidth,
      offsetTop: 0,
      offsetLeft: 0,
      onresize: null,
      onscroll: null,
      pageLeft: window.scrollX,
      pageTop: window.scrollY,
      scale: 1,
      addEventListener: window.addEventListener,
      removeEventListener: window.removeEventListener,
      dispatchEvent: window.dispatchEvent,
    };
  }

  const { width, height, x, y } = menuRect;

  switch (inPosition) {
    case "top-right": {
      position.value = "top-right";
      computedPosition.value = {
        left: x,
        bottom: visualViewport.height - y,
      };
      return;
    }

    case "top-left": {
      position.value = "top-left";
      computedPosition.value = {
        right: visualViewport.width - x - width,
        bottom: visualViewport.height - y,
      };
      return;
    }

    case "bottom-right": {
      position.value = "bottom-right";
      computedPosition.value = {
        left: x,
        top: y + height,
      };
      return;
    }

    case "bottom-left": {
      position.value = "bottom-left";
      computedPosition.value = {
        right: visualViewport.width - x - width,
        top: y + height,
      };
      return;
    }
  }
}

function close() {
  isOpen.value = false;
}

function open() {
  isOpen.value = true;
}

function handleClick(e: MouseEvent | TouchEvent) {
  if (!contextMenu.value) return;

  if (!isClickInsideElements(e, Array.from(contextMenu.value.children))) {
    close();
  }
}

function registerListeners() {
  document.addEventListener("click", handleClick);
  // TODO?: reposition or close, close is less computationally intensive
  document.addEventListener("scroll", close);
  document.addEventListener("resize", close);
  window.addEventListener("resize", close);
}

function unregisterListeners() {
  document.removeEventListener("click", handleClick);
  document.removeEventListener("scroll", close);
  document.removeEventListener("resize", close);
  window.removeEventListener("resize", close);
}
</script>

<style scoped>
.context-menu {
  left: v-bind(computedLeft);
  top: v-bind(computedTop);
  right: v-bind(computedRight);
  bottom: v-bind(computedBottom);
}
</style>

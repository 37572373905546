import validate from "/builds/namicomi/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._fe3d701d018244c9cb26ca7a24c94131/node_modules/nuxt/dist/pages/runtime/validate.js";
import analytics_45global from "/builds/namicomi/frontend/middleware/analytics.global.ts";
import locale_45global from "/builds/namicomi/frontend/middleware/locale.global.ts";
import persist_45query_45global from "/builds/namicomi/frontend/middleware/persistQuery.global.ts";
import manifest_45route_45rule from "/builds/namicomi/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._fe3d701d018244c9cb26ca7a24c94131/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  analytics_45global,
  locale_45global,
  persist_45query_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}
<template>
  <NotificationsItem :is-unread :href :date="notification.date">
    <template #icon>
      <NotificationsIcon :icon="IconUserPlus" class="!text-green-500" />
    </template>
    <template #content>
      <div class="space-y-1">
        <UserAvatar
          :size="32"
          no-link
          :user="notification.data.userWhoFollowed"
        />
        <p>
          <i18n-t keypath="notifications.social.new_follow.body" scope="global">
            <template #follower>
              <strong>{{ followerName }}</strong>
            </template>
          </i18n-t>
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import { IconUserPlus } from "@tabler/icons-vue";
import type { NamiNotificationSocialNewFollowData } from "~/src/notifications/types/nami/social-new-follow";
import type { Props } from "../NotificationsItem.vue";
import type { UserEntity } from "~/src/api";

const props = defineProps<
  {
    notification: NamiNotificationSocialNewFollowData;
  } & Omit<Props, "actionIcon">
>();

const href = computed(() => {
  return linkTo(props.notification.data.userWhoFollowed);
});

const followerName = computed(() => {
  return getFollowerName(props.notification.data.userWhoFollowed);
});

function getFollowerName(user: UserEntity) {
  return user.attributes.displayName ?? user.attributes.username;
}
</script>

import { assertSuccess, paramsParser } from "../core";
import { Headers } from "./generic";
import { nanoid } from "nanoid";
import type {
  Collection,
  CollectionResult,
  CompactResult,
  CompactSuccess,
  GatingLockType,
  MessageResult,
  PostDraftBody,
  PostEntity,
  PostFeedCollection,
  PostFeedParams,
  PostListParams,
  PostPublishBody,
  Result,
} from "../types";
import axios from "../core/axios";
import type { AxiosProgressEvent } from "axios";
import { Statistics } from "./statistics";

export class Post {
  static async getPosts(
    params?: PostListParams,
    auth?: string,
  ): Promise<Collection<PostEntity>> {
    const resp = await axios.get<CollectionResult<PostEntity>>(
      "/post" + paramsParser(params ?? {}),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  static async getPost(
    id: string,
    includes?: PostListParams["includes"],
    auth?: string,
  ): Promise<PostEntity> {
    const resp = await axios.get<Result<PostEntity>>(
      `/post/${id}` + paramsParser({ includes }),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getPostStatistics(id: string) {
    return Statistics.getPostStats(id);
  }

  static async getFeed(
    params: PostFeedParams,
    auth: string,
  ): Promise<CompactSuccess<PostFeedCollection>> {
    const resp = await axios.get<CompactResult<PostFeedCollection>>(
      `/post/feed` + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  static async create(body: PostDraftBody, auth: string) {
    const resp = await axios.post<Result<PostEntity>>("/post/create", body, {
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data).data;
  }

  static async repost(id: string, auth: string, orgId?: string) {
    const resp = await axios<Result<PostEntity>>(`/post/create`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: {
        kind: "repost",
        repost: id,
        organizationId: orgId,
      },
    });

    return assertSuccess(resp.data).data;
  }

  static async publish(
    id: string,
    body: PostPublishBody,
    auth: string,
  ): Promise<PostEntity> {
    const resp = await axios.post<Result<PostEntity>>(
      `/post/${id}/publish`,
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async uploadMedia(
    id: string,
    version: number,
    file: File | Blob,
    auth: string,
    sensitive = false,
    onUploadProgress?: (e: AxiosProgressEvent) => any,
  ): Promise<PostEntity> {
    const formData = new FormData();
    formData.append(`attachment`, file, nanoid());

    const resp = await axios.post<Result<PostEntity>>(
      `/post/${id}/upload?version=${version}${
        sensitive ? "&spoiler=true" : ""
      }`,
      formData,
      {
        headers: Headers.Bearer(auth),
        onUploadProgress,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async attachVideo(
    id: string,
    body: {
      title: string | null;
      contentExcerpt: string | null;
      version: number;
      content?: string;
      removeAttachments?: string[];
      gating?: GatingLockType;
    },
    auth: string,
    // sensitive = false,
  ): Promise<PostEntity> {
    const resp = await axios.patch<Result<PostEntity>>(
      `/post/${id}`,
      {
        videoAction: "attach",
        ...body,
      },
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async delete(id: string, version: number, auth: string) {
    const resp = await axios<MessageResult<"Post was deleted.">>(
      `/post/${id}?version=${version}`,
      {
        method: "DELETE",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).result === "ok";
  }
}

import axios from "../core/axios";
import { assertSuccess, paramsParser } from "../core";
import { Headers } from "./generic";
import { nanoid } from "nanoid";
import { AxiosError } from "axios";
import { getBitAtPosition } from "../helpers";
import type {
  AuthResponse,
  CollectionResult,
  CompactResult,
  CompactSuccess,
  IncludeArr,
  MessageResult,
  PostUserBody,
  PostUserProfileBody,
  Result,
  StaffUserEntity,
  StaffUserListParams,
  UserEntity,
  UserFollow,
  UserFuzzySearchListParams,
  UserListFollowsParams,
  UserListParams,
  UserProfile,
} from "../types";

export class User {
  static async get(
    id: string,
    includes: IncludeArr<UserEntity> = [],
    auth?: string,
  ): Promise<UserEntity> {
    const resp = await axios<Result<UserEntity>>(
      `/user/${id}` + paramsParser({ includes: includes }),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
        responseType: "json",
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getByUsername(
    username: string,
    includes: IncludeArr<UserEntity> = [],
  ) {
    const resp = await axios<Result<string>>(
      `/user/from-username` + paramsParser({ username, includes: includes }),
    );

    return assertSuccess(resp.data).data;
  }

  static async search(params: UserListParams, auth: string) {
    const resp = await axios<CollectionResult<UserEntity>>(
      `/user` + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
        responseType: "json",
      },
    );

    return assertSuccess(resp.data);
  }

  static async fuzzySearch(
    params: UserFuzzySearchListParams = {},
    auth: string,
  ) {
    const resp = await axios<CollectionResult<UserEntity>>(
      `/user/search` + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
      },
    );

    const { data, meta } = assertSuccess(resp.data);

    return { data, meta };
  }

  static async getMyProfile(
    auth: string,
    includes: IncludeArr<UserEntity> = [],
  ): Promise<UserEntity> {
    const resp = await axios<Result<UserEntity>>(
      `/user/me` + paramsParser({ includes: includes }),
      {
        headers: Headers.Bearer(auth),
        responseType: "json",
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getMyPermsAndRoles(
    auth: string,
  ): Promise<CompactSuccess<AuthResponse>> {
    const resp = await axios<CompactResult<AuthResponse>>(`/auth/check`, {
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data);
  }

  /**
   * Gets a user with staff attributes (email)
   * @param userId
   * @param reason 3 - 512 char reason
   * @param auth
   */

  static async staffGetUser(
    userId: string,
    reason: string,
    auth: string,
  ): Promise<StaffUserEntity> {
    const resp = await axios.get<Result<StaffUserEntity>>(
      `/user/staff/${userId}` + paramsParser({ reason }),
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async staffGetUsers(params: StaffUserListParams, auth: string) {
    const resp = await axios.get<CollectionResult<StaffUserEntity>>(
      `/admin/user` + paramsParser(params),
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  static async gdprDelete(
    userId: string,
    reason: string,
    confirmation: string,
    auth: string,
  ): Promise<StaffUserEntity> {
    const resp = await axios.post<Result<StaffUserEntity>>(
      `/user/${userId}/gdpr-disable`,
      {
        reason,
        confirmation,
      },
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async checkUsernameAvailability(
    username: string,
    auth: string,
  ): Promise<boolean> {
    const resp = await axios.post<
      MessageResult<
        "Username is unavailable" | "Username is available",
        { available: boolean; user: string }
      >
    >(
      "/user/username/availability",
      {
        username,
      },
      {
        headers: Headers.Bearer(auth),
      },
    );

    return !!resp.data.data?.available;
  }

  static async getProfile(
    id: string,
    auth?: string,
  ): Promise<UserProfile | null> {
    const resp = await axios<Result<UserProfile>>(`/profile/${id}`, {
      responseType: "json",
      headers: auth ? Headers.Bearer(auth) : undefined,
    }).catch((e) => {
      // User profile not set up will return 400 status code
      if (e instanceof AxiosError && e.response?.status === 400) {
        return null;
      } else {
        throw e;
      }
    });

    return resp === null ? null : assertSuccess(resp.data).data;
  }

  static async createProfile(
    id: string,
    body: PostUserProfileBody,
    auth: string,
  ): Promise<UserProfile> {
    let resp = await axios.post<Result<UserProfile>>(
      `/profile/create`,
      {
        ...body,
        userId: id,
      },
      {
        headers: Headers.Bearer(auth),
        responseType: "json",
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async updateProfile(
    id: string,
    version: number,
    body: PostUserProfileBody,
    auth: string,
  ): Promise<UserProfile> {
    let resp = await axios.put<Result<UserProfile>>(
      `/profile/${id}`,
      { ...body, version: version },
      {
        headers: Headers.Bearer(auth),
        responseType: "json",
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async updateUser(
    id: string,
    version: number,
    body: PostUserBody,
    auth: string,
  ): Promise<UserEntity> {
    const resp = await axios.put<Result<UserEntity>>(
      `/user/${id}`,
      { ...body, version: version },
      { headers: Headers.Bearer(auth), responseType: "json" },
    );

    return assertSuccess(resp.data).data;
  }

  static async uploadAvatar(
    image: File | Blob,
    userId: string,
    version: number,
    auth: string,
  ) {
    const formData = new FormData();
    formData.append(`avatar`, image, nanoid());
    formData.append("version", version.toString());

    const resp = await axios<Result<UserEntity>>(`/user/${userId}/avatar`, {
      method: "POST",
      headers: {
        ...Headers.Bearer(auth),
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    return assertSuccess(resp.data).data;
  }

  static async deleteAvatar(userId: string, version: number, auth: string) {
    const resp = await axios<Result<UserEntity>>(`/user/${userId}/avatar`, {
      method: "DELETE",
      headers: {
        ...Headers.Bearer(auth),
        "Content-Type": "application/json",
      },
      data: {
        version,
      },
    });

    return assertSuccess(resp.data).data;
  }

  static async uploadBanner(
    image: File | Blob,
    userId: string,
    version: number,
    auth: string,
  ) {
    const formData = new FormData();
    formData.append(`avatar`, image, nanoid());
    formData.append("version", version.toString());

    const resp = await axios<Result<UserEntity>>(`/user/${userId}/banner`, {
      method: "POST",
      headers: {
        ...Headers.Bearer(auth),
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    return assertSuccess(resp.data).data;
  }

  static async deleteBanner(userId: string, version: number, auth: string) {
    const resp = await axios<Result<UserEntity>>(`/user/${userId}/banner`, {
      method: "DELETE",
      headers: {
        ...Headers.Bearer(auth),
        "Content-Type": "application/json",
      },
      data: {
        version,
      },
    });

    return assertSuccess(resp.data).data;
  }

  static async verifyAge(verified: boolean, auth: string): Promise<string> {
    const resp = await axios<MessageResult<"Age Verification set">>(
      `/settings/ageVerification`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: {
          ageVerified: verified,
        },
      },
    );

    return assertSuccess(resp.data).message;
  }

  static async follow(userId: string, auth: string) {
    const resp = await axios<Result<UserFollow>>(`/follows/user/${userId}`, {
      method: "POST",
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data).data;
  }

  static async unfollow(userId: string, auth: string) {
    const resp = await axios<MessageResult<"User unfollowed">>(
      `/follows/user/${userId}`,
      {
        method: "DELETE",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).message;
  }

  static async getFollows(params: UserListFollowsParams) {
    const resp = await axios<CollectionResult<UserFollow>>(
      `/follows/user${paramsParser(params)}`,
    );

    const data = assertSuccess(resp.data);

    return {
      data: data.data,
      meta: data.meta,
    };
  }

  static async checkIfUserFollowsUser(originId: string, targetId: string) {
    const resp = await axios<CollectionResult<UserFollow>>(
      `/follows/user/${originId}/${targetId}`,
    );

    const data = assertSuccess(resp.data).data;

    return !!data.at(0);
  }
}

export class UserProfileTemplate {
  template: PostUserProfileBody;
  initial?: UserProfile;

  constructor() {
    this.template = {
      about: "",
      ageRange: undefined,
      location: undefined,
      pronouns: undefined,
      link: "",
      privacy: {
        pronoun: false,
        age: false,
        location: false,
        conversationDisabled: false,
        conversationRestricted: false,
      },
    };
  }

  static parseFromUserProfile(user: UserProfile): PostUserProfileBody {
    return {
      about: user.attributes.about,
      ageRange: user.attributes.ageRange,
      privacy: {
        pronoun: !!getBitAtPosition(user.attributes.privacy, 0),
        age: !!getBitAtPosition(user.attributes.privacy, 1),
        location: !!getBitAtPosition(user.attributes.privacy, 2),
        conversationDisabled: !!getBitAtPosition(user.attributes.privacy, 3),
        conversationRestricted: !!getBitAtPosition(user.attributes.privacy, 4),
      },
      location: user.attributes.location,
      link: user.attributes.link ?? "",
      pronouns: user.attributes.pronouns,
    };
  }

  parseFromUserProfile(user: UserProfile) {
    this.initial = user;
    this.template = {
      about: user.attributes.about,
      ageRange: user.attributes.ageRange,
      privacy: {
        pronoun: !!getBitAtPosition(user.attributes.privacy, 0),
        age: !!getBitAtPosition(user.attributes.privacy, 1),
        location: !!getBitAtPosition(user.attributes.privacy, 2),
        conversationDisabled: !!getBitAtPosition(user.attributes.privacy, 3),
        conversationRestricted: !!getBitAtPosition(user.attributes.privacy, 4),
      },
      location: user.attributes.location,
      link: user.attributes.link ?? "",
      pronouns: user.attributes.pronouns,
    };
    return this;
  }

  reset() {
    if (this.initial) this.parseFromUserProfile(this.initial);
    else
      this.template = {
        about: "",
        ageRange: undefined,
        location: undefined,
        pronouns: undefined,
        link: "",
        privacy: {
          pronoun: false,
          age: false,
          location: false,
          conversationDisabled: false,
          conversationRestricted: false,
        },
      };
  }

  getBody(): PostUserProfileBody {
    return this.template;
  }
}

import { defineStore } from "pinia";
import type {
  PresetOptions,
  NotifyPresetHandlers,
  NotifyHandlers,
  NotificationType,
} from "~/types/notifications";
import type {
  Props as ModalPresetProps,
  Options as ModalPresetOptions,
} from "~/types/modal";
import type { Props as ButtonProps } from "~/types/button";
import { doLogin } from "~/utils/functions";

export type PromptOptions = {
  detail?: string;
  icon?: ModalPresetOptions["icon"];
  preventOutsideClicks?: boolean;
  dismissOnRouteChange?: boolean;
  censorBackground?: boolean;
  large?: boolean;
  inherit?: boolean;
  darker?: boolean;
  buttons?: {
    [key: string]: Omit<ButtonProps, "onButtonClick"> | undefined;
  };
  forceCloseKey?: string;
};

export type PromptArgsComponent<T extends abstract new (...args: any) => any> =
  [component: T, options?: PromptOptions, props?: InstanceType<T>["$props"]];
export type PromptArgsOptions<T extends PromptOptions> = [
  message: string,
  options: T,
];

export const useAppStore = defineStore({
  id: "app",
  state: () => {
    return {
      state: {
        offsetContentOnWebkitBecauseScrollIsDisabled: false,
      },
      globalNotificationComponent: null as any,
      advancedNotificationComponent: null as any,
      modalPresetArray: [] as ModalPresetProps[],
      modalComponentArray: [] as {
        modelValue: boolean;
        component: any;
        defaultAction?: string;
        resolver: (action: any) => void;
        modalProps: {
          large?: boolean;
          inherit?: boolean;
          darker?: boolean;
        };
        props: any;
      }[],
      currentLayoutScroll: 0,
    };
  },
  actions: {
    setOffsetContentOnWebkitBecauseScrollIsDisabled(v: boolean) {
      if (
        /Chrome/.test(navigator.userAgent) &&
        !/Mac/.test(navigator.userAgent)
      ) {
        this.state.offsetContentOnWebkitBecauseScrollIsDisabled = v;
      }
    },

    async openLoginRequiredModal() {
      const action = await this.prompt("You need to log in to do this.", {
        icon: "wink",
        buttons: {
          login: {
            buttonType: "primary",
            buttonText: "Login",
          },
          cancel: {
            buttonType: "secondary",
            buttonText: "Cancel",
          },
        },
        forceCloseKey: "cancel",
      });

      switch (action) {
        case "login": {
          doLogin();
        }
      }
    },

    notify(options: PresetOptions): Promise<NotifyPresetHandlers> {
      if (!this.globalNotificationComponent) {
        throw new Error("Global notifications component not initialized.");
      }
      // This is any becuase type resolution is not available in pinia stores
      return this.globalNotificationComponent.notify(options);
    },

    notifyCustom(notification: NotificationType): Promise<NotifyHandlers> {
      if (!this.advancedNotificationComponent) {
        throw new Error("Global notifications component not initialized.");
      }

      return this.advancedNotificationComponent.notify(notification);
    },

    closeAllNotifications() {
      if (!this.globalNotificationComponent) {
        throw new Error("Global notifications component not initialized.");
      }
      // This is any becuase type resolution is not available in pinia stores
      return this.globalNotificationComponent.closeAll();
    },

    setGlobalNotificationsComponent(component: any) {
      this.globalNotificationComponent = component;
    },

    setAdvancedNotificationsComponent(component: any) {
      this.advancedNotificationComponent = component;
    },

    async prompt<
      T extends PromptOptions | (abstract new (...args: any) => any),
      M extends string = string,
    >(
      ...args: T extends PromptOptions
        ? PromptArgsOptions<T>
        : T extends abstract new (...args: any) => any
          ? PromptArgsComponent<T>
          : never[]
    ): Promise<T extends PromptOptions ? keyof T["buttons"] : M> {
      const messageOrComponent = args[0];
      const optionsOrAction = args[1];
      const props = args[2];

      if (
        typeof messageOrComponent === "string" &&
        typeof optionsOrAction === "object"
      ) {
        return new Promise<any>((resolve, reject) => {
          this.modalPresetArray.push({
            modelValue: true,
            onClickOutside: () => {
              if (optionsOrAction.forceCloseKey) {
                resolve(optionsOrAction.forceCloseKey);
              } else {
                reject("Modal forcefully closed by user.");
              }
            },
            preventOutsideClicks: optionsOrAction.preventOutsideClicks,
            dismissOnRouteChange: optionsOrAction.dismissOnRouteChange,
            censorBackground: optionsOrAction.censorBackground,
            options: {
              title: messageOrComponent,
              detail: optionsOrAction.detail,
              icon: optionsOrAction.icon,
              buttons: Object.entries(optionsOrAction.buttons ?? {})
                .filter(
                  <T>(entry: [string, T | undefined]): entry is [string, T] =>
                    !!entry[1],
                )
                .map(([key, props]) => ({
                  ...props,
                  onButtonClick: (callToClose) => {
                    callToClose();
                    resolve(key);
                  },
                })),
            },
          });
        });
      } else if (typeof messageOrComponent !== "string") {
        return new Promise<any>((resolve) => {
          this.modalComponentArray.push({
            modelValue: true,
            component: shallowRef(messageOrComponent),
            defaultAction: optionsOrAction?.forceCloseKey ?? "",
            resolver: (action: M) => resolve(action),
            modalProps: {
              large: optionsOrAction?.large,
              inherit: optionsOrAction?.inherit,
              darker: optionsOrAction?.darker,
            },
            props: props,
          });
        });
      } else {
        throw new Error("Invalid arguments.");
      }
    },

    updateCurrentLayoutScroll(y: number) {
      this.currentLayoutScroll = y;
    },
  },
});

import { nanoid } from "nanoid";
import type { Dayjs } from "dayjs";

export type MessageAuthor = {
  type: "user" | "organization";
  hasLeftConversation: boolean;
  id: string;
  name: string;
  avatarUrl?: string;
};

export type MessageAttachment = {
  name: string;
  url: string | null;
  type: string;
  refresh: () => Promise<string | null>;
  expiryDate: Dayjs;
  size: number;
};

export type MessageActions = {
  delete?: () => Promise<void>;
  hardDelete?: () => Promise<void>;
};

export type Message = {
  id: string;
  author?: MessageAuthor;
  content: string;
  date: Dayjs;
  updated?: {
    by: string | null;
    date: Dayjs;
  };
  attachments?: MessageAttachment[];
  index: number;
  attributes: any;
  isDelivered: boolean;
  actions?: MessageActions;
};

export type MutableMessageAttributes = keyof Message extends infer U
  ? U extends
      | "content"
      | "updated"
      | "isDelivered"
      | "attributes"
      | "date"
      | "attachments"
    ? U
    : never
  : never;

export enum GroupType {
  SAMEDAY,
  SAMEAUTHOR,
  MEDIAONLY,
}

export type SameDayGroup = {
  type: GroupType.SAMEDAY;
  date: Dayjs;
};

export type SameAuthorGroup = {
  type: GroupType.SAMEAUTHOR;
  author?: MessageAuthor;
};

export type MediaOnlyGroup = {
  type: GroupType.MEDIAONLY;
};

export type AnyGroup = SameDayGroup | SameAuthorGroup | MediaOnlyGroup;

export type IndexRange = [start: number, end: number];

export type MessageGroup = AnyGroup & {
  id: string;
  indexRange: IndexRange;
};

export type MessageGroupFamily = MessageGroup & {
  groups: MessageGroupFamilyOrContainer[];
};
export type MessageGroupContainer = MessageGroup & { messages: Message[] };
export type MessageGroupFamilyOrContainer =
  | MessageGroupFamily
  | MessageGroupContainer;

export type MessageGroupFamilyMap = {
  [GroupType.SAMEDAY]: MessageGroupFamily & { type: GroupType.SAMEDAY };
  [GroupType.SAMEAUTHOR]: MessageGroupFamily & { type: GroupType.SAMEAUTHOR };
  [GroupType.MEDIAONLY]: MessageGroupFamily & { type: GroupType.MEDIAONLY };
};

export type MessageGroupContainerMap = {
  [GroupType.SAMEDAY]: MessageGroupContainer & { type: GroupType.SAMEDAY };
  [GroupType.SAMEAUTHOR]: MessageGroupContainer & {
    type: GroupType.SAMEAUTHOR;
  };
  [GroupType.MEDIAONLY]: MessageGroupContainer & { type: GroupType.MEDIAONLY };
};

export type UseMessagesReturn = {
  addMessage(message: Message): void;
  removeMessage(messageIndex: number): void;
  updateMessage: <Attribute extends MutableMessageAttributes>(
    messageIndex: number,
    attribute: Attribute,
    value: Message[Attribute],
  ) => void;
  groups: MessageGroupFamilyOrContainer[];
};

const appropriatenessCheckPerGroupType: Record<
  GroupType,
  (message: Message, group: MessageGroupFamilyOrContainer) => boolean
> = {
  [GroupType.SAMEDAY]: (message, group) =>
    group.type === GroupType.SAMEDAY && group.date.isSame(message.date, "day"),
  [GroupType.SAMEAUTHOR]: (message, group) =>
    group.type === GroupType.SAMEAUTHOR &&
    group.author?.id === message.author?.id &&
    (isMediaOnly(message) ? isGroupFamily(group) : true),
  [GroupType.MEDIAONLY]: (message, group) =>
    group.type === GroupType.MEDIAONLY && isMediaOnly(message),
};

const createMessageGroupContainerMap: {
  [GroupType.SAMEDAY]: (
    message: Message,
  ) => MessageGroupContainerMap[GroupType.SAMEDAY];
  [GroupType.SAMEAUTHOR]: (
    message: Message,
  ) => MessageGroupContainerMap[GroupType.SAMEAUTHOR];
  [GroupType.MEDIAONLY]: (
    message: Message,
  ) => MessageGroupContainerMap[GroupType.MEDIAONLY];
} = {
  [GroupType.SAMEDAY]: (message) => ({
    id: nanoid(),
    type: GroupType.SAMEDAY,
    date: message.date,
    indexRange: [message.index, message.index],
    messages: [message],
  }),
  [GroupType.SAMEAUTHOR]: (message) => ({
    id: nanoid(),
    type: GroupType.SAMEAUTHOR,
    author: message.author,
    indexRange: [message.index, message.index],
    messages: [message],
  }),
  [GroupType.MEDIAONLY]: (message) => ({
    id: nanoid(),
    type: GroupType.MEDIAONLY,
    indexRange: [message.index, message.index],
    messages: [message],
  }),
};

const createMessageGroupFamilyMap: {
  [GroupType.SAMEDAY]: (
    date: Dayjs,
    indexRange: IndexRange,
    id?: string,
  ) => MessageGroupFamilyMap[GroupType.SAMEDAY];
  [GroupType.SAMEAUTHOR]: (
    author: MessageAuthor | undefined,
    indexRange: IndexRange,
    id?: string,
  ) => MessageGroupFamilyMap[GroupType.SAMEAUTHOR];
  [GroupType.MEDIAONLY]: (
    indexRange: IndexRange,
    id?: string,
  ) => MessageGroupFamilyMap[GroupType.MEDIAONLY];
} = {
  [GroupType.SAMEDAY]: (date, indexRange) => ({
    id: nanoid(),
    type: GroupType.SAMEDAY,
    date: date,
    indexRange: indexRange,
    groups: [],
  }),
  [GroupType.SAMEAUTHOR]: (author, indexRange) => ({
    id: nanoid(),
    type: GroupType.SAMEAUTHOR,
    author: author,
    indexRange: indexRange,
    groups: [],
  }),
  [GroupType.MEDIAONLY]: (indexRange) => ({
    id: nanoid(),
    type: GroupType.MEDIAONLY,
    indexRange: indexRange,
    groups: [],
  }),
};

export function useMessages(): UseMessagesReturn {
  const groups = reactive<MessageGroupFamilyOrContainer[]>([]);
  const idToParentMap: { [id: string]: MessageGroupFamily } = {};

  function addMessage(message: Message) {
    const group = getAppropriateGroupForMessage(message, groups);
    if (group) {
      addMessageToGroup(message, group);
    } else {
      const targetIndex = groups.findIndex(
        (g) => g.indexRange[0] > message.index,
      );
      if (targetIndex !== -1) {
        groups.splice(targetIndex, 0, createSameDayGroupForMessage(message));
      } else {
        groups.push(createSameDayGroupForMessage(message));
      }
    }
  }

  function removeMessage(messageIndex: number) {
    const result = findGroupWhereMessageIsIn(messageIndex, groups);
    if (!result) return;
    removeMessageFromGroup(result.index, result.group);
    recursivelyRemoveGroupIfEmpty(result.group);
    recursivelyResetGroupMetadata(result.group);
    delete idToParentMap[result.group.id];
  }

  function updateMessage<Attribute extends MutableMessageAttributes>(
    messageIndex: number,
    attribute: Attribute,
    value: Message[Attribute],
  ) {
    const result = findGroupWhereMessageIsIn(messageIndex, groups);
    if (!result) return;
    result.group.messages[result.index][attribute] = value;
  }

  function findGroupWhereMessageIsIn(
    messageIndex: number,
    startGroups: MessageGroupFamilyOrContainer[],
  ): { group: MessageGroupContainer; index: number } | undefined {
    for (const group of startGroups) {
      if (
        group.indexRange[0] > messageIndex ||
        group.indexRange[1] < messageIndex
      ) {
        continue;
      }

      if (isGroupFamily(group)) {
        return findGroupWhereMessageIsIn(messageIndex, group.groups);
      }

      const index = group.messages.findIndex((m) => m.index === messageIndex);
      if (index === -1) return undefined;

      return { group, index };
    }

    return undefined;
  }

  function getAppropriateGroupForMessage(
    message: Message,
    startGroups: MessageGroupFamilyOrContainer[],
  ): MessageGroupFamilyOrContainer | undefined {
    const lastGroup = startGroups.at(-1);
    if (!lastGroup) return undefined;

    if (message.index > lastGroup.indexRange[1]) {
      if (!isMessageAppropriateForThisGroup(message, lastGroup))
        return undefined;

      if (isGroupContainer(lastGroup)) {
        return lastGroup;
      }

      const deeperGroup = getAppropriateGroupForMessage(
        message,
        lastGroup.groups,
      );
      if (deeperGroup && isGroupContainer(deeperGroup)) return deeperGroup;
      else return lastGroup;
    }

    for (const group of startGroups) {
      if (!isMessageAppropriateForThisGroup(message, group)) continue;

      if (isGroupContainer(group)) {
        return group;
      }

      if (isGroupFamily(group)) {
        const deeperGroup = getAppropriateGroupForMessage(
          message,
          group.groups,
        );
        if (deeperGroup && isGroupContainer(deeperGroup)) return deeperGroup;
        else return group;
      }
    }

    return undefined;
  }

  function isMessageAppropriateForThisGroup(
    message: Message,
    group: MessageGroupFamilyOrContainer,
  ) {
    return appropriatenessCheckPerGroupType[group.type](message, group);
  }

  function addMessageToGroup<
    G extends MessageGroupFamily | MessageGroupContainer,
  >(message: Message, group: G) {
    updateIndexRangeUpwards(message, group);

    if (isGroupContainer(group)) {
      const targetIndex = group.messages.findIndex(
        (m) => m.index > message.index,
      );
      if (targetIndex !== -1) {
        group.messages.splice(targetIndex, 0, message);
      } else {
        group.messages.push(message);
      }
      return;
    }

    switch (group.type) {
      case GroupType.SAMEDAY: {
        const sameAuthorGroup = createSameAuthorGroupForMessage(message);
        idToParentMap[sameAuthorGroup.id] = group;
        const targetIndex = group.groups.findIndex(
          (g) => g.indexRange[0] > message.index,
        );
        if (targetIndex !== -1) {
          group.groups.splice(targetIndex, 0, sameAuthorGroup);
        } else {
          group.groups.push(sameAuthorGroup);
        }
        break;
      }

      case GroupType.SAMEAUTHOR: {
        if (isMediaOnly(message)) {
          const mediaOnlyGroup = createMediaOnlyGroupForMessage(message);
          idToParentMap[mediaOnlyGroup.id] = group;
          const targetIndex = group.groups.findIndex(
            (g) => g.indexRange[0] > message.index,
          );
          if (targetIndex !== -1) {
            group.groups.splice(targetIndex, 0, mediaOnlyGroup);
          } else {
            group.groups.push(mediaOnlyGroup);
          }
        } else {
          throw new Error(
            "Cannot add a non-media-only message to a sameauthor group family",
          );
        }
        break;
      }

      case GroupType.MEDIAONLY: {
        throw new Error("Cannot add another group inside media-only groups");
      }
    }
  }

  function removeMessageFromGroup(index: number, group: MessageGroupContainer) {
    group.messages.splice(index, 1);
  }

  function recursivelyRemoveGroupIfEmpty(group: MessageGroupFamilyOrContainer) {
    const parent = idToParentMap[group.id];
    if (isGroupEmpty(group) && parent) {
      const groupIndex = parent.groups.findIndex((g) => g.id === group.id);
      if (groupIndex !== -1) {
        parent.groups.splice(groupIndex, 1);
      }
      recursivelyRemoveGroupIfEmpty(parent);
    }
  }

  function isGroupEmpty(group: MessageGroupFamilyOrContainer) {
    return isGroupContainer(group)
      ? group.messages.length === 0
      : group.groups.length === 0;
  }

  function resetGroupIndexRange(group: MessageGroupFamilyOrContainer) {
    group.indexRange = getGroupIndexRange(group);
  }

  function recursivelyResetGroupMetadata(group: MessageGroupFamilyOrContainer) {
    resetGroupIndexRange(group);

    switch (group.type) {
      case GroupType.SAMEDAY: {
        group.date = getGroupDate(group) ?? group.date;
        break;
      }

      case GroupType.SAMEAUTHOR: {
        break;
      }

      case GroupType.MEDIAONLY: {
        break;
      }
    }

    const parent = idToParentMap[group.id];

    if (parent) recursivelyResetGroupMetadata(parent);
  }

  function getGroupIndexRange(
    group: MessageGroupFamilyOrContainer,
  ): [number, number] {
    if (isGroupContainer(group)) {
      const firstMessageIndex = group.messages.at(0)?.index ?? 0;

      return [
        group.messages.reduce(
          (min, message) => (message.index < min ? message.index : min),
          firstMessageIndex,
        ),
        group.messages.reduce(
          (max, message) => (message.index > max ? message.index : max),
          firstMessageIndex,
        ),
      ];
    } else {
      const firstGroupIndexRange = group.groups.at(0)?.indexRange ?? [0, 0];

      return group.groups.reduce<[number, number]>((range, group) => {
        const innerRange = getGroupIndexRange(group);
        range[0] = Math.min(range[0], innerRange[0]);
        range[1] = Math.max(range[1], innerRange[1]);
        return range;
      }, firstGroupIndexRange);
    }
  }

  function getGroupDate(
    group: MessageGroupFamilyOrContainer,
  ): Dayjs | undefined {
    if (isGroupContainer(group)) {
      return group.messages.at(0)?.date;
    } else {
      return group.groups.reduce<Dayjs | undefined>((date, group) => {
        const d = getGroupDate(group);
        if (d && !date) return d;
        if (d && d.isBefore(date)) return d;
        return date;
      }, undefined);
    }
  }

  function updateIndexRangeUpwards(message: Message, group: MessageGroup) {
    group.indexRange[0] = Math.min(message.index, group.indexRange[0]);
    group.indexRange[1] = Math.max(message.index, group.indexRange[1]);

    const parent = idToParentMap[group.id];

    if (!parent) return;
    updateIndexRangeUpwards(message, parent);
  }

  function createSameDayGroupForMessage(
    message: Message,
  ): (MessageGroupFamilyMap | MessageGroupContainerMap)[GroupType.SAMEDAY] {
    const sameDayGroup = createMessageGroupFamilyMap[GroupType.SAMEDAY](
      message.date,
      [message.index, message.index],
    );
    const sameAuthorGroup = createSameAuthorGroupForMessage(message);
    sameDayGroup.groups.push(sameAuthorGroup);
    idToParentMap[sameAuthorGroup.id] = sameDayGroup;
    return sameDayGroup;
  }

  function createSameAuthorGroupForMessage(
    message: Message,
  ): (MessageGroupFamilyMap | MessageGroupContainerMap)[GroupType.SAMEAUTHOR] {
    if (isMediaOnly(message)) {
      const sameAuthorGroup = createMessageGroupFamilyMap[GroupType.SAMEAUTHOR](
        message.author,
        [message.index, message.index],
      );
      const mediaOnlyGroup = createMediaOnlyGroupForMessage(message);
      sameAuthorGroup.groups.push(mediaOnlyGroup);
      idToParentMap[mediaOnlyGroup.id] = sameAuthorGroup;
      return sameAuthorGroup;
    } else {
      const sameAuthorGroup =
        createMessageGroupContainerMap[GroupType.SAMEAUTHOR](message);
      return sameAuthorGroup;
    }
  }

  function createMediaOnlyGroupForMessage(
    message: Message,
  ): (MessageGroupFamilyMap | MessageGroupContainerMap)[GroupType.MEDIAONLY] {
    const mediaOnlyGroup =
      createMessageGroupContainerMap[GroupType.MEDIAONLY](message);
    return mediaOnlyGroup;
  }

  return {
    addMessage,
    removeMessage,
    updateMessage,
    groups,
  };
}

export function isGroupFamily(
  group: MessageGroupFamilyOrContainer,
): group is MessageGroupFamily {
  return "groups" in group;
}

export function isGroupContainer(
  group: MessageGroupFamilyOrContainer,
): group is MessageGroupContainer {
  return "messages" in group;
}

export function isMediaOnly(message: Message) {
  return message.attachments?.length === 1 && message.content === "";
}

<template>
  <NotificationsItem
    :is-unread
    :href
    :action-icon="IconRefresh"
    :date="notification.date"
  >
    <template #content>
      <div class="space-y-1">
        <UserAvatar
          v-if="notification.data.reposter.type === 'user'"
          :user="notification.data.reposter"
          :size="32"
          no-link
        />
        <OrganizationAvatar
          v-else-if="notification.data.reposter.type === 'organization'"
          :org="notification.data.reposter"
          :size="32"
        />
        <p class="line-clamp-2">
          <i18n-t
            keypath="notifications.social.repost_of_post.body"
            scope="global"
          >
            <template #reposter>
              <strong>{{ reposterName }}</strong>
            </template>
          </i18n-t>
        </p>
        <p class="text-sm text-neutral-600 dark:text-neutral-400 line-clamp-2">
          <template v-if="postContent">
            {{ postContent }}
          </template>
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import { IconRefresh } from "@tabler/icons-vue";
import { stripMarkdown } from "~/utils/ui/excerpt";
import type { Props } from "../NotificationsItem.vue";
import type { NamiNotificationSocialRepostOfPost } from "~/src/notifications/types/nami/social-repost-of-post";
import type { OrganizationEntity, UserEntity } from "~/src/api";

const props = defineProps<
  {
    notification: NamiNotificationSocialRepostOfPost;
  } & Omit<Props, "actionIcon">
>();

const href = computed(() => {
  return linkTo(props.notification.data.repost);
});

const reposterName = computed(() => {
  return props.notification.data.reposter.type === "user"
    ? getNameForUser(props.notification.data.reposter)
    : getNameForOrganization(props.notification.data.reposter);
});

const postContent = computed(() => {
  const message = props.notification.data.repost.attributes.contentExcerpt;
  if (!message) return null;
  return stripMarkdown(message.replaceAll(/[\n]+/g, " "));
});

function getNameForUser(user: UserEntity) {
  return user.attributes.displayName ?? user.attributes.username;
}

function getNameForOrganization(organization: OrganizationEntity) {
  return organization.attributes.name;
}
</script>

import type {
  Collection,
  CollectionResult,
  Result,
  CompactResult,
  CompactSuccess,
  Success,
  CollectionResultWithErrors,
} from "../types/result";

type ISuccessAssert = {
  <T, Extra>(
    res: CollectionResult<T, Extra>,
    msg?: string,
  ): Collection<T, Extra>;
  <T>(res: Result<T>, msg?: string): Success<T>;
  <T>(res: CompactResult<T>, msg?: string): CompactSuccess<T>;
};

/**
 * Assert that the provided result was in fact a success. Any failures
 * will instead throw an error based either on `msg` or derived from `res`.
 *
 * This should only be used for GET requests.
 *
 * @param res The result from our API call
 * @param msg An optional error message to show instead of the generic one
 * @returns The provided result, with any failure types stripped
 */
export const assertSuccess: ISuccessAssert = <T, Extra>(
  res:
    | CollectionResult<T, Extra>
    | CollectionResultWithErrors<T>
    | Result<T>
    | CompactResult<T>,
  msg?: string,
) => {
  if (res.result === "error")
    throw new Error(
      msg ??
        (res.errors.map((x) => x.detail).join("\n") ||
          "Sorry, we ran into an issue while fetching the resource."),
    );
  if ("errors" in res && res.type === "collection") return res;
  if ("response" in res && res.response === "collection") return res;
  return res;
};

import axios from "../core/axios";
import { assertSuccess } from "../core/assert";
import { paramsParser } from "../core/paramsParser";
import { Headers } from "./generic";
import { nanoid } from "nanoid";
import type { CollectionResult, MessageResult, Result } from "../types/result";
import type { TierEntity, TierListParams, TierPostBody } from "../types/tier";

export class Tier {
  static async get(
    id: string,
    includes: "organization"[],
    auth: string,
  ): Promise<TierEntity> {
    const resp = await axios<Result<TierEntity>>(
      `/subscription/tier/${id}` + paramsParser({ includes }),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async search(params: TierListParams = {}) {
    const resp = await axios<CollectionResult<TierEntity>>(
      `/subscription/tier` + paramsParser(params),
    );

    const { data, meta } = assertSuccess(resp.data);
    return { data, meta };
  }

  static async create(body: TierPostBody, auth: string, asAdmin?: boolean) {
    body.description = Object.fromEntries(
      Object.entries(body.description).map(([lang, text]) => [
        lang,
        text.trim(),
      ]),
    );
    body.name = Object.fromEntries(
      Object.entries(body.name).map(([lang, text]) => [lang, text.trim()]),
    );

    const resp = await axios<Result<TierEntity>>("/subscription/tier/create", {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: body,
    });

    return assertSuccess(resp.data).data;
  }

  static async update(
    id: string,
    template: TierPostBody,
    version: number,
    auth: string,
  ): Promise<TierEntity> {
    const editableFields: (keyof TierPostBody)[] = [
      "description",
      "name",
      "slug",
    ];
    const filteredTemplate = Object.fromEntries(
      // @ts-expect-error Fuck you TS and your Object.entries() not returning keyof T
      Object.entries(template).filter(([key]) => editableFields.includes(key)),
    ) as Pick<TierPostBody, "description" | "name" | "slug">;

    filteredTemplate.description = Object.fromEntries(
      Object.entries(filteredTemplate.description).map(([lang, text]) => [
        lang,
        text.trim(),
      ]),
    );
    filteredTemplate.name = Object.fromEntries(
      Object.entries(filteredTemplate.name).map(([lang, text]) => [
        lang,
        text.trim(),
      ]),
    );

    const resp = await axios<Result<TierEntity>>(`/subscription/tier/${id}`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: {
        ...filteredTemplate,
        version: version,
      },
    });

    const tierEntity = assertSuccess(resp.data).data;

    return tierEntity;
  }

  static async uploadBanner(
    image: File | Blob,
    tierId: string,
    version: number,
    auth: string,
  ) {
    const formData = new FormData();
    formData.append(`banner`, image, nanoid());
    // formData.append("version", version.toString());
    const resp = await axios<Result<TierEntity>>(
      `/subscription/tier/${tierId}/banner?version=${version}`,
      {
        method: "POST",
        headers: {
          ...Headers.Bearer(auth),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async deleteBanner(tierId: string, version: number, auth: string) {
    const resp = await axios<Result<TierEntity>>(
      `/subscription/tier/${tierId}/banner`,
      {
        method: "DELETE",
        headers: {
          ...Headers.Bearer(auth),
          "Content-Type": "application/json",
        },
        data: {
          version,
        },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async delete(id: string, version: number, auth: string) {
    // CONTROLLER: https://gitlab.com/namicomi/backend/-/blob/develop/application/src/Subscription/Controller/WriteTierItemController.php
    // TESTS: https://gitlab.com/namicomi/backend/-/blob/develop/application/tests/Functional/Subscription/TierItemWriteTest.php#L325
    const resp = await axios<MessageResult<"TierItem was deleted.">>(
      `/subscription/tier/${id}?version=${version}`,
      {
        method: "DELETE",
        headers: Headers.Bearer(auth),
      },
    );

    return resp.data.result === "ok";
  }
}

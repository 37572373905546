export type SessionData<K extends string = "default"> = {
  sessionKey: K;
  titleFollowStatuses: Record<string, boolean>;
  allOverlayKeys: string[];
  aModalIsOpen: boolean;
  _deviceScanCompleted: boolean;
  device: {
    scrollbarSize: Record<"width" | "height", number>;
    canHover: boolean;
  };
};

function getDefaultState<K extends string = "default">(
  key: K | "default" = "default",
) {
  return {
    sessionKey: key as K,
    titleFollowStatuses: {},
    allOverlayKeys: [],
    aModalIsOpen: false,
    _deviceScanCompleted: false,
    device: {
      scrollbarSize: {
        width: 0,
        height: 0,
      },
      canHover: true,
    },
  } satisfies SessionData<K>;
}

export function useSession<K extends string = "default">(
  key: K | "default" = "default",
) {
  const state = useState<SessionData<K>>(`nami-session-${key}`, () =>
    getDefaultState(key),
  );

  onMounted(() => {
    if (!state.value._deviceScanCompleted) {
      initializeDeviceScan(state);
      state.value._deviceScanCompleted = true;
    }
  });

  return state;
}

function getScrollbarWidth() {
  // Create a div element with a width larger than the viewport
  const div = document.createElement("div");
  div.style.width = "100px";
  div.style.height = "100px";
  div.style.overflow = "scroll";
  div.style.position = "absolute";
  div.style.left = "-9999px";
  div.style.opacity = "0";
  document.body.appendChild(div);

  // Calculate the scrollbar width
  const scrollbarWidth = div.offsetWidth - div.clientWidth;

  // Remove the temporary div
  document.body.removeChild(div);

  return scrollbarWidth;
}

function getScrollbarHeight() {
  // Create a div element with a height larger than the viewport
  const div = document.createElement("div");
  div.style.width = "100px";
  div.style.height = "100px";
  div.style.overflow = "scroll";
  div.style.position = "absolute";
  div.style.top = "-9999px";
  div.style.opacity = "0";
  document.body.appendChild(div);

  // Calculate the scrollbar height
  const scrollbarHeight = div.offsetHeight - div.clientHeight;

  // Remove the temporary div
  document.body.removeChild(div);

  return scrollbarHeight;
}

function initializeDeviceScan(state: Ref<SessionData<any>>) {
  state.value.device.scrollbarSize.width = getScrollbarWidth();
  state.value.device.scrollbarSize.height = getScrollbarHeight();

  if (state.value.device.scrollbarSize.width > 0) {
    state.value.device.scrollbarSize.width = 10;
    state.value.device.scrollbarSize.height = 10;
    document.documentElement.setAttribute("custom-scrollbar", "true");
  }

  setInterval(() => {
    state.value.device.canHover = window.matchMedia("(pointer: fine)").matches;
  }, 1000);
}

declare global {
  interface Window {
    enableDeveloperMode?: () => void;
    disableDeveloperMode?: () => void;
  }
}

export type DeveloperContextData = {
  isEnabled: Ref<boolean>;
};

export const IS_ENABLED_KEY = "is-enabled";

export function createDeveloperContext(): DeveloperContextData {
  const isEnabled = ref(false);

  provide<DeveloperContextData["isEnabled"]>(IS_ENABLED_KEY, isEnabled);

  onMounted(() => {
    window.enableDeveloperMode = () => {
      isEnabled.value = true;
    };

    window.disableDeveloperMode = () => {
      isEnabled.value = false;
    };
  });

  onBeforeUnmount(() => {
    window.enableDeveloperMode = undefined;
    window.disableDeveloperMode = undefined;
  });

  return {
    isEnabled,
  };
}

export function injectDeveloperContext(): DeveloperContextData {
  const context = {
    isEnabled: inject<DeveloperContextData["isEnabled"]>(IS_ENABLED_KEY)!,
  } satisfies DeveloperContextData;

  if (Object.values(context).some((v) => !v)) {
    throw new Error("developer_context_not_fully_available");
  }

  return context;
}

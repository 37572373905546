export type CreateAsyncResourceReturn<T> = {
  data: Ref<null | T>;
  error: Ref<null | Error>;
  pending: Ref<boolean>;
  refresh: () => Promise<T | null>;
};

export function createAsyncResource<
  T,
  Fn extends (...args: string[]) => Promise<T> = (
    ...args: string[]
  ) => Promise<T>,
>(fetcher: Fn, ...args: MaybeRef<string>[]) {
  const asyncDataMap = new Map<string, CreateAsyncResourceReturn<T>>();

  return computed<CreateAsyncResourceReturn<T>>(() => {
    const unrefedArgs: string[] = [];
    for (const arg of args) {
      unrefedArgs.push(unref(arg));
    }

    const key = unrefedArgs.join("");

    const foundCreateAsyncResourceReturn = asyncDataMap.get(key);
    if (foundCreateAsyncResourceReturn) return foundCreateAsyncResourceReturn;

    const pending = ref(true);
    const data = ref(null) as Ref<T | null>;
    const error = ref<null | Error>(null);
    const refresh = async () => {
      pending.value = true;

      return await fetcher(...unrefedArgs)
        .then((result) => {
          pending.value = false;
          error.value = null;
          data.value = result;
          return result;
        })
        .catch((error) => {
          pending.value = false;
          error.value = error;
          data.value = null;
          return null;
        });
    };

    refresh();

    const createAsyncDataReturn = {
      data,
      pending,
      error,
      refresh,
    } satisfies CreateAsyncResourceReturn<T>;

    asyncDataMap.set(key, createAsyncDataReturn);

    return createAsyncDataReturn;
  });
}

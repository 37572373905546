import { Chapter, type ChapterEntity } from "~/src/api";
import { useGenericCache } from "./generic";

export function useChapterCache(timeout = 1000 * 60) {
  const cache = useGenericCache<string, ChapterEntity>("chapters", timeout);

  async function getByid(id: string, authenticated = false) {
    const cached = cache.get(id);
    if (cached) return cached;

    const chapter = await Chapter.get(
      id,
      ["organization", "title", "user"],
      authenticated ? await getTokenOrThrow() : undefined,
    );
    cache.store(id, chapter);
    return chapter;
  }

  return {
    cache,
    getByid,
  };
}

<template>
  <nuxt-layout>
    <ErrorPage :error />
  </nuxt-layout>
</template>

<script lang="ts" setup>
const nuxtApp = useNuxtApp();
const route = useRoute();
const error = useError();

// The stores are undefined on the server
const authStore = nuxtApp.$auth();
const settingsStore = nuxtApp.$settings();

if (route.params.locale && nuxtApp?.$i18n?.global?.locale)
  nuxtApp.$i18n.global.locale.value = route.params
    .locale as typeof nuxtApp.$i18n.global.locale.value;

onMounted(async () => {
  if (!authStore || !settingsStore) {
    return;
  }
  try {
    await authStore.syncAuthState();
    await settingsStore.initialize(authStore.getUser);
  } catch (err) {}

  const theme = settingsStore.settings.site.theme;
  if (
    (theme === "system" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches) ||
    theme === "dark"
  ) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
});
</script>

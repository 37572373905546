import { defineStore } from "pinia";

export const useFeedsStore = defineStore({
  id: "feeds",
  state: () => {
    // null values are accepted so that if we want to unload a value from memory, we make it eligible for garbage collection
    return {
      userIdNameDict: {} as { [id: string]: string },
    };
  },
  actions: {
    // async load(type: CommentableResource, id: string) {
    //   this.feeds[id] = await Comment.search(type, id, {
    //     parentId: "NULL",
    //     order: { createdAt: "desc" },
    //     includes: ["user"],
    //   });
    // },
    //
    // unload(id: string) {
    //   this.feeds[id] = null;
    // },

    setUserDict(initial: { [id: string]: string }) {
      this.userIdNameDict = initial;
    },

    updateUserDict(id: string, username: string | undefined) {
      if (username !== undefined) this.userIdNameDict[id] = username;
      else delete this.userIdNameDict[id];
    },
  },
  getters: {
    getUsername: (state) => (id: string) => state.userIdNameDict[id] ?? null,
    getIdFromUsername: (state) => (username: string) => {
      Object.entries(state.userIdNameDict).forEach((ent) => {
        if (ent[1] === username) return ent[0];
      });
      return null;
    },
  },
});

import { Tier, type TierEntity } from "~/src/api";
import { useGenericCache } from "./generic";

export function useTierCache(timeout = 1000 * 60) {
  const cache = useGenericCache<string, TierEntity>("tiers", timeout);

  async function getById(id: string) {
    const cached = cache.get(id);
    if (cached) return cached;

    const tier = await Tier.get(id, ["organization"], await getTokenOrThrow());
    cache.store(id, tier);
    return tier;
  }

  return {
    getById,
    cache,
  };
}

<template>
  <div
    :class="`
  bg-gradient-to-r skeleton rounded-md shadow-md
  from-25% via-50% to-75%
  from-neutral-200 via-neutral-100 to-neutral-200
  dark:from-neutral-800 dark:via-neutral-700 dark:to-neutral-800
  `"
  ></div>
</template>

<script setup lang="ts"></script>

<style scoped>
.skeleton {
  background-size: 200% 200%;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: 200% 200%;
  }

  100% {
    background-position: -200% 200%;
  }
}
</style>

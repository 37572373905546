import { User, type UserEntity } from "~/src/api";
import { useGenericCache } from "./generic";

export function useUserCache(timeout = 1000 * 60) {
  const idCache = useGenericCache<string, UserEntity>("users-id", timeout);
  const usernameCache = useGenericCache<string, UserEntity>(
    "users-username",
    timeout,
  );

  async function getById(id: string) {
    const cached = idCache.get(id);
    if (cached) return cached;

    const user = await User.get(id, ["*"]);
    idCache.store(id, user);
    return user;
  }

  async function getByUsername(username: string) {
    const cached = usernameCache.get(username);
    if (cached) return cached;

    const id = await User.getByUsername(username);
    const user = await User.get(id, ["*"]);
    usernameCache.store(username, user);
    return user;
  }

  return {
    idCache,
    usernameCache,
    getById,
    getByUsername,
  };
}

<template>
  <div>
    <div
      class="relative grid grid-cols-[auto_auto_1fr_auto] items-center gap-2 p-2 bg-neutral-200 dark:bg-neutral-800"
      :class="{
        'rounded-xl': addedFiles.length === 0,
        'rounded-b-xl': addedFiles.length > 0,
      }"
    >
      <NamiButton
        disabled
        small
        button-type="primary"
        text
        no-waves
        pill
        :icon="IconMoodSmile"
      />
      <input
        ref="hiddenFileInput"
        hidden
        type="file"
        multiple
        @input="handleInputForFiles"
      />
      <NamiButton
        small
        button-type="primary"
        text
        no-waves
        pill
        :icon="IconPaperclip"
        :disabled="disabled"
        @button-click="hiddenFileInput?.click()"
      />
      <div
        class="flex items-center"
        :class="[{ 'opacity-50 pointer-events-none': disabled }]"
      >
        <textarea
          ref="inputArea"
          class="bg-neutral-200 dark:bg-neutral-800 border-none rounded-xl py-[0.325rem] h-8 text-sm w-full resize-none"
          placeholder="Start a new message"
          :value="modelValue"
          @input="
            (event) =>
              $emit(
                'update:modelValue',
                (event.target as HTMLTextAreaElement).value,
              )
          "
          @keydown="handleHotkey"
        />
      </div>
      <NamiButton
        small
        button-type="primary"
        text
        no-waves
        pill
        :icon="IconSend"
        @button-click="$emit('send')"
        :disabled="disabled || combinedFileSize > ONE_HUNDRED_FIFTY_MB"
      />
      <div
        v-if="addedFiles.length > 0"
        class="absolute bottom-full left-0 w-full p-2 overflow-x-auto bg-neutral-200 dark:bg-neutral-800 rounded-t-xl"
      >
        <div class="h-24 flex gap-2">
          <Tooltip v-for="(file, index) in addedFiles" fixed size="large">
            <div class="w-16 h-full">
              <img
                v-if="file.type.includes('image')"
                :src="fileToThumbnailUrlMap.get(file)"
                class="rounded-md h-full w-full object-contain bg-white dark:bg-black hover:opacity-50 transition-opacity"
              />
              <div
                v-else
                class="rounded-md bg-white dark:bg-black h-full flex items-center justify-center"
              >
                <IconFile />
              </div>
            </div>
            <template #tooltip>
              <div class="truncate text-xs w-full text-left">
                <span class="text-xs" :title="file.name">
                  {{ file.name }}
                </span>
                <div
                  class="flex my-2 justify-between text-neutral-600 dark:text-neutral-400"
                >
                  <span>{{ fileTypeMap[file.type] ?? "File" }}</span>
                  <span>{{ normalizeSize(file.size) }}</span>
                </div>
                <NamiButton
                  button-type="danger"
                  text
                  block
                  small
                  @button-click="removeFileAtIndex(index)"
                >
                  Remove
                </NamiButton>
              </div>
            </template>
          </Tooltip>
        </div>
        <p
          v-if="combinedFileSize > ONE_HUNDRED_FIFTY_MB"
          class="text-red-500 text-sm mt-2"
        >
          The attached files' size exceeds 150MB.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  IconMoodSmile,
  IconPaperclip,
  IconSend,
  IconFile,
} from "@tabler/icons-vue";

const ONE_HUNDRED_FIFTY_MB = 1024 * 1024 * 150;
const EIGHT_MB = 1024 * 1024 * 8;

const props = defineProps<{
  modelValue: string;
  noAutoFocus?: boolean;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", v: string): void;
  (e: "send"): void;
}>();

const fileDropper = useFileDropper();
const cleanupFileDropHook = fileDropper.onDrop((files) => addNewFiles(files));

const inputArea = ref<HTMLTextAreaElement>();
const hiddenFileInput = ref<HTMLInputElement>();
const addedFiles = reactive<File[]>([]);
const combinedFileSize = computed(() =>
  addedFiles.reduce((acc, file) => acc + file.size, 0),
);
const fileToThumbnailUrlMap = new WeakMap<File, string>();

const fileTypeMap: Record<string, string> = {
  "image/jpeg": "Image",
  "image/png": "Image",
  "image/webm": "Image",
};

function normalizeSize(size: number) {
  let pow = 0;
  const powerToSize = ["B", "KB", "MB", "GB", "TB"];
  const getNormalizedSize = () =>
    Math.round((size / Math.pow(1024, pow)) * 100) / 100;
  while (size + Math.pow(1024, pow + 1) / 8 > Math.pow(1024, pow + 1)) {
    pow++;
  }
  return `${getNormalizedSize()} ${powerToSize[pow]}`;
}

watch(
  () => props.modelValue,
  async () => {
    await resetTextAreaHeight();
    adjustTextAreaHeight();
  },
);

async function resetTextAreaHeight() {
  assertDefined(inputArea);
  inputArea.value.style.height = "2rem";
  await nextTick();
}

function adjustTextAreaHeight() {
  assertDefined(inputArea);
  const scrollHeight = inputArea.value.scrollHeight;
  if (scrollHeight > inputArea.value.clientHeight)
    inputArea.value.style.height = `${Math.min(90, scrollHeight)}px`;
}

function handleHotkey(event: KeyboardEvent) {
  if (event.shiftKey) return;
  if (event.key === "Enter") {
    event.preventDefault();
    emit("send");
  }
}

function fileRule(file: File) {
  return null;
}

function validateFiles(files: File[]) {
  const violatedRules = new Set(files.map(fileRule));
  violatedRules.delete(null);
  if (violatedRules.size > 0)
    throw new Error(Array.from(violatedRules.values()).join());
}

function handleNewFiles(files: File[]) {
  for (const file of files)
    if (file.type.includes("image"))
      fileToThumbnailUrlMap.set(file, URL.createObjectURL(file));
  addedFiles.push(...files);
}

async function addNewFiles(files: File[]) {
  await executeWithNotificationOnError(async () => {
    validateFiles(files);
    handleNewFiles(files);
  }).catch(() => {});
}

async function handleInputForFiles(e: Event) {
  const inputElement = e.target as HTMLInputElement;
  if (!inputElement.files) return;
  await addNewFiles(Array.from(inputElement.files));
  inputElement.value = "";
}

function removeFileAtIndex(index: number) {
  const file = addedFiles[index];
  if (file.type.includes("image")) revokeFileThumbnailUrl(file);
  addedFiles.splice(index, 1);
}

function removeAllFiles() {
  for (const file of addedFiles)
    if (file.type.includes("image")) revokeFileThumbnailUrl(file);
  addedFiles.splice(0, addedFiles.length);
}

function revokeFileThumbnailUrl(file: File) {
  const url = fileToThumbnailUrlMap.get(file);
  if (url) URL.revokeObjectURL(url);
  fileToThumbnailUrlMap.delete(file);
}

defineExpose({
  addedFiles,
  clear: () => {
    emit("update:modelValue", "");
    removeAllFiles();
  },
});

onBeforeUnmount(() => {
  cleanupFileDropHook();
});
</script>

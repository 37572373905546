export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"},{"name":"description","content":"NamiComi is the global ecosystem for creators and fans of comics and manga."},{"property":"og:title","content":"NamiComi"},{"property":"og:site_name","content":"NamiComi"},{"property":"og:type","content":"website"},{"property":"og:image","content":"https://namicomi.com/assets/og/about.png"},{"property":"twitter:site","content":"@NamiComi"},{"property":"twitter:domain","content":"namicomi.com"},{"property":"twitter:card","content":"summary_large_image"},{"name":"theme-color","content":"#02c3ff"}],"link":[{"rel":"preconnect","href":"https://api.namicomi.com"},{"rel":"dns-prefetch","href":"https://api.namicomi.com"},{"rel":"icon","href":"/favicon.ico"},{"rel":"icon","href":"/favicon.svg","type":"image/svg+xml"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"},{"rel":"search","href":"/opensearch.xml","title":"NamiComi","type":"application/opensearchdescription+xml"}],"style":[{"innerHTML":"\n            @media(prefers-color-scheme: dark) {\n              html {\n                background-color: black;\n              }\n            }\n          "}],"script":[{"type":"text/javascript","innerHTML":"if (window.matchMedia(\"(prefers-color-scheme: dark)\").matches) {document.documentElement.classList.add(\"dark\")}"}],"noscript":[],"title":"NamiComi","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false
import { useAuthStore } from "~/stores/auth";
import type { AnalyticEntity } from "~/src/api";
import { Analytics, User } from "~/src/api";
import deepEqual from "deep-eql";

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) {
    return;
  }

  // this portion handles deduplicating datapoints when redirecting after tracking a UI element navigation
  if (to.redirectedFrom?.path === to.path) {
    const newQ = { ...to.redirectedFrom.query, s: undefined };
    delete newQ.s;
    if (deepEqual(newQ, to.query)) return;
  }

  const authStore = useAuthStore();
  authStore
    .waitUntilRefreshIsComplete()
    .then(async () => {
      // this should match ONLY entity page routes, ie. /<lang>/<type>/<id>
      // BE regex for lang: [a-z]{2,3}(-(?:[0-9a-z]{2,4}|roman))?
      const regx =
        /\/[a-z]{2,3}(-(?:[0-9a-z]{2,4}|roman))?\/(chapter|title|organization|post|user)\/([^\/ ]+)\/?(.*)?$/s;
      const parsed = regx.exec(to.path);

      const entityType = parsed?.[2] as AnalyticEntity | undefined;
      let entityId = parsed?.[3];

      if (entityType === "user" && entityId) {
        return {
          entityType,
          entityId: await User.getByUsername(entityId!),
        };
      }

      return { entityType, entityId };
    })
    .then(({ entityType, entityId }) => {
      const visibleScreenWidth = (
        window.screen.width * window.devicePixelRatio
      ).round();
      const visibleScreenHeight = (
        window.screen.height * window.devicePixelRatio
      ).round();

      const res = `${visibleScreenWidth}x${visibleScreenHeight}`;
      const userID = authStore.user?.profile.sub ?? "";
      const userProfile = authStore?.userProfile;

      return {
        to: to.fullPath,
        // history.length <= 1: from external, i.e. clicking a link
        // history.length <= 2: from browser new tab + entering url TODO: test and verify
        from: history.length <= 1 ? undefined : from.fullPath,
        res,
        ref: document.referrer,
        lang: window.navigator.language,
        user_id: userID,
        gender: userProfile?.attributes.pronouns,
        age: userProfile?.attributes.ageRange,
        entityId,
        entityType,
      };
    })
    .then(async (params) => ({
      params,
      token: (await authStore.getToken()) ?? undefined,
    }))
    .then(({ params, token }) => {
      console.debug("ANALYTICS:", params);
      return Analytics.collectDatapoint(params, token);
    })
    .catch();

  // strip the UI element identifier, if any
  if (to.query.s)
    return navigateTo({
      path: to.path,
      query: {
        ...to.query,
        s: undefined,
      },
    });
});

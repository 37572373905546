<template>
  <NamiModal
    :modelValue="modelValue"
    @update:modelValue="(v) => emit('update:modelValue', v)"
    :censorBackground="censorBackground"
    :preventOutsideClicks="preventOutsideClicks"
    :dismiss-on-route-change="dismissOnRouteChange"
    @clickOutside="emit('clickOutside')"
  >
    <div class="space-y-4">
      <AsyncImage
        v-if="options.icon"
        :src="getAbsoluteAssetLink(`nami/stickers/${options.icon}.png`)"
        class="w-48 h-48 mx-auto"
      />
      <h2
        v-if="options.title"
        class="font-medium text-lg sm:text-xl text-center dark:text-neutral-50"
      >
        {{ options.title }}
      </h2>
      <p v-if="options.detail" class="dark:text-neutral-200">
        {{ options.detail }}
      </p>
      <slot></slot>
      <div v-if="options.buttons" class="flex space-x-2 justify-end">
        <NamiButton
          v-for="button in options.buttons"
          v-bind="{
            ...button,
            onButtonClick: undefined,
          }"
          @buttonClick="
            () =>
              button.onButtonClick?.call(null, () =>
                emit('update:modelValue', false),
              )
          "
        />
      </div>
    </div>
  </NamiModal>
</template>

<script setup lang="ts">
import type { Props as ModalProps } from "~/types/modal";

export interface Props extends ModalProps {}

interface Events {
  (e: "update:modelValue", v: boolean): void;
  (e: "clickOutside"): void;
}

defineProps<Props>();
const emit = defineEmits<Events>();
</script>

<template>
  <div :class="{ isFirstGroup }">
    <div
      class="flex justify-center"
      :style="{
        zIndex: group.indexRange[0],
      }"
    >
      <span
        class="py-1 px-4 my-2 rounded-full text-xs bg-neutral-300 dark:bg-neutral-700"
      >
        {{ group.date.format("DD MMM YYYY") }}
      </span>
    </div>
    <div
      ref="container"
      :virt-width="width"
      :virt-height="height"
      :class="{
        isRendered: virtualItem?.isRendered,
        isVisible: virtualItem?.isVisible,
      }"
      :style="{
        height:
          virtualItem?.isRendered && virtualItem?.isVisible
            ? undefined
            : `${height}px`,
        width:
          virtualItem?.isRendered && virtualItem?.isVisible
            ? undefined
            : `${width}px`,
      }"
    >
      <template
        v-for="sameAuthorGroup in group.groups"
        v-if="virtualItem?.isRendered"
        v-show="virtualItem?.isVisible"
        :key="sameAuthorGroup.id"
      >
        <ConversationMessageGroupSameauthorContainer
          v-if="isGroupContainer(sameAuthorGroup)"
          :group="sameAuthorGroup as AuthorGroupContainer"
          :is-group-conversation
          :conversation-sid
          :current-user
          @report-message="(message) => $emit('reportMessage', message)"
          @images-preview="
            (images, index) => $emit('imagesPreview', images, index)
          "
        />
        <ConversationMessageGroupSameauthorFamily
          v-else-if="isGroupFamily(sameAuthorGroup)"
          :group="sameAuthorGroup as AuthorGroupFamily"
          :conversation-sid
          :current-user
          :is-group-conversation
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  isGroupContainer,
  isGroupFamily,
  type GroupType,
  type MessageGroupContainer,
  type MessageGroupFamily,
} from "~/composables/messages";
import { useElementBounding } from "@vueuse/core";
import type { UserDisplayData } from "./common";

type AuthorGroupFamily = MessageGroupFamily & { type: GroupType.SAMEAUTHOR };
type AuthorGroupContainer = MessageGroupContainer & {
  type: GroupType.SAMEAUTHOR;
};

const props = defineProps<{
  group: MessageGroupFamily & { type: GroupType.SAMEDAY };
  conversationSid: string;
  isGroupConversation: boolean;
  currentUser: UserDisplayData;
  isFirstGroup?: boolean;
}>();

const emit = defineEmits<{
  (e: "imagesPreview", images: string[], index: number): void;
  (e: "reportMessage", message: Message): void;
}>();

const conversationUiState = useConversationUiState(
  computed(() => props.conversationSid),
);
const virtualItem = ref<VirtualItem | null>(null);
const container = ref<HTMLDivElement>();

const { width, height, x, y } = useElementBounding(container);

watch(
  [() => props.group, conversationUiState],
  () => {
    if (virtualItem.value) {
      virtualItem.value = null;
      conversationUiState.value.removeGroup(props.group.id);
    }

    virtualItem.value = conversationUiState.value.addGroup(props.group, {
      width: width.value,
      height: height.value,
      x: x.value,
      y: y.value,
    });
  },
  { immediate: true },
);

watch(
  [width, height, x, y],
  () => {
    if (!virtualItem.value) return;
    virtualItem.value.width = width.value;
    virtualItem.value.height = height.value;
    virtualItem.value.x = x.value;
    virtualItem.value.y = y.value;

    if (props.isFirstGroup) {
      conversationUiState.value.updateStartPosition(y.value);
    }
  },
  { immediate: true },
);

onUnmounted(() => {
  virtualItem.value = null;
  conversationUiState.value.removeGroup(props.group.id);
});
</script>

<template>
  <div
    class="fixed pointer-events-none top-0 z-[100] py-16 sm:py-4 flex flex-col h-screen"
    :class="{
      'sm:w-[32rem] w-[calc(100%-1rem)]': !fullWidth,
      'w-[calc(100%-1rem)]': fullWidth,
      'left-0': position === 'top-left',
      'sm:left-[calc(50%-16rem)] left-2': position === 'top-middle',
      'right-0': position === 'top-right',
      'left-0 flex-col-reverse': position === 'bottom-left',
      'sm:left-[calc(50%-16rem)] left-2 flex-col-reverse':
        position === 'bottom-middle',
      'right-0 flex-col-reverse': position === 'bottom-right',
    }"
  >
    <div
      v-for="(notification, index) in notifications"
      class="relative w-full pointer-events-auto"
      @mouseenter="
        () =>
          notification.timeout?.pauseTimerOnHover
            ? manager.pause(notification._internal.id)
            : null
      "
      @mouseleave="
        () =>
          notification.timeout?.pauseTimerOnHover
            ? manager.resume(notification._internal.id)
            : null
      "
      @click="
        notification.extra?.clickToClose &&
        manager.close(notification._internal.id)
      "
    >
      <div
        class="grid"
        :class="{
          'transition-[opacity,transform,grid-template-rows]':
            notifications[index]._internal.state !== 'opening',
          'transition-[opacity,transform]':
            notifications[index]._internal.state === 'opening',
          'grid-rows-[0fr_0fr] opacity-0':
            notifications[index]._internal.state === 'closed',

          'translate-x-12':
            notifications[index]._internal.state === 'closed' &&
            (position === 'top-right' || position === 'bottom-right'),
          '-translate-x-12':
            notifications[index]._internal.state === 'closed' &&
            (position === 'top-left' || position === 'bottom-left'),
          'translate-y-12':
            notifications[index]._internal.state === 'closed' &&
            position === 'bottom-middle',
          '-translate-y-12':
            notifications[index]._internal.state === 'closed' &&
            position === 'top-middle',

          'grid-rows-[0fr_1fr] p-4 opacity-100':
            notifications[index]._internal.state === 'opening' ||
            notifications[index]._internal.state === 'open',
          'grid-rows-[0fr_1fr] p-4 opacity-0':
            notifications[index]._internal.state === 'closing',
        }"
      >
        <!-- Placeholder div to make height auto -> 0 transitions -->
        <div></div>
        <div
          v-if="notification.content"
          class="relative rounded-md shadow-xl overflow-hidden"
        >
          <div
            :class="notification.content.class"
            :style="notification.content.style"
          >
            <div
              v-for="child in notification.content.children"
              :class="child.class"
              :style="child.style"
            >
              <template v-for="item in child.items">
                <component
                  v-if="item.kind === 'component'"
                  :is="item.component"
                  :class="item.class"
                  :style="item.style"
                  v-bind="item.props"
                />
                <p
                  v-if="item.kind === 'text'"
                  :class="item.class"
                  :style="item.style"
                >
                  {{ item.content }}
                </p>
                <img
                  v-if="item.kind === 'image'"
                  :src="item.src"
                  :alt="item.alt"
                  :class="item.class"
                  :style="item.style"
                />
                <div v-if="item.kind === 'multitext'">
                  <p
                    v-for="textitem in item.items"
                    :class="textitem.class"
                    :style="textitem.style"
                  >
                    {{ textitem.content }}
                  </p>
                </div>
                <component
                  v-else-if="item.kind === 'icon'"
                  :is="item.icon"
                  :class="item.class"
                  :style="item.style"
                />
                <NamiButton
                  v-else-if="item.kind === 'button'"
                  @buttonClick="item.onButtonClick"
                  v-bind="item"
                />
                <NamiSelect
                  v-else-if="item.kind === 'select'"
                  v-model="item.modelValue"
                  @selectEvent="item.onSelectEvent"
                  :select="item.select"
                />
              </template>
            </div>
          </div>
          <!-- Progress bar for timed-notification clear -->
          <div
            v-if="notification.timeout?.enabled"
            class="absolute bottom-0 h-[1px] bg-nami-comi-blue"
            :style="{
              width: `${
                ((notification._internal.remaining - 20) * 100) /
                notification.timeout.duration
              }%`,
            }"
          ></div>
          <div
            v-if="notification.extra?.showCloseButton !== false"
            class="absolute right-1 top-1"
          >
            <NamiButton
              text
              pill
              small
              noWaves
              :icon="IconX"
              buttonType="secondary"
              @buttonClick="manager.close(notification._internal.id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconX } from "@tabler/icons-vue";
import type { Ref } from "vue";
import type {
  Props as NotifyProps,
  NotifyHandlers,
  NotificationInternal,
  NotificationType,
} from "~/types/notifications";

export interface Props extends NotifyProps {}

interface Exposes {
  notify: (notification: NotificationType) => Promise<NotifyHandlers>;
  closeAll: () => void;
}

const props = defineProps<Props>();
defineExpose<Exposes>({
  notify,
  closeAll,
});

class NotificationManager {
  notifications: Ref<NotificationInternal[]>;
  queue: NotificationType[];

  constructor(notifications: Ref<NotificationInternal[]>) {
    this.notifications = notifications;
    this.queue = [];
  }

  update(id: string, notification: NotificationType) {
    const index = this.notifications.value.find((n) => n._internal.id === id)
      ?._internal.index;

    if (typeof index === "undefined") return;

    this.notifications.value[index].content = notification.content;
    this.notifications.value[index].extra = notification.extra;
    this.notifications.value[index].timeout = notification.timeout;
  }

  close(id: string) {
    const index = this.notifications.value.find((n) => n._internal.id === id)
      ?._internal.index;

    if (typeof index === "undefined") return;

    this.notifications.value[index]._internal.state = "closing";
    setTimeout(() => {
      this.notifications.value[index]._internal.state = "closed";

      setTimeout(() => {
        this.notifications.value[index]._internal.hasContent = false;
        this.notifications.value[index]._internal.remaining = 0;

        if (this.notifications.value[index]._internal.timer) {
          clearTimeout(this.notifications.value[index]._internal.timer!);
          this.notifications.value[index]._internal.timer = null;
        }

        delete this.notifications.value[index].content;
        delete this.notifications.value[index].extra;
        delete this.notifications.value[index].timeout;
      }, 200);
    }, 200);

    this.notifications.value[index].timeout?.onClose?.call(null);
  }

  pause(id: string) {
    const index = this.notifications.value.find((n) => n._internal.id === id)
      ?._internal.index;

    if (typeof index === "undefined") return;

    if (this.notifications.value[index]._internal.timer) {
      clearInterval(this.notifications.value[index]._internal.timer!);
      this.notifications.value[index]._internal.timer = null;
    }
  }

  resume(id: string) {
    const index = this.notifications.value.find((n) => n._internal.id === id)
      ?._internal.index;

    if (typeof index === "undefined") return;

    if (!this.notifications.value[index]._internal.timer) {
      this.notifications.value[index]._internal.timer = setInterval(() => {
        this.notifications.value[index]._internal.remaining -= 10;

        if (this.notifications.value[index]._internal.remaining === 0)
          this.close(this.notifications.value[index]._internal.id);
      }, 10);
    }
  }

  addToQueue(notification: NotificationType) {
    this.queue.push(notification);
  }
}

const notifications = ref(createNotificationSlotArray());
const manager = new NotificationManager(notifications);

function closeAll() {
  notifications.value
    .filter((n) => n._internal.hasContent)
    .forEach((n) => manager.close(n._internal.id));
}

function createNotificationSlotArray() {
  const notifications: NotificationInternal[] = [];

  for (let i = 0; i < props.maxVisibleNotifications; i++) {
    notifications.push({
      _internal: {
        index: i,
        hasContent: false,
        startedAt: Date.now(),
        timer: null,
        remaining: 0,
        state: "closed",
        id: Math.random().toString().replace(".", ""),
      },
    });
  }

  return notifications;
}

async function addToNotificationsArray(
  notification: NotificationType,
  index: number,
): Promise<NotifyHandlers> {
  notifications.value[index] = {
    _internal: {
      index: index,
      hasContent: true,
      startedAt: Date.now(),

      timer: notification.timeout?.enabled
        ? setInterval(() => {
            notifications.value[index]._internal.remaining -= 10;

            if (notifications.value[index]._internal.remaining === 0)
              manager.close(notifications.value[index]._internal.id);
          }, 10)
        : null,

      remaining: notification.timeout?.enabled
        ? notification.timeout.duration
        : 0,
      state: "opening",
      id: notifications.value[index]._internal.id,
    },
    timeout: notification.timeout,
    content: notification.content,
    extra: notification.extra,
  };

  setTimeout(() => {
    notifications.value[index]._internal.state = "open";
  }, 200);

  return {
    notification: notification,
    update: function (notification: NotificationType) {
      manager.update(notifications.value[index]._internal.id, notification);
    },
    close: function () {
      manager.close(notifications.value[index]._internal.id);
    },
    pause: function () {
      manager.pause(notifications.value[index]._internal.id);
    },
    resume: function () {
      manager.resume(notifications.value[index]._internal.id);
    },
  };
}

async function notify(notification: NotificationType): Promise<NotifyHandlers> {
  const availableIndex = notifications.value.find(
    (n) => n._internal.hasContent === false,
  )?._internal.index;

  if (typeof availableIndex !== "undefined")
    return addToNotificationsArray(notification, availableIndex);
  else return queueNotification(notification);
}

async function queueNotification(notification: NotificationType) {
  manager.addToQueue(notification);

  return new Promise<NotifyHandlers>((resolve) => {
    const timer = setInterval(() => {
      if (manager.queue[0].content === notification.content) {
        const availableIndex = notifications.value.find(
          (n) => n._internal.hasContent === false,
        )?._internal.index;
        if (typeof availableIndex !== "undefined") {
          clearInterval(timer);
          resolve(addToNotificationsArray(notification, availableIndex));
          manager.queue = manager.queue.slice(1);
        }
      }
    }, 100);
  });
}
</script>

import type { MarkedExtension, Token, Tokens } from "marked";

interface SpoilerToken extends Tokens.Generic {
  type: "spoiler";
  raw: string;
  text: string;
  tokens: Token[];
}

// TODO: enable multiline in the same way that other inline styles work
export default function (options = {}): MarkedExtension {
  // extension code here
  const rule = /^\|\|(.+?)\|\|/;

  return {
    extensions: [
      {
        name: "spoiler",
        level: "inline",
        start: (src: string) => src.match(/\|\|/)?.index,
        tokenizer(src, tokens) {
          const match = rule.exec(src);
          if (match) {
            const token: SpoilerToken = {
              // Token to generate
              type: "spoiler", // Should match "name" above
              raw: match[0], // Text to consume from the source
              text: match[1].trim(), // Additional custom properties
              tokens: [], // Array where child inline tokens will be generated
            };

            this.lexer.inline(token.text, token.tokens); // Queue this data to be processed for inline tokens
            return token;
          }
        },
        renderer(token) {
          return `<details class="spoiler">${this.parser.parseInline(
            token.tokens!,
          )}<summary>${this.parser.parseInline(
            token.tokens!,
          )}</summary></details>`;
        },
      },
    ],
  };
}

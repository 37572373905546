<template>
  <div
    v-if="currentUser"
    class="hidden md:flex justify-end items-end gap-4 px-2"
  >
    <div
      v-if="openedConversationSid && conversation"
      class="*:w-96 h-144 flex gap-2 items-end"
    >
      <ConversationChat
        class="h-full shadow-xl pointer-events-auto"
        :conversation-sid="openedConversationSid"
        :current-user
        :groups
        :conversation
        :has-more-messages
        :users
        :last-message-index
        :last-message-read-index
        :next-messages-loading
        :total-unread-messages
        :conversation-name="friendlyName"
        :avatar-url="conversationAvatarUrl ?? null"
        @close="openedConversationSid = null"
      />
    </div>
    <div
      class="overflow-auto pointer-events-auto relative w-80 h-full shadow-xl transition-transform duration-300 flex flex-col rounded-lg rounded-b-none bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-800"
      :class="{
        'translate-y-[calc(100%-3.5rem)] overflow-hidden': !isMessagesExpanded,
      }"
    >
      <button
        class="sticky top-0 z-[4] px-4 h-[3.5rem] min-h-[3.5rem] max-h-[3.5rem] font-semibold flex justify-between items-center transition-colors hover:bg-neutral-100 dark:hover:bg-neutral-800 border-b border-neutral-300 dark:border-neutral-700"
        @click="isMessagesExpanded = !isMessagesExpanded"
      >
        <span>Messages</span>
        <IconChevronUp
          :size="24"
          class="transition-transform inline-block"
          :class="{
            'rotate-180': isMessagesExpanded,
          }"
        />
      </button>
      <div>
        <ul>
          <li v-for="conversation in conversations" :key="conversation.sid">
            <ConversationUserBox
              class="!rounded-none transition-colors"
              v-if="conversation"
              :conversation
              :conversation-message-data="
                conversationsMessageData[conversation.sid]
              "
              :has-read-all-messages="
                (conversationsMessageData[conversation.sid]
                  .lastMessageReadIndex ?? 0) ===
                (conversationsMessageData[conversation.sid].lastMessage
                  ?.index ?? 0)
              "
              :current-user
              no-link
              :avatar-file-name="
                metadataPerConversation[conversation.sid].avatarFileName
              "
              :conversation-name="
                metadataPerConversation[conversation.sid].friendlyName
              "
              :groups
              @click="openConversation(conversation.sid)"
            >
              <template #conversationName>
                <ConversationName
                  :conversation="conversation"
                  :name="friendlyName"
                />
              </template>
            </ConversationUserBox>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconChevronUp } from "@tabler/icons-vue";
import type { ClientReturn } from "~/composables/twilio";
import {
  convertTwilioUserToDisplayData,
  convertUserOrOrgToDisplayData,
} from "../conversation/common";
import { injectTwilioContext } from "../app/context/sources/twilio/context";

const { primaryTwilioClient } = injectTwilioContext();

const {
  client,
  conversations,
  conversationsMessageData,
  metadataPerConversation,
} = primaryTwilioClient;

const auth = useNuxtApp().$auth();
const { getImageUrl } = useMediaLink();
const currentUser = computed(() =>
  auth?.userEntity ? convertUserOrOrgToDisplayData(auth.userEntity) : null,
);

const isMessagesExpanded = ref(false);
const openedConversationSid = ref<string | null>(null);

const {
  conversation,
  groups,
  participantUserMap,
  hasMoreMessages,
  lastMessageIndex,
  lastMessageReadIndex,
  totalUnreadMessages,
  nextMessagesLoading,
  avatarFileName,
  friendlyName,
} = useTwilioConversation(
  client,
  computed(() => openedConversationSid.value ?? ""),
);

const users = computed(() =>
  Object.values(participantUserMap.value).map((user) =>
    convertTwilioUserToDisplayData(user),
  ),
);

const conversationAvatarUrl = computed(() => {
  if (!avatarFileName.value || !openedConversationSid.value) return null;
  return getImageUrl(
    openedConversationSid.value,
    "conversation",
    "avatar",
    avatarFileName.value,
    "256",
  );
});

function openConversation(sid: string) {
  openedConversationSid.value = sid;
}
</script>

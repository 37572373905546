export const StopMatcher = /(?![\w' \-,*~_]+)/;

export const getExcerpt = (
  input: string,
  words = 40,
  maxChars = 300,
  numStops = 3,
  stopMatcher = StopMatcher,
) => {
  const actualInput = Array.from(
    stripMarkdown(input.replaceAll(/[\n]+/g, " ")),
  );

  const first = actualInput.join("").split(" ").splice(0, words);
  const joined = first.join(" ");

  let excerpt = "";
  for (let num = 0; num < numStops; num++) {
    const subInput = actualInput.slice(excerpt.length);
    const matched = stopMatcher.exec(subInput.join(""));

    if (matched) {
      const stopIndex = matched.index + 1;
      excerpt += actualInput
        .slice(excerpt.length, excerpt.length + stopIndex)
        .join("");
    }
  }

  if (excerpt.split(" ").length < words && excerpt.length < maxChars)
    return excerpt;

  if (joined.length < maxChars) return joined;

  return actualInput.slice(0, maxChars).join("");
};

export const stripMarkdown = (input: string) => {
  let stripped = input;

  // bold
  stripped = replaceMd(stripped, /\*\*(\**?.+?\**?)\*\*/);
  stripped = replaceMd(stripped, /__(_*?.+?_*?)__/);
  // italic
  stripped = replaceMd(stripped, /\*(.+?)\*/);
  stripped = replaceMd(stripped, /_(.+?)_/);
  // spoiler
  stripped = replaceMd(stripped, /\|\|(.+)\|\|/, "<spoiler>");
  // strikethrough
  stripped = replaceMd(stripped, /~~(.+)~~/);

  // emoji
  stripped = replaceMd(stripped, /<emoji:[^>]*>/, "");

  return stripped;
};

const replaceMd = (input: string, regex: RegExp, replaceValue?: string) => {
  let stripped = input;

  let matched = regex.exec(stripped);
  while (matched) {
    stripped = stripped.replace(matched[0], replaceValue ?? matched[1]);
    matched = regex.exec(stripped);
  }

  return stripped;
};

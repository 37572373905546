import {
  NamiNotificationType,
  type NamiNotification,
} from "~/src/notifications/types/nami";
import {
  NotificationType,
  type Notification,
} from "~/src/notifications/types/notifications";
import {
  TwilioNotificationType,
  type TwilioNotification,
} from "~/src/notifications/types/twilio";
import {
  isNamiNotificationPayload,
  isTwilioNewMessagePayload,
} from "~/src/notifications/helpers";
import { useUserCache } from "../cache/user";
import { useTierCache } from "../cache/tier";
import { useChapterCache } from "../cache/chapter";
import { useCommentCache } from "../cache/comment";
import { useTitleCache } from "../cache/title";
import { usePostCache } from "../cache/post";
import { useDeveloperTools } from "../dev/tools";
import type { MessagePayload } from "firebase/messaging";
import type { NewMessage, TwilioNotificationData } from "./twilio";
import type {
  CreatorCommentToChapter,
  CreatorCommentToTitle,
  CreatorReactionToChapter,
  CreatorTitleFollow,
  NamiNotificationData,
  OfficialAnnouncement,
  PaymentOrganizationSubscription,
  ReaderChapterPublishedToFollowedTitle,
  SocialNewFollow,
  SocialReactionToComment,
  SocialReactionToPost,
  SocialReplyToComment,
  SocialReplyToPost,
  SocialRepostOfPost,
  TestNotification,
} from "./nami";
import {
  isTitle,
  type CommentEntity,
  type NotificationEntryEntity,
  type PostEntity,
  type ReactionType,
} from "~/src/api";
import { getAsyncContext } from "~/components/app/context/util/async";
import { useOrganizationCache } from "../cache/organization";

export function useNotificationParser() {
  const namiNotificationParser = useNamiNotificationParser();
  const twilioNotificationParser = useTwilioNotificationParser();

  async function parseNotification(
    payload: MessagePayload,
  ): Promise<Notification | null> {
    const notification = isNamiNotificationPayload(payload)
      ? await namiNotificationParser.parse(payload)
      : isTwilioNewMessagePayload(payload)
        ? await twilioNotificationParser.parse(payload)
        : null;

    return notification;
  }

  return {
    parseNotification,
  };
}

export function useNamiNotificationParser() {
  const chapterCache = useChapterCache();
  const tierCache = useTierCache();
  const userCache = useUserCache();
  const commentCache = useCommentCache();
  const titleCache = useTitleCache();
  const postCache = usePostCache();
  const organizationCache = useOrganizationCache();
  const { debug } = useDeveloperTools();

  function parseNamiNotificationPayloadIntoData(
    payload: MessagePayload,
  ): NamiNotificationData | null {
    if (!isNamiNotificationPayload(payload)) return null;
    const data = {
      ...(payload.data as any),
      id: payload.messageId,
      params: JSON.parse((payload.data as any).parameters),
    } as NamiNotificationData;
    return data;
  }

  function parseNamiNotificationEntityIntoData(
    notification: NotificationEntryEntity,
  ): NamiNotificationData {
    return {
      id: notification.id,
      key: notification.attributes.key,
      params: notification.attributes.parameters as any,
      collapseCount: notification.attributes.collapseCount,
      date: new Date(notification.attributes.createdAt),
    } satisfies NamiNotificationData;
  }

  async function parse(
    payload: MessagePayload | NotificationEntryEntity,
  ): Promise<NamiNotification | null> {
    try {
      return await parseNamiNotification(payload);
    } catch (e) {
      debug("Error while parsing nami notification");
      debug(e);

      return null;
    }
  }

  async function parseNamiNotification(
    payload: MessagePayload | NotificationEntryEntity,
  ): Promise<NamiNotification | null> {
    debug("received nami payload", payload);

    const data =
      "type" in payload && payload.type === "notification_entry"
        ? parseNamiNotificationEntityIntoData(payload)
        : "messageId" in payload
          ? parseNamiNotificationPayloadIntoData(payload)
          : null;

    if (data === null) return null;

    switch (data.key) {
      case "creator_comment_to_chapter":
        return await parseCreatorCommentToChapterNotification(data);

      case "creator_comment_to_title":
        return await parseCreatorCommentToTitleNotification(data);

      case "creator_reaction_to_chapter":
        return await parseCreatorReactionToChapterNotification(data);

      case "creator_title_follow":
        return await parseCreatorTitleFollowNotification(data);

      case "official_announcement":
        return await parseOfficialAnnouncementNotification(data);

      case "payment_organization_subscription":
        return await parsePaymentOrganizationSubscriptionNotification(data);

      case "reader_chapter_published_to_followed_title":
        return await parseReaderChapterPublishedToFollowedTitleNotification(
          data,
        );

      case "social_new_follow":
        return await parseSocialNewFollowNotification(data);

      case "social_reaction_to_comment":
        return await parseSocialReactionToCommentNotification(data);

      case "social_reaction_to_post":
        return await parseSocialReactionToPostNotification(data);

      case "social_reply_to_comment":
        return await parseSocialReplyToCommentNotification(data);

      case "social_reply_to_post":
        return await parseSocialReplyToPostNotification(data);

      case "social_repost_of_post":
        return await parseSocialRepostOfPostNotification(data);

      case "test_notification":
        return parseTestNotificationNotification(data);
    }
  }

  async function parseCreatorCommentToChapterNotification(
    data: CreatorCommentToChapter,
  ): Promise<NamiNotification> {
    const [chapter, comment, title] = await Promise.all([
      chapterCache.getByid(data.params.chapterId),
      commentCache.getById(data.params.commentId),
      titleCache.getById(data.params.titleId),
    ]);

    // TODO remove this workaround when BE-583 is resolved
    const commenter = await getCommenter(comment, data.params.userId);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.CreatorCommentToChapter,
        data: {
          chapter,
          comment,
          title,
          commenter,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parseCreatorCommentToTitleNotification(
    data: CreatorCommentToTitle,
  ): Promise<NamiNotification> {
    const [comment, title] = await Promise.all([
      commentCache.getById(data.params.commentId),
      titleCache.getById(data.params.titleId),
    ]);

    // TODO remove this workaround when BE-583 is resolved
    const commenter = await getCommenter(comment, data.params.userId);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.CreatorCommentToTitle,
        data: {
          comment,
          title,
          commenter,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parseCreatorReactionToChapterNotification(
    data: CreatorReactionToChapter,
  ): Promise<NamiNotification | null> {
    const [chapter, reaction, reactor] = await Promise.all([
      chapterCache.getByid(data.params.chapterId),
      data.params.reactionType as ReactionType,
      userCache.getById(data.params.userId),
    ]);

    const titleId = chapter.relationships.find(isTitle)?.id;
    if (!titleId) return null;
    const title = await titleCache.getById(titleId);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.CreatorReactionToChapter,
        data: {
          chapter,
          reaction,
          reactor,
          title,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parseCreatorTitleFollowNotification(
    data: CreatorTitleFollow,
  ): Promise<NamiNotification> {
    const [title, follower] = await Promise.all([
      titleCache.getById(data.params.titleId),
      userCache.getById(data.params.userId),
    ]);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.CreatorTitleFollow,
        data: {
          title,
          follower,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parseOfficialAnnouncementNotification(
    data: OfficialAnnouncement,
  ): Promise<NamiNotification> {
    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.OfficialAnnouncement,
        data: {
          title: data.params.title,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parsePaymentOrganizationSubscriptionNotification(
    data: PaymentOrganizationSubscription,
  ): Promise<NamiNotification> {
    const [userWhoSubscribed, organization, tier] = await Promise.all([
      userCache.getById(data.params.userId),
      organizationCache.getById(data.params.organizationId),
      tierCache.getById(data.params.tierItemId),
    ]);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.PaymentOrganizationSubscription,
        data: {
          userWhoSubscribed,
          organization,
          tier,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parseReaderChapterPublishedToFollowedTitleNotification(
    data: ReaderChapterPublishedToFollowedTitle,
  ): Promise<NamiNotification> {
    const [chapter, title] = await Promise.all([
      chapterCache.getByid(data.params.chapterId),
      titleCache.getById(data.params.titleId),
    ]);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType:
          NamiNotificationType.ReaderChapterPublishedToFollowedTitle,
        data: {
          chapter,
          title,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parseSocialNewFollowNotification(
    data: SocialNewFollow,
  ): Promise<NamiNotification> {
    const [userWhoFollowed] = await Promise.all([
      userCache.getById(data.params.userId),
    ]);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.SocialNewFollow,
        data: {
          userWhoFollowed,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parseSocialReactionToCommentNotification(
    data: SocialReactionToComment,
  ): Promise<NamiNotification> {
    const [comment, reaction, userWhoReacted] = await Promise.all([
      commentCache.getById(data.params.commentId),
      data.params.reactionType as ReactionType,
      userCache.getById(data.params.userId),
    ]);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.SocialReactionToComment,
        data: {
          comment,
          reaction,
          userWhoReacted,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parseSocialReactionToPostNotification(
    data: SocialReactionToPost,
  ): Promise<NamiNotification> {
    const [post, reaction, userWhoReacted] = await Promise.all([
      postCache.getById(data.params.postId),
      data.params.reactionType as ReactionType,
      userCache.getById(data.params.userId),
    ]);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.SocialReactionToPost,
        data: {
          post,
          reaction,
          userWhoReacted,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parseSocialReplyToCommentNotification(
    data: SocialReplyToComment,
  ): Promise<NamiNotification> {
    const [comment] = await Promise.all([
      commentCache.getById(data.params.commentId),
    ]);

    // TODO remove this workaround when BE-583 is resolved
    const commenter = await getCommenter(comment, data.params.userId);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.SocialReplyToComment,
        data: {
          comment,
          commenter,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parseSocialReplyToPostNotification(
    data: SocialReplyToPost,
  ): Promise<NamiNotification> {
    const [comment, post] = await Promise.all([
      commentCache.getById(data.params.commentId),
      postCache.getById(data.params.postId),
    ]);

    // TODO remove this workaround when BE-583 is resolved
    const commenter = await getCommenter(comment, data.params.userId);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.SocialReplyToPost,
        data: {
          comment,
          post,
          commenter,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  async function parseSocialRepostOfPostNotification(
    data: SocialRepostOfPost,
  ): Promise<NamiNotification> {
    const [repost] = await Promise.all([
      postCache.getById(data.params.repostId),
    ]);

    const reposter = await getReposter(repost, data.params.userId);

    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.SocialRepostOfPost,
        data: {
          repost,
          reposter,
        },
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  function parseTestNotificationNotification(
    data: TestNotification,
  ): NamiNotification {
    return {
      type: NotificationType.Nami,
      notification: {
        id: data.id,
        notificationType: NamiNotificationType.TestNotification,
        data: data.params,
        date: data.date ?? new Date(),
        collapseCount: data.collapseCount,
      },
    };
  }

  // TODO remove this workaround when BE-583 is resolved
  async function getCommenter(comment: CommentEntity, fallbackId: string) {
    const orgId = comment.relationships.find(
      (rel) => rel.type === "organization",
    )?.id;
    const commenter = orgId
      ? await organizationCache.getById(orgId)
      : await userCache.getById(fallbackId);
    return commenter;
  }

  async function getReposter(post: PostEntity, fallbackId: string) {
    const orgId = post.relationships.find(
      (rel) => rel.type === "organization",
    )?.id;
    const reposter = orgId
      ? await organizationCache.getById(orgId)
      : await userCache.getById(fallbackId);
    return reposter;
  }

  return { parse };
}

export function useTwilioNotificationParser() {
  const { debug } = useDeveloperTools();
  const userCache = useUserCache();
  const organizationCache = useOrganizationCache();
  const asyncTwilioContext = getAsyncContext("twilio");
  const twiBodyRegex =
    /(?<conversaion_sid>[^:]*):(?<participant>[^:]*):\s(?<message>.*)/;

  function parseTwiBody(body: string) {
    const groups = twiBodyRegex.exec(body)?.groups;
    if (!groups) return null;
    return groups as {
      conversation_sid: string;
      participant: string;
      message: string;
    };
  }

  async function parseTwilioNotification(payload: MessagePayload) {
    debug("received twilio payload", payload);

    if (!isTwilioNewMessagePayload(payload)) return null;
    const data = payload.data as any as TwilioNotificationData;

    switch (data.twi_message_type) {
      case "twilio.channel.consumption_update":
        return null;

      case "twilio.conversations.new_message":
        return parseNewMessageNotification(data);
    }
  }

  async function parseNewMessageNotification(
    data: NewMessage,
  ): Promise<TwilioNotification | null> {
    const attachments = Number(data.mediaCount ?? "0");
    const totalAttachments = Number.isNaN(attachments) ? 0 : attachments;
    const body = parseTwiBody(data.twi_body);
    const { primaryTwilioClient } = await asyncTwilioContext;
    const { conversations } = primaryTwilioClient;

    const conversation = conversations.value.find(
      (c) => c.sid === data.conversation_sid,
    );
    if (!conversation) return null;
    const paginator = await conversation.getMessages(
      1,
      Number(data.message_index),
    );
    const message = paginator.items.at(0);
    if (!message) return null;
    const participant = (await message.getParticipant()) as EnhancedParticipant;
    const sender = participant.attributes.is_organization
      ? await organizationCache.getById(participant.attributes.organization_id)
      : participant.identity
        ? await userCache.getById(participant.identity)
        : null;

    return {
      type: NotificationType.Twilio,
      notification: {
        id: data.twi_message_id,
        notificationType: TwilioNotificationType.NewMessage,
        data: {
          message: {
            attachments: totalAttachments,
            content: body?.message ?? "",
          },
          sender: sender,
          conversation: {
            sid: conversation.sid,
            name: conversation.friendlyName ?? "",
          },
        },
        date: new Date(),
      },
    };
  }

  return {
    parse: parseTwilioNotification,
  };
}

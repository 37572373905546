//===========================================
// localStorage
//===========================================

import type {
  AvailableLanguages,
  ChapterKind,
  ReaderSettings,
  VolumeKind,
} from "~/src/api";
import {
  getLanguages,
  getLocales,
  type ContentRating,
  type Demographic,
  type SettingsPostBody,
} from "~/src/api";
import type { VueI18nTranslation } from "vue-i18n";

export { getLanguages, getLocales };

export const VerticalDesktop: ReaderSettings[number] = {
  profile: "Longstrip - Desktop",
  condition: 'title.readingMode == "vls" and screen.width > 1280',
  listCondition: 'title.readingMode == "vls"',
  readingMode: "single",
  readingDirection: "content",
  scrollDirection: "vertical",
  hideReadingHint: false,
  continuous: true,
  imageFit: {
    current: "custom",
    custom: { maximumWidth: 0.5 },
  },
  upscaleSmallPages: true,
  animation: true,
  useNaturalScrolling: true,
  openMenuOnPageLoad: true,
  scrollWheelBehavior: "none",
};

export const VerticalTablet: ReaderSettings[number] = {
  profile: "Longstrip - Tablet",
  condition:
    'title.readingMode == "vls" and screen.width > 640 and screen.width <= 1280',
  listCondition: 'title.readingMode == "vls"',
  readingMode: "single",
  readingDirection: "content",
  scrollDirection: "vertical",
  hideReadingHint: false,
  continuous: true,
  imageFit: {
    current: "custom",
    custom: { maximumWidth: 0.75 },
  },
  upscaleSmallPages: true,
  animation: true,
  useNaturalScrolling: true,
  openMenuOnPageLoad: true,
  scrollWheelBehavior: "none",
};

export const VerticalPhone: ReaderSettings[number] = {
  profile: "Longstrip - Phone",
  condition: 'title.readingMode == "vls" and screen.width <= 640',
  listCondition: 'title.readingMode == "vls"',
  readingMode: "single",
  readingDirection: "content",
  scrollDirection: "vertical",
  hideReadingHint: false,
  continuous: true,
  imageFit: {
    current: "fit",
    custom: { maximumWidth: 1 },
  },
  upscaleSmallPages: true,
  animation: true,
  useNaturalScrolling: true,
  openMenuOnPageLoad: false,
  scrollWheelBehavior: "none",
};

export const HorizontalDesktop: ReaderSettings[number] = {
  profile: "Comics/Manga - Desktop",
  condition:
    'title.readingMode == "rtl" or title.readingMode == "ltr" and screen.width > 1280',
  listCondition: 'title.readingMode != "vls"',
  readingMode: "single",
  readingDirection: "content",
  scrollDirection: "horizontal",
  hideReadingHint: false,
  continuous: false,
  imageFit: {
    current: "fit",
    custom: { maximumWidth: 1 },
  },
  upscaleSmallPages: true,
  animation: true,
  useNaturalScrolling: false,
  openMenuOnPageLoad: true,
  scrollWheelBehavior: "none",
};

export const HorizontalTablet: ReaderSettings[number] = {
  profile: "Comics/Manga - Tablet",
  condition:
    'title.readingMode == "rtl" or title.readingMode == "ltr" and screen.width > 640 and screen.width <= 1280',
  listCondition: 'title.readingMode != "vls"',
  readingMode: "single",
  readingDirection: "content",
  scrollDirection: "horizontal",
  hideReadingHint: false,
  continuous: false,
  imageFit: {
    current: "fit",
    custom: { maximumWidth: 1 },
  },
  upscaleSmallPages: true,
  animation: true,
  useNaturalScrolling: false,
  openMenuOnPageLoad: true,
  scrollWheelBehavior: "none",
};

export const HorizontalPhone: ReaderSettings[number] = {
  profile: "Comics/Manga - Phone",
  condition:
    'title.readingMode == "rtl" or title.readingMode == "ltr" and screen.width <= 640',
  listCondition: 'title.readingMode != "vls"',
  readingMode: "single",
  readingDirection: "content",
  scrollDirection: "horizontal",
  hideReadingHint: false,
  continuous: false,
  imageFit: {
    current: "fit",
    custom: { maximumWidth: 1 },
  },
  upscaleSmallPages: true,
  animation: true,
  useNaturalScrolling: false,
  openMenuOnPageLoad: false,
  scrollWheelBehavior: "none",
};

export const profileMap = {
  [VerticalDesktop.profile]: VerticalDesktop,
  [VerticalTablet.profile]: VerticalTablet,
  [VerticalPhone.profile]: VerticalPhone,
  [HorizontalDesktop.profile]: HorizontalDesktop,
  [HorizontalTablet.profile]: HorizontalTablet,
  [HorizontalPhone.profile]: HorizontalPhone,
};

export function getDefaultSettings(): SettingsPostBody {
  return {
    data: {
      site: {
        locale: "en",
        theme: "system",
        quality: "medium",
      },
      platform: {
        preferredTranslationLocales: [],
        matureContentEnabled: false,
      },
      reader: [
        VerticalDesktop,
        HorizontalDesktop,
        VerticalTablet,
        HorizontalTablet,
        VerticalPhone,
        HorizontalPhone,
      ],
    },
  };
}

export function getThemes() {
  return [
    { text: "System", value: "system" },
    { text: "Light", value: "light" },
    { text: "Dark", value: "dark" },
  ];
}

export function getPublicationStatuses() {
  return [
    { text: "Ongoing", value: "ongoing" },
    { text: "Completed", value: "completed" },
    { text: "Hiatus", value: "hiatus" },
    { text: "Cancelled", value: "cancelled" },
  ];
}

export function getPublicationDemographics(): {
  text: string;
  value: Demographic;
}[] {
  return [
    { text: "None", value: "none" },
    { text: "Shounen", value: "shounen" },
    { text: "Shoujo", value: "shoujo" },
    { text: "Seinen", value: "seinen" },
    { text: "Josei", value: "josei" },
    { text: "Teenage Boys", value: "teenage-boys" },
    { text: "Teenage Girls", value: "teenage-girls" },
    { text: "Young Men", value: "young-men" },
    { text: "Young Women", value: "young-women" },
  ];
}

export function getContentRatings(): ContentRating[] {
  return ["safe", "mature", "restricted"];
}

export function getVolumeLabels(
  translate?: VueI18nTranslation,
): { text: string; value: VolumeKind }[] {
  return ["volume", "season", "book", "act", "arc"].map((kind) => ({
    value: kind as VolumeKind,
    text: translate ? translate(`entity.title.volumeKind.long.${kind}`) : kind,
  }));
}

export function getChapterLabels(
  translate?: VueI18nTranslation,
): { text: string; value: ChapterKind }[] {
  return ["chapter", "episode"].map((kind) => ({
    value: kind as ChapterKind,
    text: translate ? translate(`entity.title.chapterKind.long.${kind}`) : kind,
  }));
}

export const LimitOptions = [8, 16, 32, 64, 100].map((x) => ({
  text: x.toString(),
  value: x,
}));

/*export function getTags() {
  return {
    content: [
      { value: 'b29d6a3d-1569-4e7a-8caf-7557bc92cd5d', text: 'gore' },
      { value: '97893a4c-12af-4dac-b6be-0dffb353568e', text: 'sexual violence' },
    ],
    format: [
      { value: 'b11fda93-8f1d-4bef-b2ed-8803d3733170', text: '4-koma' },
      { value: 'f4122d1c-3b44-44d0-9936-ff7502c39ad3', text: 'adaptation' },
      { value: '51d83883-4103-437c-b4b1-731cb73d786c', text: 'anthology' },
      { value: '0a39b5a1-b235-4886-a747-1d05d216532d', text: 'award winning' },
      { value: 'b13b2a48-c720-44a9-9c77-39c9979373fb', text: 'doujinshi' },
      { value: '7b2ce280-79ef-4c09-9b58-12b7c23a9b78', text: 'fan colored' },
      { value: 'f5ba408b-0e7a-484d-8d49-4e9125ac96de', text: 'full color' },
      { value: '3e2b8dae-350e-4ab8-a8ce-016e844b9f0d', text: 'long strip' },
      { value: '320831a8-4026-470b-94f6-8353740e6f04', text: 'official colored' },
      { value: '0234a31e-a729-4e28-9d6a-3f87c4966b9e', text: 'oneshot' },
      { value: '891cf039-b895-47f0-9229-bef4c96eccd4', text: 'user created' },
      { value: 'e197df38-d0e7-43b5-9b09-2842d0c326dd', text: 'web comic' },
    ],
    genre: [
      { value: '391b0423-d847-456f-aff0-8b0cfc03066b', text: 'action' },
      { value: '87cc87cd-a395-47af-b27a-93258283bbc6', text: 'adventure' },
      { value: '5920b825-4181-4a17-beeb-9918b0ff7a30', text: "boys' love" },
      { value: '4d32cc48-9f00-4cca-9b5a-a839f0764984', text: 'comedy' },
      { value: '5ca48985-9a9d-4bd8-be29-80dc0303db72', text: 'crime' },
      { value: 'b9af3a63-f058-46de-a9a0-e0c13906197a', text: 'drama' },
      { value: 'cdc58593-87dd-415e-bbc0-2ec27bf404cc', text: 'fantasy' },
      { value: 'a3c67850-4684-404e-9b7f-c69850ee5da6', text: "girls' love" },
      { value: '33771934-028e-4cb3-8744-691e866a923e', text: 'historical' },
      { value: 'cdad7e68-1419-41dd-bdce-27753074a640', text: 'horror' },
      { value: 'ace04997-f6bd-436e-b261-779182193d3d', text: 'isekai' },
      { value: '81c836c9-914a-4eca-981a-560dad663e73', text: 'magical girls' },
      { value: '50880a9d-5440-4732-9afb-8f457127e836', text: 'mecha' },
      { value: 'c8cbe35b-1b2b-4a3f-9c37-db84c4514856', text: 'medical' },
      { value: 'ee968100-4191-4968-93d3-f82d72be7e46', text: 'mystery' },
      { value: 'b1e97889-25b4-4258-b28b-cd7f4d28ea9b', text: 'philosophical' },
      { value: '3b60b75c-a2d7-4860-ab56-05f391bb889c', text: 'psychological' },
      { value: '423e2eae-a7a2-4a8b-ac03-a8351462d71d', text: 'romance' },
      { value: '256c8bd9-4904-4360-bf4f-508a76d67183', text: 'sci-fi' },
      { value: 'e5301a23-ebd9-49dd-a0cb-2add944c7fe9', text: 'slice of life' },
      { value: '69964a64-2f90-4d33-beeb-f3ed2875eb4c', text: 'sports' },
      { value: '7064a261-a137-4d3a-8848-2d385de3a99c', text: 'superhero' },
      { value: '07251805-a27e-4d59-b488-f0bfbec15168', text: 'thriller' },
      { value: 'f8f62932-27da-4fe4-8ee1-6779a8c5edba', text: 'tragedy' },
      { value: 'acc803a4-c95a-4c22-86fc-eb6b582d82a2', text: 'wuxia' },
    ],
    theme: [
      { value: 'e64f6742-c834-471d-8d72-dd51fc02b835', text: 'aliens' },
      { value: '3de8c75d-8ee3-48ff-98ee-e20a65c86451', text: 'animals' },
      { value: 'ea2bc92d-1c26-4930-9b7c-d5c0dc1b6869', text: 'cooking' },
      { value: '9ab53f92-3eed-4e9b-903a-917c86035ee3', text: 'crossdressing' },
      { value: 'da2d50ca-3018-4cc0-ac7a-6b7d472a29ea', text: 'delinquents' },
      { value: '39730448-9a5f-48a2-85b0-a70db87b1233', text: 'demons' },
      { value: '2bd2e8d0-f146-434a-9b51-fc9ff2c5fe6a', text: 'genderswap' },
      { value: '3bb26d85-09d5-4d2e-880c-c34b974339e9', text: 'ghosts' },
      { value: 'fad12b5e-68ba-460e-b933-9ae8318f5b65', text: 'gyaru' },
      { value: 'aafb99c1-7f60-43fa-b75f-fc9502ce29c7', text: 'harem' },
      { value: '5bd0e105-4481-44ca-b6e7-7544da56b1a3', text: 'incest' },
      { value: '2d1f5d56-a1e5-4d0d-a961-2193588b08ec', text: 'loli' },
      { value: '85daba54-a71c-4554-8a28-9901a8b0afad', text: 'mafia' },
      { value: 'a1f53773-c69a-4ce5-8cab-fffcd90b1565', text: 'magic' },
      { value: '799c202e-7daa-44eb-9cf7-8a3c0441531e', text: 'martial arts' },
      { value: 'ac72833b-c4e9-4878-b9db-6c8a4a99444a', text: 'military' },
      { value: 'dd1f77c5-dea9-4e2b-97ae-224af09caf99', text: 'monster girls' },
      { value: '36fd93ea-e8b8-445e-b836-358f02b3d33d', text: 'monsters' },
      { value: 'f42fbf9e-188a-447b-9fdc-f19dc1e4d685', text: 'music' },
      { value: '489dd859-9b61-4c37-af75-5b18e88daafc', text: 'ninja' },
      { value: '92d6d951-ca5e-429c-ac78-451071cbf064', text: 'office workers' },
      { value: 'df33b754-73a3-4c54-80e6-1a74a8058539', text: 'police' },
      { value: '9467335a-1b83-4497-9231-765337a00b96', text: 'post-apocalyptic' },
      { value: '0bc90acb-ccc1-44ca-a34a-b9f3a73259d0', text: 'reincarnation' },
      { value: '65761a2a-415e-47f3-bef2-a9dababba7a6', text: 'reverse harem' },
      { value: '81183756-1453-4c81-aa9e-f6e1b63be016', text: 'samurai' },
      { value: 'caaa44eb-cd40-4177-b930-79d3ef2afe87', text: 'school life' },
      { value: 'ddefd648-5140-4e5f-ba18-4eca4071d19b', text: 'shota' },
      { value: 'eabc5b4c-6aff-42f3-b657-3e90cbd00b75', text: 'supernatural' },
      { value: '5fff9cde-849c-4d78-aab0-0d52b2ee1d25', text: 'survival' },
      { value: '292e862b-2d17-4062-90a2-0356caa4ae27', text: 'time travel' },
      { value: '31932a7e-5b8e-49a6-9f12-2afa39dc544c', text: 'traditional games' },
      { value: 'd7d1730f-6eb0-4ba6-9437-602cac38664c', text: 'vampires' },
      { value: '9438db5a-7e2a-4ac0-b39e-e0d95a34b8a8', text: 'video games' },
      { value: 'd14322ac-4d6f-4e9b-afd9-629d5f4d8a41', text: 'villainess' },
      { value: '8c86611e-fab7-4986-9dec-d1a2f44acdd5', text: 'virtual reality' },
      { value: '631ef465-9aba-4afb-b0fc-ea10efe274a8', text: 'zombies' },
    ]
  };
}*/

export function getOrderStrings() {
  return [
    { text: "Most relevant", value: "relevance.desc" },
    { text: "Published Asc", value: "latestUploadedChapter.asc" },
    { text: "Published Desc", value: "latestUploadedChapter.desc" },
    { text: "Title Asc", value: "title.asc" },
    { text: "Title Desc", value: "title.desc" },
    { text: "Rating Asc", value: "rating.asc" },
    { text: "Rating Desc", value: "rating.desc" },
    { text: "Follows Asc", value: "followedCount.asc" },
    { text: "Follows Desc", value: "followedCount.desc" },
    { text: "Added Asc", value: "createdAt.asc" },
    { text: "Added Desc", value: "createdAt.desc" },
    { text: "Year Asc", value: "year.asc" },
    { text: "Year Desc", value: "year.desc" },
  ];
}

export function getChapterOrders() {
  return [
    { text: "Desc", value: "desc" },
    { text: "Asc", value: "asc" },
  ];
}

export function getViewModes() {
  return [
    { text: "Cover", value: 0 },
    { text: "Simple", value: 1 },
    { text: "Expanded", value: 2 },
    { text: "Detailed", value: 3 },
  ];
}

export function getReadingStatuses() {
  return [
    { text: "Reading", value: "reading" },
    { text: "Plan to read", value: "plan_to_read" },
    { text: "Completed", value: "completed" },
    { text: "On hold", value: "on_hold" },
    { text: "Re-reading", value: "re_reading" },
    { text: "Dropped", value: "dropped" },
  ];
}

export function getTitlesPerPageOptions() {
  return [
    { text: "16 Titles", value: 16 },
    { text: "32 Titles", value: 32 },
    { text: "48 Titles", value: 48 },
    { text: "64 Titles", value: 64 },
  ];
}

export function getChaptersPerPageOptions() {
  return [
    { text: "16 Chapters", value: 16 },
    { text: "32 Chapters", value: 32 },
    { text: "48 Chapters", value: 48 },
    { text: "64 Chapters", value: 64 },
  ];
}

export function getTitleStates(): {
  text: string;
  value: "draft" | "pending" | "published" | "unpublished";
}[] {
  return [
    { text: "Draft", value: "draft" },
    { text: "Pending", value: "pending" },
    { text: "Published", value: "published" },
    { text: "Unpublished", value: "unpublished" },
  ];
}

export function getOrganizationTypes(): {
  text: string;
  value: "creator" | "publisher" | "translator";
}[] {
  return [
    { text: "A Creator's", value: "creator" },
    { text: "A Publisher's", value: "publisher" },
    { text: "A Translator's", value: "translator" },
  ];
}

export const countries = {
  AD: "Andorra",
  AE: "United Arab Emirates",
  AF: "Afghanistan",
  AG: "Antigua and Barbuda",
  AI: "Anguilla",
  AL: "Albania",
  AM: "Armenia",
  AO: "Angola",
  AQ: "Antarctica",
  AR: "Argentina",
  AS: "American Samoa",
  AT: "Austria",
  AU: "Australia",
  AW: "Aruba",
  AZ: "Azerbaijan",
  BA: "Bosnia and Herzegovina",
  BB: "Barbados",
  BD: "Bangladesh",
  BE: "Belgium",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BL: "Saint Barthélemy",
  BM: "Bermuda",
  BN: "Brunei Darussalam",
  BO: "Bolivia",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BR: "Brazil",
  BS: "Bahamas",
  BT: "Bhutan",
  BV: "Bouvet Island",
  BW: "Botswana",
  BY: "Belarus",
  BZ: "Belize",
  CA: "Canada",
  CC: "Cocos Islands",
  CD: "Congo",
  CF: "Central African Republic",
  CG: "Congo",
  CH: "Switzerland",
  CI: "Ivory Coast",
  CK: "Cook Islands",
  CL: "Chile",
  CM: "Cameroon",
  CN: "China",
  CO: "Colombia",
  CR: "Costa Rica",
  CU: "Cuba",
  CV: "Cabo Verde",
  CW: "Curaçao",
  CX: "Christmas Island",
  CY: "Cyprus",
  CZ: "Czechia",
  DE: "Germany",
  DJ: "Djibouti",
  DK: "Denmark",
  DM: "Dominica",
  DO: "Dominican Republic",
  DZ: "Algeria",
  EC: "Ecuador",
  EE: "Estonia",
  EG: "Egypt",
  EH: "Western Sahara",
  ER: "Eritrea",
  ES: "Spain",
  ET: "Ethiopia",
  FI: "Finland",
  FJ: "Fiji",
  FK: "Falkland Islands",
  FM: "Micronesia",
  FO: "Faroe Islands",
  FR: "France",
  GA: "Gabon",
  GB: "United Kingdom",
  GD: "Grenada",
  GE: "Georgia",
  GF: "French Guiana",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Greenland",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadeloupe",
  GQ: "Equatorial Guinea",
  GR: "Greece",
  GS: "South Georgia and the South Sandwich Islands",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Heard Island and McDonald Islands",
  HN: "Honduras",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  ID: "Indonesia",
  IE: "Ireland",
  IL: "Israel",
  IM: "Isle of Man",
  IN: "India",
  IQ: "Iraq",
  IR: "Iran",
  IS: "Iceland",
  IT: "Italy",
  JE: "Jersey",
  JM: "Jamaica",
  JO: "Jordan",
  JP: "Japan",
  KE: "Kenya",
  KG: "Kyrgyzstan",
  KH: "Cambodia",
  KI: "Kiribati",
  KM: "Comoros",
  KN: "Saint Kitts and Nevis",
  KP: "North Korea",
  KR: "South Korea",
  KW: "Kuwait",
  KY: "Cayman Islands",
  KZ: "Kazakhstan",
  LA: "Laos",
  LB: "Lebanon",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  LY: "Libya",
  MA: "Morocco",
  MC: "Monaco",
  MD: "Moldova",
  ME: "Montenegro",
  MF: "Saint Martin",
  MG: "Madagascar",
  MH: "Marshall Islands",
  MK: "North Macedonia",
  ML: "Mali",
  MM: "Myanmar",
  MN: "Mongolia",
  MO: "Macao",
  MP: "Northern Mariana Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritius",
  MV: "Maldives",
  MW: "Malawi",
  MX: "Mexico",
  MY: "Malaysia",
  MZ: "Mozambique",
  NA: "Namibia",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "Netherlands",
  NO: "Norway",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "New Zealand",
  OM: "Oman",
  PA: "Panama",
  PE: "Peru",
  PF: "French Polynesia",
  PG: "Papua New Guinea",
  PH: "Philippines",
  PK: "Pakistan",
  PL: "Poland",
  PM: "Saint Pierre and Miquelon",
  PN: "Pitcairn",
  PR: "Puerto Rico",
  PT: "Portugal",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Qatar",
  RO: "Romania",
  RS: "Serbia",
  RU: "Russia",
  RW: "Rwanda",
  SA: "Saudi Arabia",
  SB: "Solomon Islands",
  SC: "Seychelles",
  SD: "Sudan",
  SE: "Sweden",
  SG: "Singapore",
  SH: "Saint Helena, Ascension and Tristan da Cunha",
  SI: "Slovenia",
  SJ: "Svalbard and Jan Mayen",
  SK: "Slovakia",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Suriname",
  SS: "South Sudan",
  ST: "São Tomé and Principe",
  SV: "El Salvador",
  SX: "Sint Maarten",
  SY: "Syrian Arab Republic",
  SZ: "Eswatini",
  TC: "Turks and Caicos Islands",
  TD: "Chad",
  TF: "French Southern Territories",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tajikistan",
  TK: "Tokelau",
  TL: "Timor-Leste",
  TM: "Turkmenistan",
  TN: "Tunisia",
  TO: "Tonga",
  TR: "Türkiye",
  TT: "Trinidad and Tobago",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "Tanzania",
  UA: "Ukraine",
  UG: "Uganda",
  UM: "United States Minor Outlying Islands",
  US: "United States of America",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VA: "Holy See",
  VC: "Saint Vincent and the Grenadines",
  VE: "Venezuela",
  VG: "British Virgin Islands",
  VI: "U.S. Virgin Islands",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis and Futuna",
  WS: "Samoa",
  YE: "Yemen",
  YT: "Mayotte",
  ZA: "South Africa",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

export const getCountryOptions = (translate?: VueI18nTranslation) =>
  Object.entries(countries).map((en) => ({
    value: en[0],
    text: translate ? translate(`countries.${en[0]}`) : en[1],
  }));

export const ALL_LANGUAGES: AvailableLanguages[] = [
  "ar",
  "bg",
  "ca",
  "cs",
  "da",
  "de",
  "el",
  "en",
  "es-419",
  "es-es",
  "et",
  "fa",
  "fi",
  "fil",
  "fr",
  "ga",
  "he",
  "hi",
  "hr",
  "hu",
  "id",
  "is",
  "it",
  "ja",
  "ko",
  "lt",
  "ms",
  "ne",
  "nl",
  "no",
  "pa",
  "pl",
  "pt-br",
  "pt-pt",
  "ru",
  "sk",
  "sl",
  "sv",
  "th",
  "tr",
  "uk",
  "zh-hans",
  "zh-hant",
];

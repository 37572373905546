<template>
  <component
    :is="tooltip ? Tooltip : 'div'"
    variant="contrast"
    class="button--wrapper"
    :class="{
      blockWrapper: block,
    }"
    v-bind="{ class: $attrs?.class }"
  >
    <Skeleton v-if="skeleton" class="absolute inset-0 z-10" />
    <template #tooltip v-if="tooltip">
      <template v-if="typeof tooltip === 'string'">
        {{ tooltip }}
      </template>
      <slot v-else name="tooltip" />
    </template>
    <component
      :is="href ? (external ? 'a' : TheNuxtLink) : 'button'"
      :to="external ? undefined : href"
      :href="external ? href : undefined"
      v-bind="$attrs"
      @click="onClick"
      ref="buttonRef"
      class="namibutton"
      :style="{ zIndex: wave ? extraWaves + 2 : 0 }"
      :class="{
        disabled,
        pill,
        small,
        blockButton: block,
        iconRight,
        iconOnly,
        hideTextOnSmallScreen,
        forceIconOnly,
        text,
        inset,
        tile,
        [buttonType]: buttonType,
      }"
    >
      <div
        v-if="icon || loading"
        class="icon"
        :style="{
          width: `${iconSize}px`,
          height: `${iconSize}px`,
        }"
      >
        <component
          :is="loading ? IconLoader2 : icon"
          :size="iconSize"
          class="my-auto"
          :class="loading && 'animate-spin'"
        />
      </div>
      <div
        v-if="slots.icon"
        class="icon"
        :style="{
          width: `${iconSize}px`,
          height: `${iconSize}px`,
        }"
      >
        <slot name="icon" />
      </div>
      <div class="buttonText" :class="{ expandText }">
        {{ buttonText }}
        <slot />
      </div>
      <div :class="{ wave }"></div>
      <!-- We're spawning as many waves as the user clicks. If it makes their browser slow, it's their fault to blame for clicking inhumanely many times a second -->
      <div
        v-for="wave in extraWaves"
        class="wave"
        :style="{ zIndex: wave + 1 }"
      ></div>
    </component>
  </component>
</template>

<script setup lang="ts">
import type { Props as ButtonProps } from "~/types/button";
import Tooltip from "~/components/Tooltip.vue";
import { IconLoader2 } from "@tabler/icons-vue";

export type Props = ButtonProps;

interface Events {
  (e: "buttonClick", v: void): void;
}

const TheNuxtLink = resolveComponent("TheNuxtLink");

const props = defineProps<Props>();
const emit = defineEmits<Events>();
const slots = defineSlots<{
  icon: {};
  tooltip: {};
  default: {};
}>();

const buttonRef = ref<HTMLButtonElement | HTMLAnchorElement>();

const iconRight = computed(
  () =>
    props.iconPosition === "right" || (!props.iconPosition && props.loading),
);
const iconSize = computed(() => (props.small ? 20 : 24));
const iconOnly = computed(
  () =>
    ((props.icon || slots.icon) && !props.buttonText && !slots.default) ||
    props.forceIconOnly,
);

const wave = ref(false);
const extraWaves = ref(0);

function showWaves() {
  if (props.noWaves) return;

  if (wave.value === false) {
    wave.value = true;

    setTimeout(() => {
      wave.value = false;
    }, 350);

    return;
  }

  extraWaves.value += 1;
  setTimeout(() => {
    extraWaves.value -= 1;
  }, 350);
}

function onClick(e: MouseEvent) {
  if (props.disabled || props.loading) return;

  if (!props.external && !props.href) e.preventDefault();

  showWaves();
  emit("buttonClick");
}

onMounted(() => {
  if (
    buttonRef.value &&
    typeof buttonRef.value.addEventListener === "function"
  ) {
    // This is important because without it, the "active" classes are not applied
    // And vue doesn't add passive listeners with @touchstart
    buttonRef.value.addEventListener("touchstart", () => {}, { passive: true });
  }
});
</script>

<script lang="ts">
export default {
  // We don't want the attributes on the parent div, but in our <button>
  inheritAttrs: false,
};
</script>

<style scoped lang="postcss">
.button--wrapper {
  display: inline-block;
  @apply relative;

  &.blockWrapper {
    display: block;
    @apply w-full;
  }
}

.namibutton {
  display: inline-flex;
  @apply rounded-md text-sm sm:text-base p-2 w-full h-full relative select-none items-center font-medium justify-center border border-transparent transition-all duration-75;

  &.primary {
    @apply bg-nami-comi-blue;

    &:not(.text, .inset) {
      @apply canhover:hover:bg-nami-nami-blue active:bg-nami-nami-blue canhover:active:bg-nami-seafoam text-white;
    }

    &.inset,
    &.text {
      @apply text-nami-comi-blue;
    }

    &.inset {
      @apply border border-nami-nami-blue border-solid;
    }

    .wave {
      @apply text-nami-nami-blue;
    }
  }

  &.gold {
    @apply bg-yellow-400;

    &:not(.text, .inset) {
      @apply canhover:hover:bg-yellow-500 active:bg-yellow-500 canhover:active:bg-yellow-600 dark:text-neutral-700;
    }

    &.inset,
    &.text {
      @apply text-yellow-600 dark:text-yellow-400;
    }

    &.inset {
      @apply border border-yellow-400 border-solid;
    }

    .wave {
      @apply text-yellow-400;
    }
  }

  &.storm {
    @apply bg-nami-storm-grey;

    &:not(.text, .inset) {
      @apply canhover:hover:bg-nami-storm-grey active:bg-nami-storm-grey canhover:active:bg-nami-seafoam text-white;
    }

    &.inset,
    &.text {
      @apply text-nami-storm-grey;
    }

    &.inset {
      @apply border border-nami-storm-grey border-solid;
    }

    .wave {
      @apply text-nami-storm-grey;
    }
  }

  &.seafoam {
    @apply bg-nami-seafoam;

    &:not(.text, .inset) {
      @apply canhover:hover:bg-nami-seafoam active:bg-nami-seafoam canhover:active:bg-nami-seafoam text-white;
    }

    &.inset,
    &.text {
      @apply text-nami-seafoam;
    }

    &.inset {
      @apply border border-nami-seafoam border-solid;
    }

    .wave {
      @apply text-nami-seafoam;
    }
  }

  &.rain {
    @apply bg-nami-rain-grey;

    &:not(.text, .inset) {
      @apply canhover:hover:bg-nami-rain-grey active:bg-nami-rain-grey canhover:active:bg-nami-seafoam text-white;
    }

    &.inset,
    &.text {
      @apply text-nami-rain-grey;
    }

    &.inset {
      @apply border border-nami-rain-grey border-solid;
    }

    .wave {
      @apply text-nami-rain-grey;
    }
  }

  &.secondary {
    @apply text-neutral-700 dark:text-neutral-300 bg-neutral-200 dark:bg-neutral-700;

    &:not(.text, .inset) {
      @apply canhover:hover:bg-neutral-300 canhover:hover:dark:bg-neutral-600 active:bg-neutral-200 dark:active:bg-neutral-600 canhover:active:bg-neutral-400 canhover:active:dark:bg-neutral-500;
    }

    /*&.inset,
    &.text {
      @apply text-neutral-400;
    }*/

    &.inset {
      @apply border border-neutral-200 dark:border-neutral-700 border-solid;
    }

    &.wave {
      @apply text-neutral-500;
    }
  }

  &.danger {
    @apply bg-red-400 dark:bg-red-600;

    &:not(.text, .inset) {
      @apply canhover:hover:bg-red-500 canhover:hover:dark:bg-red-700 active:bg-red-500 active:dark:bg-red-700 canhover:active:bg-red-600 canhover:active:dark:bg-red-800 text-white;
    }

    &.inset,
    &.text {
      @apply text-red-500;
    }

    &.inset {
      @apply border border-red-500 border-solid;
    }

    .wave {
      @apply text-red-500 dark:text-red-500;
    }
  }

  &.success {
    @apply bg-green-500 dark:bg-green-600;

    &:not(.text, .inset) {
      @apply canhover:hover:bg-green-500 canhover:hover:dark:bg-green-700 active:bg-green-500 active:dark:bg-green-700 canhover:active:bg-green-600 canhover:active:dark:bg-green-800 text-white;
    }

    &.inset,
    &.text {
      @apply text-green-500;
    }

    &.inset {
      @apply border border-green-500 border-solid;
    }

    .wave {
      @apply text-green-500 dark:text-green-500;
    }
  }

  &.inset,
  &.text {
    @apply bg-opacity-0 dark:bg-opacity-0 canhover:hover:bg-opacity-30 canhover:dark:hover:bg-opacity-30 active:bg-opacity-40 active:dark:bg-opacity-40 canhover:active:bg-opacity-50 canhover:dark:active:bg-opacity-50;
  }

  &.pill {
    @apply rounded-full;

    .wave {
      @apply rounded-full;
    }
  }

  &.tile {
    @apply rounded-none;

    .wave {
      @apply rounded-none;
    }
  }

  &.small {
    @apply text-xs sm:text-sm p-1;
  }

  &.blockButton {
    display: flex;
    @apply text-center w-full h-full;
  }

  &.iconRight {
    @apply flex-row-reverse;
  }

  &.hideTextOnSmallScreen:not(.iconOnly) {
    &:not(.iconRight) .icon {
      @apply sm:mr-1;
    }

    &.iconRight.icon {
      @apply sm:ml-1;
    }
  }

  &:not(.iconOnly):not(.hideTextOnSmallScreen) {
    &:not(.iconRight) .icon {
      @apply mr-1;
    }

    &.iconRight .icon {
      @apply ml-1;
    }
  }

  .buttonText {
    @apply my-auto;
  }

  .expandText {
    @apply flex-grow;
  }

  &.hideTextOnSmallScreen:not(.forceIconOnly) {
    .buttonText {
      @apply hidden sm:block;
    }
  }

  &.forceIconOnly {
    .buttonText {
      @apply hidden;
    }
  }
}

.wave {
  animation-name: expandGlow;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.5, 1);

  mask-image: transparent;

  @apply rounded-md pointer-events-none absolute top-0 left-0 z-0 w-full h-full;
}

@keyframes expandGlow {
  from {
    box-shadow: 0 0 0 0 currentColor;
    transform: scaleY(100%);
  }

  25% {
    box-shadow: 0 0 4px 2px currentColor;
    transform: scale(100%);
  }

  to {
    box-shadow: 0 0 0 0 currentColor;
    transform: scale(103%);
  }
}
</style>

import { defineStore } from "pinia";
import {
  Settings,
  SettingsSchema,
  SettingsTemplate,
  type AvailableLocales,
  type SettingsAttributes,
  type SettingsSchemaType,
} from "~/src/api";
import { getDefaultSettings } from "~/utils/static";
import { assert, is, pattern, string } from "superstruct";
import isEqual from "deep-eql";
import { User } from "oidc-client-ts";
import { nanoid } from "nanoid";
import { migrateQualitySetting } from "~/utils/migration";

// Persist guest settings for 1 day
const guestSettingsPersistenceDuration = 1000 * 60 * 60 * 24;

const uuidRegex =
  "([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";

const chatRegexes = {
  entry: new RegExp(
    "Greetings, tab neighbors! New tab here... is anyone leading the settings synchronization operation?",
  ),
  leaderIdResponse: new RegExp(
    `Welcome, newcomer! I lead the operation here. My id is: ${uuidRegex}`,
  ),
  newLeaderIdResponse: new RegExp(
    `Hey, tab neighbors! New leader here, my id is: ${uuidRegex}`,
  ),
  leaderExistsQuestion: new RegExp("Helloooo, is the leader here??"),
  leaderExistsResponse: new RegExp(`Yeah! Leader is alive and well!`),
};

const cookieMaxAge = 7 * 24 * 60 * 60; // the time here is in seconds, save for 1 week
const setStoredSettings = (settings: SettingsSchemaType, loggedIn = false) => {
  const cookieOptions = {
    maxAge: loggedIn ? cookieMaxAge : guestSettingsPersistenceDuration / 1000,
    path: "/",
  };

  const lang = useCookie("PREF_LANG", cookieOptions);
  const theme = useCookie("PREF_THEME", cookieOptions);

  setDefaultLinkLocale(settings.site.locale);

  lang.value = settings.site.locale;
  theme.value = settings.site.theme;
};

class SettingsSynchronizer {
  getUserFunction: () => Promise<User | null>;
  settingsSyncTimer: NodeJS.Timeout | null;
  iAmLeader: boolean;
  leaderAbsent: boolean;
  leaderId: string | null;
  leaderPingTimer: NodeJS.Timeout | null;
  settings: SettingsSchemaType;
  id: string;
  loggedIn: boolean;

  constructor() {
    this.getUserFunction = async () => null;
    this.settingsSyncTimer = null;
    this.iAmLeader = false;
    this.leaderAbsent = false;
    this.leaderId = null;
    this.leaderPingTimer = null;
    this.loggedIn = false;

    this.settings = getDefaultSettings().data;

    this.id = nanoid();
  }

  async sync() {
    let settings = localStorage.getItem("namicomi.settings");
    let guestSettings = localStorage.getItem("namicomi.settings.guest");
    const guestSettingsCreatedAt = localStorage.getItem(
      "namicomi.settings.guest.createdAt",
    );
    const settingsVersion = localStorage.getItem("namicomi.settings.version");

    const user = await this.getUserFunction();
    this.loggedIn = !!user;

    const defaultSettings = getDefaultSettings();

    const route = useRoute();

    const preferredLocale =
      route.query.preferredLocale ?? route.params.locale ?? "en";
    defaultSettings.data.site.locale = preferredLocale as AvailableLocales;

    // We're not logged in...
    if (!user) {
      console.debug("[settings]: Not logged in.");
      if (
        !guestSettings ||
        !guestSettingsCreatedAt ||
        !is(guestSettingsCreatedAt, pattern(string(), /\d+/))
      ) {
        // And it seems we don't have any settings created, or user deleted them, or they're malformed

        // Go to defaults
        this.settings = defaultSettings.data;
        localStorage.setItem(
          "namicomi.settings.guest",
          JSON.stringify(this.settings),
        );
        localStorage.setItem(
          "namicomi.settings.guest.createdAt",
          Date.now().toString(),
        );
      } else {
        // If the settings are created (aka the createdAt is a numeric string)
        if (
          parseInt(guestSettingsCreatedAt) + guestSettingsPersistenceDuration <
          Date.now()
        ) {
          // If more than the guest settings persistence duration has passed since then
          // Reset settings
          this.settings = defaultSettings.data;
          localStorage.setItem(
            "namicomi.settings.guest",
            JSON.stringify(this.settings),
          );
          localStorage.setItem(
            "namicomi.settings.guest.createdAt",
            Date.now().toString(),
          );
        } else {
          // No more than that duration has passed. Validate settings.
          try {
            guestSettings = JSON.parse(guestSettings);
          } catch (err) {
            console.debug(
              "Settings on local storage was not an parseable object",
            );
          }

          if (!is(guestSettings, SettingsSchema)) {
            // If our guest settings don't match the schema, use defaults
            this.settings = defaultSettings.data;
            localStorage.setItem(
              "namicomi.settings.guest",
              JSON.stringify(this.settings),
            );
            localStorage.setItem(
              "namicomi.settings.guest.createdAt",
              Date.now().toString(),
            );
          } else {
            // Otherwise, use them
            this.settings = guestSettings;
          }
        }
      }

      return;
    }

    const userId = user.profile.sub;
    const userToken = user.access_token;

    // We're logged in. Let's also fetch our settings from the server.
    const remoteSettings = await Settings.get(userId, userToken)
      // But for some reason that failed...
      .catch(() => null);

    if (!settings) {
      console.debug("[settings]: No local settings found.");
      // No local settings found, let's check if server has any.
      if (!remoteSettings) {
        // Looks like server is empty too. Let's go to defaults.
        const template = new SettingsTemplate(defaultSettings);

        const remoteResponse = await Settings.create(
          userId,
          template,
          userToken,
        );
        this.settings = remoteResponse.settings.attributes.data;

        // OK, server is synced, let's also save the settings locally
        localStorage.setItem(
          "namicomi.settings",
          JSON.stringify(remoteResponse.settings.attributes.data),
        );
        localStorage.setItem(
          "namicomi.settings.version",
          remoteResponse.settings.attributes.version.toString(),
        );

        return;
      } else {
        // The server has data, nice. Let's also save the settings locally
        this.settings = remoteSettings.settings.attributes.data;
        localStorage.setItem(
          "namicomi.settings",
          JSON.stringify(remoteSettings.settings.attributes.data),
        );
        localStorage.setItem(
          "namicomi.settings.version",
          remoteSettings.settings.attributes.version.toString(),
        );
      }

      return;
    }

    // Time to convert our settings string to an object for deep validation
    try {
      settings = JSON.parse(settings);
    } catch (err) {
      console.debug("Settings on local storage was not an parseable object");
    }

    if (!is(settings, SettingsSchema)) {
      console.debug("[settings]: Settings do not match the schema.");
      // Our locally-saved settings don't match the schema.

      if (!remoteSettings) {
        // And there's no settings on the server. Let's go to defaults
        const template = new SettingsTemplate(defaultSettings);

        const remoteResponse = await Settings.create(
          userId,
          template,
          userToken,
        );
        this.settings = remoteResponse.settings.attributes.data;
        // And save them locally
        localStorage.setItem(
          "namicomi.settings",
          JSON.stringify(remoteResponse.settings.attributes.data),
        );
        localStorage.setItem(
          "namicomi.settings.version",
          remoteResponse.settings.attributes.version.toString(),
        );
      } else {
        if (!is(remoteSettings.settings.attributes.data, SettingsSchema)) {
          // We have server settings but they don't match the schema! Probably due to a schema update.
          // So fallback to defaults
          console.debug(
            "[settings]: Remote settings do not match the schema. Fallbacking to defaults",
          );

          try {
            assert(remoteSettings.settings.attributes.data, SettingsSchema);
          } catch (err) {
            console.error(err);
          }

          const template = new SettingsTemplate(defaultSettings);
          const remoteResponse = await remoteSettings.update(
            template,
            userToken,
          );

          this.settings = remoteResponse.settings.attributes.data;

          localStorage.setItem(
            "namicomi.settings",
            JSON.stringify(remoteResponse.settings.attributes.data),
          );
          localStorage.setItem(
            "namicomi.settings.version",
            remoteResponse.settings.attributes.version.toString(),
          );
        } else {
          // Server settings are valid, so let's use them
          this.settings = remoteSettings.settings.attributes.data;
          // And save them locally
          localStorage.setItem(
            "namicomi.settings",
            JSON.stringify(remoteSettings.settings.attributes.data),
          );
          localStorage.setItem(
            "namicomi.settings.version",
            remoteSettings.settings.attributes.version.toString(),
          );
        }
      }

      return;
    }

    if (!remoteSettings) {
      console.debug("[settings] - No remote settings");
      // Our local settings match the schema, but the server has no data. IDK how this happened, but let's send them over.
      const remoteResponse = await Settings.create(
        userId,
        new SettingsTemplate({ data: settings }),
        userToken,
      );
      this.settings = remoteResponse.settings.attributes.data;
      // And save them locally
      localStorage.setItem(
        "namicomi.settings",
        JSON.stringify(remoteResponse.settings.attributes.data),
      );
      localStorage.setItem(
        "namicomi.settings.version",
        remoteResponse.settings.attributes.version.toString(),
      );

      return;
    }

    // We have locally saved settings, and the server also has data. Let's compare.

    if (isEqual(settings, remoteSettings.settings.attributes.data)) {
      console.debug("[settings] - Settings equal with server");
      // So we're synced with the server, nice. Let's synchronize our versions just in case
      localStorage.setItem(
        "namicomi.settings.version",
        new Date(remoteSettings.settings.attributes.version)
          .valueOf()
          .toString(),
      );
      this.settings = settings;
    } else {
      // Settings exist on server, but we're not synced... Let's compare versions.

      if (!settingsVersion || !is(settingsVersion, pattern(string(), /\d+/))) {
        // If our version value is malformed somehow, use the server's settings.
        this.settings = remoteSettings.settings.attributes.data;
        // And save them locally
        localStorage.setItem(
          "namicomi.settings",
          JSON.stringify(remoteSettings.settings.attributes.data),
        );
        localStorage.setItem(
          "namicomi.settings.version",
          remoteSettings.settings.attributes.version.toString(),
        );

        return;
      }

      const remoteVersion = new Date(
        remoteSettings.settings.attributes.version,
      ).valueOf();

      if (remoteVersion > parseInt(settingsVersion)) {
        // If the server is ahead of us, use the server's settings
        this.settings = remoteSettings.settings.attributes.data;
        // And save them locally
        localStorage.setItem(
          "namicomi.settings",
          JSON.stringify(remoteSettings.settings.attributes.data),
        );
        localStorage.setItem(
          "namicomi.settings.version",
          remoteSettings.settings.attributes.version.toString(),
        );
      } else {
        // Else, if we are ahead of the server, update them on the server
        const remoteResponse = await Settings.update(
          userId,
          new SettingsTemplate({ data: settings }),
          remoteVersion,
          userToken,
        );
        this.settings = remoteResponse.settings.attributes.data;
        localStorage.setItem(
          "namicomi.settings.version",
          remoteResponse.settings.attributes.version.toString(),
        );
      }
    }
  }
}

export const useSettingsStore = defineStore({
  id: "settings",
  state: () => {
    // Before I report my presence, I need to have my trackers ready!
    const synchronizer = new SettingsSynchronizer();

    const channel = new BroadcastChannel(
      "namicomi-settings-sync-communication",
    );

    // First I'll make sure I can handle any type of query...
    // I never know what may come my way!
    channel.onmessage = (event) => {
      console.debug(`[${synchronizer.id}] Received message: ${event.data}`);

      if (chatRegexes.entry.test(event.data)) {
        // Oh-ho! New tab friend has joined us!

        if (synchronizer.iAmLeader) {
          // I am the leader, I must report them my id.
          console.debug(
            `[${synchronizer.id}] Sending message message: Welcome, newcomer! I lead the operation here. My id is: ${synchronizer.id}`,
          );
          channel.postMessage(
            `Welcome, newcomer! I lead the operation here. My id is: ${synchronizer.id}`,
          );
        }
      } else if (chatRegexes.leaderIdResponse.test(event.data)) {
        // Leader responded with id. Nice. Let's store it.
        const id = (event.data as string)
          .match(chatRegexes.leaderIdResponse)!
          .at(1)!;

        synchronizer.leaderId = id;
        synchronizer.leaderAbsent = false;
      } else if (chatRegexes.newLeaderIdResponse.test(event.data)) {
        // New leader? Great to have you! Let's store your id.
        const id = (event.data as string)
          .match(chatRegexes.newLeaderIdResponse)!
          .at(1)!;

        synchronizer.leaderId = id;
        synchronizer.leaderAbsent = false;
      } else if (chatRegexes.leaderExistsQuestion.test(event.data)) {
        // Routine leader status check.

        if (synchronizer.iAmLeader) {
          // I am the leader, let's respond that I am active.
          console.debug(
            `[${synchronizer.id}] Sending message message: Yeah! Leader is alive and well!`,
          );
          channel.postMessage("Yeah! Leader is alive and well!");
        }
      } else if (chatRegexes.leaderExistsResponse.test(event.data)) {
        // OK, leader responded. Phew... Let's not assume they're gone, then.
        synchronizer.leaderAbsent = false;
      }
    };

    // Great! We're all set up, time to find any leaders.
    channel.postMessage(
      "Greetings, tab neighbors! New tab here... is anyone leading the settings synchronization operation?",
    );
    // OK, message sent! Let's wait 1 second for the reply.
    setTimeout(() => {
      if (synchronizer.leaderId === null) {
        // Hmm, no response... there must be no leader then.
        // Alright, I'm going to take on the leader position!

        // Let's inform other tabs that may have joined while I was waiting.
        // Timing can be impeccable sometimes!
        channel.postMessage(
          `Hey, tab neighbors! New leader here, my id is: ${synchronizer.id}`,
        );
        // And update my parameters! Very important!
        synchronizer.leaderId = synchronizer.id;
        synchronizer.iAmLeader = true;

        // if (!synchronizer.settingsSyncTimer) {
        //   synchronizer.settingsSyncTimer = setInterval(() => {
        //     synchronizer.sync()
        //   }, 1000 * 60 * 5)
        // }
      } else {
        // Great! We have a leader! Let's check in on them every 10 seconds!
        synchronizer.leaderPingTimer = setInterval(() => {
          if (synchronizer.leaderAbsent) {
            // So the leader was absent after all?!
            // Oh no. I'm the first to discover this, right...?
            // Of course! Otherwise, I would've been informed of a new leader. What to do...
            // I must take on the leader position to prevent any confusion!

            // First stop my check-ins to the leader; they're not here anymore...
            clearInterval(synchronizer.leaderPingTimer!);
            synchronizer.leaderPingTimer = null;

            // And then... what else...
            // Oh right! Let's inform the other tabs of this change in leader!
            channel.postMessage(
              `Hey, tab neighbors! New leader here, my id is: ${synchronizer.id}`,
            );

            // And change my notes accordingly.
            // I am the leader now, and I have the responsibility to keep things in order.
            synchronizer.leaderId = synchronizer.id;
            synchronizer.iAmLeader = true;

            // if (!synchronizer.settingsSyncTimer) {
            //   synchronizer.settingsSyncTimer = setInterval(() => {
            //     synchronizer.sync()
            //   }, 1000 * 60 * 5)
            // }

            return;
          }

          channel.postMessage(`Helloooo, is the leader here?`);
          // Unless they reply, let's assume that they're absent.
          synchronizer.leaderAbsent = true;
        }, 10000);
      }
    }, 1000);

    const currentProfile = localStorage.getItem("namicomi.settings.profile");

    return {
      synchronizer,
      settings: synchronizer.settings,
      currentProfile: currentProfile,
      initialized: false,
    };
  },
  actions: {
    async initialize(getUserFunction: () => Promise<User | null>) {
      this.initialized = false;

      const nuxtApp = useNuxtApp();
      const route = useRoute();

      const localeLang = useCookie("PREF_LANG");

      // this gets determined by middleware
      const finalLang = getSafeLocale(localeLang.value ?? route.params.locale);
      nuxtApp.$i18n.global.locale.value = localeMap(
        (route.params.locale as AvailableLocales) ?? finalLang,
      );

      // We want our OIDC getUser function here because we want to make sure our tokens are updated
      this.synchronizer.getUserFunction = getUserFunction;
      await this.synchronizer.sync();

      this.synchronizer.settings.site.locale = finalLang;
      this.settings = this.synchronizer.settings;
      setStoredSettings(this.settings, this.synchronizer.loggedIn);
      this.initialized = true;

      if (this.synchronizer.settingsSyncTimer || !this.synchronizer.iAmLeader)
        return;

      // this.synchronizer.settingsSyncTimer = setInterval(async () => {
      //   this.synchronizer.sync()
      // }, 1000 * 60 * 5)
    },

    async waitForInitialization() {
      if (this.initialized) {
        return;
      }

      return new Promise<void>((resolve) => {
        const timer = setInterval(() => {
          if (!this.initialized) {
            return;
          }

          clearInterval(timer);
          resolve();
        }, 50);
      });
    },

    stopSyncing() {
      if (this.synchronizer.settingsSyncTimer) {
        clearInterval(this.synchronizer.settingsSyncTimer);
        this.synchronizer.settingsSyncTimer = null;
      }
    },

    async update(settings: SettingsAttributes["data"]) {
      const nuxtApp = useNuxtApp();
      const user = await this.synchronizer.getUserFunction();
      nuxtApp.$i18n.global.locale.value = settings.site.locale;
      setStoredSettings(this.settings, !!user);

      if (!user) {
        this.settings = settings;
        localStorage.setItem(
          "namicomi.settings.guest",
          JSON.stringify(settings),
        );

        return;
      }

      const userId = user.profile.sub;
      const userToken = user.access_token;

      const currentSettings = await Settings.get(userId, userToken);

      settings.site.quality = migrateQualitySetting(settings.site.quality);

      if (!currentSettings) {
        // No settings on the server? No problem, create them.
        const newSettings = await Settings.create(
          userId,
          new SettingsTemplate({ data: settings }),
          userToken,
        );
        localStorage.setItem(
          "namicomi.settings",
          JSON.stringify(newSettings.settings.attributes.data),
        );
        localStorage.setItem(
          "namicomi.settings.version",
          newSettings.settings.attributes.version.toString(),
        );

        setStoredSettings(newSettings.settings.attributes.data, !!user);

        return;
      }

      const newSettings = await Settings.update(
        userId,
        new SettingsTemplate({ data: settings }),
        currentSettings.settings.attributes.version,
        userToken,
      );
      localStorage.setItem(
        "namicomi.settings",
        JSON.stringify(newSettings.settings.attributes.data),
      );
      localStorage.setItem(
        "namicomi.settings.version",
        newSettings.settings.attributes.version.toString(),
      );
      setStoredSettings(newSettings.settings.attributes.data, !!user);
    },

    setCurrentProfile(profile: string) {
      this.currentProfile = profile;
      localStorage.setItem("namicomi.settings.profile", profile);
    },
  },
});


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutL0J5koWhWzBPaN031zxSMparvhuI_Pb0SSHCxeyyVZQMeta } from "/builds/namicomi/frontend/pages/[locale]/about.vue?macro=true";
import { default as _91id_93cjwglZCOh5YqxsuxzIHV4FQPAmoxgF_45FMfdi5L9TpdUMeta } from "/builds/namicomi/frontend/pages/[locale]/callback/account/refresh/[id].vue?macro=true";
import { default as _91id_930AIC5YJEjYhgTTeK6Y3j07QTi_caSKd4TyL4qZGQiqAMeta } from "/builds/namicomi/frontend/pages/[locale]/callback/account/return/[id].vue?macro=true";
import { default as connectjx_45icpw8gWGgeXkLfBF_45mlDygYwofnYb36NStH3PHiAMeta } from "/builds/namicomi/frontend/pages/[locale]/callback/paypal/connect.vue?macro=true";
import { default as signinGHqwZTl1JvyX_45q1ubuMNyMEORllYWG5GS_45KGLYbCkUYMeta } from "/builds/namicomi/frontend/pages/[locale]/callback/signin.vue?macro=true";
import { default as _91id_93ai1exyWelF2j1b6x2IUPczQVdk9C80kq0vtSpGqsneIMeta } from "/builds/namicomi/frontend/pages/[locale]/callback/subscription/[status]/[id].vue?macro=true";
import { default as _91id_93f1V2HMXTX_ks7dY2k4he7HIk9CXE__MQUEpDrZ3M4tIMeta } from "/builds/namicomi/frontend/pages/[locale]/chapter/[id].vue?macro=true";
import { default as chapterNEvpSRur7NwlHlFVapthROgtP84ZLQpXX60vkVBYYw0Meta } from "/builds/namicomi/frontend/pages/[locale]/chapter.vue?macro=true";
import { default as feedbackcjMNigA4llvQAXSEK9xqH8c4S_MvKa1bMOT4GEd2wxQMeta } from "/builds/namicomi/frontend/pages/[locale]/contact/feedback.vue?macro=true";
import { default as supportYBC7YDoMQrVG6_YctdT4WcLAAz8NOu477N3IiPEWo4AMeta } from "/builds/namicomi/frontend/pages/[locale]/contact/support.vue?macro=true";
import { default as contactZSc89suFXm9nF8vHYovSNzfaJr0CfnQXTVQpCY6XxC0Meta } from "/builds/namicomi/frontend/pages/[locale]/contact.vue?macro=true";
import { default as _91slug_93Og_CgVfWfbzBwPJF3ObWdL_d7GYiGzhDZ8AunAdRhKIMeta } from "/builds/namicomi/frontend/pages/[locale]/contest/information/[id]/[slug].vue?macro=true";
import { default as aboutIlVfEvo32wss489UaV4GcbAIOWJrcLb3Jv2hYJ7KmfEMeta } from "/builds/namicomi/frontend/pages/[locale]/contest/overview/about.vue?macro=true";
import { default as indexZtnkOc7wki_45ZDmLvCrmv8ypk3zCb3xkPcZmQkykReHMMeta } from "/builds/namicomi/frontend/pages/[locale]/contest/overview/index.vue?macro=true";
import { default as rankingsFqCaL1YycBCDyxm8qGuskvq9J_45r0rOip2Z8VSbun8CQMeta } from "/builds/namicomi/frontend/pages/[locale]/contest/overview/rankings.vue?macro=true";
import { default as winnersPbCer6qqTskWU5_rZWRRZcXPJq0koFFhNBgzaaZIOZsMeta } from "/builds/namicomi/frontend/pages/[locale]/contest/overview/winners.vue?macro=true";
import { default as overview5Q08eMYAdBin4bfglXRuzI9mgBkAZW_YAl1Jb9JyFP4Meta } from "/builds/namicomi/frontend/pages/[locale]/contest/overview.vue?macro=true";
import { default as creatorsCzwNFpkiHhW3QXShKtB5SCqbNkXBHMhB52qxHw6vq3QMeta } from "/builds/namicomi/frontend/pages/[locale]/creators.vue?macro=true";
import { default as _91tag_93cwq41k_w_8WrYDGfsF8LLhd0906L5yum1p2X_mKwD9YMeta } from "/builds/namicomi/frontend/pages/[locale]/discover/[tag].vue?macro=true";
import { default as indexkPpVul_45RU1htdJiV_8rO_iRB8tSX7XGE29WPYWsZjEcMeta } from "/builds/namicomi/frontend/pages/[locale]/discover/index.vue?macro=true";
import { default as fansp9s38X5c87GU67yp3zEPgB27v9lVeUfTmz8VhPs_0i4Meta } from "/builds/namicomi/frontend/pages/[locale]/fans.vue?macro=true";
import { default as contestsiTBPjPqVtSH_HOHZHf3F_459wcAhC7tRAZBViGSf682FkMeta } from "/builds/namicomi/frontend/pages/[locale]/faq/contests.vue?macro=true";
import { default as creatorshutDJCtqbRbN2Ovz9ZTpT0NWUjMs0N4DoU_45Qqivz5v8Meta } from "/builds/namicomi/frontend/pages/[locale]/faq/creators.vue?macro=true";
import { default as general1p8isDszVCuuhquupuf_45nnOJOTzwlGdsdLEPa_45oLINUMeta } from "/builds/namicomi/frontend/pages/[locale]/faq/general.vue?macro=true";
import { default as readersQmPRkpDG8qQXxDJVmc6ScjP41BmPCF1aBKdRwEgiRc4Meta } from "/builds/namicomi/frontend/pages/[locale]/faq/readers.vue?macro=true";
import { default as faq_45qA4gw58xgavJGGpp_aWY3_intKLKY7KFfid3XkH_4508Meta } from "/builds/namicomi/frontend/pages/[locale]/faq.vue?macro=true";
import { default as followingYOuX2oSGXs_45riOFrl_456ntmER_lwvtXA_NoDDfAHDUuwMeta } from "/builds/namicomi/frontend/pages/[locale]/feed/following.vue?macro=true";
import { default as indexLp9hsT0Gy2Olo_45trQ66vMgt_45hxIwQz3vSCibTTEJiyoMeta } from "/builds/namicomi/frontend/pages/[locale]/feed/index.vue?macro=true";
import { default as feedIVJWSMvwjeixBhIZMctTENHox0W63JIRU0Nel4TNtkYMeta } from "/builds/namicomi/frontend/pages/[locale]/feed.vue?macro=true";
import { default as indexjQCsVDfxtL_45d6NEWzG9dC5A5HlURNSuMgnQEBQMNtiMMeta } from "/builds/namicomi/frontend/pages/[locale]/index.vue?macro=true";
import { default as followsuASe8dphALHR6Ea1zHm_45VMVypTfd1LEgklf_45knN9OikMeta } from "/builds/namicomi/frontend/pages/[locale]/library/follows.vue?macro=true";
import { default as historyKv9x5_wuFvITHKwuSqAm1nelwshmXUiBX0aKQbbHXIgMeta } from "/builds/namicomi/frontend/pages/[locale]/library/history.vue?macro=true";
import { default as index7vr35XimFAYSZWXZ2E_45THsPPB03YqFGeADJMikMkC0cMeta } from "/builds/namicomi/frontend/pages/[locale]/library/index.vue?macro=true";
import { default as listsN8WCslCyWAF_8Za8Lxdhy6wnbjMDjT0FPrBPwlwGktwMeta } from "/builds/namicomi/frontend/pages/[locale]/library/lists.vue?macro=true";
import { default as _91status_93R6KpysfxJf8uNIIYJa8mWh0fyrcBS30OXpvN64ONihQMeta } from "/builds/namicomi/frontend/pages/[locale]/library/status/[status].vue?macro=true";
import { default as statussy_45Ci6IrwH24H3vyLDln0f6nJeReyi9qtZL66CLEmkgMeta } from "/builds/namicomi/frontend/pages/[locale]/library/status.vue?macro=true";
import { default as library76kNaPpDuIv5sn3Ei99DSXLBUAhOaDSxOaoivIWEYa8Meta } from "/builds/namicomi/frontend/pages/[locale]/library.vue?macro=true";
import { default as _91id_93Dgz4NgmXUXak3ZUDZ9sIdtT5BKpLktKII_vSkGs9w4MMeta } from "/builds/namicomi/frontend/pages/[locale]/list/[id].vue?macro=true";
import { default as createJEngtUhkDlihgGUeOTo6Kh_LicxJ4CCS41qf0oXv5ogMeta } from "/builds/namicomi/frontend/pages/[locale]/list/create.vue?macro=true";
import { default as _91id_93kmC6HutaR8eqP64JQL9Mnvo12_Hxv4PVxdEAI4X9uDgMeta } from "/builds/namicomi/frontend/pages/[locale]/list/edit/[id].vue?macro=true";
import { default as _91id_93ovf_450QvA_45D_45K3_45pf2hfD8f98goP_4525aRO4r0oNcU_45zgMeta } from "/builds/namicomi/frontend/pages/[locale]/messages/[id].vue?macro=true";
import { default as messages8n40I59z4AKkRmmCXfrNRv_OEQZd_K7HiHW_45vt6xHeYMeta } from "/builds/namicomi/frontend/pages/[locale]/messages.vue?macro=true";
import { default as _91id_93urxVjtVmt24xStUHs9idPT_457AVmX_45v5HjTrsfi5_453DMMeta } from "/builds/namicomi/frontend/pages/[locale]/news/[id].vue?macro=true";
import { default as _91slug_93aAAVdiZGCI90jFCTpbGC0o_45Pz_45DSAVCtH0vLM2Av46AMeta } from "/builds/namicomi/frontend/pages/[locale]/news/article/[id]/[slug].vue?macro=true";
import { default as _91slug_93FhenhBLX4LoTbz3TJB6KnDMc3Wvl78KiBvH7dqDOJq0Meta } from "/builds/namicomi/frontend/pages/[locale]/news/category/[slug].vue?macro=true";
import { default as newsNCa_LdM_LK8cqixZ_QBW4ZtozlXwn54NAGEOGnv7UD0Meta } from "/builds/namicomi/frontend/pages/[locale]/news.vue?macro=true";
import { default as notificationswvvEtWtevGDN43pzoJM1YtWEbYHLHPFmYQG0cSIsr7wMeta } from "/builds/namicomi/frontend/pages/[locale]/notifications.vue?macro=true";
import { default as achievementsEsRVAUbWVlBHtPbngxhpLWnn3cwJY5buaUCQriCc0pwMeta } from "/builds/namicomi/frontend/pages/[locale]/org/[slug]/achievements.vue?macro=true";
import { default as followers7_45XtkPvxIsiKMBTeEorT2lLL_451wToG8GPx4DH_45KmgOEMeta } from "/builds/namicomi/frontend/pages/[locale]/org/[slug]/followers.vue?macro=true";
import { default as followingWAcjPOj8OUAYv3_DH65gPL3wpkURoT6x8PtLEuZQ_jIMeta } from "/builds/namicomi/frontend/pages/[locale]/org/[slug]/following.vue?macro=true";
import { default as homeHCJxurquYrbV4XbqJmsoQWt2mAg4QvPrtvNiMQx8WX8Meta } from "/builds/namicomi/frontend/pages/[locale]/org/[slug]/home.vue?macro=true";
import { default as indexBHQE22pDLu16QqYuVSHhwmV_QMNRRXf8cu8dF7CX1t8Meta } from "/builds/namicomi/frontend/pages/[locale]/org/[slug]/index.vue?macro=true";
import { default as rewards4eE0AA3QnlyvvUWWZ_457mNiOubSY0wtf93Qko5g7UhloMeta } from "/builds/namicomi/frontend/pages/[locale]/org/[slug]/rewards.vue?macro=true";
import { default as subscriptionsUkrqDkG4T5_45B1gqc4DBehOLNK9UgWE7sYc5hDaPsaVgMeta } from "/builds/namicomi/frontend/pages/[locale]/org/[slug]/subscriptions.vue?macro=true";
import { default as titles7dNNoIGLI4DkTx6iGF654fjz7iYyhosGcS0BsjyS8E4Meta } from "/builds/namicomi/frontend/pages/[locale]/org/[slug]/titles.vue?macro=true";
import { default as _91slug_93YqnmsR_45wzsRFGl_L4N8vSY1zRojG4wWpfLb5QLwNLoUMeta } from "/builds/namicomi/frontend/pages/[locale]/org/[slug].vue?macro=true";
import { default as _91id_93_45_45eJ50Xw3VCsMm8a9ph9A7VMduwt_zLX1_45Xx37pAR4UMeta } from "/builds/namicomi/frontend/pages/[locale]/post/[id].vue?macro=true";
import { default as makemoneyIXYEInC2A5zXYp1ZT7y8QRxrYOwzdtEtWSyYV_KD_45rMMeta } from "/builds/namicomi/frontend/pages/[locale]/publish/makemoney.vue?macro=true";
import { default as overviewh1CtmlKhxOVFIH_u7ePtVHR0y6Y_457jQ1UtPkybCgPWIMeta } from "/builds/namicomi/frontend/pages/[locale]/publish/overview.vue?macro=true";
import { default as resourcesImd0v2vg8HqkF9SOMNKC2lgxCGoi_45iH2uN3qfyagp2EMeta } from "/builds/namicomi/frontend/pages/[locale]/publish/resources.vue?macro=true";
import { default as publishtLs4TSvxebAZH8eliPDi_bcIatUBKFShrRxb9_hFLU0Meta } from "/builds/namicomi/frontend/pages/[locale]/publish.vue?macro=true";
import { default as account_45preferencesXwDUjYZYguF3rOyemFH_JyOVxjOWDsOrSx43iw_450kAcMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/account-preferences.vue?macro=true";
import { default as device_45managementBHjIetFxdllolIMAR0wOx4y5jejm2Gw2pU8uo03EoMAMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/account/device-management.vue?macro=true";
import { default as emailTA9zt4aCHHVuMieH3tcj_45_45XSzdxYhQr6168YJKRzbisMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/account/email.vue?macro=true";
import { default as indexhu_458Oz6CHSfY5M5f2o_45gugXE6s6bIhPBLKnydKXHCwMMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/account/index.vue?macro=true";
import { default as password8v0GQnIfTVmgGVse56Lbx3xpKzCIiW8aTXSaAwxCVv4Meta } from "/builds/namicomi/frontend/pages/[locale]/settings/account/password.vue?macro=true";
import { default as two_45factorN8Ou6zK8o0utpwYwx_8UlVZ_45Sl2JnA_DoLgKMwxC_SkMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/account/two-factor.vue?macro=true";
import { default as connected_45appsWSCXKkEFwfZtZhpOHX0Rj__45GyYxrmSqGCODxyw61jU8Meta } from "/builds/namicomi/frontend/pages/[locale]/settings/connected-apps.vue?macro=true";
import { default as indexHYrLUhoCSSANPHXXmeat_Oj5Lb2Q3clkdhym5p_y8UIMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/index.vue?macro=true";
import { default as languaget7QjTC0V63io2QZy5AoeFhWV5ifptDTUApRoALpOwQsMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/language.vue?macro=true";
import { default as notificationsjfKAEHtbrFPqLjOGl85Z2u4BBPb4TcI7Rpbzmcu5DosMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/notifications.vue?macro=true";
import { default as privacyT4a22w8_eo7l_45kRbOVbFYDAjNCebZIhFBxXQ0z70vKsMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/privacy.vue?macro=true";
import { default as profileXFBTpR_45qCIJI3hS8ZHIiIPdeEITCpTf2Jk5uIl7s2F0Meta } from "/builds/namicomi/frontend/pages/[locale]/settings/profile.vue?macro=true";
import { default as site_45preferencesgYWMRTbH63XBU7WTsA3BX4LrvKOboufdGfv1Wy72YYQMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/site-preferences.vue?macro=true";
import { default as active9YJ3dUJLblGeKdA7X94METzhMxWv59JwoaHyDuxmKhIMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/subscription/history/active.vue?macro=true";
import { default as cancelledno_45k0AsGITDB2iZrCPEUp_45ep_hZ5hiYVZ2IsRs8_i9EMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/subscription/history/cancelled.vue?macro=true";
import { default as index47HTAEIzPgrJOjGf9zzHMENgrMi_45DDNVYuvcQPKcfr8Meta } from "/builds/namicomi/frontend/pages/[locale]/settings/subscription/history/index.vue?macro=true";
import { default as pendingChlFfD6EOMeEypA7hCFHhd6asCz3dv0GSlWrBU9He2YMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/subscription/history/pending.vue?macro=true";
import { default as historysL7aby2gzTt5qKTRTQdSJSTZ1yYIkU_45t0ybaTlgtAHIMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/subscription/history.vue?macro=true";
import { default as indexXMru9m2wthMkP0_e5ECWJzhGKazUr8bd4Sl9Yqr2GGgMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/subscription/index.vue?macro=true";
import { default as paymenthlcGlHozu_4516_45h9M6CXKmauVEjxlRIBgLeDL1jAMuR0Meta } from "/builds/namicomi/frontend/pages/[locale]/settings/subscription/payment.vue?macro=true";
import { default as transaction_45historyNVtxsc5BkkBKBHACuEozl6JgC4wjQbq_45lNFmqgtVkiAMeta } from "/builds/namicomi/frontend/pages/[locale]/settings/subscription/transaction-history.vue?macro=true";
import { default as settings2ANnrO14pfUUFq40LBTGtPcUL6sgjiSgBH2Ztl1BROMMeta } from "/builds/namicomi/frontend/pages/[locale]/settings.vue?macro=true";
import { default as subscriptionsYUTaqhjGBDRI0qY_yo9FmTotxhflvjCeQV_nCoj2xukMeta } from "/builds/namicomi/frontend/pages/[locale]/subscriptions.vue?macro=true";
import { default as contentuywSL_ZCqx_6WqVuCk17jPBEiLowEpqWNaR1uiJgxMAMeta } from "/builds/namicomi/frontend/pages/[locale]/terms/content.vue?macro=true";
import { default as contestIMZ7Of_d7SzKwgpdkU6YAC5w3O_45XLmf2E4wg1ZKFQNIMeta } from "/builds/namicomi/frontend/pages/[locale]/terms/contest.vue?macro=true";
import { default as cookie_45KDcofL4_45LPa47qQorsmlsVQkbeAwOf95nNR8EzEzZMMeta } from "/builds/namicomi/frontend/pages/[locale]/terms/cookie.vue?macro=true";
import { default as creatorrewardsvRv5EueE3gV8YH29BF51pXhFAx68Hnw68ZwRqAedIE8Meta } from "/builds/namicomi/frontend/pages/[locale]/terms/creatorrewards.vue?macro=true";
import { default as dtiys4cyEmBsYBMzQi0L2ghFeDoj3a46mrh0ntJqsIufKfyEMeta } from "/builds/namicomi/frontend/pages/[locale]/terms/dtiys.vue?macro=true";
import { default as index95o2WRaPoTg8SWRyOop8nmk0cr_45WDKklbUUGINfj_y4Meta } from "/builds/namicomi/frontend/pages/[locale]/terms/index.vue?macro=true";
import { default as privacyPSMELOeiSXtNi4llAwCrq_45w1IiOVbztAprvthVoZzJEMeta } from "/builds/namicomi/frontend/pages/[locale]/terms/privacy.vue?macro=true";
import { default as site8npcuonewtl5ddFZn1ydgkcjirki9a_45ZeTQ9mj9zz6cMeta } from "/builds/namicomi/frontend/pages/[locale]/terms/site.vue?macro=true";
import { default as subscriptionsiW7cTLkFGOizWF5WdTyRMMCCbVPadtjJCOPzDxud228Meta } from "/builds/namicomi/frontend/pages/[locale]/terms/subscriptions.vue?macro=true";
import { default as termsL9emVLH7K7GqxH3tiT_mfYzRH_OkCsXk8Q5enOtcORUMeta } from "/builds/namicomi/frontend/pages/[locale]/terms.vue?macro=true";
import { default as attachlOgVPkMKqz8PqYlsj4ET7mMqmYyaD1DjV576FTuXvTMMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/attach.vue?macro=true";
import { default as comment_45formHFBLM4SXk0DxX7AOz98wepMW_77yuBhiJIUxq1aAj8AMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/comment-form.vue?macro=true";
import { default as comment_45contentV_4FNIM12IIAaG2z8hgrOsS1UfoMK0wA88O0xmN3o6cMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/comment/comment-content.vue?macro=true";
import { default as date_45month_45picker5fvvPCo7Dc2VvM6zjrhSZH46Kd6ZRQ3SWER8LMXyH1YMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/date-month-picker.vue?macro=true";
import { default as date_45pickerXNc3Lz1ESd7zzgHa_45gqCzpAEN4YFbKR2dfFGzU039I0Meta } from "/builds/namicomi/frontend/pages/[locale]/test/components/date-picker.vue?macro=true";
import { default as drag_45and_45dropJU8YQOce08Gew2WTgzuxOC6ax0yHNtkT0o2pypsLPLcMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/drag-and-drop.vue?macro=true";
import { default as emote_45composerhjjspwxzVH_45nidJEFrxDzeWkKVxgGEGlFPOq77jOPlgMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/emote-composer.vue?macro=true";
import { default as hover_45overlayJzic9V55PSMHUpukMT2_45EtMKr18VGtJmJ4UiGJv8S_45YMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/hover-overlay.vue?macro=true";
import { default as list_45dropdowne3PiVx8T9rPgXA_45yw_dAt9WC17ZKPtSdkm45wHRsQkMMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/list-dropdown.vue?macro=true";
import { default as markdown_brhc4AxtH_45vIAYa_454qRUHM_m8xXSBXheejrG28DHDAMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/markdown.vue?macro=true";
import { default as base3Uuhc5s_45mL35zaXs8kjsodHmJj88eYAobmWl_45kG9xogMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/base.vue?macro=true";
import { default as creator_45comment_45to_45chapterEz_45aAevi6MO3fGdH0Y6AY2xrPc_8bLHk326vsYEBJ6sMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/creator-comment-to-chapter.vue?macro=true";
import { default as creator_45comment_45to_45titleY_6pJ9opACD0eEFg7wzjk9Ag4Qg_45_w3RK_45x_piT4hyMMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/creator-comment-to-title.vue?macro=true";
import { default as creator_45reaction_45to_45chapterDlLqMXBXh99hklm2_4_45diNFKCiULZA8YWxQ9FpI2Ec8Meta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/creator-reaction-to-chapter.vue?macro=true";
import { default as creator_45title_45followLPmUAd_jZJGTJepAwussW4uNAJqO80KxEECnE_6w0XEMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/creator-title-follow.vue?macro=true";
import { default as new_45messageIQ46Xqi5DBV8M6ly5_45HQPaO5Lj6EDJB_45Zysv8jIZXo4Meta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/new-message.vue?macro=true";
import { default as official_45announcementGyrzJo8_45srkeQaelkcMWZl1DRDyQEtBNC37TA4ZVNQkMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/official-announcement.vue?macro=true";
import { default as payment_45organization_45subscriptionxXoKxnctri6ypgxTzFlVeGPhG_45QttCLITjsgEJWia9cMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/payment-organization-subscription.vue?macro=true";
import { default as reader_45chapter_45published_45to_45followed_45titlewrNVl5IURO2wBjysxDc8dRQpuel2s17lozq_I0llv_45gMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/reader-chapter-published-to-followed-title.vue?macro=true";
import { default as social_45new_45followD4HZtMRq3ks1XBuDfjsDUpiMJjcPU27k2F96rh_bLVIMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-new-follow.vue?macro=true";
import { default as social_45reaction_45to_45commentfg6iqBAN0vW29jmNopkm0gFWwYD3EP6HLAGX0I9Mx6UMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-reaction-to-comment.vue?macro=true";
import { default as social_45reaction_45to_45post2GDzadWDyOKgBz7ezo25FgyOdKlF4T3mOBp8xj5MqrwMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-reaction-to-post.vue?macro=true";
import { default as social_45reply_45to_45commentpHVkGgjuXWvuKxmfAyIGJ9EDAsxEZW9dgGliZxx2H50Meta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-reply-to-comment.vue?macro=true";
import { default as social_45reply_45to_45postQ7GevmcvX1e1hyi_yHFWDoqYmC38moax7qDgcw9Z8tYMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-reply-to-post.vue?macro=true";
import { default as social_45repost_45of_45postnn964zUA2nURSvOEFAhcccTT1QpKEZLbS1msAuCGA_45AMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-repost-of-post.vue?macro=true";
import { default as overlayRrw6oPcLuWM4pA_45vYPmdfRnWASbYW8TaexV1CaCSQzEMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/overlay.vue?macro=true";
import { default as radio_45group500Ix_XlK1n9H8UyqCxBTVWkNvwmKp3Vgjtd7DGx4woMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/radio-group.vue?macro=true";
import { default as rich_45editorJtr4ce4QCJ6_458JabSLnU5HAfYR2SnSQoJZRT0bCuzq0Meta } from "/builds/namicomi/frontend/pages/[locale]/test/components/rich-editor.vue?macro=true";
import { default as tableNQENNGRP5pzEjFoLKlI1ZHRimcDzdtXEdjaXQSTNNWcMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/table.vue?macro=true";
import { default as time_45pickeruu_45aPhg0vxKiH___HPexComrRJesPS_iDR_0mU6YECsMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/time-picker.vue?macro=true";
import { default as title_45createtvh_458z7fHcDCp8uYDb_45l9VDaLa_Fx0aM_HHso7zqdpYMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/title-create.vue?macro=true";
import { default as tooltipQEXR_azb0jc6Yc6FR0Xc_0g52Sn_45cofxubd_6hm0NNUMeta } from "/builds/namicomi/frontend/pages/[locale]/test/components/tooltip.vue?macro=true";
import { default as notification_45toastsid4_45w_jK7TDo2xYGr2AIPjZr9xAkudePgaqO5EOhLncMeta } from "/builds/namicomi/frontend/pages/[locale]/test/events/notification-toasts.vue?macro=true";
import { default as batcherJUdgPVokPaIqE48DO_u7nK4FL79_PkLBz1WxfoqHUjUMeta } from "/builds/namicomi/frontend/pages/[locale]/test/functions/batcher.vue?macro=true";
import { default as testi5l3dbIbh7n_45yHrcawiUkQPPObA5B1dehkXfl10xKDwMeta } from "/builds/namicomi/frontend/pages/[locale]/test.vue?macro=true";
import { default as art_wUQLvkiK2Bg99vTkObKY_ZT_45XXAVL9aqnv_45Y4QaLBkMeta } from "/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/art.vue?macro=true";
import { default as chaptersMy4jiMIPD3O3hCl0Nzlh2fFkxSxTh9KDQgj0j5WI27UMeta } from "/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/chapters.vue?macro=true";
import { default as _91volume_93xPwx5HXhYFKRDGrxpxp9AQkpex1WIu_t5NqVoFGBQ0EMeta } from "/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/collections/[volume].vue?macro=true";
import { default as indexBzHFUwhOlscXLDiZGIG_45t6c5SJuWclrGUK_DMGiwphUMeta } from "/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/collections/index.vue?macro=true";
import { default as commentsmR9RO7EnrNhoBHRaiV0cwzKHAqqFvpDN3h_45ku70AjxkMeta } from "/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/comments.vue?macro=true";
import { default as detailsE5oiOQwHWZb9RYg0JkMBlH8fQ30gJcNRDu6_jMF_lzcMeta } from "/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/details.vue?macro=true";
import { default as index2h2UvkK90YZrDce_455cdhMpYirZVZsdUtxpJeXlXUksgMeta } from "/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/index.vue?macro=true";
import { default as relationsC5l54vZs0_VBFP5mWYknpCRFafsDX3nbtVrRV82E2OcMeta } from "/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/relations.vue?macro=true";
import { default as reviewsJbqzaYAKHHbVQswhnm_45Y2d9Kn5WD37_45szQiWgli9WV0Meta } from "/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/reviews.vue?macro=true";
import { default as _91slug_93JB_B5ui822P4fjjFlclSJw0pwPDnMkQFAA8JuDtlqNsMeta } from "/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug].vue?macro=true";
import { default as indextzcpxIlsJ_45Ar_45mrOqMWb5VJkBAgF1XO1BXDhLhVvSxoMeta } from "/builds/namicomi/frontend/pages/[locale]/title/[id]/index.vue?macro=true";
import { default as searchnXn9MrsSroCNLmnJavLyWq7FT4gfYeA7VYUDQL2oK1MMeta } from "/builds/namicomi/frontend/pages/[locale]/title/search.vue?macro=true";
import { default as titlesP5AbMIorwBjfed9Ta6hIQJ_ZRD85y9JMhQZQhHVIEeIMeta } from "/builds/namicomi/frontend/pages/[locale]/titles.vue?macro=true";
import { default as followsNxxkagmvxZwJaHRjd7N_cf8MgYsO_45abaYktW7ShFgnEMeta } from "/builds/namicomi/frontend/pages/[locale]/updates/follows.vue?macro=true";
import { default as latestE7_45Ee2u5KECcO4XZwJ2CUJWlfc3loaSC_45XBCprdDEswMeta } from "/builds/namicomi/frontend/pages/[locale]/updates/latest.vue?macro=true";
import { default as updatesKDyLxOyr3Trm_45aICmccK2ic2kG_Ulq5lTq32Jum5h64Meta } from "/builds/namicomi/frontend/pages/[locale]/updates.vue?macro=true";
import { default as _91id_93tBgWOjCYLcGp7z0ql2s_rm_45eUs4H53t_Pq1f20heetoMeta } from "/builds/namicomi/frontend/pages/[locale]/user/_/[id].vue?macro=true";
import { default as achievementsynbgQ3Zh4o1XBZ_45h2q2Ts4_45X0dIdFkNfIxobxcu2B4MMeta } from "/builds/namicomi/frontend/pages/[locale]/user/[username]/achievements.vue?macro=true";
import { default as followersvBFM34HrPD9__wueRQ3orHf7Tj_45ougVK0RyfREq0QIUMeta } from "/builds/namicomi/frontend/pages/[locale]/user/[username]/followers.vue?macro=true";
import { default as followingRlNzhRMv3GvpK7J9GdJuYNIGqbi1xoWoylD_45vgzG_459sMeta } from "/builds/namicomi/frontend/pages/[locale]/user/[username]/following.vue?macro=true";
import { default as indexoL4afmaJ7amaC_45uW6apR0NytiP9EfUyz_45NsI_45tTjvOUMeta } from "/builds/namicomi/frontend/pages/[locale]/user/[username]/index.vue?macro=true";
import { default as listsXcsUytjXv4MJVoc8lKT_v8OFcalAawWhGYFejPLE21sMeta } from "/builds/namicomi/frontend/pages/[locale]/user/[username]/lists.vue?macro=true";
import { default as organizationsM47wUKo6RtzXd9A6vpaC6b9dExrEmU4BZjqngDBPcVkMeta } from "/builds/namicomi/frontend/pages/[locale]/user/[username]/organizations.vue?macro=true";
import { default as postszv9eFxXRfVSYhtX69RajgzmLU1wuGYittL_xuhHW2_45kMeta } from "/builds/namicomi/frontend/pages/[locale]/user/[username]/posts.vue?macro=true";
import { default as _91username_93uDHXlMp1WtBMRYUnPwWbxbqreQKn_SB810COs_CawGcMeta } from "/builds/namicomi/frontend/pages/[locale]/user/[username].vue?macro=true";
import { default as accountcUKxJHHTeypiWhqW3ivAoxotO5pIsYaao_UqYssOA7YMeta } from "/builds/namicomi/frontend/pages/account.vue?macro=true";
import { default as chapterswQLoIUBWKgHnG0TfxqJW7VryDgw851g2lOXGxEMr1q0Meta } from "/builds/namicomi/frontend/pages/admin/approval/chapters.vue?macro=true";
import { default as titlesB8De6z7EQFHoitbGr7zr_ohekf86uaGxPVPCOdK28L0Meta } from "/builds/namicomi/frontend/pages/admin/approval/titles.vue?macro=true";
import { default as chaptersjlEj87EguGn5SwmyldBLxQl1xj2h65m_EWjjSjoNmMAMeta } from "/builds/namicomi/frontend/pages/admin/chapters.vue?macro=true";
import { default as _91id_93xkcJWhh1JsM6FdvmhsTEgides7eiGfWdIDuDWzAOiAMMeta } from "/builds/namicomi/frontend/pages/admin/competition/[id].vue?macro=true";
import { default as createMBbj9A_SHqv1g1bVnNWQRhdk5k2O7iYIfdKhdBhbyJ4Meta } from "/builds/namicomi/frontend/pages/admin/competition/create.vue?macro=true";
import { default as _91id_93unB0h689z_45AJ1ecZGUPsttXkep3Ivxx3aae8QrKcW_QMeta } from "/builds/namicomi/frontend/pages/admin/competition/entries/[id].vue?macro=true";
import { default as competitionswGLQfpnodRBmkoPFMQeQSSvehhFUl3d6CoZE1N_451gn0Meta } from "/builds/namicomi/frontend/pages/admin/competitions.vue?macro=true";
import { default as _91id_93fK13ytfDHiXgg0klOScd_45Obm9z9a2nmrRofdRZuAIeIMeta } from "/builds/namicomi/frontend/pages/admin/distribution/[id].vue?macro=true";
import { default as index_45_45MwUvEcuzC83nF_4544J4OJGaqg5Ct_45sLswKmkCb11QoMeta } from "/builds/namicomi/frontend/pages/admin/distribution/index.vue?macro=true";
import { default as jobsD7UOHgelhGTWlOWhsRDaFH1jrh6v4968Rf67o_45gi6LwMeta } from "/builds/namicomi/frontend/pages/admin/distribution/jobs.vue?macro=true";
import { default as _91id_93Hfj1LO_WKACMaFAhx3BUpqvC33l2aBOUYfQaBmkZUeYMeta } from "/builds/namicomi/frontend/pages/admin/edit/chapter/[id].vue?macro=true";
import { default as _91id_93bJCXP6F4mVOulQI2dpDnIcjLWXqy4RzC79mFe1jbBvUMeta } from "/builds/namicomi/frontend/pages/admin/edit/emote/emote/[id].vue?macro=true";
import { default as _91id_93ruynvL2n_DDRWNwvScCazqNzYTi_zSQwiEE0ebaieHoMeta } from "/builds/namicomi/frontend/pages/admin/edit/emote/sticker/[id].vue?macro=true";
import { default as _91id_93YJf2Zm8jDOPtr7fwo_S5neHnYLSAO_457D1JSdYdD4tnYMeta } from "/builds/namicomi/frontend/pages/admin/edit/organization/[id].vue?macro=true";
import { default as accessQpzFtbSq3NSk00cS2_45FKONAAHjT1pqKmxKZBy9dL1MoMeta } from "/builds/namicomi/frontend/pages/admin/edit/title/[id]/access.vue?macro=true";
import { default as indexduwkgy6KVnQ3rNT_45VvDjSL5JK1jUp1N0D4o3NOXBb9UMeta } from "/builds/namicomi/frontend/pages/admin/edit/title/[id]/index.vue?macro=true";
import { default as _91id_93b11HFwcLZM28QQUwYINALtF8JiHRpVEOdK2rGDQFwr8Meta } from "/builds/namicomi/frontend/pages/admin/edit/title/[id].vue?macro=true";
import { default as _91id_938nru3UaCR8dK09kS1F0FsfvibGxc1Gmpuy4JiHA_rNUMeta } from "/builds/namicomi/frontend/pages/admin/edit/user/[id].vue?macro=true";
import { default as createoXUOdW4xhlb0GsQ8Tcpdxe6DOjoxzmcvxZEQQkiRkwoMeta } from "/builds/namicomi/frontend/pages/admin/engagement/achievements/create.vue?macro=true";
import { default as _91id_93516DD7HMOyiNxlyFbgXulqCq6BJn_ToGGgyV0LYNf_QMeta } from "/builds/namicomi/frontend/pages/admin/engagement/achievements/edit/[id].vue?macro=true";
import { default as indexYxOTGqHJ0ckuu5KILwcm7eoWDiJfLqRTcmcatw2mzlMMeta } from "/builds/namicomi/frontend/pages/admin/engagement/achievements/index.vue?macro=true";
import { default as _91id_93O4up8Ych1JcYhrhilqZ8cqfO3IT2cW9zRvt37slvyE8Meta } from "/builds/namicomi/frontend/pages/admin/engagement/badges/award/[id].vue?macro=true";
import { default as createNQ6AmLvKwFW2XzjPzL0XD5TUhxO5fzKnzjn_qSfy0iwMeta } from "/builds/namicomi/frontend/pages/admin/engagement/badges/create.vue?macro=true";
import { default as _91id_935Jnr8rlZuI0Nzzea7rAnx4ba_KNGWJV4wkwI82UQgqwMeta } from "/builds/namicomi/frontend/pages/admin/engagement/badges/edit/[id].vue?macro=true";
import { default as indexfIfjOMsolwrCOyOqOEB2dAI9UNoMn4yTWjg95lsYAgQMeta } from "/builds/namicomi/frontend/pages/admin/engagement/badges/index.vue?macro=true";
import { default as createjUjd3sXR6JSScx3I6liEhg6v6xSIUgIesS9SP7DMptMMeta } from "/builds/namicomi/frontend/pages/admin/engagement/emotes/create.vue?macro=true";
import { default as _91id_93D21j2Gy0G3u7Joai471BjTDxxMKaeLZAeA0W3EKtEjEMeta } from "/builds/namicomi/frontend/pages/admin/engagement/emotes/edit/[id].vue?macro=true";
import { default as index47xAe925QwJRzj2yWgrvgvQYOVPBQWg9T5_fq2ZI9RgMeta } from "/builds/namicomi/frontend/pages/admin/engagement/emotes/index.vue?macro=true";
import { default as createboSMAuABsI7HdPc7r1YAU9Ecwrh8XChWT2yR4BEfy_QMeta } from "/builds/namicomi/frontend/pages/admin/engagement/frames/create.vue?macro=true";
import { default as _91id_93nBk9X_vT_45x7LP2ZEokrNSmvl_5wV883kI3_5Dpe0JUsMeta } from "/builds/namicomi/frontend/pages/admin/engagement/frames/edit/[id].vue?macro=true";
import { default as indexKpYe3djV_45ktusE_45Pf_srNZV_45DeuG9VsdCATaBnWPsbQMeta } from "/builds/namicomi/frontend/pages/admin/engagement/frames/index.vue?macro=true";
import { default as createqGgjj0DXmC77EKcKNLOrRJ_ofWfoJXMvaGMPY6zqgjIMeta } from "/builds/namicomi/frontend/pages/admin/engagement/slots/create.vue?macro=true";
import { default as _91id_93s_Y8euawupJDZRl1kCicWCkPpapivaHUKjbp_R_456mDoMeta } from "/builds/namicomi/frontend/pages/admin/engagement/slots/edit/[id].vue?macro=true";
import { default as indexnCQx3xirIH8E0GLNrQNaJyEhaxULpDugvOpRMIZprOoMeta } from "/builds/namicomi/frontend/pages/admin/engagement/slots/index.vue?macro=true";
import { default as create5E5FSG3c5OiQilMlI9gY8N2bSxszpCRZY9uVxNQfs38Meta } from "/builds/namicomi/frontend/pages/admin/engagement/stickers/create.vue?macro=true";
import { default as _91id_93VfqBl3rRN9POf7HTSk9Zkvx9O2xvr__45IaTmdEfTTM4sMeta } from "/builds/namicomi/frontend/pages/admin/engagement/stickers/edit/[id].vue?macro=true";
import { default as indexXQ2p_459vLyNNT9gW710jZLB8RFAPJ_451yRkeiAdOmaIoQMeta } from "/builds/namicomi/frontend/pages/admin/engagement/stickers/index.vue?macro=true";
import { default as announcementsVxKMDG_58mujghQV9Or_ta6DFoJV1XKzScc2j2NelWgMeta } from "/builds/namicomi/frontend/pages/admin/homepage/announcements.vue?macro=true";
import { default as _91id_930mRHx7jKC13_45nrSyFErou2WIL7NDIoxxigGX2yaTjRQMeta } from "/builds/namicomi/frontend/pages/admin/homepage/asset/[id].vue?macro=true";
import { default as createHmpeAwxG0_VVCTs_KfTKvXMx7KEdiEBVFjDZY0fcw1gMeta } from "/builds/namicomi/frontend/pages/admin/homepage/asset/create.vue?macro=true";
import { default as _91group_93w0shpjyggQi4gVXS0p0lmnyOH8mItUuO2cwvlYOWVl4Meta } from "/builds/namicomi/frontend/pages/admin/homepage/asset/group/[group].vue?macro=true";
import { default as createc88SexscBT_X6i80Qiv3Oi72_45zk_45z9vyQ8Jzjf5PE_4Meta } from "/builds/namicomi/frontend/pages/admin/homepage/asset/group/create.vue?macro=true";
import { default as assetsDqs7tQ7nDgVexIklRV6ock2gIyuAeXTLEKM0kiEeEEgMeta } from "/builds/namicomi/frontend/pages/admin/homepage/assets.vue?macro=true";
import { default as _91id_93Mev_45d1xfG_45tr2e3vQF4Qw_45Te5JBhdQAoCx3OlO17SuIMeta } from "/builds/namicomi/frontend/pages/admin/homepage/carousel/[id].vue?macro=true";
import { default as createhzMmXZTXk8XMMt55jyUf0gLcj47aiH0yLnKoS0CTVSAMeta } from "/builds/namicomi/frontend/pages/admin/homepage/carousel/create.vue?macro=true";
import { default as carouselstWOFYUKDi5Ih2Gze11x74tQd8N5QjXcBukRYtluXnZ8Meta } from "/builds/namicomi/frontend/pages/admin/homepage/carousels.vue?macro=true";
import { default as _91id_93zN22EdRo5yEIUnSfmlv0dfF6IXkCBpO6PNfVYVVe_0YMeta } from "/builds/namicomi/frontend/pages/admin/homepage/list/[id].vue?macro=true";
import { default as createhN9Y3Xc8Zw1ENecukLzAbQK9ZgP3i4iY5xOYl_6jSGYMeta } from "/builds/namicomi/frontend/pages/admin/homepage/list/create.vue?macro=true";
import { default as staff_45picks6nda9tlFybXyb6WbOsYqgfpAxYLRxtY7RF2_5aOI6bcMeta } from "/builds/namicomi/frontend/pages/admin/homepage/staff-picks.vue?macro=true";
import { default as indexNlgxbQw1SHPpaRWTOfynYIXtrlBO4RqQhvd2IddQt4sMeta } from "/builds/namicomi/frontend/pages/admin/index.vue?macro=true";
import { default as logs1UCDAQZ7h6idRsor12cTmjvFjBuOcI8nUeCRAr2ZnoMMeta } from "/builds/namicomi/frontend/pages/admin/logs.vue?macro=true";
import { default as organizationsWvPcmj_45oaNi5_85OXB8z7tPW62jpyi_gdrOvwWjDfUIMeta } from "/builds/namicomi/frontend/pages/admin/organizations.vue?macro=true";
import { default as accountsMO5xUp5AgRWJaglKhgt0mSKEX7TmpNu2uLAV5_UbUmkMeta } from "/builds/namicomi/frontend/pages/admin/payments/accounts.vue?macro=true";
import { default as balancezR_45yv9W9FDwp3IydlqaEP9fsZksfXsrYS4C_45Fi551PgMeta } from "/builds/namicomi/frontend/pages/admin/payments/balance.vue?macro=true";
import { default as ledger9LScQXGi3lFpZ2hH8U7bkPBplskWrLNMqaJi42STNJsMeta } from "/builds/namicomi/frontend/pages/admin/payments/ledger.vue?macro=true";
import { default as payouts2RXvLYiZqle84vl1iTpDfSivqOccqqDt_45cjqFdlNjWMMeta } from "/builds/namicomi/frontend/pages/admin/payments/payouts.vue?macro=true";
import { default as payments0f9xQcgqwKQmHEfp67C6gIperfjpoXM6PDUqWZTdOeUMeta } from "/builds/namicomi/frontend/pages/admin/payments.vue?macro=true";
import { default as reportsvCR3GrgJkf5AY9ta2z_LvZb6QNA_45SxI_45_4EuVSTRwWQMeta } from "/builds/namicomi/frontend/pages/admin/reports.vue?macro=true";
import { default as creators6Ma4N6rY_vQUKKP_jcXk4r6GMPI7xNjNZFcMGkNNm3cMeta } from "/builds/namicomi/frontend/pages/admin/stats/creators.vue?macro=true";
import { default as userspxBr0SiRTh6by6Rn2_6icRfo9_MKjOqiCqFuziS_ILMMeta } from "/builds/namicomi/frontend/pages/admin/stats/users.vue?macro=true";
import { default as titleskuP2lRwSFX3vGBxaAIcVb_E0LzkSBkHq2SLmlaZT7nQMeta } from "/builds/namicomi/frontend/pages/admin/titles.vue?macro=true";
import { default as usersY6sxKsPRfrCp1SuARn8fJZzTnVYIeagduvPobMegPrYMeta } from "/builds/namicomi/frontend/pages/admin/users.vue?macro=true";
import { default as careers6T2yEt5qQhCeLYoiAud7A3EcUhZCtUOTHoXyHkOi6tUMeta } from "/builds/namicomi/frontend/pages/bin/careers.vue?macro=true";
import { default as dashboardYs4rNaIEUHLyaQwD4WJ2Nb5ildwi6rKfwJl_45Je5oplUMeta } from "/builds/namicomi/frontend/pages/bin/dashboard.vue?macro=true";
import { default as _91id_93ynKie9TNEvmE6YJ8Sb2j1UPPsYUponnfZ5t71qUdCv8Meta } from "/builds/namicomi/frontend/pages/bin/genre/[id].vue?macro=true";
import { default as groupsbLSoGj_QI09xl8dYaO0snC5TKlFCty_45y8dIv9y_aTUcMeta } from "/builds/namicomi/frontend/pages/bin/groups.vue?macro=true";
import { default as loginhSDXhT6_458oUuxIMXVRnFnDgi0isXHpU8qSaoqhSBU7UMeta } from "/builds/namicomi/frontend/pages/bin/login.vue?macro=true";
import { default as logoutJpZf02owKSJt4DVfQ7_45RU1LuAUOG5o9wGL7qLAnuAFUMeta } from "/builds/namicomi/frontend/pages/bin/logout.vue?macro=true";
import { default as messagesPNY69Lcd7J9QKMViWEv_nkga43fZL7OmTBvGXjdl1WUMeta } from "/builds/namicomi/frontend/pages/bin/messages.vue?macro=true";
import { default as historyHE_V_45_45wwrnlJDUanBJiezH6MVOMDgL4HytlVXMythz8Meta } from "/builds/namicomi/frontend/pages/bin/my/history.vue?macro=true";
import { default as libraryLrwEgwknQyElVO20FTX1dVHWaRb5cJkCsjCZAJ9zx0QMeta } from "/builds/namicomi/frontend/pages/bin/my/library.vue?macro=true";
import { default as listsCv2xdTA7oRmkxc5OchN0U_yj8GCDZBE2Cc5c8jzJkpwMeta } from "/builds/namicomi/frontend/pages/bin/my/lists.vue?macro=true";
import { default as updatesldBYrEnp6ZAY6gQy2_n_jVBdc92ORalqWV0H0u_JkmUMeta } from "/builds/namicomi/frontend/pages/bin/my/updates.vue?macro=true";
import { default as myfdxMnZuErXzMWhgoVz8HRiadhshNdN_jFNJ0ZM9yMxcMeta } from "/builds/namicomi/frontend/pages/bin/my.vue?macro=true";
import { default as _91id_93ASpCyRi4zvOxYSxYZmeSdD6untX34SbeXTTjUXny7OwMeta } from "/builds/namicomi/frontend/pages/bin/news/[id].vue?macro=true";
import { default as newsScd64JvFCOOR18rCUZu10hdnw9dIh2O_45JMCYzmuCVOIMeta } from "/builds/namicomi/frontend/pages/bin/news.vue?macro=true";
import { default as pressmwQvyHoMYcRT9nvqfzrnsmmlKJnZabey4hym029nN0EMeta } from "/builds/namicomi/frontend/pages/bin/press.vue?macro=true";
import { default as purchaseswkxG_fGQgdHl3_IlQVquMI2IpkqmIXKHJ5W5kbheM_454Meta } from "/builds/namicomi/frontend/pages/bin/purchases.vue?macro=true";
import { default as reports41HP4Pm2hWfPgFQE_45iNdnK3rNl2Yavfha4RvIcOXbgoMeta } from "/builds/namicomi/frontend/pages/bin/reports.vue?macro=true";
import { default as resetLLTUaPkcv2cxRGURaoDm3d0KZ06FqmbT3LaJkaxbr5sMeta } from "/builds/namicomi/frontend/pages/bin/reset.vue?macro=true";
import { default as signupUcDnqunkECaR6jtOf8kYeNcwr1txug5gOlNkD2HL8gAMeta } from "/builds/namicomi/frontend/pages/bin/signup.vue?macro=true";
import { default as studiofm6GgCCea4KDdPnfX4Np7s_45phmpgvQHzse_Mmb4Z7ekMeta } from "/builds/namicomi/frontend/pages/bin/studio.vue?macro=true";
import { default as _91id_933SEAq2VcF3M4qtazaugxevxtFtv_S9IK_FWk4Wh4PQYMeta } from "/builds/namicomi/frontend/pages/c/[id].vue?macro=true";
import { default as callbackLkpfLhZGABawyWuipBCxJLdww1JYScUz8wUfYj0FAdEMeta } from "/builds/namicomi/frontend/pages/discord/callback.vue?macro=true";
import { default as callbackjjg6hA0HAB_SGMHQnqE7p_UNOj_W6SEbS9beXHOB0AsMeta } from "/builds/namicomi/frontend/pages/discord/guild/callback.vue?macro=true";
import { default as signinHQJWD8MTVrwHC1lY94hGFUUWNqDNvM4tZo1Om4JufLoMeta } from "/builds/namicomi/frontend/pages/embed/signin.vue?macro=true";
import { default as social2PqoVyCbfqBx0_45b0bhwLmTVPK2X253f_45VY5Ms7KsWN4Meta } from "/builds/namicomi/frontend/pages/embed/social.vue?macro=true";
import { default as indexT_45Ssl69TrtKsF2mar_4hwpLx3oI01sNlsWQg5pex7rwMeta } from "/builds/namicomi/frontend/pages/index.vue?macro=true";
import { default as _91id_93hR3m_45O1mtYdIZXUglESA2Xqs0eCFo6Nj8_RIeLknSPcMeta } from "/builds/namicomi/frontend/pages/l/[id].vue?macro=true";
import { default as _91id_93MdotkhE2pEd35f3_459ciCCsVPTQO_45BvzXGlvLudoob3cMeta } from "/builds/namicomi/frontend/pages/n/[id].vue?macro=true";
import { default as _91id_936g3KMdW085YjKzM3pHlYyHp37eYeAf1sYLSrD_45J8wzgMeta } from "/builds/namicomi/frontend/pages/o/[id].vue?macro=true";
import { default as _91id_93lBJiddI_L5UG6G8_45LzFiIHa41si_JscAdQA0d5kj6_oMeta } from "/builds/namicomi/frontend/pages/p/[id].vue?macro=true";
import { default as publish11jF8WuG89CKM1mOHR8PF4CXeZeiDpLlrkfebZhVFo4Meta } from "/builds/namicomi/frontend/pages/publish.vue?macro=true";
import { default as externalDIPBxNzkvw_45CpmObXOIwZAojUiTg9XfLsna_Igg2PA4Meta } from "/builds/namicomi/frontend/pages/studio/[org]/audience-acquisition/external.vue?macro=true";
import { default as internalaRg9qS8W3YFtbAP_45XldZN6gPCK___0Cc3_jP5YOMJkEMeta } from "/builds/namicomi/frontend/pages/studio/[org]/audience-acquisition/internal.vue?macro=true";
import { default as audience_45acquisitionBw9wwhO3ihcmKo1aCQU_pLqH5DEQnyX9crhOk6P6DQwMeta } from "/builds/namicomi/frontend/pages/studio/[org]/audience-acquisition.vue?macro=true";
import { default as audience_45demographicsDPVEvvgKpozCqTaT1FPGbErkE_fV3vtDvt77w6KSsaUMeta } from "/builds/namicomi/frontend/pages/studio/[org]/audience-demographics.vue?macro=true";
import { default as followersVd4FF8TobIvfSzM8bWgE3gfIU_P6uZCBBDxF4qeMbrMMeta } from "/builds/namicomi/frontend/pages/studio/[org]/audience-overview/followers.vue?macro=true";
import { default as page_45viewsU43ndDfwCv8nYLj473yVQowV18j30c1ToV1JRRhRhrgMeta } from "/builds/namicomi/frontend/pages/studio/[org]/audience-overview/page-views.vue?macro=true";
import { default as subscribersRFKiFQDU_WzKDeUNE3uDfGvkdta8006ZKZJG9S38XawMeta } from "/builds/namicomi/frontend/pages/studio/[org]/audience-overview/subscribers.vue?macro=true";
import { default as summaryknSjb7bwZyuzh_KKiIaMUm_O28P8rWJY9T5TgRsx5pIMeta } from "/builds/namicomi/frontend/pages/studio/[org]/audience-overview/summary.vue?macro=true";
import { default as users7niBmZhSieJWZEyd28YrB_fcF0lCWlIBlXljI_qddJEMeta } from "/builds/namicomi/frontend/pages/studio/[org]/audience-overview/users.vue?macro=true";
import { default as audience_45overviewxxid_452hRt43gc5hADDs_QzGzs_6KcL_Bnp_haMDGOyUMeta } from "/builds/namicomi/frontend/pages/studio/[org]/audience-overview.vue?macro=true";
import { default as _91id_93J3A6aiRDfQQOm3AhdfpvNDEMD0Ln5mGPxaimQYQSjisMeta } from "/builds/namicomi/frontend/pages/studio/[org]/chapter/[id].vue?macro=true";
import { default as _91id_93H_r_459r5_452M8ZDcuV2GmfuNe0T2isVI14e_2ttMUaDfwMeta } from "/builds/namicomi/frontend/pages/studio/[org]/draft/[id].vue?macro=true";
import { default as createcoHLbP8_avEvnQlwKutzA8A405O0SDZJbYjAjmAlntUMeta } from "/builds/namicomi/frontend/pages/studio/[org]/emotes/create.vue?macro=true";
import { default as _91id_93u7tftzHtDZic_45LUfGqim1abcZeFiPJ3HZ_QMlsDUl8gMeta } from "/builds/namicomi/frontend/pages/studio/[org]/emotes/edit/[id].vue?macro=true";
import { default as indexZ_45vytCcxkAUiHYTGWd22ROOBpGu2xJqN_45DRQcfQGYI4Meta } from "/builds/namicomi/frontend/pages/studio/[org]/emotes/index.vue?macro=true";
import { default as emotesePhIuHRrB2v__AfIqW5S1qQRCbpRN1FvevFOaIGn_U0Meta } from "/builds/namicomi/frontend/pages/studio/[org]/emotes.vue?macro=true";
import { default as create0qaR7Oh_jFvB9Lh7EuaYZYM3xNTlsX_aLLVQL3lqpEEMeta } from "/builds/namicomi/frontend/pages/studio/[org]/frames/create.vue?macro=true";
import { default as _91id_936kvdSu_45uT0cRXpqSIWmPUFtXBVYAqJ5e0NVdCKRNcKEMeta } from "/builds/namicomi/frontend/pages/studio/[org]/frames/edit/[id].vue?macro=true";
import { default as indexjEpIgnVIXp69zPz7dOGYskp4guf7mN0SI2PE2RbnmYYMeta } from "/builds/namicomi/frontend/pages/studio/[org]/frames/index.vue?macro=true";
import { default as framesOnr0F6oItukrWxJr0zWevrQJqr65X8RZ3VLtv6Po0fgMeta } from "/builds/namicomi/frontend/pages/studio/[org]/frames.vue?macro=true";
import { default as index1U4dtQ53J8mlARsJH4c_OGSte6buQDLzFpvV1nGmmiMMeta } from "/builds/namicomi/frontend/pages/studio/[org]/index.vue?macro=true";
import { default as insightsu9LDk4Ws1jHnlQ1r7NMYzLbCmOfl7zBW0ot49p7M7qsMeta } from "/builds/namicomi/frontend/pages/studio/[org]/insights.vue?macro=true";
import { default as index2UG4Ggmye4GC0wINOOMKphm1Zh5d4bWy8BmQEtF325EMeta } from "/builds/namicomi/frontend/pages/studio/[org]/payments/setup/index.vue?macro=true";
import { default as paypal5vy1GhWdgVa1y_455ym1d5hcRE1MPYpP9W42Mx5vRaGpsMeta } from "/builds/namicomi/frontend/pages/studio/[org]/payments/setup/provider/paypal.vue?macro=true";
import { default as stripeDMrrCOEbU5dv_45BrWblmyl_45NOe6wQq0tO45w8IHeoyfYMeta } from "/builds/namicomi/frontend/pages/studio/[org]/payments/setup/provider/stripe.vue?macro=true";
import { default as providernbulaorhd5WF0rzZzHQbIJ7_45Z8K6I1b74hr2PX9xIxoMeta } from "/builds/namicomi/frontend/pages/studio/[org]/payments/setup/provider.vue?macro=true";
import { default as setup7p09ASQ5g7K_45RgHwtCsTuXX7BpxiF45A3C9AAk7O_45rQMeta } from "/builds/namicomi/frontend/pages/studio/[org]/payments/setup.vue?macro=true";
import { default as appsCxiF90mhnmau6c_459SjeiblKbQVVP8qg27tlQnJTX5fYMeta } from "/builds/namicomi/frontend/pages/studio/[org]/settings/apps.vue?macro=true";
import { default as billingvZUAeYdHh5iImSORyatIz7j_45MiRX2gXU0Ru8_k_Y_45ZoMeta } from "/builds/namicomi/frontend/pages/studio/[org]/settings/billing.vue?macro=true";
import { default as editVD5f2XrcQ4ezRPm42TfPp90q3KiZjeBn61W5E_NBNJUMeta } from "/builds/namicomi/frontend/pages/studio/[org]/settings/edit.vue?macro=true";
import { default as indexnYlCpRj20mx_459DuEYzixjPJNxs71Zr9Mr7KcHQMnUzgMeta } from "/builds/namicomi/frontend/pages/studio/[org]/settings/index.vue?macro=true";
import { default as notificationsXyBg_45yuHYAEvJ4uKSFxGzTfDc4f_45NbM78jkD28XE204Meta } from "/builds/namicomi/frontend/pages/studio/[org]/settings/notifications.vue?macro=true";
import { default as _91user_93JNoyUXNN3U3c4DWL1cfGcVpiJmQCoqjHyMrLaTPAN9IMeta } from "/builds/namicomi/frontend/pages/studio/[org]/settings/team/[user].vue?macro=true";
import { default as indexIC9FtvwIBq5DRTfZYDfi08Bo46oICuYDSRtxwxFRB9UMeta } from "/builds/namicomi/frontend/pages/studio/[org]/settings/team/index.vue?macro=true";
import { default as settingsOOojvhswb127OjwF6CLfOk9VP3ZKBCc2jxoaz3gZSkUMeta } from "/builds/namicomi/frontend/pages/studio/[org]/settings.vue?macro=true";
import { default as createsjZOSwlxLhm3zq8pjY_SFBqVb_Q5QLLa8o5zbsrJJ8kMeta } from "/builds/namicomi/frontend/pages/studio/[org]/stickers/create.vue?macro=true";
import { default as _91id_93IZDsSxfI_JjUojrQ9KQ05gyjnBrFUtNmAxr5hPECPbcMeta } from "/builds/namicomi/frontend/pages/studio/[org]/stickers/edit/[id].vue?macro=true";
import { default as indexOUjoF_45lOUqbRLTJz5jD3DWaoWAncfOddH22LhBdyER0Meta } from "/builds/namicomi/frontend/pages/studio/[org]/stickers/index.vue?macro=true";
import { default as stickersWmOMjgqLn_FQkCmCQ3YLgxj0z5WL3VWAIRDAWPBmcoIMeta } from "/builds/namicomi/frontend/pages/studio/[org]/stickers.vue?macro=true";
import { default as accountbKDIvejpWFuDOzWNuTmbWmoebftLPrl7zTsk2fX11hIMeta } from "/builds/namicomi/frontend/pages/studio/[org]/subscriptions/account.vue?macro=true";
import { default as documentsNULC5R_koLBoAkt2dT9mK0_caAs7jLhU8yTSW2ELnCIMeta } from "/builds/namicomi/frontend/pages/studio/[org]/subscriptions/balance/documents.vue?macro=true";
import { default as withdrawdkeALDKvy9i1lvVOTKBMQ8nYFMxfUcinV73JJy_PiSgMeta } from "/builds/namicomi/frontend/pages/studio/[org]/subscriptions/balance/withdraw.vue?macro=true";
import { default as balancepFDfY5FhodO9hVByOXvuIGtunrm1Rj9oIl0Z3fDuD30Meta } from "/builds/namicomi/frontend/pages/studio/[org]/subscriptions/balance.vue?macro=true";
import { default as insightsK2fBb2De1HkQvpvMuaOTuU5DJbLaJMc1PDeW6DY8uc4Meta } from "/builds/namicomi/frontend/pages/studio/[org]/subscriptions/insights.vue?macro=true";
import { default as overviewaplODElBFqtar4B64_w8bmfE57r6UenGUQ_Zkep1iNsMeta } from "/builds/namicomi/frontend/pages/studio/[org]/subscriptions/overview.vue?macro=true";
import { default as _91id_93mK1ibwCPivHrEUGKQ7V2fNwjg_7dBqVpe7SHzzQMy5UMeta } from "/builds/namicomi/frontend/pages/studio/[org]/subscriptions/tier/[id].vue?macro=true";
import { default as createzTQtL302IIO1_45wlVYp7xap9jv6I9PVJC1xMcza12gQMMeta } from "/builds/namicomi/frontend/pages/studio/[org]/subscriptions/tier/create.vue?macro=true";
import { default as tiers3GDbiw7EaSWH5jfbdT3GAWYJNW9imKCbZAejuAqtP9AMeta } from "/builds/namicomi/frontend/pages/studio/[org]/subscriptions/tiers.vue?macro=true";
import { default as subscriptions3BS_8wMUHbhQNkF7wxhypIzWSvMfJADKgMCyQT3JbW4Meta } from "/builds/namicomi/frontend/pages/studio/[org]/subscriptions.vue?macro=true";
import { default as access6OWYWGYRJKkwlDll_45h_45sb6e223Ru2V1BG9JI7JgWHCIMeta } from "/builds/namicomi/frontend/pages/studio/[org]/title/[id]/access.vue?macro=true";
import { default as artaV472g2Q5tmf_45TsCHEFLZlYq6EHPrtmAJrUFUKToPFUMeta } from "/builds/namicomi/frontend/pages/studio/[org]/title/[id]/art.vue?macro=true";
import { default as chaptersHTQXTpX_45Rl_45hqJhs0bqxizl3h5bduEYqO7iHcNIwdJMMeta } from "/builds/namicomi/frontend/pages/studio/[org]/title/[id]/chapters.vue?macro=true";
import { default as _91collectionLocale_934wemam58rdxpb8oMVv9XOyqJJw8nxjkkjW2mNerne_kMeta } from "/builds/namicomi/frontend/pages/studio/[org]/title/[id]/collections/[volume]/[collectionLocale].vue?macro=true";
import { default as index35wVwG2rdDj7j3XDUftFeAFKNYo3h869ufLfqnyWmisMeta } from "/builds/namicomi/frontend/pages/studio/[org]/title/[id]/collections/index.vue?macro=true";
import { default as newOwxI5L_u24WcsNCIuJgMK0LR93r_YHRtwYSide1bkEAMeta } from "/builds/namicomi/frontend/pages/studio/[org]/title/[id]/collections/new.vue?macro=true";
import { default as contestsqL1uDd2WHiKCmUsiSSP7P8rYfZN204jAiINb_45Es8SJEMeta } from "/builds/namicomi/frontend/pages/studio/[org]/title/[id]/contests.vue?macro=true";
import { default as editnLXyo3S90HARHlGZCajpwoxaafaaxHOl_45oHU5ka8HLsMeta } from "/builds/namicomi/frontend/pages/studio/[org]/title/[id]/edit.vue?macro=true";
import { default as publish9ih_45rLrKZmutOxcLXKKYL_47Xe3tpiNEkPOGj0aECwgMeta } from "/builds/namicomi/frontend/pages/studio/[org]/title/[id]/publish.vue?macro=true";
import { default as _91id_93p6wZWB9P4yvV0SV5YexrzF_45ClLlC7kP_PErBPXIVygQMeta } from "/builds/namicomi/frontend/pages/studio/[org]/title/[id].vue?macro=true";
import { default as create3YT_45NZLuq6QLUxMiqXouhkiFD9l0LrQIRJ8QzzkKY_45AMeta } from "/builds/namicomi/frontend/pages/studio/[org]/title/create.vue?macro=true";
import { default as index3H64tu_pOpKjIfSrOWWfY6b_45VJUT7Xt8BqOQwWvyk4oMeta } from "/builds/namicomi/frontend/pages/studio/[org]/titles/index.vue?macro=true";
import { default as rejectedMEslRa1AY8bwhlgJHXMi0S86T10t18dDX0u2H0XWMDkMeta } from "/builds/namicomi/frontend/pages/studio/[org]/titles/rejected.vue?macro=true";
import { default as submittedDLQ8Tspu4KZqhNGJtMHT7eBqqUyuLZ1nx9VluZEeLOUMeta } from "/builds/namicomi/frontend/pages/studio/[org]/titles/submitted.vue?macro=true";
import { default as unpublishedCxRegrnLNBdNv95s8wkiOx_45f6sNvhWJ2Nm6tEnEByggMeta } from "/builds/namicomi/frontend/pages/studio/[org]/titles/unpublished.vue?macro=true";
import { default as unsubmittedqrl3PbBsj2eJYxeiNzu_le3VqZIgxhSY6MG7Rk_45RtYMMeta } from "/builds/namicomi/frontend/pages/studio/[org]/titles/unsubmitted.vue?macro=true";
import { default as titles_jDNn3xNXNxN0UAnvPrLgWmBQwmK8mCdeQMBVunXf20Meta } from "/builds/namicomi/frontend/pages/studio/[org]/titles.vue?macro=true";
import { default as _91org_931o_gZo21PrTLk8Pe_LBdVQFenVYW2ns5hK5oeZlpOFkMeta } from "/builds/namicomi/frontend/pages/studio/[org].vue?macro=true";
import { default as creater0gYjwaZpzrHJ5AiN_t1SHc1zHL8K26COQDsdsOwbEkMeta } from "/builds/namicomi/frontend/pages/studio/create.vue?macro=true";
import { default as index9koK9sdPvMHAWBFHzoNI1VqZQGEDejEpAMHmMMQOJfoMeta } from "/builds/namicomi/frontend/pages/studio/index.vue?macro=true";
import { default as _91id_93sGzlrwcHxTR1bZWv3w_bOx1GDZPevCrLnba69GPcE9MMeta } from "/builds/namicomi/frontend/pages/t/[id].vue?macro=true";
import { default as _91id_93Alr7u_nVSGNE3xIbyMZ66BdxZzaiHvL_GLbRHphKBRcMeta } from "/builds/namicomi/frontend/pages/u/[id].vue?macro=true";
export default [
  {
    name: "locale-about",
    path: "/:locale()/about",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/about.vue")
  },
  {
    name: "locale-callback-account-refresh-id",
    path: "/:locale()/callback/account/refresh/:id()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/callback/account/refresh/[id].vue")
  },
  {
    name: "locale-callback-account-return-id",
    path: "/:locale()/callback/account/return/:id()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/callback/account/return/[id].vue")
  },
  {
    name: "locale-callback-paypal-connect",
    path: "/:locale()/callback/paypal/connect",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/callback/paypal/connect.vue")
  },
  {
    name: "locale-callback-signin",
    path: "/:locale()/callback/signin",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/callback/signin.vue")
  },
  {
    name: "locale-callback-subscription-status-id",
    path: "/:locale()/callback/subscription/:status()/:id()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/callback/subscription/[status]/[id].vue")
  },
  {
    name: "locale-chapter",
    path: "/:locale()/chapter",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/chapter.vue"),
    children: [
  {
    name: "locale-chapter-id",
    path: ":id()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/chapter/[id].vue")
  }
]
  },
  {
    name: "locale-contact",
    path: "/:locale()/contact",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/contact.vue"),
    children: [
  {
    name: "locale-contact-feedback",
    path: "feedback",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/contact/feedback.vue")
  },
  {
    name: "locale-contact-support",
    path: "support",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/contact/support.vue")
  }
]
  },
  {
    name: "locale-contest-information-id-slug",
    path: "/:locale()/contest/information/:id()/:slug()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/contest/information/[id]/[slug].vue")
  },
  {
    name: overview5Q08eMYAdBin4bfglXRuzI9mgBkAZW_YAl1Jb9JyFP4Meta?.name,
    path: "/:locale()/contest/overview",
    meta: overview5Q08eMYAdBin4bfglXRuzI9mgBkAZW_YAl1Jb9JyFP4Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/contest/overview.vue"),
    children: [
  {
    name: "locale-contest-overview-about",
    path: "about",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/contest/overview/about.vue")
  },
  {
    name: "locale-contest-overview",
    path: "",
    meta: indexZtnkOc7wki_45ZDmLvCrmv8ypk3zCb3xkPcZmQkykReHMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/contest/overview/index.vue")
  },
  {
    name: "locale-contest-overview-rankings",
    path: "rankings",
    meta: rankingsFqCaL1YycBCDyxm8qGuskvq9J_45r0rOip2Z8VSbun8CQMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/contest/overview/rankings.vue")
  },
  {
    name: "locale-contest-overview-winners",
    path: "winners",
    meta: winnersPbCer6qqTskWU5_rZWRRZcXPJq0koFFhNBgzaaZIOZsMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/contest/overview/winners.vue")
  }
]
  },
  {
    name: "locale-creators",
    path: "/:locale()/creators",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/creators.vue")
  },
  {
    name: "locale-discover-tag",
    path: "/:locale()/discover/:tag()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/discover/[tag].vue")
  },
  {
    name: "locale-discover",
    path: "/:locale()/discover",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/discover/index.vue")
  },
  {
    name: "locale-fans",
    path: "/:locale()/fans",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/fans.vue")
  },
  {
    name: "locale-faq",
    path: "/:locale()/faq",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/faq.vue"),
    children: [
  {
    name: "locale-faq-contests",
    path: "contests",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/faq/contests.vue")
  },
  {
    name: "locale-faq-creators",
    path: "creators",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/faq/creators.vue")
  },
  {
    name: "locale-faq-general",
    path: "general",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/faq/general.vue")
  },
  {
    name: "locale-faq-readers",
    path: "readers",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/faq/readers.vue")
  }
]
  },
  {
    name: feedIVJWSMvwjeixBhIZMctTENHox0W63JIRU0Nel4TNtkYMeta?.name,
    path: "/:locale()/feed",
    meta: feedIVJWSMvwjeixBhIZMctTENHox0W63JIRU0Nel4TNtkYMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/feed.vue"),
    children: [
  {
    name: "locale-feed-following",
    path: "following",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/feed/following.vue")
  },
  {
    name: "locale-feed",
    path: "",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/feed/index.vue")
  }
]
  },
  {
    name: "locale",
    path: "/:locale()",
    meta: indexjQCsVDfxtL_45d6NEWzG9dC5A5HlURNSuMgnQEBQMNtiMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/index.vue")
  },
  {
    name: library76kNaPpDuIv5sn3Ei99DSXLBUAhOaDSxOaoivIWEYa8Meta?.name,
    path: "/:locale()/library",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/library.vue"),
    children: [
  {
    name: "locale-library-follows",
    path: "follows",
    meta: followsuASe8dphALHR6Ea1zHm_45VMVypTfd1LEgklf_45knN9OikMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/library/follows.vue")
  },
  {
    name: "locale-library-history",
    path: "history",
    meta: historyKv9x5_wuFvITHKwuSqAm1nelwshmXUiBX0aKQbbHXIgMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/library/history.vue")
  },
  {
    name: "locale-library",
    path: "",
    meta: index7vr35XimFAYSZWXZ2E_45THsPPB03YqFGeADJMikMkC0cMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/library/index.vue")
  },
  {
    name: "locale-library-lists",
    path: "lists",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/library/lists.vue")
  },
  {
    name: "locale-library-status",
    path: "status",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/library/status.vue"),
    children: [
  {
    name: "locale-library-status-status",
    path: ":status()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/library/status/[status].vue")
  }
]
  }
]
  },
  {
    name: "locale-list-id",
    path: "/:locale()/list/:id()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/list/[id].vue")
  },
  {
    name: "locale-list-create",
    path: "/:locale()/list/create",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/list/create.vue")
  },
  {
    name: "locale-list-edit-id",
    path: "/:locale()/list/edit/:id()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/list/edit/[id].vue")
  },
  {
    name: "locale-messages",
    path: "/:locale()/messages",
    meta: messages8n40I59z4AKkRmmCXfrNRv_OEQZd_K7HiHW_45vt6xHeYMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/messages.vue"),
    children: [
  {
    name: "locale-messages-id",
    path: ":id()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/messages/[id].vue")
  }
]
  },
  {
    name: "locale-news",
    path: "/:locale()/news",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/news.vue"),
    children: [
  {
    name: "locale-news-id",
    path: ":id()",
    meta: _91id_93urxVjtVmt24xStUHs9idPT_457AVmX_45v5HjTrsfi5_453DMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/news/[id].vue")
  },
  {
    name: "locale-news-article-id-slug",
    path: "article/:id()/:slug()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/news/article/[id]/[slug].vue")
  },
  {
    name: "locale-news-category-slug",
    path: "category/:slug()",
    meta: _91slug_93FhenhBLX4LoTbz3TJB6KnDMc3Wvl78KiBvH7dqDOJq0Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/news/category/[slug].vue")
  }
]
  },
  {
    name: "locale-notifications",
    path: "/:locale()/notifications",
    meta: notificationswvvEtWtevGDN43pzoJM1YtWEbYHLHPFmYQG0cSIsr7wMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/notifications.vue")
  },
  {
    name: _91slug_93YqnmsR_45wzsRFGl_L4N8vSY1zRojG4wWpfLb5QLwNLoUMeta?.name,
    path: "/:locale()/org/:slug()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/org/[slug].vue"),
    children: [
  {
    name: "locale-org-slug-achievements",
    path: "achievements",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/org/[slug]/achievements.vue")
  },
  {
    name: "locale-org-slug-followers",
    path: "followers",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/org/[slug]/followers.vue")
  },
  {
    name: "locale-org-slug-following",
    path: "following",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/org/[slug]/following.vue")
  },
  {
    name: "locale-org-slug-home",
    path: "home",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/org/[slug]/home.vue")
  },
  {
    name: "locale-org-slug",
    path: "",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/org/[slug]/index.vue")
  },
  {
    name: "locale-org-slug-rewards",
    path: "rewards",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/org/[slug]/rewards.vue")
  },
  {
    name: "locale-org-slug-subscriptions",
    path: "subscriptions",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/org/[slug]/subscriptions.vue")
  },
  {
    name: "locale-org-slug-titles",
    path: "titles",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/org/[slug]/titles.vue")
  }
]
  },
  {
    name: "locale-post-id",
    path: "/:locale()/post/:id()",
    meta: _91id_93_45_45eJ50Xw3VCsMm8a9ph9A7VMduwt_zLX1_45Xx37pAR4UMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/post/[id].vue")
  },
  {
    name: "locale-publish",
    path: "/:locale()/publish",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/publish.vue"),
    children: [
  {
    name: "locale-publish-makemoney",
    path: "makemoney",
    meta: makemoneyIXYEInC2A5zXYp1ZT7y8QRxrYOwzdtEtWSyYV_KD_45rMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/publish/makemoney.vue")
  },
  {
    name: "locale-publish-overview",
    path: "overview",
    meta: overviewh1CtmlKhxOVFIH_u7ePtVHR0y6Y_457jQ1UtPkybCgPWIMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/publish/overview.vue")
  },
  {
    name: "locale-publish-resources",
    path: "resources",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/publish/resources.vue")
  }
]
  },
  {
    name: settings2ANnrO14pfUUFq40LBTGtPcUL6sgjiSgBH2Ztl1BROMMeta?.name,
    path: "/:locale()/settings",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings.vue"),
    children: [
  {
    name: "locale-settings-account-preferences",
    path: "account-preferences",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/account-preferences.vue")
  },
  {
    name: "locale-settings-account-device-management",
    path: "account/device-management",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/account/device-management.vue")
  },
  {
    name: "locale-settings-account-email",
    path: "account/email",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/account/email.vue")
  },
  {
    name: "locale-settings-account",
    path: "account",
    meta: indexhu_458Oz6CHSfY5M5f2o_45gugXE6s6bIhPBLKnydKXHCwMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/account/index.vue")
  },
  {
    name: "locale-settings-account-password",
    path: "account/password",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/account/password.vue")
  },
  {
    name: "locale-settings-account-two-factor",
    path: "account/two-factor",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/account/two-factor.vue")
  },
  {
    name: "locale-settings-connected-apps",
    path: "connected-apps",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/connected-apps.vue")
  },
  {
    name: "locale-settings",
    path: "",
    meta: indexHYrLUhoCSSANPHXXmeat_Oj5Lb2Q3clkdhym5p_y8UIMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/index.vue")
  },
  {
    name: "locale-settings-language",
    path: "language",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/language.vue")
  },
  {
    name: "locale-settings-notifications",
    path: "notifications",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/notifications.vue")
  },
  {
    name: "locale-settings-privacy",
    path: "privacy",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/privacy.vue")
  },
  {
    name: "locale-settings-profile",
    path: "profile",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/profile.vue")
  },
  {
    name: "locale-settings-site-preferences",
    path: "site-preferences",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/site-preferences.vue")
  },
  {
    name: historysL7aby2gzTt5qKTRTQdSJSTZ1yYIkU_45t0ybaTlgtAHIMeta?.name,
    path: "subscription/history",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/subscription/history.vue"),
    children: [
  {
    name: "locale-settings-subscription-history-active",
    path: "active",
    meta: active9YJ3dUJLblGeKdA7X94METzhMxWv59JwoaHyDuxmKhIMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/subscription/history/active.vue")
  },
  {
    name: "locale-settings-subscription-history-cancelled",
    path: "cancelled",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/subscription/history/cancelled.vue")
  },
  {
    name: "locale-settings-subscription-history",
    path: "",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/subscription/history/index.vue")
  },
  {
    name: "locale-settings-subscription-history-pending",
    path: "pending",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/subscription/history/pending.vue")
  }
]
  },
  {
    name: "locale-settings-subscription",
    path: "subscription",
    meta: indexXMru9m2wthMkP0_e5ECWJzhGKazUr8bd4Sl9Yqr2GGgMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/subscription/index.vue")
  },
  {
    name: "locale-settings-subscription-payment",
    path: "subscription/payment",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/subscription/payment.vue")
  },
  {
    name: "locale-settings-subscription-transaction-history",
    path: "subscription/transaction-history",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/settings/subscription/transaction-history.vue")
  }
]
  },
  {
    name: "locale-subscriptions",
    path: "/:locale()/subscriptions",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/subscriptions.vue")
  },
  {
    name: termsL9emVLH7K7GqxH3tiT_mfYzRH_OkCsXk8Q5enOtcORUMeta?.name,
    path: "/:locale()/terms",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/terms.vue"),
    children: [
  {
    name: "locale-terms-content",
    path: "content",
    meta: contentuywSL_ZCqx_6WqVuCk17jPBEiLowEpqWNaR1uiJgxMAMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/terms/content.vue")
  },
  {
    name: "locale-terms-contest",
    path: "contest",
    meta: contestIMZ7Of_d7SzKwgpdkU6YAC5w3O_45XLmf2E4wg1ZKFQNIMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/terms/contest.vue")
  },
  {
    name: "locale-terms-cookie",
    path: "cookie",
    meta: cookie_45KDcofL4_45LPa47qQorsmlsVQkbeAwOf95nNR8EzEzZMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/terms/cookie.vue")
  },
  {
    name: "locale-terms-creatorrewards",
    path: "creatorrewards",
    meta: creatorrewardsvRv5EueE3gV8YH29BF51pXhFAx68Hnw68ZwRqAedIE8Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/terms/creatorrewards.vue")
  },
  {
    name: "locale-terms-dtiys",
    path: "dtiys",
    meta: dtiys4cyEmBsYBMzQi0L2ghFeDoj3a46mrh0ntJqsIufKfyEMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/terms/dtiys.vue")
  },
  {
    name: "locale-terms",
    path: "",
    meta: index95o2WRaPoTg8SWRyOop8nmk0cr_45WDKklbUUGINfj_y4Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/terms/index.vue")
  },
  {
    name: "locale-terms-privacy",
    path: "privacy",
    meta: privacyPSMELOeiSXtNi4llAwCrq_45w1IiOVbztAprvthVoZzJEMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/terms/privacy.vue")
  },
  {
    name: "locale-terms-site",
    path: "site",
    meta: site8npcuonewtl5ddFZn1ydgkcjirki9a_45ZeTQ9mj9zz6cMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/terms/site.vue")
  },
  {
    name: "locale-terms-subscriptions",
    path: "subscriptions",
    meta: subscriptionsiW7cTLkFGOizWF5WdTyRMMCCbVPadtjJCOPzDxud228Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/terms/subscriptions.vue")
  }
]
  },
  {
    name: "locale-test",
    path: "/:locale()/test",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test.vue"),
    children: [
  {
    name: "locale-test-components-attach",
    path: "components/attach",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/attach.vue")
  },
  {
    name: "locale-test-components-comment-form",
    path: "components/comment-form",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/comment-form.vue")
  },
  {
    name: "locale-test-components-comment-comment-content",
    path: "components/comment/comment-content",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/comment/comment-content.vue")
  },
  {
    name: "locale-test-components-date-month-picker",
    path: "components/date-month-picker",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/date-month-picker.vue")
  },
  {
    name: "locale-test-components-date-picker",
    path: "components/date-picker",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/date-picker.vue")
  },
  {
    name: "locale-test-components-drag-and-drop",
    path: "components/drag-and-drop",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/drag-and-drop.vue")
  },
  {
    name: "locale-test-components-emote-composer",
    path: "components/emote-composer",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/emote-composer.vue")
  },
  {
    name: "locale-test-components-hover-overlay",
    path: "components/hover-overlay",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/hover-overlay.vue")
  },
  {
    name: "locale-test-components-list-dropdown",
    path: "components/list-dropdown",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/list-dropdown.vue")
  },
  {
    name: "locale-test-components-markdown",
    path: "components/markdown",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/markdown.vue")
  },
  {
    name: "locale-test-components-notification-base",
    path: "components/notification/base",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/base.vue")
  },
  {
    name: "locale-test-components-notification-creator-comment-to-chapter",
    path: "components/notification/creator-comment-to-chapter",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/creator-comment-to-chapter.vue")
  },
  {
    name: "locale-test-components-notification-creator-comment-to-title",
    path: "components/notification/creator-comment-to-title",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/creator-comment-to-title.vue")
  },
  {
    name: "locale-test-components-notification-creator-reaction-to-chapter",
    path: "components/notification/creator-reaction-to-chapter",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/creator-reaction-to-chapter.vue")
  },
  {
    name: "locale-test-components-notification-creator-title-follow",
    path: "components/notification/creator-title-follow",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/creator-title-follow.vue")
  },
  {
    name: "locale-test-components-notification-new-message",
    path: "components/notification/new-message",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/new-message.vue")
  },
  {
    name: "locale-test-components-notification-official-announcement",
    path: "components/notification/official-announcement",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/official-announcement.vue")
  },
  {
    name: "locale-test-components-notification-payment-organization-subscription",
    path: "components/notification/payment-organization-subscription",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/payment-organization-subscription.vue")
  },
  {
    name: "locale-test-components-notification-reader-chapter-published-to-followed-title",
    path: "components/notification/reader-chapter-published-to-followed-title",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/reader-chapter-published-to-followed-title.vue")
  },
  {
    name: "locale-test-components-notification-social-new-follow",
    path: "components/notification/social-new-follow",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-new-follow.vue")
  },
  {
    name: "locale-test-components-notification-social-reaction-to-comment",
    path: "components/notification/social-reaction-to-comment",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-reaction-to-comment.vue")
  },
  {
    name: "locale-test-components-notification-social-reaction-to-post",
    path: "components/notification/social-reaction-to-post",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-reaction-to-post.vue")
  },
  {
    name: "locale-test-components-notification-social-reply-to-comment",
    path: "components/notification/social-reply-to-comment",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-reply-to-comment.vue")
  },
  {
    name: "locale-test-components-notification-social-reply-to-post",
    path: "components/notification/social-reply-to-post",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-reply-to-post.vue")
  },
  {
    name: "locale-test-components-notification-social-repost-of-post",
    path: "components/notification/social-repost-of-post",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/notification/social-repost-of-post.vue")
  },
  {
    name: "locale-test-components-overlay",
    path: "components/overlay",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/overlay.vue")
  },
  {
    name: "locale-test-components-radio-group",
    path: "components/radio-group",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/radio-group.vue")
  },
  {
    name: "locale-test-components-rich-editor",
    path: "components/rich-editor",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/rich-editor.vue")
  },
  {
    name: "locale-test-components-table",
    path: "components/table",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/table.vue")
  },
  {
    name: "locale-test-components-time-picker",
    path: "components/time-picker",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/time-picker.vue")
  },
  {
    name: "locale-test-components-title-create",
    path: "components/title-create",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/title-create.vue")
  },
  {
    name: "locale-test-components-tooltip",
    path: "components/tooltip",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/components/tooltip.vue")
  },
  {
    name: "locale-test-events-notification-toasts",
    path: "events/notification-toasts",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/events/notification-toasts.vue")
  },
  {
    name: "locale-test-functions-batcher",
    path: "functions/batcher",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/test/functions/batcher.vue")
  }
]
  },
  {
    name: _91slug_93JB_B5ui822P4fjjFlclSJw0pwPDnMkQFAA8JuDtlqNsMeta?.name,
    path: "/:locale()/title/:id()/:slug()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug].vue"),
    children: [
  {
    name: "locale-title-id-slug-art",
    path: "art",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/art.vue")
  },
  {
    name: "locale-title-id-slug-chapters",
    path: "chapters",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/chapters.vue")
  },
  {
    name: "locale-title-id-slug-collections-volume",
    path: "collections/:volume()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/collections/[volume].vue")
  },
  {
    name: "locale-title-id-slug-collections",
    path: "collections",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/collections/index.vue")
  },
  {
    name: "locale-title-id-slug-comments",
    path: "comments",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/comments.vue")
  },
  {
    name: "locale-title-id-slug-details",
    path: "details",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/details.vue")
  },
  {
    name: "locale-title-id-slug",
    path: "",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/index.vue")
  },
  {
    name: "locale-title-id-slug-relations",
    path: "relations",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/relations.vue")
  },
  {
    name: "locale-title-id-slug-reviews",
    path: "reviews",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/[id]/[slug]/reviews.vue")
  }
]
  },
  {
    name: "locale-title-id",
    path: "/:locale()/title/:id()",
    meta: indextzcpxIlsJ_45Ar_45mrOqMWb5VJkBAgF1XO1BXDhLhVvSxoMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/[id]/index.vue")
  },
  {
    name: "locale-title-search",
    path: "/:locale()/title/search",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/title/search.vue")
  },
  {
    name: "locale-titles",
    path: "/:locale()/titles",
    meta: titlesP5AbMIorwBjfed9Ta6hIQJ_ZRD85y9JMhQZQhHVIEeIMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/titles.vue")
  },
  {
    name: "locale-updates",
    path: "/:locale()/updates",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/updates.vue"),
    children: [
  {
    name: "locale-updates-follows",
    path: "follows",
    meta: followsNxxkagmvxZwJaHRjd7N_cf8MgYsO_45abaYktW7ShFgnEMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/updates/follows.vue")
  },
  {
    name: "locale-updates-latest",
    path: "latest",
    meta: latestE7_45Ee2u5KECcO4XZwJ2CUJWlfc3loaSC_45XBCprdDEswMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/updates/latest.vue")
  }
]
  },
  {
    name: "locale-user-_-id",
    path: "/:locale()/user/_/:id()",
    meta: _91id_93tBgWOjCYLcGp7z0ql2s_rm_45eUs4H53t_Pq1f20heetoMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/user/_/[id].vue")
  },
  {
    name: _91username_93uDHXlMp1WtBMRYUnPwWbxbqreQKn_SB810COs_CawGcMeta?.name,
    path: "/:locale()/user/:username()",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/user/[username].vue"),
    children: [
  {
    name: "locale-user-username-achievements",
    path: "achievements",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/user/[username]/achievements.vue")
  },
  {
    name: "locale-user-username-followers",
    path: "followers",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/user/[username]/followers.vue")
  },
  {
    name: "locale-user-username-following",
    path: "following",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/user/[username]/following.vue")
  },
  {
    name: "locale-user-username",
    path: "",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/user/[username]/index.vue")
  },
  {
    name: "locale-user-username-lists",
    path: "lists",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/user/[username]/lists.vue")
  },
  {
    name: "locale-user-username-organizations",
    path: "organizations",
    component: () => import("/builds/namicomi/frontend/pages/[locale]/user/[username]/organizations.vue")
  },
  {
    name: "locale-user-username-posts",
    path: "posts",
    meta: postszv9eFxXRfVSYhtX69RajgzmLU1wuGYittL_xuhHW2_45kMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/[locale]/user/[username]/posts.vue")
  }
]
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/builds/namicomi/frontend/pages/account.vue")
  },
  {
    name: "admin-approval-chapters",
    path: "/admin/approval/chapters",
    meta: chapterswQLoIUBWKgHnG0TfxqJW7VryDgw851g2lOXGxEMr1q0Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/approval/chapters.vue")
  },
  {
    name: "admin-approval-titles",
    path: "/admin/approval/titles",
    meta: titlesB8De6z7EQFHoitbGr7zr_ohekf86uaGxPVPCOdK28L0Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/approval/titles.vue")
  },
  {
    name: "admin-chapters",
    path: "/admin/chapters",
    meta: chaptersjlEj87EguGn5SwmyldBLxQl1xj2h65m_EWjjSjoNmMAMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/chapters.vue")
  },
  {
    name: "admin-competition-id",
    path: "/admin/competition/:id()",
    meta: _91id_93xkcJWhh1JsM6FdvmhsTEgides7eiGfWdIDuDWzAOiAMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/competition/[id].vue")
  },
  {
    name: "admin-competition-create",
    path: "/admin/competition/create",
    meta: createMBbj9A_SHqv1g1bVnNWQRhdk5k2O7iYIfdKhdBhbyJ4Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/competition/create.vue")
  },
  {
    name: "admin-competition-entries-id",
    path: "/admin/competition/entries/:id()",
    meta: _91id_93unB0h689z_45AJ1ecZGUPsttXkep3Ivxx3aae8QrKcW_QMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/competition/entries/[id].vue")
  },
  {
    name: "admin-competitions",
    path: "/admin/competitions",
    meta: competitionswGLQfpnodRBmkoPFMQeQSSvehhFUl3d6CoZE1N_451gn0Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/competitions.vue")
  },
  {
    name: "admin-distribution-id",
    path: "/admin/distribution/:id()",
    meta: _91id_93fK13ytfDHiXgg0klOScd_45Obm9z9a2nmrRofdRZuAIeIMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/distribution/[id].vue")
  },
  {
    name: "admin-distribution",
    path: "/admin/distribution",
    meta: index_45_45MwUvEcuzC83nF_4544J4OJGaqg5Ct_45sLswKmkCb11QoMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/distribution/index.vue")
  },
  {
    name: "admin-distribution-jobs",
    path: "/admin/distribution/jobs",
    meta: jobsD7UOHgelhGTWlOWhsRDaFH1jrh6v4968Rf67o_45gi6LwMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/distribution/jobs.vue")
  },
  {
    name: "admin-edit-chapter-id",
    path: "/admin/edit/chapter/:id()",
    meta: _91id_93Hfj1LO_WKACMaFAhx3BUpqvC33l2aBOUYfQaBmkZUeYMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/edit/chapter/[id].vue")
  },
  {
    name: "admin-edit-emote-emote-id",
    path: "/admin/edit/emote/emote/:id()",
    meta: _91id_93bJCXP6F4mVOulQI2dpDnIcjLWXqy4RzC79mFe1jbBvUMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/edit/emote/emote/[id].vue")
  },
  {
    name: "admin-edit-emote-sticker-id",
    path: "/admin/edit/emote/sticker/:id()",
    meta: _91id_93ruynvL2n_DDRWNwvScCazqNzYTi_zSQwiEE0ebaieHoMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/edit/emote/sticker/[id].vue")
  },
  {
    name: "admin-edit-organization-id",
    path: "/admin/edit/organization/:id()",
    meta: _91id_93YJf2Zm8jDOPtr7fwo_S5neHnYLSAO_457D1JSdYdD4tnYMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/edit/organization/[id].vue")
  },
  {
    name: _91id_93b11HFwcLZM28QQUwYINALtF8JiHRpVEOdK2rGDQFwr8Meta?.name,
    path: "/admin/edit/title/:id()",
    meta: _91id_93b11HFwcLZM28QQUwYINALtF8JiHRpVEOdK2rGDQFwr8Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/edit/title/[id].vue"),
    children: [
  {
    name: "admin-edit-title-id-access",
    path: "access",
    component: () => import("/builds/namicomi/frontend/pages/admin/edit/title/[id]/access.vue")
  },
  {
    name: "admin-edit-title-id",
    path: "",
    component: () => import("/builds/namicomi/frontend/pages/admin/edit/title/[id]/index.vue")
  }
]
  },
  {
    name: "admin-edit-user-id",
    path: "/admin/edit/user/:id()",
    meta: _91id_938nru3UaCR8dK09kS1F0FsfvibGxc1Gmpuy4JiHA_rNUMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/edit/user/[id].vue")
  },
  {
    name: "admin-engagement-achievements-create",
    path: "/admin/engagement/achievements/create",
    meta: createoXUOdW4xhlb0GsQ8Tcpdxe6DOjoxzmcvxZEQQkiRkwoMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/achievements/create.vue")
  },
  {
    name: "admin-engagement-achievements-edit-id",
    path: "/admin/engagement/achievements/edit/:id()",
    meta: _91id_93516DD7HMOyiNxlyFbgXulqCq6BJn_ToGGgyV0LYNf_QMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/achievements/edit/[id].vue")
  },
  {
    name: "admin-engagement-achievements",
    path: "/admin/engagement/achievements",
    meta: indexYxOTGqHJ0ckuu5KILwcm7eoWDiJfLqRTcmcatw2mzlMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/achievements/index.vue")
  },
  {
    name: "admin-engagement-badges-award-id",
    path: "/admin/engagement/badges/award/:id()",
    meta: _91id_93O4up8Ych1JcYhrhilqZ8cqfO3IT2cW9zRvt37slvyE8Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/badges/award/[id].vue")
  },
  {
    name: "admin-engagement-badges-create",
    path: "/admin/engagement/badges/create",
    meta: createNQ6AmLvKwFW2XzjPzL0XD5TUhxO5fzKnzjn_qSfy0iwMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/badges/create.vue")
  },
  {
    name: "admin-engagement-badges-edit-id",
    path: "/admin/engagement/badges/edit/:id()",
    meta: _91id_935Jnr8rlZuI0Nzzea7rAnx4ba_KNGWJV4wkwI82UQgqwMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/badges/edit/[id].vue")
  },
  {
    name: "admin-engagement-badges",
    path: "/admin/engagement/badges",
    meta: indexfIfjOMsolwrCOyOqOEB2dAI9UNoMn4yTWjg95lsYAgQMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/badges/index.vue")
  },
  {
    name: "admin-engagement-emotes-create",
    path: "/admin/engagement/emotes/create",
    meta: createjUjd3sXR6JSScx3I6liEhg6v6xSIUgIesS9SP7DMptMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/emotes/create.vue")
  },
  {
    name: "admin-engagement-emotes-edit-id",
    path: "/admin/engagement/emotes/edit/:id()",
    meta: _91id_93D21j2Gy0G3u7Joai471BjTDxxMKaeLZAeA0W3EKtEjEMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/emotes/edit/[id].vue")
  },
  {
    name: "admin-engagement-emotes",
    path: "/admin/engagement/emotes",
    meta: index47xAe925QwJRzj2yWgrvgvQYOVPBQWg9T5_fq2ZI9RgMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/emotes/index.vue")
  },
  {
    name: "admin-engagement-frames-create",
    path: "/admin/engagement/frames/create",
    meta: createboSMAuABsI7HdPc7r1YAU9Ecwrh8XChWT2yR4BEfy_QMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/frames/create.vue")
  },
  {
    name: "admin-engagement-frames-edit-id",
    path: "/admin/engagement/frames/edit/:id()",
    meta: _91id_93nBk9X_vT_45x7LP2ZEokrNSmvl_5wV883kI3_5Dpe0JUsMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/frames/edit/[id].vue")
  },
  {
    name: "admin-engagement-frames",
    path: "/admin/engagement/frames",
    meta: indexKpYe3djV_45ktusE_45Pf_srNZV_45DeuG9VsdCATaBnWPsbQMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/frames/index.vue")
  },
  {
    name: "admin-engagement-slots-create",
    path: "/admin/engagement/slots/create",
    meta: createqGgjj0DXmC77EKcKNLOrRJ_ofWfoJXMvaGMPY6zqgjIMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/slots/create.vue")
  },
  {
    name: "admin-engagement-slots-edit-id",
    path: "/admin/engagement/slots/edit/:id()",
    meta: _91id_93s_Y8euawupJDZRl1kCicWCkPpapivaHUKjbp_R_456mDoMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/slots/edit/[id].vue")
  },
  {
    name: "admin-engagement-slots",
    path: "/admin/engagement/slots",
    meta: indexnCQx3xirIH8E0GLNrQNaJyEhaxULpDugvOpRMIZprOoMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/slots/index.vue")
  },
  {
    name: "admin-engagement-stickers-create",
    path: "/admin/engagement/stickers/create",
    meta: create5E5FSG3c5OiQilMlI9gY8N2bSxszpCRZY9uVxNQfs38Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/stickers/create.vue")
  },
  {
    name: "admin-engagement-stickers-edit-id",
    path: "/admin/engagement/stickers/edit/:id()",
    meta: _91id_93VfqBl3rRN9POf7HTSk9Zkvx9O2xvr__45IaTmdEfTTM4sMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/stickers/edit/[id].vue")
  },
  {
    name: "admin-engagement-stickers",
    path: "/admin/engagement/stickers",
    meta: indexXQ2p_459vLyNNT9gW710jZLB8RFAPJ_451yRkeiAdOmaIoQMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/engagement/stickers/index.vue")
  },
  {
    name: "admin-homepage-announcements",
    path: "/admin/homepage/announcements",
    meta: announcementsVxKMDG_58mujghQV9Or_ta6DFoJV1XKzScc2j2NelWgMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/announcements.vue")
  },
  {
    name: "admin-homepage-asset-id",
    path: "/admin/homepage/asset/:id()",
    meta: _91id_930mRHx7jKC13_45nrSyFErou2WIL7NDIoxxigGX2yaTjRQMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/asset/[id].vue")
  },
  {
    name: "admin-homepage-asset-create",
    path: "/admin/homepage/asset/create",
    meta: createHmpeAwxG0_VVCTs_KfTKvXMx7KEdiEBVFjDZY0fcw1gMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/asset/create.vue")
  },
  {
    name: "admin-homepage-asset-group-group",
    path: "/admin/homepage/asset/group/:group()",
    meta: _91group_93w0shpjyggQi4gVXS0p0lmnyOH8mItUuO2cwvlYOWVl4Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/asset/group/[group].vue")
  },
  {
    name: "admin-homepage-asset-group-create",
    path: "/admin/homepage/asset/group/create",
    meta: createc88SexscBT_X6i80Qiv3Oi72_45zk_45z9vyQ8Jzjf5PE_4Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/asset/group/create.vue")
  },
  {
    name: "admin-homepage-assets",
    path: "/admin/homepage/assets",
    meta: assetsDqs7tQ7nDgVexIklRV6ock2gIyuAeXTLEKM0kiEeEEgMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/assets.vue")
  },
  {
    name: "admin-homepage-carousel-id",
    path: "/admin/homepage/carousel/:id()",
    meta: _91id_93Mev_45d1xfG_45tr2e3vQF4Qw_45Te5JBhdQAoCx3OlO17SuIMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/carousel/[id].vue")
  },
  {
    name: "admin-homepage-carousel-create",
    path: "/admin/homepage/carousel/create",
    meta: createhzMmXZTXk8XMMt55jyUf0gLcj47aiH0yLnKoS0CTVSAMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/carousel/create.vue")
  },
  {
    name: "admin-homepage-carousels",
    path: "/admin/homepage/carousels",
    meta: carouselstWOFYUKDi5Ih2Gze11x74tQd8N5QjXcBukRYtluXnZ8Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/carousels.vue")
  },
  {
    name: "admin-homepage-list-id",
    path: "/admin/homepage/list/:id()",
    meta: _91id_93zN22EdRo5yEIUnSfmlv0dfF6IXkCBpO6PNfVYVVe_0YMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/list/[id].vue")
  },
  {
    name: "admin-homepage-list-create",
    path: "/admin/homepage/list/create",
    meta: createhN9Y3Xc8Zw1ENecukLzAbQK9ZgP3i4iY5xOYl_6jSGYMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/list/create.vue")
  },
  {
    name: "admin-homepage-staff-picks",
    path: "/admin/homepage/staff-picks",
    meta: staff_45picks6nda9tlFybXyb6WbOsYqgfpAxYLRxtY7RF2_5aOI6bcMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/homepage/staff-picks.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexNlgxbQw1SHPpaRWTOfynYIXtrlBO4RqQhvd2IddQt4sMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/index.vue")
  },
  {
    name: "admin-logs",
    path: "/admin/logs",
    meta: logs1UCDAQZ7h6idRsor12cTmjvFjBuOcI8nUeCRAr2ZnoMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/logs.vue")
  },
  {
    name: "admin-organizations",
    path: "/admin/organizations",
    meta: organizationsWvPcmj_45oaNi5_85OXB8z7tPW62jpyi_gdrOvwWjDfUIMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/organizations.vue")
  },
  {
    name: "admin-payments",
    path: "/admin/payments",
    component: () => import("/builds/namicomi/frontend/pages/admin/payments.vue"),
    children: [
  {
    name: "admin-payments-accounts",
    path: "accounts",
    meta: accountsMO5xUp5AgRWJaglKhgt0mSKEX7TmpNu2uLAV5_UbUmkMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/payments/accounts.vue")
  },
  {
    name: "admin-payments-balance",
    path: "balance",
    meta: balancezR_45yv9W9FDwp3IydlqaEP9fsZksfXsrYS4C_45Fi551PgMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/payments/balance.vue")
  },
  {
    name: "admin-payments-ledger",
    path: "ledger",
    meta: ledger9LScQXGi3lFpZ2hH8U7bkPBplskWrLNMqaJi42STNJsMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/payments/ledger.vue")
  },
  {
    name: "admin-payments-payouts",
    path: "payouts",
    meta: payouts2RXvLYiZqle84vl1iTpDfSivqOccqqDt_45cjqFdlNjWMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/payments/payouts.vue")
  }
]
  },
  {
    name: "admin-reports",
    path: "/admin/reports",
    meta: reportsvCR3GrgJkf5AY9ta2z_LvZb6QNA_45SxI_45_4EuVSTRwWQMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/reports.vue")
  },
  {
    name: "admin-stats-creators",
    path: "/admin/stats/creators",
    meta: creators6Ma4N6rY_vQUKKP_jcXk4r6GMPI7xNjNZFcMGkNNm3cMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/stats/creators.vue")
  },
  {
    name: "admin-stats-users",
    path: "/admin/stats/users",
    meta: userspxBr0SiRTh6by6Rn2_6icRfo9_MKjOqiCqFuziS_ILMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/stats/users.vue")
  },
  {
    name: "admin-titles",
    path: "/admin/titles",
    meta: titleskuP2lRwSFX3vGBxaAIcVb_E0LzkSBkHq2SLmlaZT7nQMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/titles.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: usersY6sxKsPRfrCp1SuARn8fJZzTnVYIeagduvPobMegPrYMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/admin/users.vue")
  },
  {
    name: "bin-careers",
    path: "/bin/careers",
    component: () => import("/builds/namicomi/frontend/pages/bin/careers.vue")
  },
  {
    name: "bin-dashboard",
    path: "/bin/dashboard",
    component: () => import("/builds/namicomi/frontend/pages/bin/dashboard.vue")
  },
  {
    name: "bin-genre-id",
    path: "/bin/genre/:id()",
    component: () => import("/builds/namicomi/frontend/pages/bin/genre/[id].vue")
  },
  {
    name: "bin-groups",
    path: "/bin/groups",
    component: () => import("/builds/namicomi/frontend/pages/bin/groups.vue")
  },
  {
    name: "bin-login",
    path: "/bin/login",
    meta: loginhSDXhT6_458oUuxIMXVRnFnDgi0isXHpU8qSaoqhSBU7UMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/bin/login.vue")
  },
  {
    name: "bin-logout",
    path: "/bin/logout",
    component: () => import("/builds/namicomi/frontend/pages/bin/logout.vue")
  },
  {
    name: "bin-messages",
    path: "/bin/messages",
    component: () => import("/builds/namicomi/frontend/pages/bin/messages.vue")
  },
  {
    name: "bin-my",
    path: "/bin/my",
    component: () => import("/builds/namicomi/frontend/pages/bin/my.vue"),
    children: [
  {
    name: "bin-my-history",
    path: "history",
    component: () => import("/builds/namicomi/frontend/pages/bin/my/history.vue")
  },
  {
    name: "bin-my-library",
    path: "library",
    component: () => import("/builds/namicomi/frontend/pages/bin/my/library.vue")
  },
  {
    name: "bin-my-lists",
    path: "lists",
    component: () => import("/builds/namicomi/frontend/pages/bin/my/lists.vue")
  },
  {
    name: "bin-my-updates",
    path: "updates",
    component: () => import("/builds/namicomi/frontend/pages/bin/my/updates.vue")
  }
]
  },
  {
    name: "bin-news",
    path: "/bin/news",
    component: () => import("/builds/namicomi/frontend/pages/bin/news.vue"),
    children: [
  {
    name: "bin-news-id",
    path: ":id()",
    component: () => import("/builds/namicomi/frontend/pages/bin/news/[id].vue")
  }
]
  },
  {
    name: "bin-press",
    path: "/bin/press",
    component: () => import("/builds/namicomi/frontend/pages/bin/press.vue")
  },
  {
    name: "bin-purchases",
    path: "/bin/purchases",
    component: () => import("/builds/namicomi/frontend/pages/bin/purchases.vue")
  },
  {
    name: "bin-reports",
    path: "/bin/reports",
    component: () => import("/builds/namicomi/frontend/pages/bin/reports.vue")
  },
  {
    name: "bin-reset",
    path: "/bin/reset",
    meta: resetLLTUaPkcv2cxRGURaoDm3d0KZ06FqmbT3LaJkaxbr5sMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/bin/reset.vue")
  },
  {
    name: "bin-signup",
    path: "/bin/signup",
    meta: signupUcDnqunkECaR6jtOf8kYeNcwr1txug5gOlNkD2HL8gAMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/bin/signup.vue")
  },
  {
    name: "bin-studio",
    path: "/bin/studio",
    component: () => import("/builds/namicomi/frontend/pages/bin/studio.vue")
  },
  {
    name: "c-id",
    path: "/c/:id()",
    component: () => import("/builds/namicomi/frontend/pages/c/[id].vue")
  },
  {
    name: "discord-callback",
    path: "/discord/callback",
    component: () => import("/builds/namicomi/frontend/pages/discord/callback.vue")
  },
  {
    name: "discord-guild-callback",
    path: "/discord/guild/callback",
    component: () => import("/builds/namicomi/frontend/pages/discord/guild/callback.vue")
  },
  {
    name: "embed-signin",
    path: "/embed/signin",
    component: () => import("/builds/namicomi/frontend/pages/embed/signin.vue")
  },
  {
    name: "embed-social",
    path: "/embed/social",
    meta: social2PqoVyCbfqBx0_45b0bhwLmTVPK2X253f_45VY5Ms7KsWN4Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/embed/social.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexT_45Ssl69TrtKsF2mar_4hwpLx3oI01sNlsWQg5pex7rwMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/index.vue")
  },
  {
    name: "l-id",
    path: "/l/:id()",
    component: () => import("/builds/namicomi/frontend/pages/l/[id].vue")
  },
  {
    name: "n-id",
    path: "/n/:id()",
    component: () => import("/builds/namicomi/frontend/pages/n/[id].vue")
  },
  {
    name: "o-id",
    path: "/o/:id()",
    component: () => import("/builds/namicomi/frontend/pages/o/[id].vue")
  },
  {
    name: "p-id",
    path: "/p/:id()",
    component: () => import("/builds/namicomi/frontend/pages/p/[id].vue")
  },
  {
    name: "publish",
    path: "/publish",
    meta: publish11jF8WuG89CKM1mOHR8PF4CXeZeiDpLlrkfebZhVFo4Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/publish.vue")
  },
  {
    name: _91org_931o_gZo21PrTLk8Pe_LBdVQFenVYW2ns5hK5oeZlpOFkMeta?.name,
    path: "/studio/:org()",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org].vue"),
    children: [
  {
    name: "studio-org-audience-acquisition",
    path: "audience-acquisition",
    meta: audience_45acquisitionBw9wwhO3ihcmKo1aCQU_pLqH5DEQnyX9crhOk6P6DQwMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/audience-acquisition.vue"),
    children: [
  {
    name: "studio-org-audience-acquisition-external",
    path: "external",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/audience-acquisition/external.vue")
  },
  {
    name: "studio-org-audience-acquisition-internal",
    path: "internal",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/audience-acquisition/internal.vue")
  }
]
  },
  {
    name: "studio-org-audience-demographics",
    path: "audience-demographics",
    meta: audience_45demographicsDPVEvvgKpozCqTaT1FPGbErkE_fV3vtDvt77w6KSsaUMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/audience-demographics.vue")
  },
  {
    name: "studio-org-audience-overview",
    path: "audience-overview",
    meta: audience_45overviewxxid_452hRt43gc5hADDs_QzGzs_6KcL_Bnp_haMDGOyUMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/audience-overview.vue"),
    children: [
  {
    name: "studio-org-audience-overview-followers",
    path: "followers",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/audience-overview/followers.vue")
  },
  {
    name: "studio-org-audience-overview-page-views",
    path: "page-views",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/audience-overview/page-views.vue")
  },
  {
    name: "studio-org-audience-overview-subscribers",
    path: "subscribers",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/audience-overview/subscribers.vue")
  },
  {
    name: "studio-org-audience-overview-summary",
    path: "summary",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/audience-overview/summary.vue")
  },
  {
    name: "studio-org-audience-overview-users",
    path: "users",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/audience-overview/users.vue")
  }
]
  },
  {
    name: "studio-org-chapter-id",
    path: "chapter/:id()",
    meta: _91id_93J3A6aiRDfQQOm3AhdfpvNDEMD0Ln5mGPxaimQYQSjisMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/chapter/[id].vue")
  },
  {
    name: "studio-org-draft-id",
    path: "draft/:id()",
    meta: _91id_93H_r_459r5_452M8ZDcuV2GmfuNe0T2isVI14e_2ttMUaDfwMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/draft/[id].vue")
  },
  {
    name: emotesePhIuHRrB2v__AfIqW5S1qQRCbpRN1FvevFOaIGn_U0Meta?.name,
    path: "emotes",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/emotes.vue"),
    children: [
  {
    name: "studio-org-emotes-create",
    path: "create",
    meta: createcoHLbP8_avEvnQlwKutzA8A405O0SDZJbYjAjmAlntUMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/emotes/create.vue")
  },
  {
    name: "studio-org-emotes-edit-id",
    path: "edit/:id()",
    meta: _91id_93u7tftzHtDZic_45LUfGqim1abcZeFiPJ3HZ_QMlsDUl8gMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/emotes/edit/[id].vue")
  },
  {
    name: "studio-org-emotes",
    path: "",
    meta: indexZ_45vytCcxkAUiHYTGWd22ROOBpGu2xJqN_45DRQcfQGYI4Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/emotes/index.vue")
  }
]
  },
  {
    name: framesOnr0F6oItukrWxJr0zWevrQJqr65X8RZ3VLtv6Po0fgMeta?.name,
    path: "frames",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/frames.vue"),
    children: [
  {
    name: "studio-org-frames-create",
    path: "create",
    meta: create0qaR7Oh_jFvB9Lh7EuaYZYM3xNTlsX_aLLVQL3lqpEEMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/frames/create.vue")
  },
  {
    name: "studio-org-frames-edit-id",
    path: "edit/:id()",
    meta: _91id_936kvdSu_45uT0cRXpqSIWmPUFtXBVYAqJ5e0NVdCKRNcKEMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/frames/edit/[id].vue")
  },
  {
    name: "studio-org-frames",
    path: "",
    meta: indexjEpIgnVIXp69zPz7dOGYskp4guf7mN0SI2PE2RbnmYYMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/frames/index.vue")
  }
]
  },
  {
    name: "studio-org",
    path: "",
    meta: index1U4dtQ53J8mlARsJH4c_OGSte6buQDLzFpvV1nGmmiMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/index.vue")
  },
  {
    name: "studio-org-insights",
    path: "insights",
    meta: insightsu9LDk4Ws1jHnlQ1r7NMYzLbCmOfl7zBW0ot49p7M7qsMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/insights.vue")
  },
  {
    name: setup7p09ASQ5g7K_45RgHwtCsTuXX7BpxiF45A3C9AAk7O_45rQMeta?.name,
    path: "payments/setup",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/payments/setup.vue"),
    children: [
  {
    name: "studio-org-payments-setup",
    path: "",
    meta: index2UG4Ggmye4GC0wINOOMKphm1Zh5d4bWy8BmQEtF325EMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/payments/setup/index.vue")
  },
  {
    name: "studio-org-payments-setup-provider",
    path: "provider",
    meta: providernbulaorhd5WF0rzZzHQbIJ7_45Z8K6I1b74hr2PX9xIxoMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/payments/setup/provider.vue"),
    children: [
  {
    name: "studio-org-payments-setup-provider-paypal",
    path: "paypal",
    meta: paypal5vy1GhWdgVa1y_455ym1d5hcRE1MPYpP9W42Mx5vRaGpsMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/payments/setup/provider/paypal.vue")
  },
  {
    name: "studio-org-payments-setup-provider-stripe",
    path: "stripe",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/payments/setup/provider/stripe.vue")
  }
]
  }
]
  },
  {
    name: settingsOOojvhswb127OjwF6CLfOk9VP3ZKBCc2jxoaz3gZSkUMeta?.name,
    path: "settings",
    meta: settingsOOojvhswb127OjwF6CLfOk9VP3ZKBCc2jxoaz3gZSkUMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/settings.vue"),
    children: [
  {
    name: "studio-org-settings-apps",
    path: "apps",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/settings/apps.vue")
  },
  {
    name: "studio-org-settings-billing",
    path: "billing",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/settings/billing.vue")
  },
  {
    name: "studio-org-settings-edit",
    path: "edit",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/settings/edit.vue")
  },
  {
    name: "studio-org-settings",
    path: "",
    meta: indexnYlCpRj20mx_459DuEYzixjPJNxs71Zr9Mr7KcHQMnUzgMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/settings/index.vue")
  },
  {
    name: "studio-org-settings-notifications",
    path: "notifications",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/settings/notifications.vue")
  },
  {
    name: "studio-org-settings-team-user",
    path: "team/:user()",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/settings/team/[user].vue")
  },
  {
    name: "studio-org-settings-team",
    path: "team",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/settings/team/index.vue")
  }
]
  },
  {
    name: stickersWmOMjgqLn_FQkCmCQ3YLgxj0z5WL3VWAIRDAWPBmcoIMeta?.name,
    path: "stickers",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/stickers.vue"),
    children: [
  {
    name: "studio-org-stickers-create",
    path: "create",
    meta: createsjZOSwlxLhm3zq8pjY_SFBqVb_Q5QLLa8o5zbsrJJ8kMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/stickers/create.vue")
  },
  {
    name: "studio-org-stickers-edit-id",
    path: "edit/:id()",
    meta: _91id_93IZDsSxfI_JjUojrQ9KQ05gyjnBrFUtNmAxr5hPECPbcMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/stickers/edit/[id].vue")
  },
  {
    name: "studio-org-stickers",
    path: "",
    meta: indexOUjoF_45lOUqbRLTJz5jD3DWaoWAncfOddH22LhBdyER0Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/stickers/index.vue")
  }
]
  },
  {
    name: "studio-org-subscriptions",
    path: "subscriptions",
    meta: subscriptions3BS_8wMUHbhQNkF7wxhypIzWSvMfJADKgMCyQT3JbW4Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/subscriptions.vue"),
    children: [
  {
    name: "studio-org-subscriptions-account",
    path: "account",
    meta: accountbKDIvejpWFuDOzWNuTmbWmoebftLPrl7zTsk2fX11hIMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/subscriptions/account.vue")
  },
  {
    name: "studio-org-subscriptions-balance",
    path: "balance",
    meta: balancepFDfY5FhodO9hVByOXvuIGtunrm1Rj9oIl0Z3fDuD30Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/subscriptions/balance.vue"),
    children: [
  {
    name: "studio-org-subscriptions-balance-documents",
    path: "documents",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/subscriptions/balance/documents.vue")
  },
  {
    name: "studio-org-subscriptions-balance-withdraw",
    path: "withdraw",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/subscriptions/balance/withdraw.vue")
  }
]
  },
  {
    name: "studio-org-subscriptions-insights",
    path: "insights",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/subscriptions/insights.vue")
  },
  {
    name: "studio-org-subscriptions-overview",
    path: "overview",
    meta: overviewaplODElBFqtar4B64_w8bmfE57r6UenGUQ_Zkep1iNsMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/subscriptions/overview.vue")
  },
  {
    name: "studio-org-subscriptions-tier-id",
    path: "tier/:id()",
    meta: _91id_93mK1ibwCPivHrEUGKQ7V2fNwjg_7dBqVpe7SHzzQMy5UMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/subscriptions/tier/[id].vue")
  },
  {
    name: "studio-org-subscriptions-tier-create",
    path: "tier/create",
    meta: createzTQtL302IIO1_45wlVYp7xap9jv6I9PVJC1xMcza12gQMMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/subscriptions/tier/create.vue")
  },
  {
    name: "studio-org-subscriptions-tiers",
    path: "tiers",
    meta: tiers3GDbiw7EaSWH5jfbdT3GAWYJNW9imKCbZAejuAqtP9AMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/subscriptions/tiers.vue")
  }
]
  },
  {
    name: "studio-org-title-id",
    path: "title/:id()",
    meta: _91id_93p6wZWB9P4yvV0SV5YexrzF_45ClLlC7kP_PErBPXIVygQMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/title/[id].vue"),
    children: [
  {
    name: "studio-org-title-id-access",
    path: "access",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/title/[id]/access.vue")
  },
  {
    name: "studio-org-title-id-art",
    path: "art",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/title/[id]/art.vue")
  },
  {
    name: "studio-org-title-id-chapters",
    path: "chapters",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/title/[id]/chapters.vue")
  },
  {
    name: "studio-org-title-id-collections-volume-collectionLocale",
    path: "collections/:volume()/:collectionLocale()",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/title/[id]/collections/[volume]/[collectionLocale].vue")
  },
  {
    name: "studio-org-title-id-collections",
    path: "collections",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/title/[id]/collections/index.vue")
  },
  {
    name: "studio-org-title-id-collections-new",
    path: "collections/new",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/title/[id]/collections/new.vue")
  },
  {
    name: "studio-org-title-id-contests",
    path: "contests",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/title/[id]/contests.vue")
  },
  {
    name: "studio-org-title-id-edit",
    path: "edit",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/title/[id]/edit.vue")
  },
  {
    name: "studio-org-title-id-publish",
    path: "publish",
    meta: publish9ih_45rLrKZmutOxcLXKKYL_47Xe3tpiNEkPOGj0aECwgMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/title/[id]/publish.vue")
  }
]
  },
  {
    name: "studio-org-title-create",
    path: "title/create",
    meta: create3YT_45NZLuq6QLUxMiqXouhkiFD9l0LrQIRJ8QzzkKY_45AMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/title/create.vue")
  },
  {
    name: titles_jDNn3xNXNxN0UAnvPrLgWmBQwmK8mCdeQMBVunXf20Meta?.name,
    path: "titles",
    meta: titles_jDNn3xNXNxN0UAnvPrLgWmBQwmK8mCdeQMBVunXf20Meta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/titles.vue"),
    children: [
  {
    name: "studio-org-titles",
    path: "",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/titles/index.vue")
  },
  {
    name: "studio-org-titles-rejected",
    path: "rejected",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/titles/rejected.vue")
  },
  {
    name: "studio-org-titles-submitted",
    path: "submitted",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/titles/submitted.vue")
  },
  {
    name: "studio-org-titles-unpublished",
    path: "unpublished",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/titles/unpublished.vue")
  },
  {
    name: "studio-org-titles-unsubmitted",
    path: "unsubmitted",
    component: () => import("/builds/namicomi/frontend/pages/studio/[org]/titles/unsubmitted.vue")
  }
]
  }
]
  },
  {
    name: "studio-create",
    path: "/studio/create",
    meta: creater0gYjwaZpzrHJ5AiN_t1SHc1zHL8K26COQDsdsOwbEkMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/create.vue")
  },
  {
    name: "studio",
    path: "/studio",
    meta: index9koK9sdPvMHAWBFHzoNI1VqZQGEDejEpAMHmMMQOJfoMeta || {},
    component: () => import("/builds/namicomi/frontend/pages/studio/index.vue")
  },
  {
    name: "t-id",
    path: "/t/:id()",
    component: () => import("/builds/namicomi/frontend/pages/t/[id].vue")
  },
  {
    name: "u-id",
    path: "/u/:id()",
    component: () => import("/builds/namicomi/frontend/pages/u/[id].vue")
  }
]
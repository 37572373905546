import { nanoid } from "nanoid";

export type UseVirtualScrollerOptions = {
  area?: {
    width: number;
    height: number;
  };
  renderArea?: {
    width: number;
    height: number;
  };
  pollingRate?: number;
};

export type VirtualItem = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  isRendered: boolean;
  isVisible: boolean;
};

export type AreaMetadata = {
  renderStartX: number;
  renderStartY: number;
  renderEndX: number;
  renderEndY: number;
  visibleStartX: number;
  visibleStartY: number;
  visibleEndX: number;
  visibleEndY: number;
};

export function useVirtualScroller({
  area = { width: 2048, height: 2048 },
  renderArea = { width: 4096, height: 4096 },
  pollingRate = 100,
}: UseVirtualScrollerOptions) {
  const position = { x: 0, y: 0 };
  const start = { x: 0, y: 0 };
  const items = reactive<VirtualItem[]>([]);

  const debouncedUpdate = createDeferredFn(updateItems, pollingRate);

  function update(dimension: "x" | "y", value: number) {
    position[dimension] = value;
    debouncedUpdate();
  }

  function updateStart(dimension: "x" | "y", value: number) {
    start[dimension] = value;
    debouncedUpdate();
  }

  function updateX(x: number) {
    update("x", x);
  }

  function updateY(y: number) {
    update("y", y);
  }

  function updateStartX(x: number) {
    updateStart("x", x);
  }

  function updateStartY(y: number) {
    updateStart("y", y);
  }

  function updateItems() {
    const meta = getCurrentAreaMetadata();

    items.forEach((item) => {
      item.isRendered = isItemRendered(item, meta);
      item.isVisible = isItemVisible(item, meta);
    });
  }

  function getCurrentAreaMetadata(): AreaMetadata {
    const xPosition = start.x + position.x;
    const yPosition = start.y + position.y;

    const renderStartX = xPosition - renderArea.width / 2;
    const renderStartY = yPosition - renderArea.height / 2;
    const renderEndX = xPosition + renderArea.width / 2;
    const renderEndY = yPosition + renderArea.height / 2;

    const visibleStartX = xPosition - area.width / 2;
    const visibleStartY = yPosition - area.height / 2;
    const visibleEndX = xPosition + area.width / 2;
    const visibleEndY = yPosition + area.height / 2;

    return {
      renderStartX,
      renderStartY,
      renderEndX,
      renderEndY,
      visibleStartX,
      visibleStartY,
      visibleEndX,
      visibleEndY,
    };
  }

  function isItemVisible(
    item: VirtualItem | Omit<VirtualItem, "isVisible" | "isRendered" | "id">,
    meta: AreaMetadata,
  ) {
    return (
      item.x + item.width >= meta.visibleStartX &&
      item.x <= meta.visibleEndX &&
      item.y + item.height >= meta.visibleStartY &&
      item.y <= meta.visibleEndY
    );
  }

  function isItemRendered(
    item: VirtualItem | Omit<VirtualItem, "isVisible" | "isRendered" | "id">,
    meta: AreaMetadata,
  ) {
    return (
      item.x + item.width >= meta.renderStartX &&
      item.x <= meta.renderEndX &&
      item.y + item.height >= meta.renderStartY &&
      item.y <= meta.renderEndY
    );
  }

  function addItem(
    item: Omit<VirtualItem, "isVisible" | "isRendered" | "id"> & {
      id?: string;
    },
  ) {
    const meta = getCurrentAreaMetadata();

    const filledItem: VirtualItem = {
      ...item,
      isRendered: isItemRendered(item, meta),
      isVisible: isItemVisible(item, meta),
      id: item.id ?? nanoid(),
    };

    const newLength = items.push(filledItem);
    return items[newLength - 1];
  }

  function removeItem(id: string) {
    const index = items.findIndex((i) => i.id === id);
    if (index !== -1) {
      items.splice(index, 1);
    }
  }

  return {
    updateX,
    updateY,
    updateStartX,
    updateStartY,
    addItem,
    removeItem,
    items,
  };
}

import { Notifications } from "~/src/api";
import { isNamiNotificationPayload } from "~/src/notifications/helpers";
import type { MessagePayload } from "firebase/messaging";

export type OnBackgroundNotificationsReceivedHook = (
  messages: MessagePayload[],
) => void;

export type NotificationsContextData = {
  allNotifications: Ref<MessagePayload[]>;
  readNotificationIds: Ref<Set<string>>;
  namiNotifications: ComputedRef<MessagePayload[]>;
  messageNotifications: ComputedRef<MessagePayload[]>;
  markNotificationAsRead(messageId: string): Promise<void>;
  onBackgroundNotificationsReceived: (
    hook: OnBackgroundNotificationsReceivedHook,
  ) => void;
  onBackgroundNotificationsReceivedHooks: OnBackgroundNotificationsReceivedHook[];
  firebaseToken: Ref<string | null>;
  onActivated: Ref<undefined | ((hook: (token: string) => any) => any)>;
  begForEnablePushFunction: Ref<(() => Promise<void>) | undefined>;
};

export const ALL_NOTIFICATIONS_KEY = "all-notifications";
export const READ_NOTIFICATION_IDS_KEY = "read-notification-ids";
export const NAMI_NOTIFICATIONS_KEY = "nami-notifications";
export const MESSAGES_NOTIFICATIONS_KEY = "message-notifications";
export const MARK_NOTIFICATION_AS_READ_KEY = "mark-notification-as-read";
export const ON_BACBGROUND_NOTIFICATIONS_RECEIVED_KEY =
  "on-background-notifications-received";
export const ON_BACBGROUND_NOTIFICATIONS_RECEIVED_HOOKS_KEY =
  "on-background-notifications-received-hooks";
export const FIREBASE_TOKEN_KEY = "firebase-token";
export const ON_ACTIVATED_KEY = "on-activated";
export const BEG_FOR_ENABLE_PUSH_FUNCTION = "beg-for-enable-push-function";

export function injectNotificationsContext(): NotificationsContextData {
  const context = {
    allNotifications: inject<NotificationsContextData["allNotifications"]>(
      ALL_NOTIFICATIONS_KEY,
    )!,
    readNotificationIds: inject<
      NotificationsContextData["readNotificationIds"]
    >(READ_NOTIFICATION_IDS_KEY)!,
    namiNotifications: inject<NotificationsContextData["namiNotifications"]>(
      NAMI_NOTIFICATIONS_KEY,
    )!,
    messageNotifications: inject<
      NotificationsContextData["messageNotifications"]
    >(MESSAGES_NOTIFICATIONS_KEY)!,
    markNotificationAsRead: inject<
      NotificationsContextData["markNotificationAsRead"]
    >(MARK_NOTIFICATION_AS_READ_KEY)!,
    onBackgroundNotificationsReceived: inject<
      NotificationsContextData["onBackgroundNotificationsReceived"]
    >(ON_BACBGROUND_NOTIFICATIONS_RECEIVED_KEY)!,
    onBackgroundNotificationsReceivedHooks: inject<
      NotificationsContextData["onBackgroundNotificationsReceivedHooks"]
    >(ON_BACBGROUND_NOTIFICATIONS_RECEIVED_HOOKS_KEY)!,
    firebaseToken:
      inject<NotificationsContextData["firebaseToken"]>(FIREBASE_TOKEN_KEY)!,
    onActivated:
      inject<NotificationsContextData["onActivated"]>(ON_ACTIVATED_KEY)!,
    begForEnablePushFunction: inject<
      NotificationsContextData["begForEnablePushFunction"]
    >(BEG_FOR_ENABLE_PUSH_FUNCTION)!,
  } satisfies NotificationsContextData;

  if (Object.values(context).some((v) => !v)) {
    throw new Error("notification_context_not_fully_available");
  }

  return context;
}

export function createNotificationsContext() {
  const allNotifications = ref<MessagePayload[]>([]);
  const readNotificationIds = ref(new Set<string>());
  const namiNotifications = computed(() =>
    allNotifications.value.filter(isNamiNotificationPayload),
  );
  const messageNotifications = computed(() =>
    allNotifications.value.filter(isNamiNotificationPayload),
  );
  const onBackgroundNotificationsReceivedHooks: NotificationsContextData["onBackgroundNotificationsReceivedHooks"] =
    [];
  const onBackgroundNotificationsReceived: NotificationsContextData["onBackgroundNotificationsReceived"] =
    (hook) => onBackgroundNotificationsReceivedHooks.push(hook);
  const firebaseToken = ref<string | null>(null);
  const onActivated = ref<undefined | ((hook: (token: string) => any) => any)>(
    undefined,
  );
  const begForEnablePushFunction = ref<(() => Promise<void>) | undefined>(
    undefined,
  );

  async function markNotificationAsRead(messageId: string) {
    await Notifications.markRead([messageId], false, await getTokenOrThrow());
    readNotificationIds.value.add(messageId);
  }

  provide<NotificationsContextData["allNotifications"]>(
    ALL_NOTIFICATIONS_KEY,
    allNotifications,
  );
  provide<NotificationsContextData["readNotificationIds"]>(
    READ_NOTIFICATION_IDS_KEY,
    readNotificationIds,
  );
  provide<NotificationsContextData["namiNotifications"]>(
    NAMI_NOTIFICATIONS_KEY,
    namiNotifications,
  );
  provide<NotificationsContextData["messageNotifications"]>(
    MESSAGES_NOTIFICATIONS_KEY,
    messageNotifications,
  );
  provide<NotificationsContextData["markNotificationAsRead"]>(
    MARK_NOTIFICATION_AS_READ_KEY,
    markNotificationAsRead,
  );
  provide<NotificationsContextData["onBackgroundNotificationsReceived"]>(
    ON_BACBGROUND_NOTIFICATIONS_RECEIVED_KEY,
    onBackgroundNotificationsReceived,
  );
  provide<NotificationsContextData["onBackgroundNotificationsReceivedHooks"]>(
    ON_BACBGROUND_NOTIFICATIONS_RECEIVED_HOOKS_KEY,
    onBackgroundNotificationsReceivedHooks,
  );
  provide<NotificationsContextData["firebaseToken"]>(
    FIREBASE_TOKEN_KEY,
    firebaseToken,
  );
  provide<NotificationsContextData["onActivated"]>(
    ON_ACTIVATED_KEY,
    onActivated,
  );
  provide<NotificationsContextData["begForEnablePushFunction"]>(
    BEG_FOR_ENABLE_PUSH_FUNCTION,
    begForEnablePushFunction,
  );

  return {
    allNotifications,
    readNotificationIds,
    namiNotifications,
    messageNotifications,
    markNotificationAsRead,
    onBackgroundNotificationsReceived,
    onBackgroundNotificationsReceivedHooks,
    firebaseToken,
    onActivated,
    begForEnablePushFunction,
  } satisfies NotificationsContextData;
}

import type { NamiNotification } from "./nami";
import type { TwilioNotification } from "./twilio";

export enum NotificationType {
  Nami,
  Twilio,
}

export type BaseNotification = {
  id: string;
  date: Date;
  collapseCount?: number;
};

export type Notification = NamiNotification | TwilioNotification;

import { assertSuccess } from "../core/assert";
import axios from "../core/axios";
import { paramsParser } from "../core/paramsParser";
import type {
  DeviceTokenEntity,
  NotificationEntryEntity,
  NotificationListParams,
  NotificationType,
  NotificationsPreferencesEntity,
} from "../types/notifications";
import type { CollectionResult, MessageResult, Result } from "../types/result";
import { Headers } from "./generic";

export enum NotificationPreference {
  MUTE = 0,
  BELL = 1,
  EMAIL = 2,
  PUSH = 4,
  BELL_AND_EMAIL = 3,
  BELL_AND_PUSH = 5,
  BELL_EMAIL_AND_PUSH = 7,
  EMAIL_AND_PUSH = 6,
}

export class Notifications {
  static async getPreferences(auth: string) {
    const resp = await axios<Result<NotificationsPreferencesEntity>>(
      `/notification/preferences`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async setPreferences(
    preferences: Record<NotificationType, NotificationPreference>,
    auth: string,
  ) {
    const resp = await axios<Result<NotificationsPreferencesEntity>>(
      `/notification/preferences`,
      {
        method: "POST",
        data: {
          notificationPreferences: preferences,
        },
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async setDeviceToken(token: string, auth: string) {
    const resp = await axios<Result<DeviceTokenEntity>>(
      `/notification/token/web`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: { token },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async removeDeviceToken(token: string) {
    const resp = await axios<
      MessageResult<"Device token was removed successfully">
    >(`/notification/token/web/${token}`, {
      method: "DELETE",
    });

    return assertSuccess(resp.data).result === "ok";
  }

  static async markRead(ids: string[], collapse: boolean, auth: string) {
    const resp = await axios<MessageResult<"Notifications marked as read">>(
      `/notification/read`,
      {
        method: "POST",
        data: {
          notificationIds: ids,
          collapse,
        },
        headers: Headers.Bearer(auth),
      },
    );

    return resp.data.result === "ok";
  }

  static async markReadBefore(before: string, auth: string) {
    const resp = await axios<MessageResult<"Notifications marked as read">>(
      `/notification/read`,
      {
        method: "POST",
        data: {
          createdAtBefore: before,
        },
        headers: Headers.Bearer(auth),
      },
    );

    return resp.data.result === "ok";
  }

  static async getSummary(auth: string) {
    const resp = await axios<CollectionResult<NotificationEntryEntity>>(
      `/notification/summary`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    const { data, meta } = assertSuccess(resp.data);

    return {
      data,
      meta,
    };
  }

  static async find(params: NotificationListParams = {}, auth: string) {
    const resp = await axios<CollectionResult<NotificationEntryEntity>>(
      `/notification${paramsParser(params)}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    const { data, meta } = assertSuccess(resp.data);

    return {
      data,
      meta,
    };
  }

  static async sendTest(
    token: string,
    deviceType: string,
    key: NotificationType,
    parameters: object,
    auth: string,
  ) {
    const resp = await axios<MessageResult<"Notification was dispatched">>(
      `/notification/test`,
      {
        method: "POST",
        data: {
          token,
          deviceType,
          key,
          parameters: parameters,
        },
        headers: Headers.Bearer(auth),
      },
    );

    return resp.data.result === "ok";
  }
}

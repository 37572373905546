import payload_plugin_jcD5OiTKWJEAx3J3PwSvP_PEPq_TY7hGat24oGU2lc4 from "/builds/namicomi/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_wK8QsL6wAsaN1D0pLzAdDSfVxW49TRJW95V5Kz16Jr8 from "/builds/namicomi/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._fe3d701d018244c9cb26ca7a24c94131/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QXUth12HcJf3LQRIQofUp2GPBFbo9GJ9iz6aEzsrPcU from "/builds/namicomi/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._fe3d701d018244c9cb26ca7a24c94131/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ua1FjpnvVw_frPMKmkLvSdeait57g09VpbCLk1O4zEQ from "/builds/namicomi/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._fe3d701d018244c9cb26ca7a24c94131/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_3SOVCPadTm6pFwkpwNhGKBhOlmIig9llNppc98Uzhwg from "/builds/namicomi/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._fe3d701d018244c9cb26ca7a24c94131/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_l3wPYnJPmmPMW4f7MzGRYVt7fERKNAw_s2VkZyJFPFI from "/builds/namicomi/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._fe3d701d018244c9cb26ca7a24c94131/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Xx90oimMwcRkYPb0U4oQQ9jpKoMRSZMCzcrW1hsatnI from "/builds/namicomi/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._fe3d701d018244c9cb26ca7a24c94131/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_TDu6puKQILlWR9fZo2iVpzEozLMZbwWn_gbBCnNQyIw from "/builds/namicomi/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._fe3d701d018244c9cb26ca7a24c94131/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_N9zpwHXcU8JTY6CwlekYtSaKGwbinW2VWmYVrt9VKGU from "/builds/namicomi/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/namicomi/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_ljTGyA5fbRWww7WKpnyR6oCFyka0u3gdCw2G1UybJb4 from "/builds/namicomi/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._fe3d701d018244c9cb26ca7a24c94131/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_fXPpxB_ZSw_rMlGMlcVXvjilrknbXiwAk_nSW1DpMK0 from "/builds/namicomi/frontend/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import motion_tNVL67j_ylBdrcN_SVJFYNvX1nleoqXjMiucfYS71_E from "/builds/namicomi/frontend/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import extensions_StQcECR3AtTIyHK8505hLlbdNVrNl6raWuEIMWfQLVE from "/builds/namicomi/frontend/plugins/extensions.ts";
import getStores_rQUufkiDL_3fk3vcA5BaSgq6rMj5xcjNsH48kDJv4DQ from "/builds/namicomi/frontend/plugins/getStores.ts";
import gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA from "/builds/namicomi/frontend/plugins/gtm.client.ts";
import i18n_M6WuPocwmDZfR2LKAqoIP7SPPiCebMfT5sB7ls3Be_c from "/builds/namicomi/frontend/plugins/i18n.ts";
import tanstack_WSexpMPWqBFfwl_2yvHtEZ0VggLhkxaUE_Dhq2uSa9Q from "/builds/namicomi/frontend/plugins/tanstack.ts";
export default [
  payload_plugin_jcD5OiTKWJEAx3J3PwSvP_PEPq_TY7hGat24oGU2lc4,
  revive_payload_client_wK8QsL6wAsaN1D0pLzAdDSfVxW49TRJW95V5Kz16Jr8,
  unhead_QXUth12HcJf3LQRIQofUp2GPBFbo9GJ9iz6aEzsrPcU,
  router_Ua1FjpnvVw_frPMKmkLvSdeait57g09VpbCLk1O4zEQ,
  payload_client_3SOVCPadTm6pFwkpwNhGKBhOlmIig9llNppc98Uzhwg,
  navigation_repaint_client_l3wPYnJPmmPMW4f7MzGRYVt7fERKNAw_s2VkZyJFPFI,
  check_outdated_build_client_Xx90oimMwcRkYPb0U4oQQ9jpKoMRSZMCzcrW1hsatnI,
  chunk_reload_client_TDu6puKQILlWR9fZo2iVpzEozLMZbwWn_gbBCnNQyIw,
  plugin_vue3_N9zpwHXcU8JTY6CwlekYtSaKGwbinW2VWmYVrt9VKGU,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_ljTGyA5fbRWww7WKpnyR6oCFyka0u3gdCw2G1UybJb4,
  plugin_fXPpxB_ZSw_rMlGMlcVXvjilrknbXiwAk_nSW1DpMK0,
  motion_tNVL67j_ylBdrcN_SVJFYNvX1nleoqXjMiucfYS71_E,
  extensions_StQcECR3AtTIyHK8505hLlbdNVrNl6raWuEIMWfQLVE,
  getStores_rQUufkiDL_3fk3vcA5BaSgq6rMj5xcjNsH48kDJv4DQ,
  gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA,
  i18n_M6WuPocwmDZfR2LKAqoIP7SPPiCebMfT5sB7ls3Be_c,
  tanstack_WSexpMPWqBFfwl_2yvHtEZ0VggLhkxaUE_Dhq2uSa9Q
]
<template>
  <NotificationsItem
    :is-unread
    :href="href ?? undefined"
    :action-icon="IconMessage"
    :date="notification.date"
  >
    <template #avatar>
      <UserAvatar
        v-if="notification.data.commenter.type === 'user'"
        :user="notification.data.commenter"
        :size="40"
        no-link
      />
      <OrganizationAvatar
        v-else-if="notification.data.commenter.type === 'organization'"
        :org="notification.data.commenter"
        :size="40"
      />
    </template>
    <template #content>
      <div class="space-y-2">
        <p class="line-clamp-2">
          <i18n-t
            keypath="notifications.social.reply_to_comment.body"
            scope="global"
          >
            <template #commenter>
              <strong>{{ commenterName }}</strong>
            </template>
          </i18n-t>
        </p>
        <p class="text-sm text-neutral-600 dark:text-neutral-400 line-clamp-2">
          <template v-if="commentContent">
            {{ commentContent }}
          </template>
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import type { NamiNotificationSocialReplyToComment } from "~/src/notifications/types/nami/social-reply-to-comment";
import type { Props } from "../NotificationsItem.vue";
import { IconMessage } from "@tabler/icons-vue";
import { stripMarkdown } from "~/utils/ui/excerpt";
import {
  Comment,
  isChapter,
  isComment,
  isExpanded,
  isOrg,
  isPost,
  isTitle,
  type CommentEntity,
  type OrganizationEntity,
  type UserEntity,
} from "~/src/api";

const props = defineProps<
  {
    notification: NamiNotificationSocialReplyToComment;
  } & Omit<Props, "actionIcon">
>();

const { data: href } = useAsyncData(
  `notification-link-${props.notification.id}`,
  async () => {
    const link = await getLinkToComment(props.notification.data.comment);
    return link;
  },
  { watch: [() => props.notification.data.comment] },
);

const commenterName = computed(() => {
  return props.notification.data.commenter.type === "user"
    ? getNameForUser(props.notification.data.commenter)
    : getNameForOrganization(props.notification.data.commenter);
});

const commentContent = computed(() => {
  const message = props.notification.data.comment.attributes.message;
  if (!message) return null;
  return stripMarkdown(message.replaceAll(/[\n]+/g, " "));
});

function getNameForUser(user: UserEntity) {
  return user.attributes.displayName ?? user.attributes.username;
}

function getNameForOrganization(organization: OrganizationEntity) {
  return organization.attributes.name;
}

async function getLinkToComment(
  comment: CommentEntity,
): Promise<string | undefined> {
  const chapterRelation = comment.relationships.find(isChapter);
  if (chapterRelation && isExpanded(chapterRelation)) {
    return linkTo(chapterRelation);
  }

  const postRelation = comment.relationships.find(isPost);
  if (postRelation && isExpanded(postRelation)) {
    return linkTo(postRelation);
  }

  const organizationRelation = comment.relationships.find(isOrg);
  if (organizationRelation && isExpanded(organizationRelation)) {
    return linkTo(organizationRelation);
  }

  const titleRelation = comment.relationships.find(isTitle);
  if (titleRelation && isExpanded(titleRelation)) {
    return linkTo(titleRelation);
  }

  const newsRelation = comment.relationships.find(
    (rel) => rel.type === "wp_news",
  );
  if (newsRelation) {
    return linkTo("wp_news", newsRelation.id);
  }

  // No link set so far, so there are no direct relationships.
  // What's left is for the reaction to be to a comment on another entity, let's fine it
  const commentRelation = comment.relationships.find(isComment);

  if (!commentRelation || !isExpanded(commentRelation)) return undefined;
  const deeperComment = await Comment.get(
    commentRelation.id,
    ["*"],
    await getTokenOrThrow(),
  );
  return getLinkToComment(deeperComment);
}
</script>

<template>
  <div v-if="isGroup && !avatarUrl" class="relative">
    <div
      v-for="(user, index) in otherUsers.slice(0, 3)"
      class="absolute left-0 top-0 w-full h-full grid"
      :class="{
        'grid-cols-[0_66%_0]': index === 0 && otherUsers.length > 2,
        'grid-cols-[0_66%_0] items-end': index === 0 && otherUsers.length === 2,
        'grid-cols-[1fr_66%_0]': index === 1,
        'grid-cols-[1fr_66%_1fr] items-end': index === 2,
      }"
      :style="{ zIndex: index }"
    >
      <div></div>
      <AsyncImage
        :src="
          getImageUrl(user.id, user.type, 'avatar', user.avatarFileName, '128')
        "
        class="aspect-square object-cover border-2 border-neutral-100 dark:border-black"
        :class="{
          'rounded-full': user.type === 'user',
          'rounded-md': user.type === 'organization',
        }"
      />
      <div></div>
    </div>
  </div>
  <AsyncImage
    v-else-if="avatarUrl"
    :src="avatarUrl"
    class="aspect-square object-cover p-1 rounded-full"
  />
  <AsyncImage
    v-else-if="otherUsers[0]"
    :src="
      getImageUrl(
        otherUsers[0].id,
        otherUsers[0].type,
        'avatar',
        otherUsers[0].avatarFileName,
        '128',
      )
    "
    class="w-full aspect-square object-cover p-1"
    :class="{
      'rounded-full': otherUsers[0].type === 'user',
      'rounded-md': otherUsers[0].type === 'organization',
    }"
  />
</template>

<script setup lang="ts">
import { type UserDisplayData } from "./common";

const props = defineProps<{
  avatarUrl?: string | null;
  hasAvatar?: boolean;
  users: UserDisplayData[];
  currentUser: UserDisplayData;
}>();

const { getImageUrl } = useMediaLink();

const otherUsers = computed(() => {
  const currentTwilioUserId = props.currentUser.id;
  return props.users.filter((user) => user.id !== currentTwilioUserId);
});
const isGroup = computed(() => otherUsers.value.length > 1);
</script>

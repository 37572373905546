import type {
  CollectionResult,
  Entity,
  GenericListParams,
  IncludeArr,
  MessageResult,
  Result,
  TitleListEntity,
} from "../types";
import axios from "../core/axios";
import { assertSuccess, paramsParser } from "../core";
import { nanoid } from "nanoid";

export const Headers = {
  JSON: { "Content-Type": "application/json" },
  Bearer: (token: string) => ({ Authorization: "Bearer " + token }),
  AcceptJSON: { Accept: "application/json" },
};

export class EntityView<
  RParams extends GenericListParams,
  E extends Entity<string, any>,
> {
  constructor(public apiPath: string = "") {}

  async find(params: Partial<RParams> = {}, auth?: string) {
    const resp = await axios.get<CollectionResult<E>>(
      this.apiPath + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  async get(id: string, includes: IncludeArr<E> = [], auth?: string) {
    const resp = await axios.get<Result<E>>(
      this.apiPath + `/${id}` + paramsParser({ includes }),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }
}

export class EntityViewSet<
  RParams extends GenericListParams,
  CBody extends object,
  UBody extends object,
  E extends Entity<string, any>,
> extends EntityView<RParams, E> {
  async create(body: CBody, auth: string) {
    const resp = await axios.post<Result<E>>(this.apiPath + "/create", body, {
      responseType: "json",
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data).data;
  }

  async update(id: string, body: UBody, auth: string) {
    const resp = await axios.post<Result<E>>(this.apiPath + `/${id}`, body, {
      responseType: "json",
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data).data;
  }

  async delete(id: string, version: number, auth: string) {
    const resp = await axios.delete<MessageResult>(
      this.apiPath + `/${id}?version=${version}`,
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }
}

export async function doFileUpload<E extends Entity<string, any>>(
  apiPath: string,
  image: File | Blob,
  auth: string,
) {
  const formData = new FormData();
  formData.append(`banner`, image, nanoid());

  const resp = await axios.post<Result<E>>(apiPath, formData, {
    method: "POST",
    headers: {
      ...Headers.Bearer(auth),
      "Content-Type": "multipart/form-data",
    },
  });

  return assertSuccess(resp.data).data;
}

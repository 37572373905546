import { Post, type PostEntity } from "~/src/api";
import { useGenericCache } from "./generic";

export function usePostCache(timeout = 1000 * 60) {
  const cache = useGenericCache<string, PostEntity>("posts", timeout);

  async function getById(id: string, authenticated = false) {
    const cached = cache.get(id);
    if (cached) return cached;

    const post = await Post.getPost(
      id,
      [
        "chapter",
        "emote",
        "gated_post_media",
        "organization",
        "post_embed",
        "post_media",
        "profile_frame",
        "repost",
        "title",
        "user",
      ],
      authenticated ? await getTokenOrThrow() : undefined,
    );
    cache.store(id, post);
    return post;
  }

  return {
    cache,
    getById,
  };
}

import type { Ref } from "vue";
import { marked } from "marked";

export const useMarkdownLink = <T extends HTMLElement>(
  parentContainer: Ref<T | undefined>,
  mdParser: typeof marked,
  text: Ref<string>,
) => {
  const rendered = ref("");

  watch(
    text,
    async (newText) => {
      rendered.value = await mdParser.parse(newText);

      nextTick().then(() => {
        if (!parentContainer.value) return;
        const links = parentContainer.value!.querySelectorAll("a");

        for (let link of links) {
          if (link.href?.indexOf(window.location.origin) === 0) {
            link.addEventListener(
              "click",
              (e) => {
                e.preventDefault();
                navigateTo(link.href?.replace(window.location.origin, ""));
              },
              false,
            );
          }
        }
      });
    },
    { immediate: true },
  );

  return rendered;
};

<template>
  <div class="org-avatar">
    <AsyncImage
      v-if="org"
      :src="avatar || getAbsoluteAssetLink('nami/profile/sea-deity.png')"
      alt="avatar"
      class="h-full w-full"
    />
    <AsyncImage v-else src="/img/sea-deity.png" alt="avatar" />
  </div>
</template>

<script setup lang="ts">
import { parseOrgForDisplay } from "~/utils/display/org";
import type { OrganizationEntity, OrganizationRelation } from "~/src/api";

interface Props {
  size?: number;
  org?: OrganizationEntity | OrganizationRelation;
}

const props = defineProps<Props>();

const pxSize = computed(() => (props.size ? props.size + "px" : "32px"));
const { avatar } = parseOrgForDisplay(
  computed(() => props.org),
  "small",
);
</script>

<style scoped lang="postcss">
.org-avatar {
  max-width: v-bind(pxSize);
  max-height: v-bind(pxSize);
  width: v-bind(pxSize);
  height: v-bind(pxSize);

  @apply rounded-md overflow-hidden flex;
}
</style>

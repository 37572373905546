export function useMappedLocale() {
  const nuxtApp = useNuxtApp();
  return computed(() => reverseLocaleMap(nuxtApp.$i18n.global.locale.value));
}

export function useHref() {
  const mappedLocale = useMappedLocale();

  return computed(() => ({
    home: `/${mappedLocale.value}`,
    contest: {
      overview: {
        index: `/${mappedLocale.value}/contest/overview`,
        about: `/${mappedLocale.value}/contest/overview/about`,
        rankings: `/${mappedLocale.value}/contest/overview/rankings`,
        winners: `/${mappedLocale.value}/contest/overview/winners`,
      },
    },
    title: {
      search: `/${mappedLocale.value}/title/search`,
      id: (id: string) => ({
        slug: (slug: string) => ({
          index: `/${mappedLocale.value}/title/${id}/${slug}`,
        }),
      }),
    },
    creators: {
      index: `/${mappedLocale.value}/creators`,
    },
    discover: {
      index: `/${mappedLocale.value}/discover`,
      tag: (tag: string) => `/${mappedLocale.value}/discover/${tag}`,
    },
    feed: {
      index: `/${mappedLocale.value}/feed`,
    },
    messages: {
      index: `/${mappedLocale.value}/messages`,
      id: (id: string) => `/${mappedLocale.value}/messages/${id}`,
    },
    news: {
      index: `/${mappedLocale.value}/news`,
    },
    settings: {
      privacy: `/${mappedLocale.value}/settings/privacy`,
      profile: `/${mappedLocale.value}/settings/profile`,
      sitePreferences: `/${mappedLocale.value}/settings/site-preferences`,
      subscription: {
        history: `/${mappedLocale.value}/settings/subscription/history`,
      },
    },
    test: {
      index: `/${mappedLocale.value}/test`,
    },
    org: {
      slug: {
        index: (slug: string) => `/${mappedLocale.value}/org/${slug}`,
        rewards: (slug: string) => `/${mappedLocale.value}/org/${slug}/rewards`,
        subscriptions: (slug: string) =>
          `/${mappedLocale.value}/org/${slug}/subscriptions`,
      },
    },
    publish: {
      overview: {
        index: `/${mappedLocale.value}/publish/overview`,
      },
      makemoney: {
        index: `/${mappedLocale.value}/publish/makemoney`,
      },
    },
    library: {
      status: {
        reading: `/${mappedLocale.value}/library/status/reading`,
      },
      index: `/${mappedLocale.value}/library`,
      lists: `/${mappedLocale.value}/library/lists`,
    },
    list: {
      id: (id: string) => `/${mappedLocale.value}/list/${id}`,
      edit: (id: string) => `/${mappedLocale.value}/list/edit/${id}`,
      create: `/${mappedLocale.value}/list/create`,
    },
    studio: {
      index: `/studio`,
      org: (orgId: string) => ({
        insights: {
          index: `/studio/${orgId}/insights`,
        },
        payments: {
          setup: {
            index: `/studio/${orgId}/payments/setup`,
            stripe: `/studio/${orgId}/payments/setup/provider/stripe`,
            paypal: `/studio/${orgId}/payments/setup/provider/paypal`,
          },
        },
        dashboard: `/studio/${orgId}`,
        experience: {
          emotes: {
            index: `/studio/${orgId}/emotes`,
            create: `/studio/${orgId}/emotes/create`,
            edit: (id: string) => `/studio/${orgId}/emotes/edit/${id}`,
          },
          stickers: {
            index: `/studio/${orgId}/stickers`,
            create: `/studio/${orgId}/stickers/create`,
            edit: (id: string) => `/studio/${orgId}/stickers/edit/${id}`,
          },
          frames: {
            index: `/studio/${orgId}/frames`,
            create: `/studio/${orgId}/frames/create`,
            edit: (id: string) => `/studio/${orgId}/frames/edit/${id}`,
          },
        },
        title: (titleId: string) => ({
          chapters: `/studio/${orgId}/title/${titleId}`,
        }),
        titles: {
          index: `/studio/${orgId}/titles`,
        },
        subscriptions: {
          account: `/studio/${orgId}/subscriptions/account`,
          balance: `/studio/${orgId}/subscriptions/balance`,
          tiers: `/studio/${orgId}/subscriptions/tiers`,
          tier: {
            edit: (id: string) => `/studio/${orgId}/subscriptions/tier/${id}`,
            create: `/studio/${orgId}/subscriptions/tier/create`,
          },
          overview: `/studio/${orgId}/subscriptions/overview`,
          insights: `/studio/${orgId}/subscriptions/insights`,
        },
        settings: {
          index: `/studio/${orgId}/settings`,
        },
        audienceOverview: {
          index: `/studio/${orgId}/audience-overview`,
        },
        audienceDemographics: {
          index: `/studio/${orgId}/audience-demographics`,
        },
        audienceAcquisition: {
          index: `/studio/${orgId}/audience-acquisition`,
        },
      }),
    },
    admin: {
      user: {
        index: "/admin/users",
        edit: (id: string) => `/admin/edit/user/${id}`,
      },
      approval: {
        titles: `/admin/approval/titles`,
        chapters: `/admin/approval/chapters`,
      },
      chapters: `/admin/chapters`,
      competitions: `/admin/competitions`,
      distribution: {
        index: `/admin/distribution`,
        jobs: `/admin/distribution/jobs`,
      },
      edit: {
        chapter: (id: string) => `/admin/edit/chapter/${id}`,
        emote: (id: string) => `/admin/edit/emote/${id}`,
        organization: (id: string) => `/admin/edit/organization/${id}`,
        title: (id: string) => ({
          index: `/admin/edit/title/${id}`,
          access: `/admin/edit/title/${id}/access`,
        }),
        user: (id: string) => `/admin/edit/user/${id}`,
      },
      engagement: {
        achievements: {
          index: "/admin/engagement/achievements",
          create: "/admin/engagement/achievements/create",
          edit: (id: string) => `/admin/engagement/achievements/edit/${id}`,
        },
        badges: {
          index: "/admin/engagement/badges",
          create: "/admin/engagement/badges/create",
          edit: (id: string) => `/admin/engagement/badges/edit/${id}`,
          award: (id: string) => `/admin/engagement/badges/award/${id}`,
        },
        emotes: {
          index: `/admin/engagement/emotes`,
          create: `/admin/engagement/emotes/create`,
          edit: (id: string) => `/admin/engagement/emotes/edit/${id}`,
        },
        stickers: {
          index: `/admin/engagement/stickers`,
          create: `/admin/engagement/stickers/create`,
          edit: (id: string) => `/admin/engagement/stickers/edit/${id}`,
        },
        frames: {
          index: `/admin/engagement/frames`,
          create: `/admin/engagement/frames/create`,
          edit: (id: string) => `/admin/engagement/frames/edit/${id}`,
        },
        slots: {
          index: `/admin/engagement/slots`,
          create: "/admin/engagement/slots/create",
          edit: (id: string) => `/admin/engagement/slots/edit/${id}`,
        },
      },
      homepage: {
        assets: `/admin/homepage/assets`,
        carousels: `/admin/homepage/carousels`,
        staffPicks: `/admin/homepage/staff-picks`,
      },
      organizations: `/admin/organizations`,
      stats: {
        creators: `/admin/stats/creators`,
        users: `/admin/stats/users`,
      },
      users: `/admin/users`,
      asset: {
        list: "/admin/homepage/assets",
        edit: (id: string) => `/admin/homepage/asset/${id}`,
        create: "/admin/homepage/asset/create",
        group: {
          create: "/admin/homepage/asset/group/create",
          edit: (group: string) => `/admin/homepage/asset/group/${group}`,
        },
      },
      banner: {
        list: "/admin/homepage/carousels",
        edit: (id: string) => `/admin/homepage/carousel/${id}`,
        create: "/admin/homepage/carousel/create",
      },
      list: {
        list: "/admin/homepage/staff-picks",
        edit: (id: string) => `/admin/homepage/list/${id}`,
        create: "/admin/homepage/list/create",
      },
      payments: {
        payouts: "/admin/payments/payouts",
        ledger: "/admin/payments/ledger",
        accounts: "/admin/payments/accounts",
        balance: "/admin/payments/balance",
      },
      reports: `/admin/reports`,
      titles: `/admin/titles`,
      dashboard: `/admin`,
      logs: "/admin/logs",
    },
    external: {
      helpCenter: `https://help.namicomi.com/hc/${mappedLocale.value}`,
    },
  }));
}

export function withQuery(path: string, query: Record<string, string>) {
  const url = new URL("https://example.com");
  url.pathname = path;
  Object.entries(query).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });
  return url.toString().replace("https://example.com", "");
}

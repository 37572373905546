import type { Ref } from "vue";
import type { MaybeRef } from "@vueuse/shared";
import {
  getMultipleRelationships,
  getRelationship,
  isExpanded,
  isRelationship,
  type ChapterEntity,
  type ChapterRelation,
  type ChapterState,
  type OrganizationRelation,
  type UserRelation,
  type AvailableLanguages,
  type ChapterKind,
  type VolumeKind,
} from "~/src/api";
import { linkTo } from "~/utils/functions";

export type ParsedChapterOutput = {
  id: string;
  name: string;
  chapterNo: string;
  volumeNo: string;
  language: AvailableLanguages;
  thumbnail: string;
  href: string;

  state: ChapterState;

  createdAt: string;
  updatedAt: string;
  publishAt: string | null;
  readableAt: string | null;
  wip: boolean;

  uploader: UserRelation | null;
  organizations: OrganizationRelation[];

  longName: string;
  shortName: string;
};

export type ParsedChapterOutputRefs = {
  [K in keyof ParsedChapterOutput]: Ref<ParsedChapterOutput[K]>;
};

const DefaultReturn: ParsedChapterOutput = {
  id: "",
  name: "",
  chapterNo: "",
  volumeNo: "",
  language: "en",
  thumbnail: "",
  state: "pending",
  href: "",

  createdAt: "",
  updatedAt: "",
  publishAt: new Date().toISOString(),
  readableAt: "",
  wip: false,

  uploader: null,
  organizations: [],

  longName: "",
  shortName: "",
};

export const getChapterName = (
  chapter: string | null,
  volume: string | null,
  name: string | null,
  length: "long" | "short" = "short",
  chapterLabel: ChapterKind = "chapter",
  volumeLabel: VolumeKind = "volume",
) => {
  let title = "";
  const translate = useNuxtApp().$i18n.global.t;
  if (volume)
    title += `${translate(`entity.title.volumeKind.${length}.${volumeLabel}`, {
      volume,
    })}, `;
  if (chapter)
    title += `${translate(
      `entity.title.chapterKind.${length}.${chapterLabel}`,
      {
        chapter,
      },
    )} `;
  if (!volume && !chapter) title += translate("reader.oneshot");
  if (name) title += " - " + name;
  return title;
};

export function parseChapterForDisplay(
  x: ChapterEntity | ChapterRelation | null | undefined,
  res?: "small" | "med" | "large" | "original",
  chapterLabel?: ChapterKind,
  volumeLabel?: VolumeKind,
): ParsedChapterOutput;
export function parseChapterForDisplay(
  x: Ref<ChapterEntity | ChapterRelation | null | undefined>,
  res?: "small" | "med" | "large" | "original",
  chapterLabel?: ChapterKind,
  volumeLabel?: VolumeKind,
): ParsedChapterOutputRefs;
export function parseChapterForDisplay(
  chapterInput: MaybeRef<ChapterEntity | ChapterRelation | null | undefined>,
  res: "small" | "med" | "large" | "original" = "original",
  chapterLabel: ChapterKind = "chapter",
  volumeLabel: VolumeKind = "volume",
): ParsedChapterOutput | ParsedChapterOutputRefs {
  const { getChapterThumbnail } = useMediaLink();

  const thumbRes =
    res === "small"
      ? "128"
      : res === "med"
        ? "256"
        : res === "large"
          ? "512"
          : "original";

  if (isRef(chapterInput)) {
    const id = computed(() => chapterInput.value?.id ?? "");
    const name = computed(() => chapterInput.value?.attributes?.name ?? "");
    const chapterNo = computed(
      () => chapterInput.value?.attributes?.chapter ?? "",
    );
    const volumeNo = computed(
      () => chapterInput.value?.attributes?.volume ?? "",
    );
    const language = computed(
      () => chapterInput.value?.attributes?.translatedLanguage ?? "en",
    );
    const thumbnail = computed(() =>
      chapterInput.value && isExpanded(chapterInput.value)
        ? (getChapterThumbnail(chapterInput.value, thumbRes) ?? "")
        : "",
    );
    const href = computed(() =>
      chapterInput.value ? linkTo(chapterInput.value) : "",
    );

    const state = computed(
      () => chapterInput.value?.attributes?.state ?? "pending",
    );

    const createdAt = computed(
      () => chapterInput.value?.attributes?.createdAt ?? "",
    );
    const updatedAt = computed(
      () => chapterInput.value?.attributes?.updatedAt ?? "",
    );
    const publishAt = computed(
      () => chapterInput.value?.attributes?.publishAt ?? "",
    );
    const readableAt = computed(
      () =>
        chapterInput.value?.attributes?.publishAt ??
        chapterInput.value?.attributes?.createdAt ??
        "",
    );
    const wip = computed(
      () => !!chapterInput.value?.attributes?.workInProgress,
    );

    const longName = computed(() =>
      chapterInput.value
        ? getChapterName(
            chapterInput.value.attributes?.chapter ?? null,
            chapterInput.value.attributes?.volume ?? null,
            chapterInput.value.attributes?.name ?? null,
            "long",
            chapterLabel,
            volumeLabel,
          )
        : "",
    );
    const shortName = computed(() =>
      chapterInput.value
        ? getChapterName(
            chapterInput.value.attributes?.chapter ?? null,
            chapterInput.value.attributes?.volume ?? null,
            chapterInput.value.attributes?.name ?? null,
            "short",
            chapterLabel,
            volumeLabel,
          )
        : "",
    );

    const uploader = computed(() =>
      chapterInput.value && !isRelationship(chapterInput.value)
        ? getRelationship(chapterInput.value, "user")
        : null,
    );
    const organizations = computed(() =>
      chapterInput.value && !isRelationship(chapterInput.value)
        ? getMultipleRelationships(chapterInput.value, "organization")
        : [],
    );

    return {
      id,
      name,
      chapterNo,
      volumeNo,
      language,
      thumbnail,
      href,
      state,
      createdAt,
      updatedAt,
      publishAt,
      readableAt,
      wip,
      longName,
      shortName,
      uploader,
      organizations,
    };
  } else {
    if (!chapterInput) return DefaultReturn;

    const id = chapterInput.id;
    const name = chapterInput.attributes?.name ?? "";
    const chapterNo = chapterInput.attributes?.chapter ?? "";
    const volumeNo = chapterInput.attributes?.volume ?? "";
    const language = chapterInput.attributes?.translatedLanguage ?? "en";
    const thumbnail =
      chapterInput && isExpanded(chapterInput)
        ? (getChapterThumbnail(chapterInput, thumbRes) ?? "")
        : "";
    const href = linkTo(chapterInput);

    const state = chapterInput.attributes?.state ?? "pending";

    const createdAt = chapterInput.attributes?.createdAt ?? "";
    const updatedAt = chapterInput.attributes?.updatedAt ?? "";
    const publishAt = chapterInput.attributes?.publishAt ?? "";
    const readableAt =
      chapterInput.attributes?.publishAt ??
      chapterInput.attributes?.createdAt ??
      "";
    const wip = !!chapterInput.attributes?.workInProgress;

    const longName = getChapterName(
      chapterInput.attributes?.chapter ?? "",
      chapterInput.attributes?.volume ?? "",
      chapterInput.attributes?.name ?? "",
      "long",
      chapterLabel,
      volumeLabel,
    );
    const shortName = getChapterName(
      chapterInput.attributes?.chapter ?? "",
      chapterInput.attributes?.volume ?? "",
      chapterInput.attributes?.name ?? "",
      "short",
      chapterLabel,
      volumeLabel,
    );

    const uploader = !isRelationship(chapterInput)
      ? getRelationship(chapterInput, "user")
      : null;

    const organizations = !isRelationship(chapterInput)
      ? getMultipleRelationships(chapterInput, "organization")
      : [];

    return {
      id,
      name,
      chapterNo,
      volumeNo,
      language,
      thumbnail,
      href,
      state,
      createdAt,
      updatedAt,
      publishAt,
      readableAt,
      wip,
      longName,
      shortName,
      uploader,
      organizations,
    };
  }
}

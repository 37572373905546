<template>
  <div
    class="grid gap-2 py-2"
    :class="{
      'grid-cols-[1fr_2.5rem]':
        isGroupConversation && author?.id === currentUser.id,
      'grid-cols-[2.5rem_1fr]':
        isGroupConversation && author?.id !== currentUser.id,
    }"
    ref="mediaContainer"
  >
    <AsyncImage
      v-if="isGroupConversation"
      :src="author?.avatarUrl"
      :class="{
        'rounded-full': author?.type === 'user',
        'rounded-md': author?.type === 'organization',
        'order-2': author?.id === currentUser.id,
      }"
      class="w-10 h-10"
    />
    <div
      class="max-w-[66%] grid gap-1"
      :class="{
        'justify-end ml-auto': isMe,
        'grid-cols-2': attachments.length > 3,
      }"
    >
      <button
        v-for="(attachment, index) in attachments.slice(0, 4)"
        @click="() => openLightbox(index)"
        :key="attachment.url ?? `attachment-${index}`"
        class="relative bg-neutral-200 dark:bg-neutral-800 rounded-md aspect-square"
      >
        <AsyncImage
          :src="attachment.url ?? undefined"
          :alt="attachment.name"
          @error="() => refreshUrlAtIndex(index)"
          class="rounded-md transition-opacity hover:opacity-90 aspect-square object-cover"
        />
        <div
          v-if="index === 3 && attachments.length > 4"
          class="absolute pointer-events-none bg-black/60 font-medium text-3xl left-0 top-0 w-full h-full flex justify-center items-center"
        >
          +{{ attachments.length - 3 }}
        </div>
      </button>
    </div>
  </div>
  <VueEasyLightbox
    :visible="isLightboxVisible"
    :imgs="lightboxImagesUrls"
    :index="startingLightboxImageIndex"
    @hide="isLightboxVisible = false"
    teleport="body"
    :zoomScale="1"
  />
</template>

<script setup lang="ts">
import VueEasyLightbox from "vue-easy-lightbox";
import { useIntersectionObserver } from "@vueuse/core";
import type { UserDisplayData } from "./common";
import type { GroupType, MessageGroupContainer } from "~/composables/messages";

const props = defineProps<{
  group: MessageGroupContainer & { type: GroupType.MEDIAONLY };
  currentUser: UserDisplayData;
  isGroupConversation: boolean;
}>();

const dayjs = useDayjs();
const reportReadIndex = inject<(index: number) => void>("reportReadIndex");
const isLightboxVisible = ref(false);
const startingLightboxImageIndex = ref(0);
const mediaContainer = ref<HTMLDivElement>();
const retryTracker = reactive<number[]>([]);

useIntersectionObserver(mediaContainer, ([entry]) => {
  const lastMessage = props.group.messages.at(-1);
  if (!lastMessage) return;
  if (entry.intersectionRatio > 0.25) {
    reportReadIndex?.(lastMessage.index);
  }
});

const lightboxImagesUrls = computed(() =>
  props.group.messages.map((message) => message.attachments![0].url!),
);

const attachments = computed(() =>
  props.group.messages.map((message) => message.attachments![0]),
);

const isMe = computed(() =>
  theCurrentTwilioUserIsMessageAuthor(props.group.messages[0]),
);
const author = computed(() => props.group.messages[0].author);

watch(
  attachments,
  () => {
    retryTracker.splice(0, retryTracker.length);
    attachments.value.forEach(() => {
      retryTracker.push(0);
    });
  },
  { immediate: true },
);

async function refreshUrls() {
  const now = dayjs();

  await Promise.all(
    attachments.value.map(async (attachment) => {
      if (now.isAfter(attachment.expiryDate)) {
        await attachment.refresh();
      }
    }),
  );
}

async function openLightbox(index: number) {
  await refreshUrls();
  startingLightboxImageIndex.value = index;
  isLightboxVisible.value = true;
}

async function refreshUrlAtIndex(index: number) {
  if (retryTracker[index]++ > 5) return;
  await attachments.value[index].refresh();
}

function theCurrentTwilioUserIsMessageAuthor(message: Message) {
  return message.author?.id === props.currentUser.id;
}
</script>

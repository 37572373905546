import axios from "axios";
import { setGlobalConditions } from "./network";

export const config = {
  apiUrl: "",
  cdnOrigin: "",
  paymentsUrl: "",
};

export function setApiUrl(url: string) {
  config.apiUrl = url;
  axios.defaults.baseURL = url;
}

export function setCdnOrigin(url: string) {
  config.cdnOrigin = url;
}

export function setPaymentsUrl(url: string) {
  config.paymentsUrl = url;
}

setGlobalConditions(() =>
  typeof window === "undefined" ? "immediate" : "ratelimit",
);

export default axios;

// referenced from https://tanstack.com/query/latest/docs/framework/vue/guides/ssr

import type {
  DehydratedState,
  VueQueryPluginOptions,
} from "@tanstack/vue-query";
import {
  VueQueryPlugin,
  QueryClient,
  hydrate,
  dehydrate,
} from "@tanstack/vue-query";
// Nuxt 3 app aliases
import { ApiToNuxtError, defineNuxtPlugin, useState } from "#imports";
import { setApiUrl } from "~/src/api";

export default defineNuxtPlugin((nuxt) => {
  const vueQueryState = useState<DehydratedState | null>("vue-query");
  const config = useRuntimeConfig();

  // Modify your Vue Query global settings here
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5000,
        // throwOnError: (error) => {
        //   use404Response(ApiToNuxtError(error));
        //   return false;
        // },
        refetchOnWindowFocus: (query) => {
          if (query.state.error) {
            const err = ApiToNuxtError(query.state.error);
            return err.statusCode !== 404;
          }
          return false;
        },
        // basic retry on non-404 up to 3 times
        // retry: (failureCount: number, error) => {
        //   const {code} = resolveError(error)
        //   return code !== 404 && failureCount < 3;
        // }
        retry: false,
      },
    },
  });
  const options: VueQueryPluginOptions = { queryClient };

  nuxt.vueApp.use(VueQueryPlugin, options);

  if (import.meta.server) {
    setApiUrl(process.env.PRIVATE_API_URL || config.public.apiUrl);
    nuxt.hooks.hook("app:rendered", () => {
      vueQueryState.value = dehydrate(queryClient);
    });
  }

  if (import.meta.client) {
    setApiUrl(config.public.apiUrl);
    nuxt.hooks.hook("app:created", () => {
      hydrate(queryClient, vueQueryState.value);
    });
  }
});

import type { NotificationType } from "./notifications";
import type { TwilioNotificationNewMessage } from "./twilio/new-message";

export enum TwilioNotificationType {
  NewMessage,
}

export type TwilioNotificationDetails = TwilioNotificationNewMessage;

export type TwilioNotification = {
  type: NotificationType.Twilio;
  notification: TwilioNotificationDetails;
};

<template>
  <div
    :virt-width="width"
    :virt-height="height"
    ref="container"
    :class="{
      isRendered: virtualItem?.isRendered,
      isVisible: virtualItem?.isVisible,
    }"
    :style="{
      height:
        virtualItem?.isRendered && virtualItem?.isVisible
          ? undefined
          : `${height}px`,
      width:
        virtualItem?.isRendered && virtualItem?.isVisible
          ? undefined
          : `${width}px`,
    }"
  >
    <div
      v-if="virtualItem?.isRendered"
      v-show="virtualItem?.isVisible"
      class="grid gap-2 py-2"
      :class="{
        'grid-cols-[1fr_2.5rem]':
          isGroupConversation && group.author?.id === currentUser.id,
        'grid-cols-[2.5rem_1fr]':
          isGroupConversation && group.author?.id !== currentUser.id,
      }"
    >
      <AsyncImage
        v-if="isGroupConversation"
        :src="
          group.author?.avatarUrl ??
          getAbsoluteAssetLink('nami/profile/sea-deity.png')
        "
        :class="{
          'rounded-full': group.author?.type === 'user' || !group.author,
          'rounded-md': group.author?.type === 'organization',
          'order-2': group.author?.id === currentUser.id,
        }"
        class="w-10 h-10"
      />
      <ul
        class="space-y-[2px]"
        :class="{
          'order-1': group.author?.id === currentUser.id,
        }"
      >
        <li
          v-for="(message, index) in group.messages"
          :key="message.id"
          class="max-w-[66%] flex"
          :class="{
            'justify-end ml-auto': theCurrentTwilioUserIsMessageAuthor(message),
          }"
        >
          <ConversationMessageBubble
            :message="message"
            :current-user
            :relative-order="
              group.messages.length === 1
                ? 'standalone'
                : index === 0
                  ? 'first'
                  : index === group.messages.length - 1
                    ? 'last'
                    : 'middle'
            "
            :is-group-conversation
            @report-message="$emit('reportMessage', message)"
            @images-preview="
              (images, index) => $emit('imagesPreview', images, index)
            "
          ></ConversationMessageBubble>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  GroupType,
  MessageGroupContainer,
  Message,
} from "~/composables/messages";
import { useElementBounding } from "@vueuse/core";
import { type UserDisplayData } from "./common";

const props = defineProps<{
  group: MessageGroupContainer & { type: GroupType.SAMEAUTHOR };
  conversationSid: string;
  isGroupConversation?: boolean;
  currentUser: UserDisplayData;
}>();

const emit = defineEmits<{
  (e: "imagesPreview", images: string[], index: number): void;
  (e: "reportMessage", message: Message): void;
}>();

const virtualItem = ref<VirtualItem | null>(null);

const conversationUiState = useConversationUiState(
  computed(() => props.conversationSid),
);
const container = ref<HTMLDivElement>();

const { width, height, x, y } = useElementBounding(container);

function theCurrentTwilioUserIsMessageAuthor(message: Message) {
  return message.author?.id === props.currentUser.id;
}

watch(
  [() => props.group, conversationUiState],
  () => {
    if (virtualItem.value) {
      virtualItem.value = null;
      conversationUiState.value.removeGroup(props.group.id);
    }

    virtualItem.value = conversationUiState.value.addGroup(props.group, {
      width: width.value,
      height: height.value,
      x: x.value,
      y: y.value,
    });
  },
  { immediate: true },
);

watch(
  [width, height, x, y],
  () => {
    if (!virtualItem.value) return;
    virtualItem.value.width = width.value;
    virtualItem.value.height = height.value;
    virtualItem.value.x = x.value;
    virtualItem.value.y = y.value;
  },
  { immediate: true },
);

onUnmounted(() => {
  virtualItem.value = null;
  conversationUiState.value.removeGroup(props.group.id);
});
</script>

<template>
  <NotificationsItem :is-unread :href :date="notification.date">
    <template #icon>
      <div
        class="w-10 h-10 flex items-center justify-center rounded-full bg-neutral-300 dark:bg-neutral-700"
      >
        <NamiReaction :reaction="notification.data.reaction" class="w-6 h-6" />
      </div>
    </template>
    <template #content>
      <div class="space-y-1">
        <UserAvatar
          :size="32"
          no-link
          :user="notification.data.userWhoReacted"
        />
        <p>
          <i18n-t
            keypath="notifications.social.reaction_to_post.body"
            scope="global"
          >
            <template #reactor>
              <strong>{{ reactorName }}</strong>
            </template>
            <template #reactionType>
              <strong>{{ notification.data.reaction }}</strong>
            </template>
          </i18n-t>
        </p>
        <p class="text-sm text-neutral-600 dark:text-neutral-400 line-clamp-2">
          {{ postContent }}
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import { stripMarkdown } from "~/utils/ui/excerpt";
import type { Props } from "../NotificationsItem.vue";
import type { UserEntity } from "~/src/api";
import type { NamiNotificationSocialReactionToPost } from "~/src/notifications/types/nami/social-reaction-to-post";

const props = defineProps<
  {
    notification: NamiNotificationSocialReactionToPost;
  } & Omit<Props, "actionIcon">
>();

const href = computed(() => {
  return linkTo(props.notification.data.post);
});

const postContent = computed(() => {
  const message = props.notification.data.post.attributes.contentExcerpt;
  if (!message) return null;
  return stripMarkdown(message.replaceAll(/[\n]+/g, " "));
});

const reactorName = computed(() => {
  return getReactorName(props.notification.data.userWhoReacted);
});

function getReactorName(user: UserEntity) {
  return user.attributes.displayName ?? user.attributes.username;
}
</script>

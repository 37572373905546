<template>
  <span class="flex-shrink-0">
    <Tooltip fixed>
      <img
        width="64px"
        height="64px"
        src="/assets/badges/staff.svg"
        alt="staff"
      />
      <template #tooltip>
        <span class="whitespace-nowrap">
          {{ $t("components.badgeStaff.toolTipText") }}
        </span>
      </template>
    </Tooltip>
  </span>
</template>

export function paramsParser(params: { [key: string]: any }) {
  const allParams: string[] = [];
  const appendEntries = (root: any, currKey: string = "") => {
    if (typeof root === "undefined") return;

    if (
      typeof root === "string" ||
      typeof root === "number" ||
      typeof root === "boolean" ||
      root === null
    ) {
      allParams.push(`${currKey}=${root}`);
      return;
    }

    // this shouldnt happen
    if (currKey.includes("[]"))
      console.warn("Object inside array for params, possible issue:", root);

    if ((root as any[])?.length) {
      root.forEach((val: any) => {
        appendEntries(val, `${currKey}[]`);
      });
      return;
    }

    Object.entries(root).forEach(([key, val]) => {
      appendEntries(val, !currKey ? key : `${currKey}[${key}]`);
    });
  };

  appendEntries(params);

  const joinedParams = allParams.join("&");
  if (!joinedParams.length) return "";
  return "?" + joinedParams;
}

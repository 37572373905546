import type {
  AchievementRelation,
  AssetRelation,
  BadgeRelation,
  ChapterEntity,
  ChapterRelation,
  CommentEntity,
  CommentRelation,
  CompetitionEntity,
  CompetitionRelation,
  CoverEntity,
  CoverRelation,
  CreatorRelation,
  CustomerRelation,
  EmoteRelation,
  Entity,
  FrameRelation,
  MemberRelation,
  OrganizationEntity,
  OrganizationRelation,
  PopulateRelationship,
  PostEmbedRelation,
  PostEntity,
  PostMediaRelation,
  Relation,
  Relationship,
  RepostRelation,
  SoftRepostRelation,
  TagEntity,
  TagRelation,
  TierRelation,
  TitleEntity,
  TitleListEntity,
  TitleListRelation,
  TitleRelation,
  UserEntity,
  UserProfile,
  UserProfileRelation,
  UserRelation,
  UserRelations,
} from "../types";

type Entities =
  | UserEntity
  | OrganizationEntity
  | TagEntity
  | TitleEntity
  | TitleListEntity
  | ChapterEntity
  | CoverEntity
  | CommentEntity;

// type PossibleRelation = Relationship | UserRelations | Entities;
type PossibleRelation = Relation<string, object> | Entity<string, object>;

export const isUser = (
  input: PossibleRelation,
): input is UserEntity | UserRelation => input.type === "user";
export const isCustomer = (
  input: PossibleRelation,
): input is CustomerRelation => input.type === "customer";
export const isCreator = (input: PossibleRelation): input is CreatorRelation =>
  input.type === "creator";
export const isMember = (input: PossibleRelation): input is MemberRelation =>
  input.type === "member";

export const isUserProfile = (
  input: PossibleRelation,
): input is UserProfile | UserProfileRelation => input.type === "user_profile";

export const isOrg = (
  input: PossibleRelation,
): input is OrganizationRelation | OrganizationEntity =>
  input.type === "organization";

export const isTag = (
  input: PossibleRelation,
): input is TagRelation | TagEntity => input.type === "tag";

export const isTitle = (
  input: PossibleRelation,
): input is TitleRelation | TitleEntity => input.type === "title";

export const isTitleList = (
  input: PossibleRelation,
): input is TitleListRelation | TitleListEntity => input.type === "title_list";

export const isChapter = (
  input: PossibleRelation,
): input is ChapterRelation | ChapterEntity => input.type === "chapter";

export const isCoverR = (
  input: Relationship | UserRelations,
): input is CoverRelation => input.type === "cover_art";
export const isCoverE = (input: Entities): input is CoverEntity =>
  input.type === "cover";
export const isCover = (
  input: PossibleRelation,
): input is CoverRelation | CoverEntity =>
  isCoverR(input as Relationship) || isCoverE(input as Entities);

export const isComment = (
  input: PossibleRelation,
): input is CommentRelation | CommentEntity => input.type === "comment";

export const isCompetition = (
  input: PossibleRelation,
): input is CompetitionRelation | CompetitionEntity =>
  input.type === "competition";

export const isPost = (input: PossibleRelation): input is PostEntity =>
  input.type === "post" || input.type === "gated_post";

export const isPostMedia = (
  input: PossibleRelation,
): input is PostMediaRelation =>
  input.type === "post_media" || isGatedPostMedia(input);

export const isGatedPostMedia = (
  input: PossibleRelation,
): input is PostMediaRelation => input.type === "gated_post_media";

export const isPostEmbed = (
  input: PossibleRelation,
): input is PostEmbedRelation =>
  input.type === "post_embed" || isGatedPostEmbed(input);

export const isGatedPostEmbed = (
  input: PossibleRelation,
): input is PostMediaRelation => input.type === "gated_post_embed";

export const isRepost = (
  input: PossibleRelation,
): input is SoftRepostRelation => input.type === "soft_repost";

export const isEmote = (input: PossibleRelation): input is EmoteRelation =>
  input.type === "emote";

export const isQuote = (input: PossibleRelation): input is RepostRelation =>
  input.type === "repost";

export const isTier = (input: PossibleRelation): input is TierRelation =>
  input.type === "tier_item";

export const isNews = (
  input: PossibleRelation,
): input is Relation<"wp_news", {}> => input.type === "wp_news";

export const isFrame = (input: PossibleRelation): input is FrameRelation =>
  input.type === "profile_frame";

export const isAsset = (input: PossibleRelation): input is AssetRelation =>
  input.type === "asset";

export const isBadge = (input: PossibleRelation): input is BadgeRelation =>
  input.type === "badge";

export const isAchievement = (
  input: PossibleRelation,
): input is AchievementRelation => input.type === "achievement";

export function isExpanded<R extends Relation<string, object>>(
  input: R,
): input is PopulateRelationship<R> {
  return !!input.attributes;
}

export function allExpanded<R extends Relationship>(
  input: R[],
): input is PopulateRelationship<R>[] {
  return !input.some((r) => !isExpanded(r));
}

/**
 * Checks if `input` is a relationship
 * @param input
 * @returns boolean
 */
export function isRelationship<K extends Relationship>(
  input: K | Entities,
): input is K {
  return !("relationships" in input);
}

declare global {
  interface Window {
    tools: {
      getAccessToken: () => Promise<string | null | undefined>;
      copyAccessToken: () => Promise<void>;
      api: typeof import("~/src/api");
    };
  }
}

export function useDevTools() {
  const config = useRuntimeConfig();
  if (config.public.environment !== "development") return;

  const nuxtApp = useNuxtApp();
  const authStore = nuxtApp.$auth();

  const getAccessToken = async () => {
    return await authStore?.getToken();
  };

  const copyAccessToken = async () => {
    const token = await authStore?.getToken();

    if (token) {
      console.log(
        "Token will copy to clipboard in 1 second, please click on the page so that Document is focused.",
      );
      setTimeout(() => {
        copyTextToClipboard(token)
          .then(() => console.log("Token has been copied."))
          .catch(() => console.warn("Token could not be copied."));
      }, 1000);
    } else {
      console.warn("No token available to copy.");
    }
  };

  onMounted(async () => {
    const tools = {
      getAccessToken: getAccessToken,
      copyAccessToken: copyAccessToken,
      api: await import("~/src/api"),
    };

    window.tools = tools;
  });
}

<script setup lang="ts">
import { type BadgeRelation, isExpanded } from "~/src/api";

const {
  sizeClass,
  official,
  verified,
  badges = [],
} = defineProps<{
  official?: boolean;
  verified?: boolean;
  badges?: BadgeRelation[];
  sizeClass?: string;
}>();

const sClass = computed(() => {
  if (sizeClass) return sizeClass;
  return "w-[1em] h-[1em]";
});

const remainingTotal = computed(() => {
  let total = 3;
  if (official) total -= 1;
  if (verified) total -= 1;
  return total;
});
</script>

<template>
  <div class="flex items-center gap-[0.25em] min-w-0">
    <span class="inline-block truncate">
      <slot></slot>
    </span>
    <BadgeVerified :class="sClass" v-if="verified" />
    <BadgeStaff :class="sClass" v-if="official" />
    <Badge
      v-for="badge in badges.filter(isExpanded).slice(0, remainingTotal)"
      :badge="badge"
      :size-class="sClass"
    />
    <slot name="after"></slot>
  </div>
</template>

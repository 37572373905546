export type TwilioContextData = {
  primaryTwilioClient: ClientReturn;
  secondaryTwilioClient: ClientReturn;
};

export const PRIMARY_TWILIO_CLIENT_KEY = "primary-twilio-client";
export const SECONDARY_TWILIO_CLIENT_KEY = "secondary-twilio-client";

export function createTwilioContext(): TwilioContextData {
  const primaryTwilioClient = usePrimaryTwilioClient();
  const secondaryTwilioSetup = useSecondaryTwilioSetup();
  const secondaryTwilioClient = useTwilioClient(secondaryTwilioSetup);

  provide<TwilioContextData["primaryTwilioClient"]>(
    PRIMARY_TWILIO_CLIENT_KEY,
    primaryTwilioClient,
  );
  provide<TwilioContextData["secondaryTwilioClient"]>(
    SECONDARY_TWILIO_CLIENT_KEY,
    secondaryTwilioClient,
  );

  return {
    primaryTwilioClient,
    secondaryTwilioClient,
  };
}

export function injectTwilioContext() {
  const context = {
    primaryTwilioClient: inject<TwilioContextData["primaryTwilioClient"]>(
      PRIMARY_TWILIO_CLIENT_KEY,
    )!,
    secondaryTwilioClient: inject<TwilioContextData["secondaryTwilioClient"]>(
      SECONDARY_TWILIO_CLIENT_KEY,
    )!,
  } satisfies TwilioContextData;

  if (Object.values(context).some((v) => !v)) {
    throw new Error("developer_context_not_fully_available");
  }

  return context;
}

const fileDropperHooks: ((files: File[]) => any)[] = [];

export function useFileDropper() {
  onBeforeRouteLeave(() => {
    fileDropperHooks.splice(0, fileDropperHooks.length);
  });

  return {
    drop: (files: File[]) => {
      fileDropperHooks.forEach((hook) => hook(files));
    },
    onDrop: (fn: (files: File[]) => any) => {
      fileDropperHooks.push(fn);

      // cleanup
      return () => {
        const index = fileDropperHooks.indexOf(fn);
        if (index !== -1) fileDropperHooks.splice(index, 1);
      };
    },
  };
}

<template>
  <component
    :is="
      forceFallback
        ? 'img'
        : loading
          ? Skeleton
          : failed || src
            ? 'img'
            : Skeleton
    "
    :src="forceFallback ? fallback : imgSrc"
    :alt="alt"
    :style="{ aspectRatio: aspect }"
    :class="aspect && 'w-full'"
    @error="imgSrc = fallback"
    @load="$emit('load')"
  />
</template>

<script setup lang="ts">
import Skeleton from "~/components/skeleton/Skeleton.vue";
import type { AsyncImageProps } from "~/types/image";

const props = defineProps<AsyncImageProps>();

defineEmits<{
  (e: "load"): void;
}>();

const failed = ref(false);
const fallback = getAbsoluteAssetLink("nami/banner/great-wave.png");

const imgSrc = ref(props.src);

watch(
  () => props.src,
  (newSrc) => {
    imgSrc.value = newSrc;
    failed.value = false;
  },
  { immediate: true },
);
</script>

<template>
  <NotificationsItem
    :is-unread
    :href
    :action-icon="IconSpeakerphone"
    :date="notification.date"
  >
    <template #content>
      <div class="space-y-1">
        <p>
          {{ t(`notifications.official.announcement.title`) }}
        </p>
        <p class="text-sm text-neutral-600 dark:text-neutral-400 line-clamp-2">
          {{
            t(`notifications.official.announcement.body`, {
              title: notification.data.title,
            })
          }}
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import type { NamiNotificationOfficialAnnouncement } from "~/src/notifications/types/nami/official-announcement";
import type { Props } from "../NotificationsItem.vue";
import { IconSpeakerphone } from "@tabler/icons-vue";

const props = defineProps<
  {
    notification: NamiNotificationOfficialAnnouncement;
  } & Omit<Props, "actionIcon">
>();

const nuxtApp = useNuxtApp();
const { t } = nuxtApp.$i18n.global;

const href = computed(() => {
  return undefined;
});
</script>

import {
  Conversations,
  type ConversationCreatePayload,
  type OrganizationAttributes,
  type OrganizationEntity,
  type UserAttributes,
  type UserEntity,
} from "~/src/api";
import {
  Client as TwilioClient,
  Conversation as TwilioConversation,
  Media as TwilioMedia,
  Message as TwilioMessage,
  Participant as TwilioParticipant,
  User as TwilioUser,
  type ConversationUpdateReason,
  type MessageUpdateReason,
  type Paginator,
} from "@twilio/conversations";
import {
  useMessages,
  type Message,
  type MessageAttachment,
  type MessageGroupFamilyOrContainer,
  type UseMessagesReturn,
} from "./messages";
import {
  getTwilioDisplayName,
  type UserDisplayData,
} from "~/components/conversation/common";
import type { AsyncDataRequestStatus } from "#app";
import { getAsyncContext } from "~/components/app/context/util/async";

export type EnhancedConversation = TwilioConversation & {
  get attributes(): {
    private: "false" | "true";
    conversation_starter: {
      id: string;
      type: "user" | "organization";
    };
    avatar_filename?: null | string;
  };
};

export type EnhancedParticipant = TwilioParticipant & {
  get attributes(): {
    is_conversation_starter: boolean;
    is_organization: boolean;
    is_staff: boolean;
    organization_id: string;
  };
};

export type EnhancedUserUserAttributes = {
  is_staff: boolean;
  attributes: UserAttributes & { createdAt: string };
};

export type EnhancedUserOrganizationAttributes = {
  is_organization: boolean;
  organization_id: string;
  attributes: OrganizationAttributes & { createdAt: string };
};

export type EnhancedUser = TwilioUser & {
  get attributes():
    | EnhancedUserUserAttributes
    | EnhancedUserOrganizationAttributes;
};

type ClientInitState = {
  ready: boolean;
  failed: boolean;
  failedReason: "unknown_error" | (string & {}) | null;
};

export type ConversationsMessageData = {
  [sid: string]: {
    lastMessage: TwilioMessage | undefined;
    lastMessageReadIndex: number | null;
  };
};

export type ClientReturn = {
  client: Ref<TwilioClient | null>;
  clientStatus: Ref<AsyncDataRequestStatus>;
  clientError: Ref<Error | null>;
  clientRefresh: () => Promise<void>;
  conversations: Ref<EnhancedConversation[]>;
  conversationsMessageData: ConversationsMessageData;
  groupsPerConversation: Ref<{ [sid: string]: UseMessagesReturn }>;
  metadataPerConversation: Ref<{
    [sid: string]: {
      friendlyName: string | null;
      avatarFileName: string | null | undefined;
    };
  }>;
  hasMoreConversations: Ref<boolean>;
  conversationsLoading: Ref<boolean>;
  clientInitState: Ref<ClientInitState>;
  createConversation(
    payload: ConversationCreatePayload,
  ): Promise<EnhancedConversation>;
  createConversationGroup(
    participants: { type: "user" | "organization"; id: string }[],
  ): Promise<EnhancedConversation>;
  loadNextConversations(): Promise<void>;
  setAllMessagesAsReadForConversation(sid: string): Promise<void>;
  sendMessageToConversation(
    conversation: EnhancedConversation,
    message: string,
    files: File[],
  ): Promise<void>;
};

export type ConversationReturn = {
  conversation: Ref<EnhancedConversation | null>;
  conversationStatus: Ref<AsyncDataRequestStatus>;
  groups: MessageGroupFamilyOrContainer[];
  participants: Ref<EnhancedParticipant[]>;
  participantUserMap: Ref<Record<string, EnhancedUser>>;
  hasMoreMessages: Ref<boolean>;
  lastMessageReadIndex: Ref<number>;
  lastMessageIndex: Ref<number>;
  hasReadAllMessages: ComputedRef<boolean>;
  totalUnreadMessages: Ref<number>;
  nextMessagesLoading: Ref<boolean>;
  friendlyName: Ref<string | null>;
  avatarFileName: Ref<string | null>;
  loadNextMessages(): Promise<void>;
  setAllMessagesAsRead(): Promise<void>;
};

let existingPrimaryTwilioClient: ClientReturn | null = null;

const defaultClientInitState: ClientInitState = {
  ready: false,
  failed: false,
  failedReason: null,
};

export function useSecondaryTwilioSetup() {
  return useState<UserDisplayData | null>("secondary-twilio-setup", () => null);
}

export function usePrimaryTwilioClient() {
  if (existingPrimaryTwilioClient) return existingPrimaryTwilioClient;

  const nuxtApp = useNuxtApp();
  const auth = nuxtApp.$auth();
  const setup = computed(() =>
    auth?.userEntity
      ? { type: auth.userEntity.type, id: auth.userEntity.id }
      : null,
  );

  existingPrimaryTwilioClient = useTwilioClient(setup, true);
  return existingPrimaryTwilioClient;
}

export function useTwilioClient(
  setup: MaybeRef<null | {
    type: "user" | "organization";
    id: string;
  }>,
  enableFcm?: MaybeRef<boolean>,
): ClientReturn {
  const asyncNotificationsContext = getAsyncContext("notifications");

  const {
    status: clientStatus,
    data: clientData,
    error: clientError,
    refresh: clientRefresh,
  } = useAsyncData(
    async () => {
      const setupValue = toValue(setup);
      if (!setupValue) return null;
      const { type, id } = setupValue;
      const client = new TwilioClient(await getClientToken(type, id));
      return client;
    },
    { watch: [() => toValue(setup)], immediate: false },
  );

  const conversations: Ref<EnhancedConversation[]> = ref([]);
  const conversationsMessageData = reactive<ConversationsMessageData>({});
  const hasMoreConversations = ref(false);
  const conversationsLoading = ref(true);
  const clientInitState = ref(defaultClientInitState);
  const groupsPerConversation = ref<{ [sid: string]: UseMessagesReturn }>({});
  const metadataPerConversation = ref<{
    [sid: string]: {
      friendlyName: string | null;
      avatarFileName: string | null | undefined;
    };
  }>({});
  let conversationsPaginator = null as Paginator<EnhancedConversation> | null;

  watch(
    clientData,
    async (client) => {
      if (!client) return;

      const { onActivated } = await asyncNotificationsContext;

      if (toValue(enableFcm)) {
        onActivated.value?.((token) => {
          client
            .setPushRegistrationId("fcm", token)
            .then(() => {
              console.debug("Successfully set the FCM token to twilio client");
            })
            .catch((error) => {
              console.warn(
                "Could not set the FCM token to Twilio TwilioClient. Error below:",
              );
              console.error(error);
            });
        });
      }

      hasMoreConversations.value = false;
      conversationsLoading.value = true;
      clientInitState.value = defaultClientInitState;
      groupsPerConversation.value = {};
      metadataPerConversation.value = {};
      conversations.value = [];

      client.on("tokenAboutToExpire", handleTokenAboutToExpire);
      client.on("initialized", handleInitialized);
      client.on("initFailed", handleInitFailed);
      client.on("conversationJoined", (c) =>
        handleConversationJoined(c as EnhancedConversation),
      );
      client.on("conversationRemoved", (c) =>
        handleConversationRemove(c as EnhancedConversation),
      );
      client.on("conversationLeft", (c) =>
        handleConversationRemove(c as EnhancedConversation),
      );
    },
    { immediate: true },
  );

  async function handleTokenAboutToExpire() {
    const setupValue = toValue(setup);
    assertDefined(setupValue);
    assertDefined(clientData.value);

    const { type, id } = setupValue;
    const token = await getClientToken(type, id);
    clientData.value.updateToken(token);
  }

  function handleInitialized() {
    conversations.value = [];
    conversationsPaginator = null;
    clientInitState.value.ready = true;
    loadNextConversations();
  }

  function handleInitFailed(error: any) {
    clientInitState.value.failed = true;
    clientInitState.value.failedReason =
      error.error?.message ?? "unknown_error";
  }

  function handleConversationJoined(conversation: EnhancedConversation) {
    if (!conversations.value.some((c) => c.sid === conversation.sid)) {
      const index = conversations.value.findIndex((c) =>
        firstConversationHasALaterMessageThanTheSecondOne(conversation, c),
      );

      if (index === -1) {
        conversations.value.push(conversation);
      } else {
        conversations.value.splice(index, 0, conversation);
      }

      registerListenersToConversation(conversation);
    }
  }

  function handleConversationRemove(conversation: EnhancedConversation) {
    const index = conversations.value.findIndex(
      (c) => c.sid === conversation.sid,
    );
    if (index !== -1) {
      conversations.value.splice(index, 1);
    }
    conversation.removeAllListeners();
    delete conversationsMessageData[conversation.sid];
  }

  async function createConversation(payload: ConversationCreatePayload) {
    if (!clientInitState.value.ready) throw new Error("client_not_ready");
    assertDefined(clientData.value);

    const { data } = await Conversations.createConversation(
      payload,
      await getTokenOrThrow(),
    );

    const conversationAlreadyExists = conversations.value.find(
      (conversation: any) => data.id === conversation.id,
    );
    if (conversationAlreadyExists) return conversationAlreadyExists;

    const conversation = (await clientData.value.getConversationBySid(
      data.id,
    )) as EnhancedConversation;

    return conversation;
  }

  async function createConversationGroup(
    participants: { type: "user" | "organization"; id: string }[],
  ) {
    return await createConversation({
      targetParticipants: participants,
      title: "conversation_group_dm",
    });
  }

  async function loadNextConversations() {
    if (!clientInitState.value.ready) throw new Error("client_not_ready");
    assertDefined(clientData.value);

    conversationsLoading.value = true;

    if (!conversationsPaginator)
      conversationsPaginator =
        (await clientData.value.getSubscribedConversations()) as Paginator<EnhancedConversation>;
    else conversationsPaginator = await conversationsPaginator.nextPage();

    hasMoreConversations.value = conversationsPaginator.hasNextPage;
    conversationsPaginator.items.forEach((item) => {
      handleConversationJoined(item);
    });

    conversationsLoading.value = false;
  }

  function registerListenersToConversation(conversation: EnhancedConversation) {
    groupsPerConversation.value[conversation.sid] = useMessages();
    metadataPerConversation.value[conversation.sid] = reactive({
      friendlyName: conversation.friendlyName,
      avatarFileName: conversation.attributes.avatar_filename,
    });

    conversationsMessageData[conversation.sid] = {
      lastMessage: undefined,
      lastMessageReadIndex: conversation.lastReadMessageIndex,
    };

    conversation.getMessages().then(async (paginator) => {
      const lastMessage = paginator.items.at(-1);
      if (!lastMessage) return;

      const parsedMessages = await Promise.all(
        paginator.items.map((message) =>
          parseMessage(message, clientData.value!),
        ),
      );
      parsedMessages.forEach((message) =>
        groupsPerConversation.value[conversation.sid].addMessage(message),
      );

      conversationsMessageData[conversation.sid].lastMessage = lastMessage;
    });

    conversation.on("messageAdded", async (message) => {
      const currentIndex = conversations.value.findIndex(
        (c) => c.sid === conversation.sid,
      );

      if (currentIndex !== -1) {
        conversations.value.splice(currentIndex, 1);
        conversations.value.unshift(conversation);
      }

      conversationsMessageData[conversation.sid].lastMessage = message;

      if (message.author === toValue(setup)?.id) {
        conversationsMessageData[conversation.sid].lastMessageReadIndex =
          message.index;
      }

      groupsPerConversation.value[conversation.sid].addMessage(
        await parseMessage(message, clientData.value!),
      );
    });

    conversation.on("updated", (data) => {
      const c = data.conversation as EnhancedConversation;
      metadataPerConversation.value[c.sid].avatarFileName =
        c.attributes.avatar_filename;
      metadataPerConversation.value[c.sid].friendlyName = c.friendlyName;
      conversationsMessageData[c.sid].lastMessageReadIndex =
        c.lastReadMessageIndex;
    });
  }

  async function setAllMessagesAsReadForConversation(sid: string) {
    const conversation = conversations.value.find((c) => c.sid === sid);
    assertDefined(conversation);

    await conversation.setAllMessagesRead();
    conversationsMessageData[sid].lastMessageReadIndex =
      conversationsMessageData[sid].lastMessage?.index ?? 0;
  }

  async function sendMessageToConversation(
    conversation: EnhancedConversation,
    message: string,
    files: File[],
  ) {
    const messageToSend = message.trim().replace(/[\n]{3,}/g, "\n\n");

    if (messageToSend === "" && files.length === 0) return;

    if (files.length === 1) {
      await conversation
        .prepareMessage()
        .setBody(messageToSend)
        .addMedia({
          contentType: files[0].type,
          filename: files[0].name,
          media: files[0],
        })
        .buildAndSend();
    } else {
      if (messageToSend !== "") {
        await conversation.sendMessage(messageToSend);
      }

      for (const file of files) {
        await conversation
          .prepareMessage()
          .addMedia({
            contentType: file.type,
            filename: file.name,
            media: file,
          })
          .buildAndSend();
      }
    }
  }

  onMounted(() => {
    clientRefresh();
  });

  return {
    client: clientData,
    clientStatus,
    clientError,
    clientRefresh: () => clientRefresh(),
    conversations,
    conversationsMessageData,
    groupsPerConversation,
    metadataPerConversation,
    hasMoreConversations,
    conversationsLoading,
    clientInitState,
    createConversation,
    createConversationGroup,
    loadNextConversations,
    setAllMessagesAsReadForConversation,
    sendMessageToConversation,
  };
}

export function useTwilioConversation(
  client: MaybeRef<TwilioClient | null>,
  sid: MaybeRef<string>,
): ConversationReturn {
  const dayjs = useDayjs();
  const { addMessage, removeMessage, updateMessage, groups } = useMessages();

  const {
    status: conversationStatus,
    data: conversationData,
    error: conversationError,
    refresh: conversationRefresh,
  } = useAsyncData(
    async () => {
      const clientValue = toValue(client);
      if (!clientValue) return null;

      const conversation = await clientValue.getConversationBySid(toValue(sid));
      return conversation as EnhancedConversation;
    },
    { watch: [() => toValue(client), () => toValue(sid)] },
  );

  const participants: Ref<EnhancedParticipant[]> = ref([]);
  const participantUserMap: Ref<Record<string, EnhancedUser>> = ref({});
  const nextMessagesLoading = ref(false);
  const hasMoreMessages = ref(false);
  const totalUnreadMessages = ref(0);
  const lastMessageReadIndex = ref(-1);
  const lastMessageIndex = ref(-1);
  const friendlyName = ref<string | null>(null);
  const avatarFileName = ref<string | null>(null);
  const hasReadAllMessages = computed(
    () => lastMessageReadIndex.value === lastMessageIndex.value,
  );
  let messagesPaginator: null | Paginator<TwilioMessage> = null;

  watch(
    conversationData,
    async (conversation) => {
      if (!conversation) return;

      participants.value = [];
      participantUserMap.value = {};
      hasMoreMessages.value = false;
      lastMessageReadIndex.value = -1;
      lastMessageIndex.value = -1;
      totalUnreadMessages.value = 0;
      nextMessagesLoading.value = false;
      messagesPaginator = null;
      friendlyName.value = conversation.friendlyName;
      avatarFileName.value = conversation.attributes.avatar_filename ?? null;
      groups.splice(0, groups.length);

      lastMessageIndex.value = conversation.lastMessage?.index ?? 0;
      lastMessageReadIndex.value = conversation.lastReadMessageIndex ?? 0;

      loadNextMessages();

      await conversation.getParticipants().then(async (ps) => {
        await Promise.all(
          ps.map(async (p) => {
            const user = (await p.getUser()) as EnhancedUser;
            participantUserMap.value[p.sid] = user;
          }),
        );

        participants.value = ps as EnhancedParticipant[];
      });

      conversation.on("participantJoined", handleParticipantJoined);
      conversation.on("participantLeft", handleParticipantLeft);
      conversation.on("messageAdded", handleMessageAdded);
      conversation.on("messageRemoved", handleMessageRemoved);
      conversation.on("messageUpdated", handleMessageUpdated);
      conversation.on("updated", handleConversationUpdated);
    },
    { immediate: true },
  );

  function handleConversationUpdated(data: {
    conversation: TwilioConversation;
    updateReasons: ConversationUpdateReason[];
  }) {
    for (const reason of data.updateReasons) {
      switch (reason) {
        case "friendlyName": {
          friendlyName.value = data.conversation.friendlyName;
        }

        case "attributes": {
          avatarFileName.value =
            (data.conversation as EnhancedConversation).attributes
              .avatar_filename ?? null;
        }
      }
    }
  }

  async function handleParticipantJoined(participant: TwilioParticipant) {
    if (conversationData.value?.sid !== toValue(sid)) return;

    participants.value.push(participant as EnhancedParticipant);
    participantUserMap.value[participant.sid] =
      (await participant.getUser()) as EnhancedUser;
  }

  function handleParticipantLeft(participant: TwilioParticipant) {
    if (conversationData.value?.sid !== toValue(sid)) return;

    const index = participants.value.indexOf(
      participant as EnhancedParticipant,
    );
    if (index !== -1) participants.value.splice(index, 1);

    delete participantUserMap.value[participant.sid];
  }

  async function handleMessageAdded(message: TwilioMessage) {
    if (conversationData.value?.sid !== toValue(sid)) return;
    addMessage(await parseMessage(message, toValue(client)!));
    lastMessageIndex.value =
      message.index > lastMessageIndex.value
        ? message.index
        : lastMessageIndex.value;
  }

  function handleMessageRemoved(message: TwilioMessage) {
    if (conversationData.value?.sid !== toValue(sid)) return;
    removeMessage(message.index);
  }

  function handleMessageUpdated(data: {
    message: TwilioMessage;
    updateReasons: MessageUpdateReason[];
  }) {
    if (conversationData.value?.sid !== toValue(sid)) return;

    data.updateReasons.forEach(async (reason) => {
      switch (reason) {
        case "attributes": {
          updateMessage(
            data.message.index,
            "attributes",
            data.message.attributes,
          );
          break;
        }

        case "body": {
          updateMessage(data.message.index, "content", data.message.body ?? "");
          break;
        }

        case "dateCreated": {
          updateMessage(
            data.message.index,
            "date",
            dayjs(data.message.dateCreated),
          );
          break;
        }

        case "dateUpdated":
        case "lastUpdatedBy": {
          const lastUpdated = data.message.dateUpdated
            ? {
                by: data.message.lastUpdatedBy,
                date: dayjs(data.message.dateUpdated!),
              }
            : undefined;

          updateMessage(data.message.index, "updated", lastUpdated);
          break;
        }

        case "deliveryReceipt": {
          updateMessage(data.message.index, "isDelivered", true);
          break;
        }

        case "media": {
          const attachments = await Promise.all(
            data.message.attachedMedia?.map(async (media) =>
              createAttachmentFromMedia(media),
            ) ?? [],
          );

          updateMessage(data.message.index, "attachments", attachments);
          break;
        }
      }
    });
  }

  async function loadNextMessages() {
    assertDefined(conversationData.value);
    if (conversationData.value.sid !== toValue(sid)) return;

    nextMessagesLoading.value = true;

    if (!messagesPaginator)
      messagesPaginator = await conversationData.value.getMessages(32);
    else messagesPaginator = await messagesPaginator.nextPage();

    hasMoreMessages.value = messagesPaginator.hasNextPage;

    const parsedMessages = await Promise.all(
      messagesPaginator.items.map((message) =>
        parseMessage(message, toValue(client)!),
      ),
    );
    parsedMessages.forEach(addMessage);

    nextMessagesLoading.value = false;
  }

  async function setAllMessagesAsRead() {
    totalUnreadMessages.value = 0;
    lastMessageReadIndex.value = groups.at(-1)?.indexRange[1] ?? 0;
    await conversationData.value?.setAllMessagesRead();
  }

  return {
    conversation: conversationData,
    conversationStatus,
    groups,
    participants,
    participantUserMap,
    hasMoreMessages,
    lastMessageReadIndex,
    lastMessageIndex,
    hasReadAllMessages,
    totalUnreadMessages,
    nextMessagesLoading,
    loadNextMessages,
    setAllMessagesAsRead,
    friendlyName,
    avatarFileName,
  };
}

export function useTargetParticipantsForNewConversation() {
  return useState<(UserEntity | OrganizationEntity)[]>(
    "target-conversation-participants",
    () => [],
  );
}

async function parseMessage(
  message: TwilioMessage,
  client: TwilioClient,
): Promise<Message> {
  const { getImageUrl } = useMediaLink();
  const dayjs = useDayjs();
  const authorParticipant = message.participantSid
    ? ((await message
        .getParticipant()
        .catch(() => null)) as EnhancedParticipant | null)
    : null;
  const authorUser = (
    authorParticipant
      ? await authorParticipant.getUser()
      : message.author
        ? await client.getUser(message.author)
        : null
  ) as EnhancedUser | null;
  const authorType = authorParticipant?.attributes.is_organization
    ? "organization"
    : "user";

  const avatarUrl =
    authorUser &&
    getImageUrl(
      authorUser.identity,
      authorType,
      "avatar",
      authorUser.attributes.attributes.avatarFileName,
    );

  const attachments = await Promise.all<MessageAttachment>(
    message.attachedMedia?.map(async (media) =>
      createAttachmentFromMedia(media),
    ) ?? [],
  );

  return {
    id: message.sid,
    author: authorUser
      ? {
          type: authorType,
          id: authorUser.identity,
          avatarUrl: avatarUrl!,
          name: getTwilioDisplayName(authorUser),
          hasLeftConversation: !!authorParticipant,
        }
      : undefined,
    content: message.body ?? "",
    date: dayjs(message.dateCreated),
    index: message.index,
    attributes: message.attributes,
    isDelivered: false,
    attachments: attachments,
    actions: {
      delete: async () => {
        await message.updateBody("");
      },
      hardDelete: async () => {
        await message.remove();
      },
    },
  };
}

async function createAttachmentFromMedia(
  media: TwilioMedia,
): Promise<MessageAttachment> {
  const attachment: Partial<MessageAttachment> = reactive({
    name: media.filename ?? "",
    type: media.contentType,
    url: null,
  });

  attachment.refresh = async () =>
    (attachment.url = await media.getContentTemporaryUrl());

  attachment.refresh();

  return attachment as MessageAttachment;
}

async function getClientToken(type: "organization" | "user", id: string) {
  const tokenResponse = await Conversations.getAccessToken(
    { organizationId: type === "organization" ? id : undefined },
    await getTokenOrThrow(),
  );

  return tokenResponse.data.attributes.token;
}

function firstConversationHasALaterMessageThanTheSecondOne(
  firstConversation: EnhancedConversation,
  secondConversation: EnhancedConversation,
) {
  const dateOfLatestMessageOfFirstConversation =
    firstConversation.lastMessage?.dateCreated;
  if (!dateOfLatestMessageOfFirstConversation) return false;

  const dateOfLatestMessageOfSecondConversation =
    secondConversation.lastMessage?.dateCreated;
  if (!dateOfLatestMessageOfSecondConversation) return true;

  return (
    dateOfLatestMessageOfFirstConversation.valueOf() >
    dateOfLatestMessageOfSecondConversation.valueOf()
  );
}

import {
  getAssetUrl,
  getChapterThumbnail,
  getCoverUrl,
  getImageUrl,
  getOrganizationAvatar,
  getOrganizationBanner,
  getTierBanner,
  getTitleBanner,
  getTitleListBanner,
  getUserAvatar,
  getUserBanner,
  selectLocalizedCover,
} from "~/src/api";

export const placeholders = {
  avatar: "/img/sea-deity.png",
  banner: "/img/great-wave.png",
  media: "/img/great-wave.png",
  cover: "/img/great-wave.png",
  post_image: "/img/great-wave.png",
  emoji: "/img/sea-deity.png",
  sticker: "/img/sea-deity.png",
};

// these functions are defined inside the composable to ensure nuxt context
export function useMediaLink() {
  function getConversationAvatar(
    conversation: EnhancedConversation,
    res?: "128" | "256" | "512" | "original",
  ) {
    return getImageUrl(
      conversation.sid,
      "conversation",
      "avatar",
      conversation.attributes.avatar_filename,
      res,
    );
  }

  return {
    getTierBanner: (...args: Parameters<typeof getTierBanner>) =>
      getTierBanner(...args) ?? placeholders.banner,
    getTitleBanner: (...args: Parameters<typeof getTitleBanner>) =>
      getTitleBanner(...args) ?? placeholders.banner,
    getUserBanner: (...args: Parameters<typeof getUserBanner>) =>
      getUserBanner(...args) ?? placeholders.banner,
    getUserAvatar: (...args: Parameters<typeof getUserAvatar>) =>
      getUserAvatar(...args) ?? placeholders.avatar,
    getOrganizationBanner: (
      ...args: Parameters<typeof getOrganizationBanner>
    ) => getOrganizationBanner(...args) ?? placeholders.banner,
    getOrganizationAvatar: (
      ...args: Parameters<typeof getOrganizationAvatar>
    ) => getOrganizationAvatar(...args) ?? placeholders.avatar,
    getChapterThumbnail: (...args: Parameters<typeof getChapterThumbnail>) =>
      getChapterThumbnail(...args),
    getConversationAvatar: (
      ...args: Parameters<typeof getConversationAvatar>
    ) => getConversationAvatar(...args) ?? placeholders.avatar,
    getTitleListBanner: (...args: Parameters<typeof getTitleListBanner>) =>
      getTitleListBanner(...args) ?? placeholders.banner,

    getAssetUrl,
    selectLocalizedCover,
    getCoverUrl,
    getImageUrl,
  };
}

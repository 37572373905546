import type {
  Collection,
  CollectionResult,
  CompactResult,
  CompetitionEntryEntity,
  ContentRating,
  CoverDetails,
  CoverEntity,
  IncludeArr,
  MessageResult,
  PostTitleBody,
  Result,
  TagEntity,
  TitleChapters,
  TitleCompetitionEntriesListParams,
  TitleEntity,
  TitleFollowBody,
  TitleFollowsEntity,
  TitleFollowsListParams,
  TitleFuzzySearchListParams,
  TitleGroupedChaptersParams,
  TitleListParams,
  TitleReadingStatus,
  TitleState,
  UserTitlesFollowsExpansionTypes,
  ViewStatistics,
} from "../../types/index";
import axios from "../../core/axios";
import { assertSuccess, paramsParser } from "../../core";
import { trimObjectStrings } from "../../helpers";
import { Headers, Statistics } from "../../index";
import { AxiosError } from "axios";
import { nanoid } from "nanoid";
import type { AxiosResponse } from "axios";

export const ALL_CONTENT: ContentRating[] = ["safe", "restricted", "mature"];
export const ALL_READING_STATUSES: TitleReadingStatus[] = [
  "reading",
  "plan_to_read",
  "on_hold",
  "dropped",
  "completed",
];

export const ALL_TITLE_STATES: TitleState[] = [
  "pending",
  "rejected",
  "draft",
  "unpublished",
  "published",
];

export class Title {
  static async getAllTags() {
    const resp = await axios<CollectionResult<TagEntity>>(`/title/tags`);

    return assertSuccess(resp.data).data;
  }

  static async search(
    params: TitleListParams = {},
    auth?: string,
  ): Promise<Collection<TitleEntity>> {
    const resp = await axios.get<CollectionResult<TitleEntity>>(
      `/title` + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  static async fuzzySearch(params: TitleFuzzySearchListParams = {}) {
    const resp = await axios.get<CollectionResult<TitleEntity>>(
      `/title/search` + paramsParser(params),
    );

    return assertSuccess(resp.data);
  }

  static async get(
    id: string,
    includes: IncludeArr<TitleEntity> = [],
    auth?: string,
  ): Promise<TitleEntity> {
    const resp = await axios.get<Result<TitleEntity>>(
      `/title/${id}` + paramsParser({ includes }),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async statistics(titleId: string): Promise<ViewStatistics> {
    const resp = await axios.get<
      CompactResult<{ statistics: { [key: string]: ViewStatistics } }>
    >(`/statistics/title/${titleId}`, {
      responseType: "json",
    });

    return assertSuccess(resp.data).statistics[titleId];
  }

  static async getChaptersList(
    titleId: string,
    params: TitleGroupedChaptersParams = {},
    auth?: string,
  ): Promise<TitleChapters> {
    const resp = await axios<Result<TitleChapters>>(
      `/title/${titleId}/chapters` + paramsParser(params),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
        responseType: "json",
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getCovers(
    titleId: string,
    auth?: string,
  ): Promise<Collection<CoverEntity>> {
    const resp = await axios.get<CollectionResult<CoverEntity>>(
      `/cover` + paramsParser({ title: [titleId] }),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
        responseType: "json",
      },
    );

    return assertSuccess(resp.data);
  }

  static async create(
    body: PostTitleBody,
    organizationId: string,
    auth: string,
    asAdmin?: boolean,
  ) {
    const resp = await axios<Result<TitleEntity>>(`/title/create`, {
      method: "POST",
      headers: {
        ...Headers.Bearer(auth),
        ...Headers.JSON,
      },
      data: {
        ...trimObjectStrings(body),
        organization: organizationId,
      },
      responseType: "json",
    });

    return assertSuccess(resp.data).data;
  }

  static async submit(
    draftId: string,
    version: number,
    auth: string,
  ): Promise<TitleEntity> {
    const resp = await axios<Result<TitleEntity>>(`/title/${draftId}/submit`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: { version: version },
    });

    return assertSuccess(resp.data).data;
  }

  static async uploadBanner(
    image: File | Blob,
    titleId: string,
    version: number,
    auth: string,
  ): Promise<AxiosResponse<Result<TitleEntity>>> {
    const formData = new FormData();
    formData.append(`banner`, image, nanoid());
    // formData.append("version", version.toString());
    return axios<Result<TitleEntity>>(
      `/title/${titleId}/banner?version=${version}`,
      {
        method: "POST",
        headers: {
          ...Headers.Bearer(auth),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      },
    );
  }

  static async deleteBanner(titleId: string, version: number, auth: string) {
    return axios<Result<TitleEntity>>(`/title/${titleId}/banner`, {
      method: "DELETE",
      headers: {
        ...Headers.Bearer(auth),
        "Content-Type": "application/json",
      },
      data: {
        version,
      },
    });
  }

  static async approve(
    draftId: string,
    version: number,
    auth: string,
  ): Promise<TitleEntity> {
    const resp = await axios<Result<TitleEntity>>(`/title/${draftId}/approve`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: { version: version },
    });

    return assertSuccess(resp.data).data;
  }

  static async reject(
    draftId: string,
    version: number,
    auth: string,
  ): Promise<TitleEntity> {
    const resp = await axios<Result<TitleEntity>>(`/title/${draftId}/reject`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: { version: version },
    });

    return assertSuccess(resp.data).data;
  }

  static async publish(
    draftId: string,
    version: number,
    auth: string,
  ): Promise<TitleEntity> {
    const resp = await axios<Result<TitleEntity>>(`/title/${draftId}/publish`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: { version: version },
    });

    return assertSuccess(resp.data).data;
  }

  static async unpublish(
    draftId: string,
    version: number,
    auth: string,
  ): Promise<TitleEntity> {
    const resp = await axios<Result<TitleEntity>>(
      `/title/${draftId}/unpublish`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: { version: version },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async update(
    titleId: string,
    body: Partial<PostTitleBody>,
    version: number,
    auth: string,
  ) {
    const resp = await axios<Result<TitleEntity>>(`/title/${titleId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        ...Headers.Bearer(auth),
      },
      data: {
        ...trimObjectStrings(body),
        version: version,
      },
      responseType: "json",
    });

    return assertSuccess(resp.data).data;
  }

  static async delete(
    titleId: string,
    version: number,
    auth: string,
  ): Promise<void> {
    const resp = await axios.delete(`/title/${titleId}`, {
      headers: Headers.Bearer(auth),
      data: { version: version },
    });

    assertSuccess(resp.data);
  }

  static async addCover(
    titleId: string,
    file: File | Blob,
    details: CoverDetails,
    auth: string,
  ): Promise<void> {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("locale", details.locale);
    details.volume && formData.append("volume", details.volume);

    const resp = await axios.post(`/cover/${titleId}`, formData, {
      headers: {
        ...Headers.Bearer(auth),
        "Content-Type": "multipart/form-data",
      },
    });

    assertSuccess(resp.data);
  }

  static async follow(
    titleId: string,
    body: TitleFollowBody = {},
    auth: string,
  ) {
    const resp = await axios.put<Result<TitleFollowsEntity>>(
      `/follows/title/${titleId}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          ...Headers.Bearer(auth),
        },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async unfollow(titleId: string, auth: string) {
    const resp = await axios<MessageResult<"Title unfollowed">>(
      `/follows/title/${titleId}`,
      {
        method: "DELETE",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  static async getFollow(
    titleId: string,
    includes: UserTitlesFollowsExpansionTypes[] = [],
    auth: string,
  ) {
    const resp = await axios<Result<TitleFollowsEntity>>(
      `/follows/title/${titleId}` + paramsParser({ includes: includes }),
      { headers: Headers.Bearer(auth) },
    ).catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 404)
        return null;
      else throw err;
    });

    if (resp === null) return null;

    return assertSuccess(resp.data).data;
  }

  static async getUserFollows(
    params: TitleFollowsListParams = {},
    auth: string,
  ): Promise<Collection<TitleFollowsEntity>> {
    const resp = await axios<CollectionResult<TitleFollowsEntity>>(
      `/follows/title` + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  static async competitions(
    titleId: string,
    params: TitleCompetitionEntriesListParams = {},
    auth: string,
  ) {
    // https://gitlab.com/namicomi/backend/-/blob/develop/application/src/Competition/Controller/CompetitionController.php?ref_type=heads#L61
    const resp = await axios<CollectionResult<CompetitionEntryEntity>>(
      `/title/${titleId}/competitions` + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getStats(id: string) {
    return Statistics.getTitleStats(id);
  }
}

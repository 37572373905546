import type { MarkedExtension, Token, Tokens } from "marked";

interface EmoteToken extends Tokens.Generic {
  type: "emote";
  raw: string;
  text: string;
  tokens: Token[];
}

export default function (options = { unsupported: false }): MarkedExtension {
  // extension code here
  const rule = /^<emoji:(?<id>[^:]*):(?<key>[^:]*):(?<fileName>[^:>]*)>/;
  const cdnUrl = useRuntimeConfig().public.cdnOrigin;

  return {
    extensions: [
      {
        name: "emote",
        level: "inline",
        start: (src) => src.match(/<emoji:/)?.index,
        tokenizer(src) {
          const match = rule.exec(src);
          if (match) {
            const token: EmoteToken = {
              // Token to generate
              type: "emote", // Should match "name" above
              raw: match[0], // Text to consume from the source
              text: "", // Additional custom properties
              tokens: [], // Array where child inline tokens will be generated
            };

            return token;
          }
        },
        renderer(token) {
          const match = rule.exec(token.raw)!;
          const { id, key, fileName } = match.groups!;
          if (options.unsupported) return `:${key}:`;
          return `
            <button class="w-6 h-6 emote align-middle">
              <img class="inline w-6 h-6 align-top emote" src="${cdnUrl}/media/emote/${id}/emoji/${fileName}" alt="${key}">
              <span class="emote-id" hidden>${id}</span>
            </button>
          `;
        },
      },
    ],
  };
}

<template>
  <template
    v-if="
      isGroupConversation &&
      conversationFriendlyName &&
      conversationFriendlyName !== defaultConversationName
    "
  >
    {{ conversationFriendlyName }}
  </template>
  <template v-else>
    {{ commaSeparatedParticipantDisplayNames }}
  </template>
</template>

<script setup lang="ts">
import { convertTwilioUserToDisplayData, type UserDisplayData } from "./common";

const defaultConversationName = "conversation_dm";

const props = defineProps<{
  conversation: EnhancedConversation;
  name: string | null;
  users?: UserDisplayData[];
}>();

const nuxtApp = useNuxtApp();
const auth = nuxtApp.$auth();

const { data: users } = useAsyncData(
  `convo.users-${props.conversation.sid}`,
  async () => {
    if (props.users) return props.users;
    const participants = await props.conversation.getParticipants();
    const users = (await Promise.all(
      participants.map((p) => p.getUser()),
    )) as EnhancedUser[];
    return users.map((user) => convertTwilioUserToDisplayData(user));
  },
  { watch: [() => props.conversation], default: () => [] },
);

const secondaryTwilioSetup = useSecondaryTwilioSetup();

const conversationFriendlyName = computed(
  () => props.name ?? props.conversation.friendlyName,
);

const otherUsers = computed(() => {
  const currentTwilioId = secondaryTwilioSetup.value
    ? secondaryTwilioSetup.value?.id
    : auth?.userEntity?.id;
  return users.value.filter((user) => user.id !== currentTwilioId);
});

const isGroupConversation = computed(() => users.value.length > 2);

const commaSeparatedParticipantDisplayNames = computed(() => {
  if (isGroupConversation.value) {
    return users.value.map((user) => user.displayName).join(", ");
  } else {
    return otherUsers.value.map((user) => user.displayName).join(", ");
  }
});
</script>

<template>
  <slot></slot>
</template>

<script setup lang="ts">
import { initDeveloperContext } from "./sources/developer/init";
import { initNotificationsContext } from "./sources/notifications/init";
import { initTwilioContext } from "./sources/twilio/init";

initDeveloperContext();
initNotificationsContext();
initTwilioContext();
</script>

import { storeToRefs } from "pinia";
import { useSettingsStore } from "~/stores/settings";

export function useCurrentTheme() {
  const { settings } = storeToRefs(useSettingsStore());

  return computed(() => {
    if (settings.value.site.theme === "dark") {
      return "dark";
    } else if (settings.value.site.theme === "light") {
      return "light";
    } else {
      return document.documentElement.classList.contains("dark")
        ? "dark"
        : "light";
    }
  });
}

<template>
  <AppContext>
    <div>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>

      <ClientOnly>
        <NamiModalPresets
          v-for="modal in appStore?.modalPresetArray"
          v-bind="modal"
          @update:modelValue="(v) => (modal.modelValue = v)"
        />
        <NamiModal
          v-for="modal in appStore?.modalComponentArray"
          :modelValue="modal.modelValue"
          @update:modelValue="(v) => (modal.modelValue = v)"
          v-bind="modal.modalProps"
        >
          <component
            :is="modal.component"
            :resolver="modal.resolver"
            @resolved="
              (action: string) => {
                modal.resolver(action);
                modal.modelValue = false;
              }
            "
            v-bind="modal.props"
          />
        </NamiModal>
        <NamiNotificationsPresets ref="notifications" />
        <NamiNotifications
          :position="'bottom-right'"
          :maxVisibleNotifications="5"
          noPadding
          ref="notificationsAdvanced"
        />
        <Overlay v-for="key in session.allOverlayKeys" :overlay-key="key" />
        <EmoteDetails></EmoteDetails>
        <div
          class="fixed pointer-events-none z-[30] left-0 top-0 w-full h-full flex flex-col justify-end"
          v-if="showMessagesOverlay"
        >
          <TheGlobalMessages class="max-h-[85dvh]"></TheGlobalMessages>
        </div>
      </ClientOnly>
    </div>
  </AppContext>
</template>

<script lang="ts" setup>
import { useCookies } from "@vueuse/integrations/useCookies";
import NamiNotificationsPresets from "~/components/nami/notifications/NamiNotificationsPresets.vue";
import NamiNotifications from "~/components/nami/notifications/NamiNotifications.vue";
import { executeWithNotificationOnError } from "~/utils/errors";
import { updateTheme } from "~/utils/functions";
import type { AvailableLocales } from "~/src/api";
import AppContext from "./components/app/context/AppContext.vue";
import { setApiUrl, setCdnOrigin, setPaymentsUrl } from "~/src/api";

const config = useRuntimeConfig();

setApiUrl(
  import.meta.server
    ? process.env.PRIVATE_API_URL || config.public.apiUrl
    : config.public.apiUrl,
);
setCdnOrigin(config.public.cdnOrigin);
setPaymentsUrl(config.public.paymentsUrl);

// Use SSR-safe IDs for Headless UI
provideHeadlessUseId(() => useId());

useHead({
  titleTemplate: (titleChunk) => {
    return !titleChunk
      ? `${config.public.siteName}`
      : titleChunk.includes("NamiComi")
        ? `${titleChunk}`
        : `${titleChunk} - ${config.public.siteName}`;
  },
});

useDevTools();
const session = useSession();
const nuxtApp = useNuxtApp();
const locale = nuxtApp.$i18n.global.locale;

const translate = nuxtApp.$i18n.global.t;
const route = useRoute();
const cookies = useCookies(["PREF_LANG"]);
const notifications = ref<InstanceType<typeof NamiNotificationsPresets>>();
const notificationsAdvanced = ref<InstanceType<typeof NamiNotifications>>();
const showMessagesOverlay = computed(() => {
  if (route.path.includes("/feed")) {
    return true;
  }

  return false;
});

const authStore = nuxtApp.$auth();
const settingsStore = nuxtApp.$settings();
const appStore = nuxtApp.$app();

watch(notifications, (v) => appStore?.setGlobalNotificationsComponent(v));
watch(notificationsAdvanced, (v) =>
  appStore?.setAdvancedNotificationsComponent(v),
);

if (route.params.locale) {
  locale.value = localeMap(route.params.locale as AvailableLocales);
}

async function updateLocale(newLocale: AvailableLocales) {
  assertDefined(settingsStore);

  settingsStore.settings.site.locale = newLocale;
  await settingsStore.update(settingsStore.settings);

  cookies.set("PREF_LANG", newLocale, { maxAge: 60 * 60 * 24 * 30, path: "/" });

  navigateTo({
    query: {
      ...route.query,
      preferredLocale: undefined,
    },
  });
}

onMounted(async () => {
  assertDefined(authStore);
  assertDefined(settingsStore);
  assertDefined(appStore);

  await executeWithNotificationOnError(async () => {
    await authStore.syncAuthState();
    await settingsStore.initialize(authStore.getUser);
  }).catch(() => {});

  const mappedRouteLocale = localeMap((route.params.locale as string) ?? "en");
  const preferredLocale = route.query.preferredLocale as
    | AvailableLocales
    | undefined;

  if (
    preferredLocale &&
    mappedRouteLocale &&
    mappedRouteLocale !== preferredLocale
  ) {
    const action = await appStore.prompt("Switch locales?", {
      detail: translate(
        "site.global.localeMismatchModal.message",
        {
          language1: translate(`languages.${mappedRouteLocale}`),
          language2: translate(
            `languages.${settingsStore.settings.site.locale}`,
          ),
        },
        { locale: settingsStore.settings.site.locale },
      ),
      icon: "confused",
      buttons: {
        always: {
          buttonType: "secondary",
          buttonText: translate(
            "site.global.localeMismatchModal.alwaysButton",
            {
              language: translate(`languages.${mappedRouteLocale}`),
            },
            { locale: settingsStore.settings.site.locale },
          ),
        },
        stay: {
          buttonType: "secondary",
          buttonText: translate(
            "site.global.localeMismatchModal.stayButton",
            {
              language: translate(`languages.${mappedRouteLocale}`),
            },
            { locale: preferredLocale },
          ),
        },
        switch: {
          buttonType: "primary",
          buttonText: translate(
            "site.global.localeMismatchModal.proceedButton",
            {
              language: translate(`languages.${preferredLocale}`),
            },
            { locale: preferredLocale },
          ),
        },
      },
      forceCloseKey: "stay",
    });

    switch (action) {
      case "always": {
        executeWithNotificationOnError(async () =>
          updateLocale(route.params.locale as AvailableLocales),
        );
        break;
      }

      case "stay": {
        locale.value = localeMap(route.params.locale as AvailableLocales);
        navigateTo({
          query: { ...route.query, preferredLocale: undefined },
        });
        break;
      }

      case "switch": {
        navigateTo(
          `/${settingsStore.settings.site.locale}${route.fullPath.replace(
            `/${route.params.locale}`,
            "",
          )}`,
        );
        break;
      }
    }
  }

  executeWithNotificationOnError(updateTheme);
});
</script>

<template>
  <component
    :is="noLink ? 'div' : TheNuxtLink"
    :to="user && user.attributes ? linkTo(user) : undefined"
    class="user-avatar"
  >
    <FrameImage
      v-if="realFrame && !noFrame"
      :frame="realFrame"
      class="absolute inset-0 w-full h-full scale-[1.2] z-[1]"
    />
    <AsyncImage
      v-if="user"
      :src="avatar"
      alt="avatar"
      class="rounded-full flex-grow h-full"
    />
    <Skeleton
      v-else-if="skeleton"
      class="!rounded-full flex-grow h-full w-full overflow-hidden"
    />
    <component
      :is="userIcon || IconUser"
      v-else
      :size="size ? (size * 5) / 8 : 24"
    />
  </component>
</template>

<script setup lang="ts">
import {
  type FrameEntity,
  type FrameRelation,
  isExpanded,
  isFrame,
  type PopulateRelationship,
  type UserEntity,
  type UserRelations,
} from "~/src/api";
import { IconUser } from "@tabler/icons-vue";
import { parseUserForDisplay } from "~/utils/display/user";
import FrameImage from "~/components/frame/FrameImage.vue";

const TheNuxtLink = resolveComponent("TheNuxtLink");

interface Props {
  size?: number;
  user?: UserEntity | UserRelations;
  frame?: FrameEntity | PopulateRelationship<FrameRelation>;
  userIcon?: typeof IconUser;
  skeleton?: boolean;
  noLink?: boolean;
  noFrame?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: 32,
});

const realFrame = computed(() => {
  if (props.frame) return props.frame;

  const frame = (props.user as UserEntity | undefined)?.relationships?.find(
    isFrame,
  );
  if (frame && isExpanded(frame)) return frame;
});

const pxSize = computed(() => props.size + "px");
const { avatar } = parseUserForDisplay(
  computed(() => props.user),
  props.size >= 512
    ? "original"
    : props.size >= 256
      ? "large"
      : props.size >= 128
        ? "med"
        : "small",
);
</script>

<style scoped lang="postcss">
.user-avatar {
  max-width: v-bind(pxSize);
  max-height: v-bind(pxSize);
  width: v-bind(pxSize);
  height: v-bind(pxSize);

  @apply flex items-center justify-center relative;
}
</style>

<template>
  <span class="flex-shrink-0 inline-block">
    <Tooltip fixed>
      <BadgeImage :badge="badge" :size :sizeClass />
      <template #tooltip>
        <div class="px-2">
          <h6 class="whitespace-nowrap">
            {{ extractFromLocale(locale, badge.attributes.name) }}
          </h6>
          <p class="text-xs opacity-70 line-clamp-3">
            {{ extractFromLocale(locale, badge.attributes.description) }}
          </p>
        </div>
      </template>
    </Tooltip>
  </span>
</template>

<script setup lang="ts">
import type {
  BadgeEntity,
  BadgeRelation,
  PopulateRelationship,
} from "~/src/api";

defineProps<{
  badge: BadgeEntity | PopulateRelationship<BadgeRelation>;
  size?: "normal" | "small" | "medium" | "large" | "fill";
  sizeClass?: string;
}>();

const nuxtApp = useNuxtApp();
const { locale } = nuxtApp.$i18n.global;
</script>

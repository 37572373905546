import { linkTo } from "~/utils/functions";
import type { Ref, ToRefs } from "vue";
import type { MaybeRef } from "@vueuse/shared";
import {
  type BadgeRelation,
  isBadge,
  isExpanded,
  type OrganizationEntity,
  type OrganizationRelation,
  type PopulateRelationship,
  type PrivateOrganizationEntity,
} from "~/src/api";

type ParsedOrgOutput = {
  id: string;
  displayName: string;
  avatar: string;
  banner: string;
  href: string;
  createdAt: string;
  tagline: string;
  official: boolean;
  verified: boolean;
  active: boolean;
  badges: BadgeRelation[];
};

const DefaultReturn: ParsedOrgOutput = {
  id: "",
  displayName: "",
  avatar: "/img/sea-deity.png",
  banner: "/img/great-wave.png",
  href: "",
  createdAt: "",
  tagline: "",
  official: false,
  verified: false,
  active: false,
  badges: [],
};

export function parseOrgForDisplay(
  x:
    | OrganizationEntity
    | OrganizationRelation
    | PrivateOrganizationEntity
    | null
    | undefined,
  res?: "small" | "med" | "large" | "original",
): ParsedOrgOutput;
export function parseOrgForDisplay(
  x: Ref<
    | OrganizationEntity
    | OrganizationRelation
    | PrivateOrganizationEntity
    | null
    | undefined
  >,
  res?: "small" | "med" | "large" | "original",
): ToRefs<ParsedOrgOutput>;
export function parseOrgForDisplay(
  orgInput: MaybeRef<
    | OrganizationEntity
    | OrganizationRelation
    | PrivateOrganizationEntity
    | null
    | undefined
  >,
  res: "small" | "med" | "large" | "original" = "original",
): ParsedOrgOutput | ToRefs<ParsedOrgOutput> {
  const { getOrganizationBanner, getOrganizationAvatar } = useMediaLink();

  let thumbRes: "128" | "256" | "512" | "original";
  let bannerRes: "640" | "1280" | "1920" | "original";
  switch (res) {
    case "small":
      thumbRes = "128";
      bannerRes = "640";
      break;
    case "med":
      thumbRes = "256";
      bannerRes = "1280";
      break;
    case "large":
      thumbRes = "512";
      bannerRes = "1920";
      break;
    default:
      thumbRes = "original";
      bannerRes = "original";
  }

  let id: MaybeRef<string>;
  let displayName: MaybeRef<string>;
  let avatar: MaybeRef<string>;
  let banner: MaybeRef<string>;
  let href: MaybeRef<string>;
  let createdAt: MaybeRef<string>;
  let tagline: MaybeRef<string>;
  let verified: MaybeRef<boolean>;
  let official: MaybeRef<boolean>;
  let active: MaybeRef<boolean>;

  if (isRef(orgInput)) {
    id = computed(() => orgInput.value?.id ?? "");
    displayName = computed(() => orgInput.value?.attributes?.name ?? "");
    avatar = computed(() =>
      orgInput.value && isExpanded(orgInput.value)
        ? getOrganizationAvatar(orgInput.value, thumbRes)
        : "",
    );
    banner = computed(() =>
      orgInput.value && isExpanded(orgInput.value)
        ? getOrganizationBanner(orgInput.value, bannerRes)
        : "",
    );
    href = computed(() =>
      orgInput.value?.id
        ? linkTo("org", orgInput.value.id, orgInput.value?.attributes?.slug)
        : "",
    );
    createdAt = computed(() =>
      orgInput.value?.attributes
        ? formatDateAlt(orgInput.value?.attributes?.createdAt)
        : "",
    );
    tagline = computed(() => orgInput.value?.attributes?.tagline ?? "");
    verified = computed(() => !!orgInput.value?.attributes?.verified);
    official = computed(() => !!orgInput.value?.attributes?.official);
    active = computed(() => !!orgInput.value?.attributes?.active);
  } else {
    if (!orgInput) return DefaultReturn;
    id = orgInput.id;
    displayName = orgInput?.attributes?.name ?? "";
    avatar =
      orgInput && isExpanded(orgInput)
        ? getOrganizationAvatar(orgInput, thumbRes)
        : "";
    banner =
      orgInput && isExpanded(orgInput)
        ? getOrganizationBanner(orgInput, bannerRes)
        : "";
    href = linkTo("org", orgInput.id, orgInput.attributes?.slug);
    createdAt = orgInput?.attributes
      ? formatDateAlt(orgInput.attributes.createdAt)
      : "";
    tagline = orgInput?.attributes?.tagline ?? "";
    verified = !!orgInput?.attributes?.verified;
    official = !!orgInput?.attributes?.official;
    active = !!orgInput?.attributes?.active;
  }

  return {
    id,
    displayName,
    avatar,
    banner,
    href,
    createdAt,
    verified,
    official,
    tagline,
    active,
    // orgs will never have badges, here for compat
    badges: [],
  } as ToRefs<ParsedOrgOutput> | ParsedOrgOutput;
}

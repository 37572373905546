<template>
  <NamiModal v-model="showDetailsModal">
    <AsyncState
      :pending="emotePreviewPending"
      :error="emotePreviewError"
      :refresh="emotePreviewRefresh"
      :data="emotePreviewData"
    >
      <template #default="{ data: emote }">
        <template v-if="emote">
          <NamiModalTitle @close="showDetailsModal = false">
            {{ emote.attributes.kind === "emoji" ? "Emote" : "Sticker" }}
            details
          </NamiModalTitle>
          <div>
            <div
              v-if="emote.attributes.kind === 'sticker'"
              class="grid grid-cols-[8rem_1fr] items-center gap-4"
            >
              <AsyncImage
                :src="
                  getImageUrl(
                    emote.id,
                    'emote',
                    emote.attributes.kind,
                    emote.attributes.fileName,
                  )
                "
                class="object-contain rounded-md w-32 h-32"
              ></AsyncImage>
              <div>
                <h3
                  :title="emote.attributes.name"
                  class="text-lg font-semibold line-clamp-2"
                >
                  {{ emote.attributes.name }}
                </h3>
                <p
                  :title="emote.attributes.key"
                  class="truncate text-sm text-neutral-600 dark:text-neutral-400"
                >
                  :{{ emote.attributes.key }}:
                </p>
              </div>
            </div>
            <div v-else>
              <div class="grid grid-cols-[4rem_1fr] gap-4 items-center mb-2">
                <AsyncImage
                  :src="
                    getImageUrl(
                      emote.id,
                      'emote',
                      emote.attributes.kind,
                      emote.attributes.fileName,
                    )
                  "
                  class="object-contain rounded-md w-16 h-16"
                ></AsyncImage>
                <div>
                  <h3
                    :title="emote.attributes.name"
                    class="text-lg font-semibold line-clamp-2"
                  >
                    {{ emote.attributes.name }}
                  </h3>
                  <p
                    :title="emote.attributes.key"
                    class="truncate text-sm text-neutral-600 dark:text-neutral-400"
                  >
                    :{{ emote.attributes.key }}:
                  </p>
                </div>
              </div>
            </div>
            <NamiDivider></NamiDivider>
            <div v-if="isGlobal">
              <p class="text-xl font-medium text-center text-nami-comi-blue">
                Global
                {{ emote.attributes.kind === "emoji" ? "emote" : "sticker" }}
              </p>
              <p class="text-sm text-center text-nami-comi-blue">
                You can use this
                {{ emote.attributes.kind === "emoji" ? "emote" : "sticker" }}
                everywhere.
              </p>
            </div>
            <div v-else>
              <template v-if="orgOwningThisEmote">
                <p class="text-sm font-light">Creator</p>
                <OrganizationCardDense
                  :org="orgOwningThisEmote"
                ></OrganizationCardDense>
                <p class="text-center mt-4 text-nami-comi-blue">
                  <template v-if="emote.attributes.requirement === 'none'">
                    You can use this
                    {{
                      emote.attributes.kind === "emoji" ? "emote" : "sticker"
                    }}
                    everywhere.
                  </template>
                  <template
                    v-else-if="emote.attributes.requirement === 'follow'"
                  >
                    Follow {{ orgOwningThisEmote.attributes.name }} to unlock
                    this
                    {{
                      emote.attributes.kind === "emoji" ? "emote" : "sticker"
                    }}.
                  </template>
                  <template
                    v-else-if="emote.attributes.requirement === 'subscription'"
                  >
                    Subscribe to {{ tierName }} to unlock this
                    {{
                      emote.attributes.kind === "emoji" ? "emote" : "sticker"
                    }}.
                  </template>
                </p>
              </template>
              <template v-else> </template>
            </div>
            <div class="flex justify-end"></div>
          </div>
        </template>
      </template>
    </AsyncState>
  </NamiModal>
</template>

<script setup lang="ts">
import { isExpanded, isOrg, isTier } from "~/src/api";
import { useEventListener } from "@vueuse/core";

const nuxtApp = useNuxtApp();
const locale = nuxtApp.$i18n.global.locale;

const { getImageUrl } = useMediaLink();

const currentId = ref("");

const emotePreviewAsyncData = useEmotePreview(currentId);

const emotePreviewPending = computed(
  () => emotePreviewAsyncData.value.pending.value,
);
const emotePreviewData = computed(() => emotePreviewAsyncData.value.data.value);
const emotePreviewError = computed(
  () => emotePreviewAsyncData.value.error.value,
);
const emotePreviewRefresh = computed(() => emotePreviewAsyncData.value.refresh);

const showDetailsModal = ref(false);

const tierToSubscribeTo = computed(() => {
  const tier = emotePreviewData.value?.relationships.find(isTier);
  if (!tier || !isExpanded(tier)) return null;
  return tier;
});

const tierName = computed(() => {
  // tier not expanded?
  if (!tierToSubscribeTo.value) return "???";
  return extractFromLocale(
    locale.value,
    tierToSubscribeTo.value.attributes.name,
  );
});

const orgOwningThisEmote = computed(() => {
  const org = emotePreviewData.value?.relationships.find(isOrg);
  if (!org || !isExpanded(org)) return null;
  return org;
});

const isGlobal = computed(() => !orgOwningThisEmote.value);

onMounted(() => {
  useEventListener(document, "click", (e) => {
    if (!(e.target instanceof HTMLElement)) return;
    const parent = e.target.parentElement;
    if (!parent || !(parent instanceof HTMLButtonElement)) return;
    const idSpanElement = parent.querySelector(".emote-id");
    if (!idSpanElement) return;
    currentId.value = idSpanElement.textContent ?? "";
    showDetailsModal.value = true;
  });
});
</script>

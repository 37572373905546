<template>
  <div
    :class="isMe ? 'show-the-animation' : 'show-the-animation-reverse'"
    ref="messageBubbleContainer"
  >
    <div
      class="flex"
      :class="{
        'justify-end': isMe,
      }"
    >
      <div
        class="grid gap-2 group"
        :class="{
          'grid-cols-[auto_2rem]':
            !isMe && isSuperuser && messageOptions.length > 0,
          'grid-cols-[2rem_auto]': isMe && messageOptions.length > 0,
          'w-full': message.attachments?.length,
        }"
      >
        <ContextMenuListNew
          v-if="(isMe || isSuperuser) && messageOptions.length > 0"
          :options="messageOptions"
          class="h-7"
          :class="{
            'order-[1]': !isMe,
          }"
          v-slot="{ toggle }"
        >
          <button
            class="opacity-0 group-hover:opacity-100 w-7 h-7 mx-1 my-[0.1rem] rounded-full hover:bg-neutral-200 hover:dark:bg-neutral-800"
            @click="toggle"
          >
            <IconDotsVertical :size="16" />
          </button>
        </ContextMenuListNew>
        <div
          class="relative w-full group py-1 px-3 rounded-xl transition-[border-radius] duration-300 gap-2"
          :class="{
            'rounded-br-none':
              isMe && (relativeOrder === 'middle' || relativeOrder === 'first'),
            'rounded-tr-none':
              isMe && (relativeOrder === 'middle' || relativeOrder === 'last'),
            'rounded-bl-none':
              !isMe &&
              (relativeOrder === 'middle' || relativeOrder === 'first'),
            'rounded-tl-none':
              !isMe && (relativeOrder === 'middle' || relativeOrder === 'last'),
            'bg-nami-comi-blue text-white order-2': isMe,
            'bg-neutral-200 dark:bg-neutral-600': !isMe,
          }"
        >
          <p
            v-if="
              isGroupConversation &&
              !isMe &&
              (relativeOrder === 'first' || relativeOrder === 'standalone')
            "
            class="text-xs"
            :style="{
              color: authorColor
                ? isMe
                  ? undefined
                  : currentTheme === 'dark'
                    ? authorColor.dark
                    : authorColor.light
                : undefined,
            }"
          >
            {{ message.author?.name }}
          </p>
          <template
            v-if="message.content !== '' || message.attachments?.length"
          >
            <span v-for="(chunk, index) in messageChunks">
              <template v-for="part in chunk">
                <template v-if="part.type === 'text'">
                  <span>{{ part.content }}</span>
                </template>
                <NamiLink
                  v-else-if="part.type === 'link'"
                  :to="part.url"
                  class="hover:underline break-all"
                  :class="{
                    'text-white': isMe,
                    'text-nami-comi-blue': !isMe,
                  }"
                >
                  {{ part.content }}
                </NamiLink>
              </template>
              <br v-if="index < messageChunks.length - 1" />
            </span>
            <div
              v-if="message.attachments?.length"
              class="grid gap-[2px] mt-2 w-full"
              :class="{
                'grid-cols-2': message.attachments.length > 1,
              }"
            >
              <template v-for="(media, index) in message.attachments">
                <Skeleton
                  v-if="!media.url"
                  class="w-full"
                  :class="{
                    'h-32': message.attachments.length > 1,
                    'h-[3.125rem]':
                      message.attachments.length === 1 &&
                      message.attachments[0].type !== 'image',
                    'col-span-2':
                      index % 2 === 0 &&
                      index === message.attachments.length - 1,
                  }"
                />
                <template v-else>
                  <img
                    v-if="media.type.includes('image')"
                    :src="media.url ?? ''"
                    class="h-32 w-full object-cover rounded-md cursor-pointer transition-[filter] hover:brightness-75"
                    :class="{
                      'col-span-2':
                        index % 2 === 0 &&
                        index === message.attachments.length - 1,
                    }"
                    @click="
                      $emit(
                        'imagesPreview',
                        message.attachments
                          .map((a) => a.url)
                          .filter(onlyTruthys),
                        index,
                      )
                    "
                    @error="() => media.refresh()"
                  />
                  <button
                    v-else
                    @click="media.refresh().then((url) => downloadMedia(url))"
                    class="text-left w-full rounded-md px-2 py-1 border dark:border-neutral-200 dark:hover:bg-neutral-200/20 transition truncate"
                    :class="{
                      'h-32': message.attachments.length > 1,
                      'col-span-2':
                        index % 2 === 0 &&
                        index === message.attachments.length - 1,
                    }"
                    :title="media.name"
                  >
                    <template v-if="message.attachments.length === 1">
                      <p class="truncate text-sm">{{ media.name }}</p>
                      <div class="flex justify-between items-end">
                        <p class="text-xs">
                          <span>{{ normalizeSize(media.size) }}</span>
                          <span class="mx-1">-</span>
                          <span class="capitalize">{{ media.type }}</span>
                        </p>
                        <IconDownload :size="20" />
                      </div>
                    </template>
                    <div v-else class="flex flex-col h-full">
                      <div class="flex-grow flex justify-center items-center">
                        <IconFile class="mx-auto" />
                      </div>
                      <p class="truncate text-xs">{{ media.name }}</p>
                      <div class="flex justify-between items-end">
                        <p class="text-xs">{{ normalizeSize(media.size) }}</p>
                        <IconDownload :size="16" />
                      </div>
                    </div>
                  </button>
                </template>
              </template>
            </div>
          </template>
          <span v-else class="opacity-60 italic text-sm">
            {{
              isMe
                ? "You deleted this message."
                : "This message has been deleted."
            }}
          </span>
          <span class="text-xs opacity-0 select-none">
            &nbsp;
            {{ sentAt }}
          </span>
          <p
            class="text-xs absolute right-2 bottom-1 select-none"
            :class="{
              'text-neutral-200': isMe,
              'text-neutral-400': !isMe,
            }"
          >
            {{ sentAt }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  IconTrash,
  IconX,
  IconDotsVertical,
  IconDownload,
  IconFile,
  IconFlag,
} from "@tabler/icons-vue";
import { useIntersectionObserver } from "@vueuse/core";
import type { Message } from "~/composables/messages";
import type { Option } from "../contextMenu/ContextMenuListNew.vue";
import type { UserDisplayData } from "./common";

type MessagePartText = {
  type: "text";
  content: string;
};

type MessagePartLink = {
  type: "link";
  content: string;
  url: string;
};

type MessagePart = MessagePartText | MessagePartLink;

const props = defineProps<{
  message: Message;
  relativeOrder: "first" | "middle" | "last" | "standalone";
  currentUser: UserDisplayData;
  isGroupConversation?: boolean;
  color?: string;
}>();

const emit = defineEmits<{
  (e: "imagesPreview", images: string[], index: number): void;
  (e: "reportMessage"): void;
}>();

const colorsPool = [
  { dark: "#ffabab", light: "#ff2929" },
  { dark: "#b2ff00", light: "#6b8238" },
  { dark: "#00ffff", light: "#1d6565" },
  { dark: "#c0c7ff", light: "#303fbd" },
  { dark: "#f9ceff", light: "#8f12a0" },
  { dark: "#b1fff9", light: "#029287" },
  { dark: "#29ff39", light: "#03920d" },
  { dark: "#feff4d", light: "#797a00" },
  { dark: "#e1b0ff", light: "#681898" },
  { dark: "#ffffff", light: "#000000" },
];

const reportReadIndex = inject<(index: number) => void>("reportReadIndex");

const linkRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-:]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/gi;

const nuxtApp = useNuxtApp();
const app = nuxtApp.$app();
const isSuperuser = useFeatureAccess("any");
const config = useRuntimeConfig();
const currentTheme = useCurrentTheme();

const messageBubbleContainer = ref<HTMLDivElement>();

useIntersectionObserver(messageBubbleContainer, ([entry]) => {
  if (entry.intersectionRatio > 0.25) {
    reportReadIndex?.(props.message.index);
  }
});

const isMe = computed(() => props.message.author?.id === props.currentUser.id);

const authorColor = computed(() => {
  if (!props.message.author) return null;
  const sum = props.message.author.id
    .split("")
    .reduce((acc, c) => acc + c.charCodeAt(0), 0);

  return colorsPool[sum % colorsPool.length];
});

const messageChunks = computed(() => {
  return props.message.content.split("\n").map((chunk) => {
    const links = Array.from(chunk.matchAll(linkRegex));
    let currentIndex = 0;
    const parts: MessagePart[] = [];
    for (const link of links.map((l) => l[0])) {
      const processedLink = processLink(link);
      const linkStartIndex = chunk.indexOf(processedLink, currentIndex);
      const linkEndIndex = linkStartIndex + processedLink.length;
      const previousText = chunk.slice(currentIndex, linkStartIndex);
      if (previousText) {
        parts.push({
          type: "text",
          content: previousText,
        });
      }
      parts.push({
        type: "link",
        content: processedLink,
        url: getLinkUrl(processedLink),
      });
      currentIndex = linkEndIndex + 1;
    }
    const remainingText = chunk.slice(currentIndex);
    if (remainingText) {
      parts.push({
        type: "text",
        content: remainingText,
      });
    }
    return parts;
  });
});

const messageOptions = computed<Option[]>(() => {
  const options: Option[] = [];

  if (props.message.attachments?.length)
    options.push({
      text: "Delete",
      type: "default",
      value: "delete",
      action: (event: MouseEvent) => deleteMessage(event.shiftKey),
      icon: IconTrash,
    });
  else if (props.message.content !== "")
    options.push({
      text: "Delete",
      type: "default",
      value: "delete",
      action: (event: MouseEvent) => deleteMessage(event.shiftKey),
      icon: IconTrash,
    });

  if (!isMe.value) {
    options.push({
      type: "danger",
      text: "Report message",
      value: "report",
      icon: IconFlag,
      action: () => emit("reportMessage"),
    });
  }

  if (isSuperuser.value)
    options.push({
      text: "Staff Delete",
      type: "danger",
      value: "hardDelete",
      action: (event: MouseEvent) => hardDeleteMessage(event.shiftKey),
      icon: IconX,
    });

  return options;
});

const sentAt = computed(() => props.message.date.format("HH:mm"));

async function downloadMedia(url: string | null) {
  if (!url) return;
  const a = document.createElement("a");
  a.href = url;
  a.download = "true";
  a.click();
}

function normalizeSize(size: number) {
  let pow = 0;
  const powerToSize = ["B", "KB", "MB", "GB", "TB"];
  const getNormalizedSize = () =>
    Math.round((size / Math.pow(1024, pow)) * 100) / 100;
  while (size + Math.pow(1024, pow + 1) / 8 > Math.pow(1024, pow + 1)) {
    pow++;
  }
  return `${getNormalizedSize()} ${powerToSize[pow]}`;
}

function processLink(link: string) {
  const firstHttpIndex = link.indexOf("http://");
  const firstHttpsIndex = link.indexOf("https://");
  const firstProtocolIndex =
    firstHttpIndex !== -1
      ? firstHttpIndex
      : firstHttpsIndex !== -1
        ? firstHttpsIndex
        : 0;

  return link.replace(/\.$/, "").slice(firstProtocolIndex);
}

function getLinkUrl(link: string) {
  if (link.startsWith(config.public.baseUrl))
    return link.replace(config.public.baseUrl, "");
  return link;
}

async function deleteMessage(force = false) {
  if (!force && (await confirmDelete()) !== "confirm") return;

  await executeWithNotificationOnError(async () => {
    await props.message.actions?.delete?.();
  });
}

async function confirmDelete() {
  return await app?.prompt("Are you sure you want to delete this message?", {
    icon: "confused",
    buttons: {
      cancel: {
        buttonType: "secondary",
        buttonText: "Cancel",
      },
      confirm: {
        buttonType: "danger",
        buttonText: "Delete",
      },
    },
  });
}

async function hardDeleteMessage(force = false) {
  if (!force && (await confirmHardDelete()) !== "confirm") return;

  await executeWithNotificationOnError(async () => {
    await props.message.actions?.hardDelete?.();
  });
}

async function confirmHardDelete() {
  return await app?.prompt(
    "Are you sure you want to hard delete this message?",
    {
      icon: "confused",
      buttons: {
        cancel: {
          buttonType: "secondary",
          buttonText: "Cancel",
        },
        confirm: {
          buttonType: "danger",
          buttonText: "Hard Delete",
        },
      },
    },
  );
}
</script>

<style scoped>
.show-the-animation {
  animation: fadeIn 0.3s;
}

.show-the-animation-reverse {
  animation: fadeInReverse 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInReverse {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>

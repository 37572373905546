export const uniqueRule = (message?: string) => (value: string[]) =>
  new Set(value).size === value.length
    ? null
    : message || "You have duplicate inputs";

export const minRule =
  (message?: string, min = 1) =>
  (value: any[]) =>
    value.length > min - 1
      ? null
      : message || `You need at least ${min} input${min === 1 ? "" : "s"}`;

export const nonEmptyRule = (message?: string) => (value: string) =>
  !!value || value.length > 0 ? null : message || "You must enter an input";

export function assertDefined<T>(
  arg: MaybeRef<T | null | undefined>,
  errorMessage?: string,
): asserts arg is MaybeRef<T> {
  if (typeof unref(arg) !== "undefined" && unref(arg) !== null) {
    return;
  } else {
    throw new Error(
      errorMessage ??
        `Error validating arg ${arg} because it's either null or undefined.`,
    );
  }
}

export function mustReturnDefined<T>(
  fn: () => T | null | undefined,
  errorMessage?: string,
): T {
  const result = fn();
  if (typeof result !== "undefined" && result !== null) {
    return result as T;
  } else {
    throw new Error(
      errorMessage ??
        `Error validating function ${fn} because it's either null or undefined.`,
    );
  }
}

<template>
  <NotificationsItem
    :is-unread
    :href
    :action-icon="IconMessage"
    :date="notification.date"
  >
    <template #avatar>
      <UserAvatar
        v-if="notification.data.commenter.type === 'user'"
        :user="notification.data.commenter"
        :size="40"
        no-link
      />
      <OrganizationAvatar
        v-else-if="notification.data.commenter.type === 'organization'"
        :org="notification.data.commenter"
        :size="40"
      />
    </template>
    <template #content>
      <div class="space-y-2">
        <p class="line-clamp-2">
          <i18n-t
            keypath="notifications.creator.comment_to_chapter.body"
            scope="global"
          >
            <template #commenter>
              <strong>{{ commenterName }}</strong>
            </template>
            <template #chapter>
              <strong>{{ shortName }}</strong>
            </template>
            <template #title>
              <strong>{{ title }}</strong>
            </template>
          </i18n-t>
        </p>
        <p class="text-sm text-neutral-600 dark:text-neutral-400 line-clamp-2">
          <template v-if="commentContent">
            {{ commentContent }}
          </template>
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import { parseTitleForDisplay } from "~/utils/display/title";
import { stripMarkdown } from "~/utils/ui/excerpt";
import { IconMessage } from "@tabler/icons-vue";
import { parseChapterForDisplay } from "~/utils/display/chapter";
import type { NamiNotificationCreatorCommentToChapter } from "~/src/notifications/types/nami/creator-comment-to-chapter";
import type { Props } from "../NotificationsItem.vue";
import type { OrganizationEntity, UserEntity } from "~/src/api";

const props = defineProps<
  {
    notification: NamiNotificationCreatorCommentToChapter;
  } & Props
>();

const { title } = parseTitleForDisplay(
  computed(() => props.notification.data.title),
);
const { shortName } = parseChapterForDisplay(
  computed(() => props.notification.data.chapter),
  "small",
  props.notification.data.title.attributes.chapterKind ?? undefined,
  props.notification.data.title.attributes.volumeKind ?? undefined,
);

const href = computed(() => {
  return linkTo(props.notification.data.chapter);
});

const commenterName = computed(() => {
  return props.notification.data.commenter.type === "user"
    ? getNameForUser(props.notification.data.commenter)
    : getNameForOrganization(props.notification.data.commenter);
});

const commentContent = computed(() => {
  const message = props.notification.data.comment.attributes.message;
  if (!message) return null;
  return stripMarkdown(message.replaceAll(/[\n]+/g, " "));
});

function getNameForUser(user: UserEntity) {
  return user.attributes.displayName ?? user.attributes.username;
}

function getNameForOrganization(organization: OrganizationEntity) {
  return organization.attributes.name;
}
</script>

<style>
.notification-avatar {
  /* We can only use px values on path() alas */
  clip-path: path("M 0 0 L 40 0 40 24 C 30,24 24,30 24,40 L 0 40 L 0 0 Z");
}
</style>

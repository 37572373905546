import { AxiosError } from "axios";
import { User } from "oidc-client-ts";
import { useAuthStore } from "~/stores/auth";
import { useSettingsStore } from "~/stores/settings";
import {
  type AvailableLanguages,
  type AvailableLocales,
  type ChapterEntity,
  type ChapterRelation,
  type CompetitionEntity,
  type CompetitionRelation,
  getRelationship,
  isAvailableLanguage,
  isAvailableLocale,
  isChapter,
  isCompetition,
  isCreator,
  isMember,
  isOrg,
  isPost,
  isTitleList,
  isUser,
  type LangCoded,
  type OrganizationEntity,
  type OrganizationRelation,
  type PopulateRelationship,
  type PostEntity,
  type PostRelation,
  type Role,
  type TitleAttributes,
  type TitleEntity,
  type TitleListEntity,
  type TitleListRelation,
  type TitleRelation,
  type UserEntity,
  type UserRelations,
} from "~/src/api";

export function arrayOfRandomNumbers(n: any, min: number, max: number) {
  let array = Array(n).fill(0);

  for (let i in array) {
    array[i] = Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return array;
}

export function nFormatter(num: number, digits?: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

interface ConvertStringParamsToObjectOptions {
  ignoreKeys?: string[];
}

type ParamsObject = {
  [key: string]:
    | string
    | string[]
    | number
    | number[]
    | null
    | null[]
    | boolean
    | boolean[]
    | { [deeperKey: string]: string };
};

export function convertStringParamsToObject<T = ParamsObject>(
  params: string,
  options?: ConvertStringParamsToObjectOptions,
) {
  const finalObject: ParamsObject = {};
  const decodedParams = decodeURIComponent(params);

  const reStr = /^[a-zA-Z]*$/;
  const reArr = /^[a-zA-Z]*\[\]$/;
  const reObj = /^[a-zA-Z]*\[([a-zA-z][a-zA-z]*)\]$/;

  const reInt = /^\d+$/;
  const reBool = /^(true|false)$/;

  (decodedParams.split("?")[1] || decodedParams).split("&").forEach((param) => {
    const [key, val] = param.split("=");

    if (options?.ignoreKeys?.includes(key)) return;
    else if (key === "") return;

    if (reStr.test(key)) {
      if (reInt.test(val)) {
        finalObject[key] = parseInt(val);
      } else if (reBool.test(val)) {
        finalObject[key] = val === "true";
      } else if (val === "null") {
        finalObject[key] = null;
      } else {
        finalObject[key] = val;
      }
    } else if (reArr.test(key)) {
      const arrKey = key.replace("[]", "");

      // Only for validation
      const field = finalObject[arrKey];

      if (typeof field === "object" && field && "length" in field) {
        (finalObject[arrKey] as string[]).push(val);
      } else {
        finalObject[arrKey] = [val];
      }
    } else if (reObj.test(key)) {
      const deeperKey = key.match(reObj)!.at(1)!;

      // Only for validation
      const field = finalObject[key];

      if (typeof field === "object" && field && "length" in field) {
        // @ts-ignore
        finalObject[key][deeperKey] = val;
      } else {
        finalObject[key] = { [deeperKey]: val };
      }
    }
  });

  return finalObject as T;
}

export function resolveError(err: any) {
  const error = {
    code: 404,
    title: "Unknown error",
    detail: "An unknown error occurred.",
  };

  if (isNuxtError(err)) {
    return {
      code: err.statusCode,
      title: err.name,
      detail: err.message || err.statusMessage,
    };
  } else if (err instanceof AxiosError) {
    if (err.code) error.code = parseInt(err.code);
    error.title = err.message;
    if (!err.response) {
      error.detail = `Could not get response from server. Error code: ${
        err.code ?? "UNKNOWN"
      }`;
    } else {
      const errorDetail = err.response.data?.errors?.at(0)?.detail;
      if (typeof errorDetail === "string") {
        error.detail = errorDetail;
      }
    }
  } else if (err instanceof Error) {
    error.title = err.name;
    error.detail = err.message;
    error.code = 520;
  }

  return error;
}

export function hasAdminAccess(user?: User | null) {
  if (user === null || !user) return false;
  const roles = user.profile.groups as Role[];

  const renamedGroupsToRole = roles
    .join(",")
    .replace(/GROUP/g, "ROLE")
    .split(",");

  return (
    renamedGroupsToRole.includes("ROLE_ADMIN") ||
    renamedGroupsToRole.includes("ROLE_DEVELOPER") ||
    renamedGroupsToRole.includes("ROLE_STAFF")
  );
}

export function extractFromLocale(
  locale: AvailableLanguages,
  coded: LangCoded,
) {
  return (
    coded[locale] ??
    coded["en"] ??
    coded[Object.keys(coded)[0] as AvailableLanguages] ??
    ""
  );
}

export function isMobile() {
  return typeof window === "undefined"
    ? false
    : (window.matchMedia("only screen and (max-width: 640px)").matches &&
        window.matchMedia("(any-pointer: coarse)").matches) ||
        window.matchMedia("(display-mode: standalone)").matches ||
        window.matchMedia("(display-mode: minimal-ui)").matches;
}

export async function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    console.error("Navigator clipboard is not accessible here.");
    return;
  }

  await navigator.clipboard.writeText(text);

  const appStore = useNuxtApp().$app();

  appStore?.notify({
    preset: "success.plain",
    timer: 3000,
    detail: "Copied to clipboard.",
  });
}

export async function copyJsonToClipboard(obj?: Object) {
  return await copyTextToClipboard(JSON.stringify(obj, undefined, 2));
}

export type FilteredLocales<T extends string> = T extends "es-419"
  ? "es-la"
  : T;

export type ReverseFilteredLocales<T extends string> = T extends "es-la"
  ? "es-419"
  : T;

export function reverseLocaleMap<T extends string>(
  locale: T,
): FilteredLocales<T> {
  switch (locale) {
    case "es-419":
      return "es-la" as FilteredLocales<T>;
    default:
      return locale as FilteredLocales<T>;
  }
}

export function localeMap<T extends string>(
  locale: T,
): ReverseFilteredLocales<T> {
  switch (locale) {
    case "es-la":
      return "es-419" as ReverseFilteredLocales<T>;
    default:
      return locale as ReverseFilteredLocales<T>;
  }
}

export type ResourceArgs = [
  type:
    | TitleEntity
    | TitleRelation
    | TitleListEntity
    | TitleListRelation
    | OrganizationEntity
    | PopulateRelationship<OrganizationRelation>
    | ChapterEntity
    | ChapterRelation
    | UserEntity
    | UserRelations
    | CompetitionEntity
    | CompetitionRelation
    | PostEntity
    | PopulateRelationship<PostRelation>,
];
export type LinkArgs = [
  type:
    | "title"
    | "title_list"
    | "org"
    | "chapter"
    | "user"
    | "contest"
    | "post"
    | "wp_news"
    | "conversation",
  id: string,
  slug?: string,
];

let locale: string = "en";

export function setDefaultLinkLocale(loc: string) {
  locale = loc;
}

export function linkTo(
  type:
    | TitleEntity
    | TitleRelation
    | TitleListEntity
    | TitleListRelation
    | OrganizationEntity
    | PopulateRelationship<OrganizationRelation>
    | ChapterEntity
    | ChapterRelation
    | UserEntity
    | UserRelations
    | CompetitionEntity
    | CompetitionRelation
    | PostEntity
    | PopulateRelationship<PostRelation>,
): string;

export function linkTo(
  type:
    | "title"
    | "title_list"
    | "org"
    | "chapter"
    | "user"
    | "contest"
    | "post"
    | "wp_news"
    | "conversation",
  id: string,
  slug?: string,
): string;

export function linkTo(...args: ResourceArgs | LinkArgs) {
  if (args.length === 1) {
    const resource = (args as ResourceArgs)[0];
    if (isChapter(resource)) {
      return `/${locale}/chapter/${resource.id}`;
    } else if (isTitleList(resource)) {
      return `/${locale}/list/${resource.id}`;
    } else if (
      isUser(resource) ||
      isMember(resource) ||
      isCreator(resource) ||
      resource.type === "leader"
    ) {
      return `/${locale}/user/${resource.attributes?.username}`;
    } else if (isCompetition(resource)) {
      return `/${locale}/contest/information/${resource.id}/${resource.attributes?.slug}`;
    } else if (isOrg(resource)) {
      return `/${locale}/org/${resource.attributes.slug}`;
    } else if (isPost(resource)) {
      return `/${locale}/post/${resource.id}`;
    } else if (resource.attributes) {
      return `/${locale}/${resource.type}/${resource.id}/${
        (resource.attributes as TitleAttributes).slug
      }`;
    } else {
      return `/${locale}/${resource.type}/${resource.id}`;
    }
  } else {
    const [type, id, slug] = args as LinkArgs;

    if (type === "contest") {
      return `/${locale}/contest/information/${id}` + (slug ? `/${slug}` : "");
    } else if (type === "wp_news") {
      return `/${locale}/news/article/${id}` + (slug ? `/${slug}` : "");
    } else if (type === "user") {
      return `/${locale}/user/${slug || id}`;
    } else if (type === "org") {
      return `/${locale}/org/${slug || id}`;
    } else if (type === "title_list") {
      return `/${locale}/list/${slug || id}`;
    } else if (type === "conversation") {
      return `/${locale}/messages/${id}`;
    } else {
      return `/${locale}/${type}/${id}` + (slug ? `/${slug}` : "");
    }
  }
}

export function linkToStudioChapter(chapter: ChapterEntity) {
  const orgId = getRelationship(chapter, "organization").id;

  return `/studio/${orgId}/chapter/${chapter.id}`;
}

export function isClickInsideElements(
  event: MouseEvent | TouchEvent,
  elements: Element[],
) {
  let tapX = 0;
  let tapY = 0;

  if (event instanceof MouseEvent) {
    tapX = event.clientX;
    tapY = event.clientY;
  } else {
    tapX = event.touches[0].clientX;
    tapY = event.touches[0].clientY;
  }

  for (const el of elements) {
    const rect = el.getBoundingClientRect();

    if (
      tapX <= rect.x + rect.width &&
      tapX >= rect.x &&
      tapY <= rect.y + rect.height &&
      tapY >= rect.y
    ) {
      return true;
    }
  }

  return false;
}

export function natSort(a: string | null, b: string | null) {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  return a.localeCompare(b, undefined, { numeric: true });
}

export async function getAllTranslationLocales() {
  const languages = await $fetch<{
    supportedLanguages: AvailableLanguages[];
  }>("/static/chapter/languages.json");

  const allLanguages = getLanguages();
  const keys = languages.supportedLanguages;
  return allLanguages.filter((lang) => keys.includes(lang.value));
}

export function getSafeLanguage(locale: any): AvailableLanguages {
  return isAvailableLanguage(locale) ? locale : "en";
}

export function getSafeLocale(locale: any): AvailableLocales {
  return isAvailableLocale(locale) ? locale : "en";
}

export function doLogin() {
  const authStore = useAuthStore();
  const route = useRoute();

  authStore.login({
    redirectBackTo: route.fullPath,
    locale: route.params.locale as string,
  });
}

export function updateTheme() {
  const settingsStore = useSettingsStore();

  const theme = settingsStore.settings.site.theme;
  if (
    (theme === "system" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches) ||
    theme === "dark"
  ) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
}

export function getAbsoluteAssetLink(path: string) {
  return `/assets/${path}`;
}

export function sum(array: Array<number>) {
  return array.reduce((partialSum: number, a: number) => partialSum + a, 0);
}

export function truncate(str: string, n: number) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export function formatDateAlt(str: string) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const objectDate = new Date(str);

  const month = months[objectDate.getMonth()];
  const year = objectDate.getFullYear();

  return `${month} ${year}`;
}

export function getLastURL(str: string) {
  const regexp = /(https?:\/\/[^\s|]*)/gi;
  const matches = str.matchAll(regexp);

  if (matches) {
    return Array.from(matches).reverse()[0];
  } else {
    return null;
  }
}

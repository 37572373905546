<template>
  <Teleport to="body">
    <div
      class="absolute flex z-[49] transition-opacity duration-0"
      :style="{
        left:
          overlayState.anchorX === 'right' ? undefined : `${bounding.left}px`,
        right:
          overlayState.anchorX === 'left'
            ? undefined
            : `${width - bounding.right - scrollBarSize.width}px`,
        top:
          overlayState.anchorY === 'bottom'
            ? undefined
            : `${bounding.top + scrollSnapshot.y}px`,
        bottom:
          overlayState.anchorY === 'top'
            ? undefined
            : `${height - bounding.bottom - scrollBarSize.height}px`,
        minWidth: `${bounding.width}px`,
        minHeight: `${bounding.height}px`,
        transitionDelay: isHidden
          ? `${overlayState.animation.hide.duration}ms`
          : undefined,
      }"
      :class="{
        'opacity-0 pointer-events-none': isHidden,
      }"
      @mouseleave="controls.hideOverlay()"
    >
      <template v-if="overlayState.current">
        <component
          :is="overlayState.current.component"
          v-bind="{
            ...overlayState.current.props,
          }"
        />
      </template>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useWindowScroll, useWindowSize } from "@vueuse/core";
import { useAppStore } from "~/stores/app";

type ToMutable<T extends object> = { -readonly [Key in keyof T]: T[Key] };

const props = defineProps<{
  overlayKey?: string;
}>();

defineEmits<{
  (e: "hide"): void;
}>();

const { width, height } = useWindowSize({ includeScrollbar: true });
const { x, y } = useWindowScroll();
const session = useSession();
const app = useAppStore();
const controls = useOverlayControls(props.overlayKey);
const overlayState = useOverlayState(props.overlayKey);

const scrollBarSize = computed(() => {
  if (app.state.offsetContentOnWebkitBecauseScrollIsDisabled) {
    return { width: 0, height: 0 };
  }

  return {
    width: session.value.device.scrollbarSize.width,
    height: session.value.device.scrollbarSize.height,
  };
});

const scrollSnapshot = reactive({
  x: 0,
  y: 0,
});

const overlayBaseTrackElement = computed(() => overlayState.value.el);

const bounding = ref<ToMutable<Omit<DOMRect, "toJSON">>>({
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  x: 0,
  y: 0,
  width: 0,
  height: 0,
});

const isHidden = computed(() => overlayState.value.state === "hide");

// Scroll values are reactive, but we don't want it to move while we scroll,
// so only take a snapshot of current scroll when it's about to be shown
watch(isHidden, (v) => {
  if (v) {
    return;
  }

  scrollSnapshot.y = y.value;
  scrollSnapshot.x = x.value;
});

provide("overlayIsHidden", isHidden);
provide("overlayRect", bounding);

let observer: ResizeObserver | undefined;
const cleanups: (() => void)[] = [];

function setupObserver(element: HTMLElement | SVGElement) {
  observer?.disconnect();

  observer = new ResizeObserver((items) => {
    const rect = items[0].target.getBoundingClientRect();
    bounding.value = rect.toJSON();
  });

  observer.observe(element);
}

onMounted(() => {
  const stopElementTrack = watch(overlayBaseTrackElement, (element) => {
    if (!element) {
      return;
    }

    const rect = element.getBoundingClientRect();
    bounding.value = rect.toJSON();
    setupObserver(element);
  });

  const stopWindowWidthTrack = watch(width, () => {
    if (!overlayBaseTrackElement.value) {
      return;
    }

    const rect = overlayBaseTrackElement.value.getBoundingClientRect();
    bounding.value = rect.toJSON();
  });

  cleanups.push(() => stopElementTrack());
  cleanups.push(() => stopWindowWidthTrack());
  cleanups.push(() => observer?.disconnect());
});

onBeforeUnmount(() => cleanups.forEach((cleanup) => cleanup()));
</script>
